import React, {Component} from 'react';
import AddParentDOM from './add-parent-dom';
import AddPartnerDOM from './add-partner-dom';
import AddSiblingDOM from './add-sibling-dom';
import AddChildrenDOM from './add-children-dom';
import { oneClickAddDropdowns, oneClickAddActionTypes } from './pedigree-constants';

class OneClickAdd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show_children_dropdown: false,
      show_partner_dropdown: false,
      show_sibling_dropdown: false
    };

    this.canAddParents = this.canAddParents.bind(this);
    this.canAddSibling = this.canAddSibling.bind(this);
    this.canAddPartner = this.canAddPartner.bind(this);
    this.canAddChildren = this.canAddChildren.bind(this);

    this.onClickAdd = this.onClickAdd.bind(this);
    this.onDropdownClicked = this.onDropdownClicked.bind(this);

    this.addParents = this.addParents.bind(this);
    this.addChildren = this.addChildren.bind(this);
    this.addPartner = this.addPartner.bind(this);
    this.addSiblings = this.addSiblings.bind(this);

    this.api_call_inprogress = false;
  }

  canAddParents() {
    // // Member can add parents if it does not have yet one
    const profile = this.props.profile;

    let donors = this.props.data.getPedigreeData().getDonors();
    let is_donor = donors.find(donor => donor.donor_id + '' == profile.id + '')
    if(is_donor && is_donor.is_random_donor){
      return false;
    }

    return profile.is_blood_related_to_proband && !profile.father_id && !profile.mother_id;
  }

  canAddSibling() {
    const profile = this.props.profile;

    let donors = this.props.data.getPedigreeData().getDonors();
    let is_donor = donors.find(donor => donor.donor_id + '' == profile.id + '')
    if(is_donor && is_donor.is_random_donor){
      return false;
    }

    return profile.is_blood_related_to_proband && profile.father_id && profile.mother_id;
  }

  canAddPartner() {
    const profile = this.props.profile;

    if(profile.is_abortion_node){
      return false;
    }

    let donors = this.props.data.getPedigreeData().getDonors();
    let is_donor = donors.find(donor => donor.donor_id + '' == profile.id + '')
    if(is_donor && is_donor.is_random_donor){
      return false;
    }
    return profile.is_blood_related_to_proband;
  }

  canAddChildren() {
    const profile = this.props.profile;
    if(profile.partners.length > 1){
      return true;
    }

    let donors = this.props.data.getPedigreeData().getDonors();
    let is_donor = donors.find(donor => donor.donor_id + '' == profile.id + '')
    if(is_donor && is_donor.is_random_donor){
      return false;
    }

    let profiles = this.props.data.getPedigreeData().getAllProfiles()
    let people = Object.values(profiles)
    let has_no_children_or_infertility_children = false;
    let either_parents_is_infertile_or_no_children = false;
    for(let partner of profile.partners){
      let children = people.filter(person => (person.father_id === partner.id && person.mother_id === profile.id) || ((person.mother_id === partner.id && person.father_id === profile.id)))
      let no_children_infertility_nodes = children.filter(child => child.is_infertility_node || child.is_no_children_node)
      let parent_1 = people.find(person => person.id == profile.id)
      let parent_2 = people.find(person => person.id == partner.id)

      if(no_children_infertility_nodes.length > 0){
        has_no_children_or_infertility_children = true;
      }

      if(parent_1 && parent_2){
        if(parent_1.no_children || parent_1.infertile || parent_2.no_children || parent_2.infertile){
          either_parents_is_infertile_or_no_children = true;
        }
      }

    }
    if(has_no_children_or_infertility_children || either_parents_is_infertile_or_no_children){
      return false;
    }

    if(profile.is_abortion_node){
      return false;
    }

    if (this.props.proband_id === profile.id+""
      || this.props.proband_mother_id === profile.id+""
      || this.props.proband_father_id === profile.id+""
      || this.props.proband_paternal_grandfather_id === profile.id+""
      || this.props.proband_paternal_grandmother_id === profile.id+""
      || this.props.proband_maternal_grandfather_id === profile.id+""
      || this.props.proband_maternal_grandmother_id === profile.id+""
      || !profile.is_blood_related_to_proband
      || profile.is_blood_related_to_proband
      ) {
        return true;
    }
    return false;
  }

  canAddNoChildrenOrInfertility(){
    const profile = this.props.profile;
    if(profile.partners.length > 1){
      return true;
    }
    let profiles = this.props.data.getPedigreeData().getAllProfiles()
    let people = Object.values(profiles)
    let has_children = people.filter(person => person.father_id === profile.id || person.mother_id === profile.id)
    if(has_children.length > 0){
      return false;
    }
    return true;
  }

  onClickAdd(type, num_to_add) {
    const actions = oneClickAddActionTypes;
    switch (type) {
      case actions.PARENTS:
        this.addParents();
        break;
      case actions.SON:
      case actions.DAUGHTER:
      case actions.UNKNOWN:
      case actions.NO_CHILDREN:
      case actions.INFERTILITY:
      case actions.SAB:
      case actions.MALE_FETAS:
      case actions.FEMALE_FETAS:
      case actions.PREGNANCY_UNKNOWN:
        this.addChildren(type, num_to_add);
        break;
      case actions.SAME_SEX:
      case actions.MARRIED:
      case actions.SEPARATED:
      case actions.DIVORCED:
      case actions.OTHER:
      case actions.CONSANGUINEOUS:
        this.addPartner(type);
        break;
      case actions.SIBLING_SON:
      case actions.SIBLING_DAUGHTER:
      case actions.SIBLING_UNKNOWN:
      case actions.SIBLING_TWIN_BROTHER:
      case actions.SIBLING_TWIN_SISTER:
      case actions.SIBLING_TWIN_UNKNOWN:
        this.addSiblings(type, num_to_add);
        break;
    }
  }

  onDropdownClicked(dropdown) {
    const dropdowns = oneClickAddDropdowns;

    let show_children_dropdown = false;
    let show_partner_dropdown = false;
    let show_sibling_dropdown = false;

    if (dropdown === dropdowns.ADD_CHILDREN) {
      show_children_dropdown = !this.state.show_children_dropdown;
    } else if (dropdown === dropdowns.ADD_PARTNER) {
      show_partner_dropdown = !this.state.show_partner_dropdown;
    } else if (dropdown === dropdowns.ADD_SIBLING) {
      show_sibling_dropdown = !this.state.show_sibling_dropdown;
    }

    this.setState({
      show_children_dropdown: show_children_dropdown,
      show_partner_dropdown: show_partner_dropdown,
      show_sibling_dropdown: show_sibling_dropdown
    });
  }

  async addParents() {
    if (!this.api_call_inprogress) {
      this.api_call_inprogress = true;
      const profile = this.props.profile;
      await this.props.onAddParents(profile);
      this.api_call_inprogress = false;
    }
  }

  async addChildren(type, num_to_add) {
    if (!this.api_call_inprogress) {
      this.api_call_inprogress = true;
      const profile = this.props.data.getPedigreeData().getProfile(this.props.profile.rkey)
      if('relationship_ids' in profile && profile.relationship_ids.length === 0) {
        await this.props.onCreatePartnerWtihChildren(profile, type, num_to_add)
      } else {
        await this.props.onAddChildren(profile, type, num_to_add);
      }
      this.api_call_inprogress = false;
    }
  }

  async addPartner(type) {
    if (!this.api_call_inprogress) {
      this.api_call_inprogress = true;
      const profile = this.props.profile;
      await this.props.onAddPartner(profile, type, false, false);
      this.api_call_inprogress = false;
    }
  }

  async addSiblings(type, num_to_add) {
    if (!this.api_call_inprogress) {
      this.api_call_inprogress = true;
      const profile = this.props.profile;
      await this.props.onAddSiblings(profile, type, num_to_add);
      this.api_call_inprogress = false;
    }
  }

  render() {
    const parentTop = "-28px";
    const parentLeft = "0px";

    const partnerTop = "10px";
    const partnerLeft = "-50px";

    const siblingTop = "10px";
    const siblingLeft = "46px";

    const childrenTop = "46px";
    const childrenLeft = "-24px";

    return (
      <>
        {this.canAddParents() && (
          <AddParentDOM
            top={parentTop}
            left={parentLeft}
            onClickAdd={this.onClickAdd}
          />
        )}
        {this.canAddPartner() && (
          <AddPartnerDOM
            top={partnerTop}
            left={partnerLeft}
            gender={this.props.gender}
            showDropdown={this.state.show_partner_dropdown}
            onClickAdd={this.onClickAdd}
            onDropdownClicked={(dropdown) => this.onDropdownClicked(dropdown)}
          />
        )}
        {this.canAddSibling() && (
          <AddSiblingDOM
            top={siblingTop}
            left={siblingLeft}
            showDropdown={this.state.show_sibling_dropdown}
            onClickAdd={this.onClickAdd}
            onDropdownClicked={(dropdown) => this.onDropdownClicked(dropdown)}
          />
        )}
        {this.canAddChildren() && (
          <AddChildrenDOM
            top={childrenTop}
            left={childrenLeft}
            showDropdown={this.state.show_children_dropdown}
            onClickAdd={this.onClickAdd}
            onDropdownClicked={(dropdown) => this.onDropdownClicked(dropdown)}
            canAddNoChildrenOrInfertility={() => this.canAddNoChildrenOrInfertility()}
          />
        )}
      </>
    );

  }
}

export default OneClickAdd;
