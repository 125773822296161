const VALID_LOGO_MIME_TYPES = [
    'image/jpeg',                                                                  // JPEG images
    'image/png',                                                                   // Portable Network Graphics
];

const VALID_USER_DOC_MIME_TYPES = [
    'application/pdf',                                                             // Adobe Portable Document Format (PDF)
    'text/plain',                                                                  // Text, (generally ASCII or ISO 8859-n)
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',    // Microsoft Word (OpenXML)
    'application/msword',                                                          // Microsoft Word
    'image/jpeg',                                                                  // JPEG images
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',           // Microsoft Excel (OpenXML)
    'application/vnd.ms-excel',                                                    // Microsoft Excel
    'image/png',                                                                   // Portable Network Graphics
];

export default  {
  VALID_LOGO_MIME_TYPES,
  VALID_USER_DOC_MIME_TYPES
}
