import sdk from './sdk';

const get_user_files = async (member_id) => {
  try {
    let url = process.env.REACT_APP_ROOT_API_URL + '/s3files/';
    let response = await sdk.request('get', url, {member_id: member_id});

    let data = response.data;
    return data;
  } catch (err) {
    throw sdk.parse_error_message(err);
  }
}

const get_org_files = async (org_id) => {
  try {
    let url = process.env.REACT_APP_ROOT_API_URL + '/s3files/';
    let response = await sdk.request('get', url, {org_id: org_id});

    let data = response.data;
    return data;
  } catch (err) {
    throw sdk.parse_error_message(err);
  }
}

const download_user_file = async (uuid_filename) => {
  try {

    let url = process.env.REACT_APP_ROOT_API_URL + '/s3/download/';
    let response = await sdk.request('get', url, {
      uuid_filename: uuid_filename
    });

    return response;
  } catch (err) {
    return {
      error: {
        msg: 'Download Failed'
      }
    };
  }
}

const upload_user_file = async (payload) => {
  try {

    let url = process.env.REACT_APP_ROOT_API_URL + '/s3/upload/';
    let response = await sdk.request('post', url, payload);

    return response;
  } catch (err) {
    return {
      error: {
        msg: 'Upload Failed'
      }
    };
  }
}

const delete_user_file_from_db = async (file_id) => {
  try {

    let url = process.env.REACT_APP_ROOT_API_URL + `/s3files/${file_id}/`;
    let response = await sdk.request('delete', url);

    let data = response.data;
    return data;

  } catch (err) {
    return {
      error: {
        msg: 'Delete Failed'
      }
    };
  }
}

const update_user_file_in_db = async (file_id, payload) => {
  try {

    let url = process.env.REACT_APP_ROOT_API_URL + `/s3files/${file_id}/`;
    let response = await sdk.request('patch', url, payload);

    return response;
  } catch (err) {
    return {
      error: {
        msg: 'Update Failed'
      }
    };
  }
}

const upload_org_logo = async (payload) => {
  try {

    let url = process.env.REACT_APP_ROOT_API_URL + '/s3/upload_clinician_logo/';
    let response = await sdk.request('post', url, payload);

    return response;
  } catch (err) {
    return {
      error: {
        msg: 'Upload Failed'
      }
    };
  }
}

const delete_user_file_from_aws = async (uuid_filename) => {
  try {

    let url = process.env.REACT_APP_ROOT_API_URL + '/s3/delete_file/';
    let response = await sdk.request('post', url, {
      uuid_filename: uuid_filename
    });

    return response;
  } catch (err) {
    return {
      error: {
        msg: 'Delete Failed'
      }
    };
  }
}

export default {
  parse_error_message: sdk.parse_error_message,
  get_user_files,
  get_org_files,
  download_user_file,
  upload_user_file,
  delete_user_file_from_db,
  update_user_file_in_db,
  upload_org_logo,
  delete_user_file_from_aws,
}
