import React, { Component } from 'react';
import { connect } from "react-redux"
import moment from 'moment'
import ErrorSummary from './error-summary';
import { radix_bases } from '../helpers/helper-number-bases';
import family_api from '../api/family-api'

class ModalDeceased extends Component {

  constructor(props) {
    super(props)
    this.state = {
      age: null,
      cause_of_death: '',
      yob: '',
      profile: null,
      errorMessages: []
    }
  }

  componentDidMount() {
    let yob = this.getYearOfBirth()
    this.props.profile.is_dead = true;
    this.setState({
      age: this.props.age_string,
      cause_of_death: this.props.cause_of_death,
      yob: yob,
      profile: this.props.profile
    })
  }

  getYearOfBirth() {
    if(this.props.yob !== null) return this.props.yob

    if(this.props.dob !== null) return moment(this.props.dob).year()

    return ''
  }

  async onClickSave() {
    try {
      await this.validate();
      let payload = this.mapPayload(this.state);
      this.props.onOk(payload);
      this.props.onClose();
    } catch (error) {
      this.setState({ errorMessages: [error.message] });
    }
  }

  mapPayload(current_state) {
    let payload = {};
    if(current_state.yob === '' || current_state.yob === undefined) {
      payload.yob = null;
    } else {
      payload.yob = current_state.yob;
    }
    payload.age = current_state.age;
    payload.cause_of_death = current_state.cause_of_death;

    return payload;
  }

  onChange(field, value) {

    this.setState({[field]: value})
  }

  async validate() {
    // Validate YOB
      let yob = this.state.yob
      if(yob !== null && yob !== '') {
        yob = yob.toString().trim();
        if(yob.length !== 4) {
          throw new Error('Invalid year of birth (year must be 4 characters).');
        }

        if(parseInt(yob, radix_bases.base10) > moment().year()) {
          throw new Error('Invalid year of birth (future year is not allowed).');
        }
      }

    let profile = this.state.profile;

    //Validate deceased date
    if(profile.relationship_data){
      let children = await family_api.get_members_memberid_children(profile.relationship_data.mother_id, profile.relationship_data.father_id)
      let childrenWithYob = children.filter(child => (child.yob !== null && child.yob !== undefined && !isNaN(child.yob)))
      if(childrenWithYob.length !== 0){
        if(profile.is_dead && this.state.yob && this.state.age){
          let highestYobAmongChildren = childrenWithYob.sort((a, b) => Number(b.yob) - Number(a.yob));
          highestYobAmongChildren = highestYobAmongChildren[0]
          if(Number(this.state.age) + Number(this.state.yob) < Number(highestYobAmongChildren.yob)){
            throw new Error("Deceased date is before child/children's date of birth.")
          }
        }
      }
    }

    if(profile.mother_id !== undefined){
      let parents = await family_api.get_member_memberid_parents(profile.id)
      if(parents.mother.is_dead && parents.mother.yob && parents.mother.age && this.state.yob){
        if(Number(this.state.yob) > Number(parents.mother.yob) + Number(parents.mother.age)){
          throw new Error("Birth date is after mother's deceased date.")
        }
      }
    }

  }

  render() {
    let self = this
    return (

      <div role="dialog"
        onClick={() => this.props.onClose()}
        style={{ display: ((this.props.isOpen) ? 'block' : 'none') }}
        className={"modal fade " + ((this.props.isOpen) ? 'in' : '')}>


        <div className="modal-dialog" role="document"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="modal-content">
            <div className="modal-header">
              <button
                onClick={() => this.props.onClose()}
                type="button" className="close" data-dismiss="modal" aria-label="Close">
                <i className="fa fa-close"></i>
              </button>
              <h4 className="modal-title text-white text-capitalize">Deceased</h4>
            </div>
            <div className="modal-body">

              <ErrorSummary errorMessages={this.state.errorMessages} />

                    <div className="row">
                        <div className="col-lg-12">
                            <div className="form-horizontal form-custom">
                                <div className="form-group">
                                    <label className="control-label col-md-3 col-sm-3 col-xs-12">Age at death</label>
                                    <div className=" col-md-2 col-sm-9 col-xs-12">
                                        <input
                                          value={this.state.age}
                                          onChange={(e) => this.onChange('age', e.target.value)}
                                          type="text" className="form-control normal-input-text" />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="control-label col-md-3 col-sm-3 col-xs-12">Cause of death</label>
                                    <div className="col-md-9 col-sm-9 col-xs-12">
                                        <input
                                          value={this.state.cause_of_death}
                                          onChange={(e) => this.onChange('cause_of_death', e.target.value)}
                                          type="text" className="form-control normal-input-text" />
                                    </div>
                                </div>

                                <div className="form-group">
                                    <label className="control-label col-md-3 col-sm-3 col-xs-12">Year of birth</label>
                                    <div className="col-md-9 col-sm-9 col-xs-12">
                                        <input
                                          value={this.state.yob}
                                          onChange={(e) => this.onChange('yob', e.target.value)}
                                          type="text" className="form-control normal-input-text" />
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>



            </div>
            <div className="modal-footer">
              <button
                onClick={() => this.props.onClose()}
                type="button" className="btn btn-light-outline no-margin-right" data-dismiss="modal">Cancel</button>
              <button
                onClick={() => this.onClickSave()}
                type="button" className="btn btn-dark" data-toggle="modal" data-target="#AddTwinError">Save</button>
            </div>
          </div>
        </div>
      </div>



    )
  }

}

const redux_state = state => ({
  ...state
})

const redux_actions = dispatch => ({
  dispatch: (action) => dispatch(action)
})

export default connect(redux_state, redux_actions)(ModalDeceased);
