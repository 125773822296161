export const systemFields = [
    'rs',
    'gay',
    'gen',
    'child',
    'shadow',
    'px1',
    'px2',
    'py2',
    'py1',
    'tree',
    'motherid',
    'noissue',
    'degree3',
    'degree2',
    'dagger',
    'proband',
    'pregnancy',
    'sibcount',
    'sibling',
    'genotyped',
    'sab',
    'rstype',
    'sx2',
    'sx1',
    'sy1',
    'sy2',
    'bx1',
    'bx2',
    'degree',
    'by2',
    'by1',
    'lstype',
    'indname',
    'rrn',
    'ind_created_by',
   'tree',
   'geno_itemtype',
    'x',
    'y',
    'cx2',
    'cx1',
    'cy1',
    'cy2',
    'geno_itemid',
    'main',
    'personnumber',
    'deceased',
    'icontext',
    'icontext2',
    'twinstyle',
    'color',
    'importno',
    'includelinkage',
    'globalid',
    'lp',
    'ls',
    'sexunknown',
    'op',
    'os',
    'adopt',
    'rp',
]