import React, { Component } from 'react';
import ReactDOM from 'react-dom';

interface State {

}
interface Props {
    onClose: () => void;
}
const QueryTimeoutModal: React.FC<Props> = ({onClose}) =>{


    return ReactDOM.createPortal(
        <div
            onClick={() => onClose()}
            style={{ display: 'block' }}
            className="modal fade in"
            role="dialog"
        > 
        <div onClick={(e) => e.stopPropagation()} className="modal-dialog modal-sm" role="document">
            <div className="modal-content">
                <div className="modal-header">
                    <button onClick={() => onClose()} type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <i className="fa fa-close"></i>
                    </button>
                    <h4 className="modal-title">Query Timeout</h4>
                </div>
                <div className="modal-body">
                    <p>Seems like the query is taking longer than expected. </p>
                    <p>Try again or contact support.</p>
        
                    <b>support@famgenix.com</b>
                </div>
                <div className="modal-footer" style={{textAlign: 'center'}}>
              <button onClick={() => onClose()} className="btn btn-light-outline no-margin-right">Close</button>
            </div>
            </div>
          
        </div>
        </div>,
      document.body
    );
}

export default QueryTimeoutModal