import React, { Component } from "react";
import ErrorSummary from "../error-summary";
import ActivityIndicator from "../activity-indicator";
import * as helper_error from "../../helpers/helper-error";
import notifications_api from "../../api/notifications-api";
import moment from "moment";
import ModalError from "../modal-error";

class NotificationConnectionRequest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorMessages: [],
      notifications: [],
      completedDisplayCount: 2,
      loading: false,
      invite_requests_count: 0,
      accepted_request_: 0,
      successMessage: false,
      show_pedigree_limit_error_modal: false,
    };

    this.handleAccept = this.handleAccept.bind(this);
    this.handleDecline = this.handleDecline.bind(this);
    this.handleShowMoreClick = this.handleShowMoreClick.bind(this);
    this.dismissNotifications = this.dismissNotifications.bind(this);
    this.getInviteRequestsCount = this.getInviteRequestsCount.bind(this);
    this.acceptAllFromInvites = this.acceptAllFromInvites.bind(this);
    this.fetchNotifications = this.fetchNotifications.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
  }

  async componentDidMount() {
    await this.fetchNotifications();
  }

  async fetchNotifications() {
    let includeUnopenedFamilies = true
    try {
      let notifications = await notifications_api.get_clinician_notifications(
        this.props.clinician_id,
        ["pending", "completed", "archived"],
        ["clinician_request"],
        includeUnopenedFamilies
      );
      await this.getInviteRequestsCount()

      this.setState({ notifications: notifications });
    } catch (error) {
      console.log(error);
    }
  }

  async getInviteRequestsCount(){
    try {
      let count = await notifications_api.invite_requests_count();
      this.setState({ invite_requests_count: count});
    } catch (error) {
      console.log(error);
    }
  }

  async handleAccept(notification) {
    if(this.state.loading) return;
    await this.accept_or_deny(notification.id, "accept");
    this.props.refreshList()
  }

  async handleDecline(notification) {
    if(this.state.loading) return;
    await this.accept_or_deny(notification.id, "deny");
  }

  async accept_or_deny(notification_id, response) {
    try {
      this.setState({ loading: true });
      let clinician_id = this.props.clinician_id;
      let user_id = this.props.clinician_user_id;
      let payload = { clinician_id, response, user_id };
      let result = await notifications_api.post_notification_notificationid_response(
        notification_id,
        payload
      );

      await this.fetchNotifications();
      await this.props.fetchNotificationCounts();

    } catch (error) {
      if(error.message == 'Maximum pedigrees for this organization has been reached.'){
        this.setState({ show_pedigree_limit_error_modal: true });
      }
      else{
        this.setState({ errorMessages: [error.message] });
      }
      helper_error.log_error(this.constructor.name, error);
    } finally {
      this.setState({ loading: false });
    }
  }

  handleShowMoreClick() {
    let completedDisplayCount = this.state.completedDisplayCount;
    completedDisplayCount += 10;
    this.setState({completedDisplayCount})
  }

 async dismissNotifications(){
    try{

      this.setState({ loading: true });
      let clinician_id = this.props.clinician_id;
      await notifications_api.post_notification_notification_dismiss_connection_request({clinician_id});
      await this.fetchNotifications();

    } catch (error) {
      this.setState({ errorMessages: [error.message] });
      helper_error.log_error(this.constructor.name, error);
    } finally {
      this.setState({ loading: false });
    }
  }

async acceptAllFromInvites(){

  try{
    this.setState({loading: true})
    let response = await notifications_api.accept_all_invites_requests();
    this.setState({successMessage: true, accepted_request_count: response['AcceptedCount']})

    setTimeout(() => {
      this.setState({successMessage: false, accepted_request_count: 0})
     }, 3000)

  }catch(err){
    if(err.message == 'Maximum pedigrees for this organization has been reached.'){
      this.setState({ show_pedigree_limit_error_modal: true });
    }
    else{
      this.setState({ errorMessages: [err.message] });
    }
    helper_error.log_error(err)
  }finally {
    this.handleUpdate()
    this.setState({ loading: false });
    }
  }

 async  handleUpdate(){
    await this.fetchNotifications();
    await this.props.fetchNotificationCounts();
    this.props.refreshList()
  }

  render() {
    let notifications_pending = this.state.notifications.filter(item => item.status == 'pending');
    notifications_pending.sort((a,b)=> Date.parse(b.created_at) - Date.parse(a.created_at))

    let notifications_completed = this.state.notifications.filter(item => item.status == 'completed');
    let notifications_completed_display = notifications_completed.slice(0, this.state.completedDisplayCount);
    notifications_completed_display.sort((a,b)=> Date.parse(b.modified_at) - Date.parse(a.modified_at))

    let display_accepted_success = this.state.successMessage && this.state.accepted_request_count > 0
    let display_accept_all_linked = this.state.invite_requests_count > 0 && !this.state.successMessage
    // clinician_requests.sort((a,b) => moment(b.modified_at).diff(moment(a.modified_at)));

    return (
      <div className="tab-pane fade in active">
        <ErrorSummary errorMessages={this.state.errorMessages} />

        {this.state.show_pedigree_limit_error_modal && (
          <ModalError
            pedigree_limit_error={true}
            message="You have reached the limit of pedigrees for a free account. Please contact us to upgrade."
            onClose={() => this.setState({ show_pedigree_limit_error_modal: false })}
          />
        )}

        <ActivityIndicator loading={this.state.loading} />
        {display_accept_all_linked && (
          <div className=" margin-four-bottom">
            <a className="link" onClick={this.acceptAllFromInvites}>Accept all linked requests ({this.state.invite_requests_count})<i></i></a>
          </div>
        )}
        {display_accepted_success && (
          <div class="alert alert-success" role="alert">
            {this.state.accepted_request_count} requests accepted
          </div>
        )}

        { notifications_pending.map((notification, index) => (
          <NotificationRequestItem
            key={index}
            data={notification}
            loading={this.state.loading}
            onAccept={this.handleAccept}
            onDecline={this.handleDecline}
          />
        ))}

        { notifications_completed.length > 0 &&
          <div className=" margin-four-bottom">
            <a className="link" onClick={this.dismissNotifications}>Dismiss all<i></i></a>
          </div>
        }

        {notifications_completed_display.map((notification, index) => (
          <NotificationCompletedItem
            key={index}
            data={notification}
          />
        ))}

        { notifications_completed.length > this.state.completedDisplayCount &&
          <div className=" margin-four-bottom">
            <a onClick={this.handleShowMoreClick} className="link">Show More <i className="fa fa-angle-down"></i></a>
          </div>
        }

      </div>
    );
  }
}

/*
| - - - - - - - - -
| Child functional components
|
*/

const NotificationRequestItem = props => {
  let data = props.data;
  let options = {year: 'numeric', month: 'long', day: 'numeric' };
  let header = data.message_header;
  let body = data.message_body.split("\n");
  let dob = body[0].replace("DOB:", "").trim();
  dob = moment(dob).format("MM/DD/YYYY");
  let ref = body[1];
  let created_date = new Date(data.created_at).toLocaleDateString(undefined, options)
  let created_time = moment(data.created_at).format("H:mm A");

  let disabled_button = props.loading ? 'disabled' : ''
  return (
    <div className="notif-item">
      <p>
        <strong>{header}</strong>
      </p>
      <p>DOB: {dob}</p>
      <p>{ref}</p>
      <p>
        <span className="date">{created_date}</span>&nbsp;
        <span className="margin-two-right time">{created_time}</span>
      </p>
      <div className="margin-two">
        <a
          className={"btn-teal--outline btn btm-sm no-margin-right " + disabled_button}
          onClick={() => props.onAccept(data)}
        >
          Accept
        </a>
        &nbsp;
        <a
          className={"btn-light--outline btn btm-sm " + disabled_button}
          onClick={() => props.onDecline(data)}
        >
          Decline
        </a>
      </div>
    </div>
  );
};

const NotificationCompletedItem = props => {
  let data = props.data;
  let options = {year: 'numeric', month: 'long', day: 'numeric' };
  let header = data.message_header;
  let body = data.message_body.split("\n");
  let dob = body[0].replace("DOB:", "").trim();
  dob = moment(dob).format("MM/DD/YYYY");
  let ref = body[1];
  let created_date = new Date(data.created_at).toLocaleDateString(undefined, options)
  let created_time = moment(data.created_at).format("H:m A");

  let completed_text = data.response == 'deny' ? 'declined' : 'accepted';
  let completed_date = moment(data.modified_at).format("MMMM DD, YYYY");
  let completed_time = moment(data.modified_at).format("H:m A");

  let user_fullname = data.user !== null ? (data.user.first_name + " " + data.user.last_name) : "";

  return (
    <div className="notif-item">
      <a className="del">
        <i className="fa fa-trash"></i>
      </a>

      <p>
        <strong>{header}</strong>
      </p>
      <p>DOB: {dob}</p>
      <p>{ref}</p>
      <p>
        <span className="date">{created_date}</span>&nbsp;
        <span className="margin-two-right time">{created_time}</span>
      </p>
      <p>
        <span className="title">{user_fullname} {completed_text}</span>&nbsp;
        <span className="date">{completed_date}</span>&nbsp;
        <span className="margin-two-right time">{completed_time}</span>
      </p>
    </div>
  );
};

export default NotificationConnectionRequest;
