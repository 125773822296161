import sdk from './sdk';

const generateRegistrationOptionsWebAuthn = async (body: object) => {
  try {
    let url: string = process.env.REACT_APP_ROOT_API_URL + '/authenticator/generate_registration_options/';
    let response = await sdk.request('post', url, body);

    if (response instanceof Object) return response.data;
    return {};
  } catch (err) {
    throw sdk.parse_error_message(err);
  }
};

const verifyRegistrationOptionsWebAuthn = async (body: object) => {
  try {
    let url: string = process.env.REACT_APP_ROOT_API_URL + '/authenticator/verify_registration_response/';
    let response = await sdk.request('post', url, body);

    if (response instanceof Object) return response.data;
    return {};
  } catch (err) {
    throw sdk.parse_error_message(err);
  }
};

const generateAuthenticationOptionsWebAuthn = async () => {
  try {
    let url: string = process.env.REACT_APP_ROOT_API_URL + '/authenticator/generate_authentication_options/';
    let response = await sdk.request('post', url);

    if (response instanceof Object) return response.data;
    return {};
  } catch (err) {
    throw sdk.parse_error_message(err);
  }
};

const verifyAuthenticationOptionsWebAuthn = async (body: object) => {
  try {
    let url: string = process.env.REACT_APP_ROOT_API_URL + '/authenticator/verify_authentication_response/';
    let response = await sdk.request('post', url, body);

    if (response instanceof Object) return response.data;
    return {};
  } catch (err) {
    throw sdk.parse_error_message(err);
  }
};

const verifyAuthenticationOptionsWebAuthn2FactorFlow = async (body: object) => {
  try {
    let url: string = process.env.REACT_APP_ROOT_API_URL + '/authenticator/verify_authentication_response_2factor_flow/';
    let response = await sdk.request('post', url, body);

    if (response instanceof Object) return response.data;
    return {};
  } catch (err) {
    throw sdk.parse_error_message(err);
  }
};

const getWebauthnCredentials = async () => {
  try {
    let url: string = process.env.REACT_APP_ROOT_API_URL + '/authenticator/get_credentials/';
    let response = await sdk.request('get', url);

    if (response instanceof Object) return response.data;
    return [];
  } catch (err) {
    throw sdk.parse_error_message(err);
  }
};

const deleteWebauthnCredentials = async (payload: object) => {
  try {
    let url: string = process.env.REACT_APP_ROOT_API_URL + '/authenticator/delete_credential/';
    let response = await sdk.request('post', url, payload);

    if (response instanceof Object) return response.data;
    return [];
  } catch (err) {
    throw sdk.parse_error_message(err);
  }
};

export {
	generateRegistrationOptionsWebAuthn,
	verifyRegistrationOptionsWebAuthn,
  generateAuthenticationOptionsWebAuthn,
  verifyAuthenticationOptionsWebAuthn,
  verifyAuthenticationOptionsWebAuthn2FactorFlow,
  getWebauthnCredentials,
  deleteWebauthnCredentials
};
