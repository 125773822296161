import React, { Component } from 'react';
import skip_logic_map from '../assets/json/diseases-skip-logic-map.json';
const keys_mapping = skip_logic_map.uterine

class SkipLogicUterineView extends Component {

  getValue(key) {
    if(!(key in keys_mapping)) return null

    let code = keys_mapping[key]
    let data = this.props.skipLogics.find(item => item.skip_logic_code == code)

    if(typeof(data) === 'undefined') return null
    return data.answer
  }

  // msiText() {
  //   let sl = this.props.skipLogic;
  //   if(sl.msi == null) return ''
  //   if(sl.msi.value=='' || sl.msi.value=='1' ) return ''

  //   return sl.msi.label + ', '
  // }

  // show(sl, key) {
  //   return (sl[key] != null && sl[key].value != '' && sl[key].value != '1')
  // }

  render() {
    let sl = this.props.skipLogics;
    if(sl == null) return null;

    let msi = this.getValue('msi');
    let mlh1_ihc = this.getValue('mlh1_ihc');
    let msh2_ihc = this.getValue('msh2_ihc');
    let msh6 = this.getValue('msh6');
    let pms2 = this.getValue('pms2');
    let mhl1_hyper = this.getValue('mhl1_hyper');

    return (
      <React.Fragment >
        <p>
          {msi && 'MSI-' + msi + ','}

          {mlh1_ihc &&
            'MLH1 IHC-' + mlh1_ihc + ', '
          }

          { msh2_ihc &&
            'MSH2 IHC-' + msh2_ihc
          }

        </p>
        <p>

          {msh6 &&
            'MSH6 IHC-' + msh6 + ', '
          }

          {pms2 &&
            'PMS2 IHC-' + pms2
          }

        </p>
        <p>

          { mhl1_hyper &&
            'MLH1 hypermethylation-' + mhl1_hyper
          }

        </p>
      </React.Fragment>
    )
  }

}

export default SkipLogicUterineView