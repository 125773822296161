import sdk from './sdk';

const get_genetic_testing = async () => {
  try {

    let url = process.env.REACT_APP_ROOT_API_URL + '/genetic_testing/';
    let response = await sdk.request('get', url);

    let data = response.data;
    return data;

  } catch (err) {
    throw sdk.parse_error_message(err)
  }
}

const get_umls_genetic_testing = async (clinician_id) => {
  try {

    let url = process.env.REACT_APP_ROOT_API_URL + '/member_genetic_testing/get_all_umls_genes/';
    let response = await sdk.request('get', url ,{clinician_id: clinician_id});

    let data = response.data;
    return data;

  } catch (err) {
    throw sdk.parse_error_message(err)
  }
}

const post_member_genetic_testing = async (payload) => {
  try {

    let url = process.env.REACT_APP_ROOT_API_URL + '/member_genetic_testing/';
    let response = await sdk.request('post', url, payload);

    let data = response.data;
    return data;

  } catch (err) {
    throw sdk.parse_error_message(err)
  }
}

const patch_member_genetic_testing_id = async (id, payload) => {
  try {

    let url = process.env.REACT_APP_ROOT_API_URL + `/member_genetic_testing/${id}/`;
    let response = await sdk.request('patch', url, payload);

    let data = response.data;
    return data;

  } catch (err) {
    throw sdk.parse_error_message(err)
  }
}

const get_members_id_genetic_testing = async (id) => {
  try {

    let url = process.env.REACT_APP_ROOT_API_URL + `/members/${id}/genetic_testing/`;
    let response = await sdk.request('get', url);

    let data = response.data;
    return data;

  } catch (err) {
    throw sdk.parse_error_message(err)
  }
}

const delete_member_genetic_testing_id = async (id) => {
  try {

    let url = process.env.REACT_APP_ROOT_API_URL + `/member_genetic_testing/${id}/`;
    let response = await sdk.request('delete', url);

    let data = response.data;
    return data;

  } catch (err) {
    throw sdk.parse_error_message(err)
  }
}

export default {
  parse_error_message: sdk.parse_error_message,
  get_genetic_testing,
  get_umls_genetic_testing,
  post_member_genetic_testing,
  get_members_id_genetic_testing,
  patch_member_genetic_testing_id,
  delete_member_genetic_testing_id
}