import { combineReducers } from 'redux';
import session from './session/reducers';
import temp from './temp/reducers';
import patient from './patient/reducers';
import data_bus from './data_bus/reducers';
import search from './search/reducers';
import static_list from './static_list/reducers';
import notifications from './notifications/reducers';
import broadcast_message from './broadcast_message/reducers';
export default combineReducers({
  session,
  data_bus,
  temp,
  patient,
  search,
  static_list,
  notifications,
  broadcast_message
});
