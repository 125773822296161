import React, { Component } from 'react';

import ErrorSummary from "../components/error-summary";
import NavTop from '../components/nav-top';
import ModalConfirmDelete from '../components/modal-confirm-delete'

import {
  generateRegistrationOptionsWebAuthn,
  verifyRegistrationOptionsWebAuthn,
  getWebauthnCredentials,
  deleteWebauthnCredentials
} from '../api/webauthn-api';

import {
  preformatMakeCredReq,
  publicKeyCredentialToJSON
} from '../helpers/authenticator-helper';

type ClassProps = {
  history: any
};

type ClassState = {
  errorMessages: string[];
  credentials: any[];
  resident_key: boolean;
  security_keys_expanded: boolean;
  internal_keys_expanded: boolean;
  show_modal_confirm_delete: ShowModalConfirmDelete;
};

type ShowModalConfirmDelete = {
  show: boolean;
  cred_id: number | null;
}

class AuthenticatorRegistrationPage extends Component<ClassProps, ClassState> {

  constructor(props: ClassProps) {
    super(props);
    this.state = {
      errorMessages: [],
      credentials: [],
      resident_key: false,
      security_keys_expanded: false,
      internal_keys_expanded: false,
      show_modal_confirm_delete: {show: false, cred_id: null},
    };

    this.handleRegister = this.handleRegister.bind(this);
    this.handleRegisterPlatform = this.handleRegisterPlatform.bind(this);
    this.handleRegisterCrossPlatform = this.handleRegisterCrossPlatform.bind(this);

    this.handleSecurityExpanded = this.handleSecurityExpanded.bind(this);
    this.handleInternalExpanded = this.handleInternalExpanded.bind(this);
    this.handleDeleteAuthenticator =  this.handleDeleteAuthenticator.bind(this);
    this.openModalConfirmDelete = this.openModalConfirmDelete.bind(this);
  }

  componentDidMount() {
    getWebauthnCredentials()
    .then((response) => {
      this.setState({credentials: response});
    })
    .catch((error) => {
      console.log(error);
    });
  }

  handleRegisterPlatform() {
    this.handleRegister("platform", true);
	}

  handleRegisterCrossPlatform() {
    this.handleRegister("cross-platform", this.state.resident_key);
	}

  async handleRegister(authenticator_attachment: string, resident_key: boolean) {
    try {
      const request_payload = {
        authenticator_attachment: authenticator_attachment,
        resident_key: resident_key
      };

  		const response = await generateRegistrationOptionsWebAuthn(request_payload);
  		const publicKey = preformatMakeCredReq(response);
      const creds = await navigator.credentials.create({ publicKey });
  		const makeCredResponse = publicKeyCredentialToJSON(creds);
  		const verified_response = await verifyRegistrationOptionsWebAuthn(makeCredResponse);

      getWebauthnCredentials()
      .then((response) => {
        this.setState({credentials: response});
      })
      .catch((error) => {
        console.log(error);
      });

    } catch (exception: any) {
      if (exception instanceof DOMException && "message" in exception) {
        this.setState({
          errorMessages: [`${exception.name}: ${exception.message}`],
          resident_key: false
        });
      }
    }
	}

  handleSecurityExpanded() {
    this.setState({ security_keys_expanded: !this.state.security_keys_expanded });
  }

  handleInternalExpanded() {
    this.setState({ internal_keys_expanded: !this.state.internal_keys_expanded });
  }

  async handleDeleteAuthenticator(authenticator_id: number | null){

    try{
      let payload: object = {
        id: authenticator_id
      }
      await deleteWebauthnCredentials(payload);

      let credentials_list = this.state.credentials
      let updated_credentials_list = credentials_list.filter(credential => credential.id != authenticator_id)

      this.setState({ show_modal_confirm_delete: {show: false, cred_id: null}, credentials: updated_credentials_list })
    }
    catch(e){
      console.log(e)
    }

  }

  openModalConfirmDelete(id: number){
      this.setState({ show_modal_confirm_delete: {show: true, cred_id: id}, errorMessages: [] })
  }

  render() {
    const copyright_year: number = new Date().getFullYear();

    const security_key_authenticators = this.state.credentials.filter(cred => cred.authenticator_attachment === "cross-platform");
    const internal_authenticators = this.state.credentials.filter(cred => cred.authenticator_attachment === "platform");

    const security_key_jsx = [];
    const internal_authenticator_jsx = [];

    for (let i=0; i<security_key_authenticators.length; i++) {
      const cred = security_key_authenticators[i];
      const name = (cred.name === "" || cred.name === null || cred.name === undefined) ? `${cred.id}` : cred.name;
      security_key_jsx.push(
        <div className="well well-sm"> {name} <a onClick={() => this.openModalConfirmDelete(cred.id)} className="pull-right"><i className="fa fa-trash"></i></a> </div>
      );
    }

    for (let i=0; i<internal_authenticators.length; i++) {
      const cred = internal_authenticators[i];
      const name = (cred.name === "" || cred.name === null || cred.name === undefined) ? `${cred.id}` : cred.name;
      internal_authenticator_jsx.push(
        <div className="well well-sm"> {name} <a onClick={() => this.openModalConfirmDelete(cred.id)} className="pull-right"><i className="fa fa-trash"></i></a> </div>
      );
    }

    const security_expanded_class = (this.state.security_keys_expanded) ? "fa-minus-square" : "fa-plus-square";
    const internal_expanded_class = (this.state.internal_keys_expanded) ? "fa-minus-square" : "fa-plus-square";

    return (
      <React.Fragment>
        <NavTop history={this.props.history} />

        {this.state.show_modal_confirm_delete.show &&
        <ModalConfirmDelete
          title="Delete Authenticator"
          isOpen={this.state.show_modal_confirm_delete.show}
          message="Are you sure you want to remove this method of authentication?"
          cred_id={this.state.show_modal_confirm_delete.cred_id}
          onCancel={() => this.setState({ show_modal_confirm_delete: {show: false, cred_id: null} })}
          onOk={() => this.handleDeleteAuthenticator(this.state.show_modal_confirm_delete.cred_id)}
          errorMessages={this.state.errorMessages}
        />}

        <div className="login_wrapper">

          <ErrorSummary
            transparent={true}
            errorMessages={this.state.errorMessages}
          />

          <div className="panel panel-default">
            <div className="panel-heading">
              <h3>Register Authenticators</h3>
            </div>
          </div>

          <div className="panel panel-default">
            <div className="panel-heading">
              <h3>Security Key</h3>
            </div>
            <div className="panel-body">

              <div>To register a security key insert it into the USB port.</div>
              <div className="checkbox">
                <label>
                  <input
                    type="checkbox"
                    name="resident_key"
                    onChange={(event) => this.setState({resident_key: !this.state.resident_key})}
                    checked={this.state.resident_key}
                  />
                  Enable passwordless login with this key.
                </label>
              </div>
              <div>Note: Passwordless requires a FIDO2 device and a browser that supports it.</div>
              <button
                type="button"
                onClick={this.handleRegisterCrossPlatform}
                className="btn btn-dark"
              >
                Register
              </button>

            </div>
          </div>

          <div className="panel panel-default">
            <div className="panel-heading">
              <div className="row">
                <div className="col-md-10">
                  <h3>Security Keys</h3>
                </div>
                <div className="col-md-2">
                  <button className="pull-right btn-link" onClick={this.handleSecurityExpanded}><i className={`fa ${security_expanded_class} extra-small-icon`}></i></button>
                </div>
              </div>
            </div>
            <div className="panel-body">
              {this.state.security_keys_expanded &&
                security_key_jsx
              }
            </div>
          </div>
        </div>

        <div className="login_wrapper">

          <ErrorSummary
            transparent={true}
            errorMessages={this.state.errorMessages}
          />

          <div className="panel panel-default">
            <div className="panel-heading">
              <h3>Internal (Built-in) Authenticator</h3>
            </div>
            <div className="panel-body">
              <button
                type="button"
                onClick={this.handleRegisterPlatform}
                className="btn btn-dark"
              >
                Register
              </button>
            </div>
          </div>

          <div className="panel panel-default">
            <div className="panel-heading">
              <div className="row">
                <div className="col-md-10">
                  <h3>Internal Keys</h3>
                </div>
                <div className="col-md-2">
                  <button className="pull-right btn-link" onClick={this.handleInternalExpanded}><i className={`fa ${internal_expanded_class} extra-small-icon`}></i></button>
                </div>
              </div>
            </div>
            <div className="panel-body">
              {this.state.internal_keys_expanded &&
                internal_authenticator_jsx
              }
            </div>
          </div>

          <div><p className="text-center text-muted margin-nine-top"><span>&copy; {copyright_year} by FamHis, Inc. All rights reserved.</span></p></div>
        </div>
      </React.Fragment>
    );
  }

}

export default AuthenticatorRegistrationPage;
