import React, { Component } from 'react';
import { connect } from "react-redux";
import lodash from 'lodash';
import { log_error } from '../../helpers/helper-error';

import ModalPartnerDetails from '../modal-partner-details';
// import ModalTwins from '../modal-twins';
import ModalAdopted from '../modal-adopted';

import * as helper_family_tree from '../../helpers/helper-family-tree';
import FamilyTreePartner from './partner';

import ButtonAddEdit from '../button-add-edit';
import family_api from '../../api/family-api';
import helpers from '../../helpers';
import ErrorSummary from '../error-summary';
import * as model from '../../model-states/m-family-tree';

import * as patient_actions from '../../store/patient/actions';

class FamilyTreeProband extends Component {

  constructor(props) {
    super(props)
    this.state = {
      collapsed: true,
      partners: null,

      // openModalTwin: false,
      // modalTwinHash: new Date().getTime(),

      openModalAdopted: false,
      modalAdoptedHash: new Date().getTime(),

      openModalHalfSiblings: false,
      modalHalfSiblingsHash: new Date().getTime(),

      openModalPartnerDetail: false,
      modalPartnerDetailHash: new Date().getTime(),
      errorMessages: [],
      first_name: null
    }

    this.updateFirstName = lodash.debounce(this.updateFirstName, 250)
  }

  async componentDidUpdate(prevProps) {
    this.loadPartnersFromRedux();

    let first_name = this.getFirstName();
    if(first_name == null) return
    if(this.state.first_name != first_name) {
      this.setState({first_name})
    }

  }

  loadPartnersFromRedux() {
    if (!('proband' in this.props.patient.partners)) return;

    let partners = this.props.patient.partners.proband;
    if(!lodash.isEqual(this.state.partners, partners)) {
      this.setState({partners});
    }
  }

  async componentDidMount() {
    if ('collapsed' in this.props) this.setState({ collapsed: this.props.collapsed});
    // await this.populatePartnersFromAPI()
  }

  // async populatePartnersFromAPI() {
  //   try {
  //     let spouse_id = this.props.patient.patient.id
  //     if(spouse_id == null) return

  //     // Clear redux before repopulating
  //     this.props.dispatch(patient_actions.delete_partner({ownerRkey: 'proband'}))

  //     // Fetch API
  //     let partners = await family_api.get_member_memberid_partners(spouse_id)
  //     for(var partner of partners) {
  //       var partner_from_model = model.createPartner()
  //       var partner_data = Object.assign({}, partner_from_model, partner)

  //       // Save to Redux
  //       // partner_data.rkey = 'member_' + partner_data.id
  //       helper_family_tree.savePartnerToRedux(this.props.dispatch, 'proband', partner_data)
  //       helper_family_tree.saveProfileToRedux(this.props.dispatch, partner_data)
  //     }
  //   } catch (error) {
  //     helpers.logger(error)
  //   }
  // }

  onCollapseClick(e) {
    this.setState({ collapsed: !this.state.collapsed });
    this.clearSelection();
  }

  clearSelection() {
    if (window.getSelection) { window.getSelection().removeAllRanges(); }
    else if (document.selection) { document.selection.empty(); }
  }


  async onChangeName(first_name) {
    try {
      this.setState({first_name: first_name})
      let profile = { rkey: this.props.patient.patient.rkey, first_name }
      helper_family_tree.saveProfileToRedux(this.props.dispatch, profile)
      this.updateFirstName(first_name)
    } catch (error) {
      log_error(this.constructor.name, error);
    }
  }

  async updateFirstName(first_name) {
    try {
      this.setState({errorMessages: []})

      let member = this.props.patient.patient
      await family_api.patch_member_memberid(member.id, {
        first_name
      })
    } catch (error) {
      this.setState({errorMessages: [error.message] })
    }
  }

  // onClickOpenModalTwin() {
  //   this.setState({
  //     openModalTwin: true,
  //     modalTwinHash: new Date().getTime()
  //   });
  // }

  onClickOpenAdopted() {
    this.setState({
      openModalAdopted: true,
      modalAdoptedHash: new Date().getTime()
    });
  }

  onClickOpenHalfSibling() {
    this.setState({
      openModalHalfSiblings: true,
      modalHalfSiblingsHash: new Date().getTime(),
    });
  }

  hasPartners() {
    try {
      return this.state.partners.length > 0;
    } catch (err) {
      return false;
    }
  }

  hasSiblings() {
    return  this.props.patient.siblings.length > 0;
  }

  onClickOpenModalPartnerDetail() {
    this.setState({
      openModalPartnerDetail: true,
      modalPartnerDetailHash: new Date().getTime()
    });
  }

  onClickModalAdoptedSave(data) {
    helper_family_tree.saveProfileToRedux(this.props.dispatch, data)
  }

  getProbandPartnersCount() {
    if (!('proband' in this.props.patient.partners)) return 0;

    return this.props.patient.partners.proband.length;
  }

  getFirstName() {

    let profile = this.getProfile();
    if(profile == null) return null;

    return profile.first_name;
  }

  getProfile() {
    return helper_family_tree.getProfileFromRedux(this.props.patient, this.props.patient.patient.rkey)
  }

  getIsAdopted() {
    let profile = this.getProfile();
    if(profile == null) return false;
    return (profile.adopted_in || profile.adopted_out)
  }

  isTwin() {
    let profile = this.getProfile();
    if(profile == null) return false;
    return (profile.twin_id !== null );
  }

  render() {
    return (
      <React.Fragment>
        <tr>
          <td>

            {this.getProbandPartnersCount() !== 0 &&
              <a onClick={(e) => this.onCollapseClick(e) }>
                <i className={"fa fa-" + (this.state.collapsed ? "minus-square" : "plus-square")}></i>
              </a>
            }

            {this.state.openModalPartnerDetail &&
              <ModalPartnerDetails
                key={this.state.modalPartnerDetailHash}
                ownerRkey='proband'
                owner={this.props.patient.patient}
                parent_side='both'
                isOpen={this.state.openModalPartnerDetail}
                onClose={() => this.setState({ openModalPartnerDetail: false })}
                onClickSave={(partner_count) => {
                  // adds partner_count to proband data
                  helper_family_tree.savePatientToRedux(this.props.dispatch, { partner_count })
                  this.setState({ openModalPartnerDetail: false })
                }}
              />
            }

            {/* { this.state.openModalTwin &&
              <ModalTwins
                key={this.state.modalTwinHash}
                twinFor="siblings"
                ownerRkey='proband'
                maleLabel="Brother"
                femaleLabel="Sister"
                dispatch={this.props.dispatch}
                patient={this.props.patient}
                fatherId={this.props.patient.patient.father.id}
                motherId={this.props.patient.patient.mother.id}
                familyId={this.props.patient.patient.familyId}
                isOpen={this.state.openModalTwin}
                onClose={() => this.setState({openModalTwin: false})}
              />
            } */}

              { this.state.openModalAdopted &&
                <ModalAdopted
                  key={this.state.modalAdoptedHash}
                  isOpen={this.state.openModalAdopted}
                  onClose={() => this.setState({openModalAdopted: false})}
                  onSave={(data) => this.onClickModalAdoptedSave(data)}
                  label={ 'Proband' }
                  adopted={this.getProfile()}
                />
              }

          </td>
          <td>

            Proband

          </td>

          <td>
            <input
              onChange={(e) => this.onChangeName(e.target.value)}
              value={ this.state.first_name ? this.state.first_name : '' }
              type="text" className="inputreg form-control normal-input-text"/>

            <ErrorSummary errorMessages={this.state.errorMessages} transparent={true}/>
          </td>

          <td>

            <ButtonAddEdit
              onClick={() => this.onClickOpenModalPartnerDetail()}
              type={ this.hasPartners() ? 'edit' : 'add' }
            />

          </td>

          <td>
            {this.hasSiblings() &&
              <button
                onClick={() => this.props.onClickTwin(this.props.patient.patient.id)}
                className={"btn btn-xs table-btn-add btn-" + (this.isTwin() ? 'teal' : 'light')}>
                <i className="fa fa-pencil"></i> Edit</button>
            }
          </td>

          <td>
            <button
              onClick={() => this.onClickOpenAdopted() }
              className={"btn btn-xs table-btn-add btn-" + (this.getIsAdopted() ? 'teal' : 'light')}>
              <i className="fa fa-pencil"></i> Edit</button>
          </td>

          <td></td>
          <td></td>
        </tr>

        {this.state.collapsed && this.state.partners &&
          this.state.partners.map((partner,index) => {
            return <FamilyTreePartner
              key={partner.rkey}
              ownerRkey='proband'
              owner={this.props.patient.patient}
              collapsed={true}
              level={0}
              extra_indent={1}
              label={'Partner #' + (index + 1) }
              partner={partner}
              parent_side='both'
              dispatch={this.props.dispatch}
              patient={this.props.patient}
              deleteMember={this.props.deleteMember}
            />
          })
        }

      </React.Fragment>
    );
  }
}

// const redux_state = state => ({
//   patient: state.patient
// });

// const redux_actions = dispatch => ({
//   dispatch: (action) => dispatch(action)
// });

// export default connect(redux_state, redux_actions)(FamilyTreeProband);

export default FamilyTreeProband;
