import React, { Component } from 'react';

import Modal from '../modal/modal';
import images from '../../configs/images';
import FocusLock from 'react-focus-lock';


class ModalResetPasswordLinkSent extends Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.handleOnkeyDown = this.handleOnkeyDown.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  handleClick(event) {
    this.props.onClose();
  }

  handleClose() {
    this.props.onClose();
  }
  handleOnkeyDown(event) {
    // handle the esc key being pressed down and close the modal for accessibility
    return event.keyCode === 27 && this.handleClose();
  }
  
  render() {
    return (
      <div
        role="dialog"
        onClick={this.props.onClose}
        onKeyDown={this.handleOnkeyDown}
        style={{ display: "block" }}
        className="modal fade in"
      >
        <div
          className="modal-dialog"
          role="document"
          onClick={e => e.stopPropagation()}
        >
          <div className='modal-content'>
            <div className='modal-header'>
              <button
                onClick={this.props.onClose}
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
              <i className="fa fa-close" />
              </button>
              <h4 className="modal-title text-white text-capitalize">Reset Password</h4>
            </div>
            <FocusLock>
            <div className='modal-body password-reset-success'>
              <p className="text-left margin-four">If an account exists for user <strong>{this.props.username}</strong>, you will receive an email describing how to reset your password.</p>
            </div>
            </FocusLock>
            <div className='modal-footer'>
              <button type="button" className="btn btn-dark"onClick={this.props.onClose}>OK</button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ModalResetPasswordLinkSent;
