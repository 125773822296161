import React from 'react';
// import Select from 'react-select'
import Dropdown from 'react-dropdown'
const SelectDefault = (props) => {
  return (
    <Dropdown
      name={props.name}
      className='react-select-container'
      classNamePrefix="react-select"
      isClearable={true}
      placeholder=""
      options={props.options}
      value={props.value}
      onChange={(value) => {
        props.onChange(value)
      }}
    />

  )
}

export default SelectDefault