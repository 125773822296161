import React, { Component } from 'react';
import ReactDOM from 'react-dom';

class ModalDeletePatient extends Component {

  constructor(props) {
    super(props);
    this.onClickDelete = this.onClickDelete.bind(this);
  }

  onClickDelete(event) {
    this.props.onClickDelete(this.props.patient.id);
    this.props.onClose();
  }

  render() {
    return ReactDOM.createPortal(
      <div onClick={() => this.props.onClose()}
        style={{ display: 'block' }}
        className="modal fade in"
        role="dialog"
        aria-labelledby="delete-account"
        id="delete-account">

        <div onClick={(e) => e.stopPropagation()} className="modal-dialog modal-sm" role="document">
          <div className="modal-content">

            <div className="modal-header">
              <button onClick={() => this.props.onClose()} type="button" className="close" data-dismiss="modal" aria-label="Close">
                <i className="fa fa-close"></i>
              </button>
              <h4 className="modal-title text-white text-capitalize">Delete Patient</h4>
            </div>

            <div className="modal-body">
              <div className="modal-confirm">
                Are you sure you want to delete this patient?
                <br/>{this.props.patient.patient_id} {this.props.patient.first_name} {this.props.patient.last_name}
              </div>
            </div>

            <div className="modal-footer">
              <button onClick={() => this.props.onClose()} className="btn btn-light-outline no-margin-right">Cancel</button>
              <button onClick={this.onClickDelete} className="btn btn-dark" data-dismiss="modal" aria-label="Close">Delete</button>
            </div>
          </div>
        </div>
      </div>,
      document.body
    );
  }

}

export default ModalDeletePatient;
