import sdk from './sdk';

const post_clinician_notification = async (clinician_id, proband_id=undefined, proband_uuid=undefined) => {
  try {
    let url = process.env.REACT_APP_ROOT_API_URL + '/clinician/notification/';
    let response = await sdk.request('post', url, {clinician_id, clinician_copy_proband_id: proband_id, clinician_copy_proband_uuid: proband_uuid});
    return response.data;

  } catch (err) {
    throw sdk.parse_error_message(err)
  }
}

const get_clinician_notifications = async (clinician_id, status_list=undefined, notification_types=undefined, include_unviewed_pedigrees=false) => {
  try {
    let url = process.env.REACT_APP_ROOT_API_URL + '/clinician/clinician_notifications/';
    let response = await sdk.request('post', url, {clinician_id, status_list: status_list, notification_types: notification_types, include_unviewed_pedigrees: include_unviewed_pedigrees});
    return response.data;

  } catch (err) {
    throw sdk.parse_error_message(err)
  }
}

const get_receiver_proband_for_notifications = async (clinician_id, notification_id) => {
  try {
    let url = process.env.REACT_APP_ROOT_API_URL + '/clinician/get_receiver_proband_for_notifications/';
    let response = await sdk.request('post', url, {clinician_id: clinician_id, notification_id: notification_id});
    return response.data;

  } catch (err) {
    throw sdk.parse_error_message(err)
  }
}

const post_notification_notificationid_response = async (notification_id, payload) => {
  try {

    let url = process.env.REACT_APP_ROOT_API_URL + '/notification/' + notification_id + '/response/';
    let response = await sdk.request('post', url, payload);
    return response.data;

  } catch (err) {
    throw sdk.parse_error_message(err)
  }
}

const post_update_sharing_settings = async (payload) => {
  try {

    let url = process.env.REACT_APP_ROOT_API_URL + '/clinician/update_sharing_settings/';
    let response = await sdk.request('post', url, payload);
    return response.data;

  } catch (err) {
    throw sdk.parse_error_message(err)
  }
}

const get_connection_date = async (clinician_id, family_id) => {
  try {
    let payload = { family_id };
    let url = process.env.REACT_APP_ROOT_API_URL + '/clinician/' + clinician_id + '/get_connection_date/';
    let response = await sdk.request('post', url, payload);
    return response.data;

  } catch (err) {
    throw sdk.parse_error_message(err)
  }
}

const post_notification_notificationid_read = async (notification_id, clinician_id) => {
  try {
    let url = process.env.REACT_APP_ROOT_API_URL + '/notification/' + notification_id + '/read/';
    let payload = { clinician_id };
    let response = await sdk.request('post', url, payload);
    return response.data;

  } catch (err) {
    throw sdk.parse_error_message(err)
  }
}

const post_notification_bulk_read = async (pending_notifications, clinician_id) => {
  let payload={
    'clinician_id': clinician_id,
    'pending_notifications': pending_notifications
  }
  try {
    let url = process.env.REACT_APP_ROOT_API_URL + '/notification/bulk_read_notifications/';

    let response = await sdk.request('post', url, payload);
    return response.data;

  } catch (err) {
    throw sdk.parse_error_message(err)
  }
}

const post_notification_notificationid_dismiss = async (notification_id, payload) => {
  try {
    let url = process.env.REACT_APP_ROOT_API_URL + '/notification/' + notification_id + '/dismiss/';
    let response = await sdk.request('post', url, payload);
    return response.data;

  } catch (err) {
    throw sdk.parse_error_message(err)
  }
}

const post_notification_notificationid_dismiss_all = async (payload) => {
  try {
    let url = process.env.REACT_APP_ROOT_API_URL + '/notification/dismiss_all/';
    let response = await sdk.request('post', url, payload);
    return response.data;

  } catch (err) {
    throw sdk.parse_error_message(err)
  }
}

const post_clinician_notification_counts = async (clinician_id) => {
  try {
    let url = process.env.REACT_APP_ROOT_API_URL + '/clinician/notification_counts/';
    let response = await sdk.request('post', url, {clinician_id});
    return response.data;

  } catch (err) {
    throw sdk.parse_error_message(err)
  }
}

const post_notification_notification_dismiss_connection_request = async (payload) =>{
  try {
    let url = process.env.REACT_APP_ROOT_API_URL + '/notification/dismiss_all_connection_request/';
    let response = await sdk.request('post', url, payload);
    return response.data;

  }catch(err){
    throw sdk.parse_error_message(err)
  }
}

const post_notification_notification_dismiss_patient_updates = async (payload) =>{
  try{
    let url = process.env.REACT_APP_ROOT_API_URL +  '/notification/dismiss_all_patient_updates/';
    let response = await sdk.request('post',url, payload);
    return response.data
  }catch(error){
    throw sdk.parse_error_message(error)
  }
}

const accept_all_invites_requests = async () =>{
  try{
    let url = process.env.REACT_APP_ROOT_API_URL +  '/account/clinician_auto_accept/';
    let response = await sdk.request('post',url);
    return response.data
  }catch(error){
    throw sdk.parse_error_message(error)
  }
}

const invite_requests_count = async () =>{
  try{
    let url = process.env.REACT_APP_ROOT_API_URL +  '/account/clinician_auto_accept_count/';
    let response = await sdk.request('get',url);
    return response.data
  }catch(error){
    throw sdk.parse_error_message(error)
  }
}

export default {
  post_clinician_notification,
  get_clinician_notifications,
  get_receiver_proband_for_notifications,
  post_notification_notificationid_response,
  post_update_sharing_settings,
  get_connection_date,
  post_notification_notificationid_read,
  post_notification_notificationid_dismiss,
  post_notification_notificationid_dismiss_all,
  post_clinician_notification_counts,
  post_notification_notification_dismiss_connection_request,
  post_notification_notification_dismiss_patient_updates,
  accept_all_invites_requests,
  invite_requests_count,
  post_notification_bulk_read
}
