type XY_Pair = { x: number; y: number };
class utils {
  static intersection(SetA: Set<string>, SetB: Set<string>): Set<string> {
    let _intersection = new Set<string>();
    SetB.forEach((elem) => {
      if (SetA.has(elem)) {
        _intersection.add(elem);
      }
    });
    return _intersection;
  }

  static intersectionList(
    SetA: Set<string>,
    ArrayB: Array<string>
  ): Set<string> {
    let _intersection = new Set<string>();
    ArrayB.forEach((elem) => {
      if (SetA.has(elem)) {
        _intersection.add(elem);
      }
    });
    return _intersection;
  }
  static map_set_add(
    map: Map<string, Set<string>>,
    key: string,
    value: string[]
  ) {
    let current = map.get(key);
    if (current === undefined) {
      current = new Set();
    }
    value.forEach((v) => {
      current.add(v);
    });
    map.set(key, current);
  }
  static map_list_append(
    map: Map<string, string[]>,
    key: string,
    value: string[]
  ) {
    let current = map.get(key);
    if (current === undefined) {
      current = [];
    }
    value.forEach((v) => {
      current.push(v);
    });
    map.set(key, current);
  }
  static map_list_remove(
    map: Map<string, string[]>,
    key: string,
    value: string
  ) {
    let current_list = map.get(key);
    if (current_list !== undefined) {
      let index = current_list.indexOf(value);
      // console.log("Removing " + key + " " + node_id + " (" + current_x + " " + current_y + ")");
      if (index > -1) {
        current_list.splice(index, 1);
      }
      if (current_list.length === 0) {
        map.delete(key);
      }
    }
  }
}

class Line {
  dom_element_id: string;
  horizontal: boolean;
  vertical: boolean;
  other: boolean;
  constructor(
    dom_element_id: string,
    horizontal: boolean,
    vertical: boolean,
    other: boolean
  ) {
    this.dom_element_id = dom_element_id;
    this.horizontal = horizontal;
    this.vertical = vertical;
    this.other = other;
  }
}
/**
 * XY cords for a location
 */
export class XY_Cords {
  x: number;
  y: number;
  constructor(x: number, y: number) {
    this.x = x;
    this.y = y;
  }
  toString(): string {
    return "(" + this.x + "," + this.y + ")";
  }
}
/**
 * Named line with start and end locations
 */
export class NamedLine {
  node_id: string;
  startLocation: XY_Cords;
  endLocation: XY_Cords;
  constructor(node_id: string, startLocation: XY_Cords, endLocation: XY_Cords) {
    this.node_id = node_id;
    this.startLocation = startLocation;
    this.endLocation = endLocation;
  }
  static named_line_equal(named1: NamedLine, named2: NamedLine) {
    return (
      named1.node_id === named2.node_id &&
      named1.startLocation.x === named2.startLocation.x &&
      named1.startLocation.y === named2.startLocation.y &&
      named1.endLocation.x === named2.endLocation.x &&
      named1.endLocation.y === named2.endLocation.y
    );
  }
}

class ConnectionWatcher {
  connected_to_nodes: Map<string, Set<string>>;
  /** nodes that have moved (this is where updates come from) */
  updated_nodes: Set<string>;
  /** edges that have moved */
  updated_edges: Set<string>;
  /** the node_id to the list of edges it is connected to */
  node_to_edges: Map<string, Set<string>>;
  /** edge_id to a list of node_id to know which nodes a line is connected to  */
  edge_to_node_list: Map<string, string[]>;
  constructor() {
    // this.connections = new Map();
    this.connected_to_nodes = new Map();
    this.node_to_edges = new Map();
    this.edge_to_node_list = new Map();
    this.updated_nodes = new Set();
    this.updated_edges = new Set();
  }

  /**
   * Check if the edge needs an update
   * @param edge_id
   * @returns
   */
  line_needs_update(edge_id: string) {
    let result = true;
    // first check of a connected node has been updated
    let edge_nodes = this.edge_to_node_list.get(edge_id);
    if (edge_nodes !== undefined && edge_nodes.length > 0) {
      // check if those nodes are in the updated list
      let inter = utils.intersectionList(this.updated_nodes, edge_nodes);
      if (inter.size > 0) {
        result = true;
      } else {
        result = false;
      }
    }
    return result;
  }
  /**
   * Add the connections from the src to target
   * @param src_id
   * @param target_id
   * @param edge_id_list
   */
  add_connections_list(
    src_id: string,
    target_id: string,
    edge_id_list: string[]
  ) {
    // this.add_connections(
    //   src_id,
    //   target_id,
    //   this.quickConnections(edge_id_list)
    // );
    edge_id_list.forEach((edge_id) => {
      utils.map_list_append(this.edge_to_node_list, edge_id, [
        src_id,
        target_id,
      ]);
    });
    utils.map_set_add(this.node_to_edges, src_id, edge_id_list);
    utils.map_set_add(this.node_to_edges, target_id, edge_id_list);
    this.add_node_connection(src_id, target_id);
    this.updated_nodes.add(src_id);
    this.updated_nodes.add(target_id);
  }

  /**
   * Add the node to the updated list and all the edges that are connected to it
   * @param node_id
   */
  update_node(node_id: string) {
    if (!this.updated_nodes.has(node_id)) {
      this.updated_nodes.add(node_id);
      let edge_list = this.node_to_edges.get(node_id);
      if (edge_list !== undefined) {
        edge_list.forEach((edge_id) => {
          this.updated_edges.add(edge_id);
        });
      }
    }
  }

  delete_node(node_id: string) {
    if(this.updated_nodes.has(node_id)){
      this.updated_nodes.delete(node_id);
    }
  }

  /**
   * Clear the updated nodes and edges
   */
  clear_updated_set() {
    this.updated_nodes.clear();
    this.updated_edges.clear();
  }

  /**
   * Add a connection between src and target (make it 2 way)
   * @param {string} src
   * @param {string} target
   */
  add_node_connection(src: string, target: string) {
    let src_connections = this.connected_to_nodes.get(src);
    if (src_connections === undefined) {
      src_connections = new Set();
      this.connected_to_nodes.set(src, src_connections);
    }
    src_connections.add(target);

    let target_connections = this.connected_to_nodes.get(target);
    if (target_connections === undefined) {
      target_connections = new Set();
      this.connected_to_nodes.set(target, target_connections);
    }
    target_connections.add(src);
  }
}
/**
 * Class to watch the boxes for intersections
 */
class BoxWatcher {
  base: number;
  box_map: Map<string, string[]>;
  //   vertical_map: Map<string, string[]>;
  //   horizontal_map: Map<string, string[]>;
  id_to_box_list: Map<string, XY_Cords[]>;
  constructor() {
    this.base = 100;
    this.box_map = new Map();
    this.id_to_box_list = new Map();
  }
  /**
   * Create the boxs for a connection.  Converts the numbers to nearest multiple of base
   * @param x1
   * @param y1
   * @param x2
   * @param y2
   * @returns
   */
  getXYList(x1: number, y1: number, x2: number, y2: number): XY_Cords[] {
    let [startx, endx] = this.re_min(x1, x2); // make sure start is less than end
    let [starty, endy] = this.re_min(y1, y2);
    let x_y_list: XY_Cords[] = []; // a list of x,y pairs that the node may cross over
    for (let current_y = starty; current_y <= endy; current_y += this.base) {
      for (let current_x = startx; current_x <= endx; current_x += this.base) {
        x_y_list.push(new XY_Cords(current_x, current_y));
      }
    }
    return x_y_list;
  }

  /**
   * Create the set of boxs for the edge
   * @param edge_id
   * @param x1
   * @param y1
   * @param x2
   * @param y2
   */
  buildBox(edge_id: string, x1: number, y1: number, x2: number, y2: number) {
    let base = 100;
    let x_y_list = this.getXYList(x1, y1, x2, y2);
    // let vertical = x1 === x2;
    // let horizontal = y1 === y2;

    if (this.id_to_box_list.has(edge_id)) {
      let previous_x_y_list = this.id_to_box_list.get(edge_id);
      if (previous_x_y_list !== undefined) {
        previous_x_y_list.forEach((loc) => {
          let current_x = loc.x;
          let current_y = loc.y;
          let key = this.get_key(current_x, current_y);
          utils.map_list_remove(this.box_map, key, edge_id);
          //   if (vertical) {
          //     utils.map_list_remove(this.vertical_map, key, edge_id);
          //   }
          //   if (horizontal) {
          //     utils.map_list_remove(this.horizontal_map, key, edge_id);
          //   }
        });
      }
    }
    x_y_list.forEach((loc) => {
      let current_x = loc.x;
      let current_y = loc.y;
      let key = this.get_key(current_x, current_y);
      utils.map_list_append(this.box_map, key, [edge_id]);
      //   if (vertical) {
      //     utils.map_list_append(this.vertical_map, key, [edge_id]);
      //   }
      //   if (horizontal) {
      //     utils.map_list_append(this.horizontal_map, key, [edge_id]);
      //   }
      current_x += base;
    });
    this.id_to_box_list.set(edge_id, x_y_list);
  }

  /**
   * Method to compute an x,y key for the box
   * @param x
   * @param y
   * @returns string key
   */
  get_key(x: number, y: number): string {
    return "(" + (x + "," + y) + ")";
  }
  /**
   * Rounds the value to the nearest multiple of base
   */
  round(value: number, base = 100) {
    return Math.round(value / base) * base;
  }
  /**
   * Rounds and finds the min of a and b
   */
  re_min(a: number, b: number) {
    a = this.round(a);
    b = this.round(b);
    if (a < b) {
      return [a, b];
    } else {
      return [b, a];
    }
  }
  get_box(edge_id: string) {
    return this.id_to_box_list.get(edge_id);
  }
  has_box(edge_id: string) {
    return this.id_to_box_list.has(edge_id);
  }
}

/**
 * Class to watch the connections and boxes for updates
 * Boxes keep track of the locations of the lines that intersect
 * Connections keep track of the nodes that are connected and the edges that connect them
 */
export class Connection_Box_Watcher {
  box: BoxWatcher;
  connection: ConnectionWatcher;
  node_box: BoxWatcher;
  node_to_location: Map<string, XY_Cords>;
  line_to_location: Map<string, NamedLine>;
  connection_cache: Map<string, string>;
  intercetion_cache: Map<string, XY_Pair[]>;
  spouse_to_connector: Map<string, string[]>;
  connector_to_spouse: Map<string, string[]>;

  constructor() {
    this.box = new BoxWatcher();
    this.connection = new ConnectionWatcher();
    this.node_to_location = new Map();
    this.line_to_location = new Map();
    this.connection_cache = new Map();
    this.intercetion_cache = new Map();
    this.node_box = new BoxWatcher();
    this.spouse_to_connector = new Map();
    this.connector_to_spouse = new Map();
  }
  /**
   * Check if this line needs an update. A moved node or a moved line will trigger an update
   * @param line_id
   * @returns true if the line needs an update
   */
  needs_update(line_id: string) {
    let result = false;
    //check if the connected nodes moved
    let connection_update = this.connection.line_needs_update(line_id);

    if (connection_update) {
      return true;
    } else {
      // if not connected nodes, then check the boxes
      let boxes = this.box.id_to_box_list.get(line_id);
      if (boxes !== undefined) {
        boxes.forEach((box) => {
          let key = this.box.get_key(box.x, box.y);
          let edge_list = this.box.box_map.get(key);
          // console.log("Checking box " + key);
          // console.log(edge_list);
          // console.log(this.connection.updated_edges);
          if (edge_list !== undefined) {
            let inter = utils.intersectionList(
              this.connection.updated_edges,
              edge_list
            );
            if (inter.size > 0) {
              result = true;
            }
          }
        });
      }
    }
    return result;
  }

  /**
   * Get all points that may interect with the line.  Looks them up in the boxes
   * @param line_id
   * @returns
   */
  get_all_intersection(line_id: string): Set<string> {
    let intersection_result = new Set<string>();
    if (!this.box.id_to_box_list.has(line_id)) {
      return intersection_result;
    }
    let boxes = this.box.id_to_box_list.get(line_id);
    if (boxes !== undefined) {
      boxes.forEach((box) => {
        let key = this.box.get_key(box.x, box.y);
        let edge_list = this.box.box_map.get(key);
        if (edge_list !== undefined) {
          edge_list.forEach((edge) => {
            intersection_result.add(edge);
          });
        }
      });
    }
    return intersection_result;
  }

  /**
   * Update the line's location.  Return the intersections and cache the result
   * @param line_id
   * @param x1
   * @param y1
   * @param x2
   * @param y2
   * @returns
   */
  update_line_location(
    line_id: string,
    x1: number,
    y1: number,
    x2: number,
    y2: number
  ): XY_Pair[] {
    // first part check to see if we need to update the boxs that the line exists in
    let current = new NamedLine(
      line_id,
      new XY_Cords(x1, y1),
      new XY_Cords(x2, y2)
    );
    let previous = this.line_to_location.get(line_id);
    let need_update = true;
    if (previous !== undefined) {
      if (NamedLine.named_line_equal(previous, current)) {
        need_update = false;
      }
    }
    // if we need an update (the line has moved or first time)
    if (need_update) {
      this.line_to_location.set(line_id, current);
      this.box.buildBox(line_id, x1, y1, x2, y2);
    }
    /* this will do the update all in one shot and cacheing*/
    let line_needs_update = this.needs_update(line_id);
    let intersection_points = this.get_cache_intersection(line_id); // grab the cached version first
    if (
      line_needs_update ||
      intersection_points === null ||
      intersection_points === undefined
    ) {
      let intersection_line_list = this.get_all_intersection(line_id);
      let current_line = new NamedLine(
        line_id,
        new XY_Cords(x1, y1),
        new XY_Cords(x2, y2)
      );
      // check the actual dom for the intersections (point or path)
      intersection_points = Intersector.get_intersection(
        current_line,
        intersection_line_list
      );

      this.cache_intersection(line_id, intersection_points);
    }
    return intersection_points;
  }
  update_node(node_id: string) {
    this.connection.update_node(node_id);
  }
  update_node_location(node_id: string, x: number, y: number): boolean {
    let update = true;
    let current = new XY_Cords(x, y);
    let previous = this.node_to_location.get(node_id);
    if (
      previous !== undefined &&
      current.x === previous.x &&
      current.y === previous.y
    ) {
      update = false;
    }

    if (update) {
      this.node_to_location.set(node_id, current);
      this.connection.update_node(node_id);
      this.node_box.buildBox(node_id, x, y, x, y);
    }
    return update;
  }
  get_node_location(node_id: string): XY_Cords | undefined {
    return this.node_to_location.get(node_id);
  }
  get_closest_sibling_to_donor(siblings: number[], donor_node_position: XY_Cords): number {
		let people: object = Object.fromEntries(this.node_to_location.entries());
    let people_array = Object.entries(people).map(([key, value]) => ({ node: key, x: value.x, y: value.y }));

		let siblings_list = people_array.filter(person => siblings.find(sibling => sibling + '' == person.node + '')).map(person => person.x);

		let closest = siblings_list.reduce(function (prev, curr) {
			return (Math.abs(curr - donor_node_position.x) < Math.abs(prev - donor_node_position.x) ? curr : prev);
		});

		return closest;
	}
  /**
   * Add connections for the src and target and the edges are the lines that connect the nodes..
   *  If ethier src or target changes, then the edeges must be recalcualted
   * @param src_id
   * @param target_id
   * @param edge_id_list
   */
  add_connections(src_id: string, target_id: string, edge_id_list: string[], type?: string | undefined) {
    let key = src_id + "_" + target_id;
    let cached_edges = this.connection_cache.get(key);
    let data1 = "" + edge_id_list;
    let new_data = true;
    if (cached_edges === undefined) {
      new_data = true;
    } else {
      if (data1 == cached_edges) {
        new_data = false;
      }
    }
    this.connection_cache.set(key, data1);
    if (new_data) {
      if (type && type === "Spouse") {
        if(this.spouse_to_connector.has(src_id)){
          let list = this.spouse_to_connector.get(src_id);
          if (list){
            if(!list.includes(target_id)){
              list.push(target_id);
              this.spouse_to_connector.set(src_id, list);
            }
          }
        }
        else{
          this.spouse_to_connector.set(src_id, [target_id]);
        }

        if(this.connector_to_spouse.has(target_id)){
          let list = this.connector_to_spouse.get(target_id);
          if (list) {
            if(!list.includes(src_id)){
              list.push(src_id);
              this.connector_to_spouse.set(target_id, list)
            }
          }
        }
        else{
          this.connector_to_spouse.set(target_id, [src_id]);
        }
      }

      this.connection.add_connections_list(src_id, target_id, edge_id_list);
    }
  }

  get_spouse_to_connector(id: string){
    if (this.spouse_to_connector.has(id)){
      let list = this.spouse_to_connector.get(id);
      if (list){
        if(list.length == 1){
          return {
            node: list[0],
            x: this.node_to_location.get(list[0])?.x,
            y: this.node_to_location.get(list[0])?.y
          }
        }
      }
    }
    return null;
  }

  get_connector_to_spouse(id: string){
    if (this.connector_to_spouse.has(id)){
      return this.connector_to_spouse.get(id);
    }
  }

  clear_from_connection_box_watcher(node_id: string) {
    if(this.node_to_location.has(node_id)){
      this.node_to_location.delete(node_id);
    }
    this.connection.delete_node(node_id);
    if(this.node_box.box_map.has(node_id)){
      this.node_box.box_map.delete(node_id);
    }
  }

  nodes_between(src_id: string, target_id: string): boolean {
    let box_nodes = new Set<string>();
    let src_location = this.node_to_location.get(src_id);
    let target_location = this.node_to_location.get(target_id);
    if (src_location === undefined || target_location === undefined) {
      return false;
    }
    let boxes = this.node_box.getXYList(
      src_location.x,
      src_location.y,
      target_location.x,
      target_location.y
    );
    boxes.forEach((box) => {
      let key = this.node_box.get_key(box.x, box.y);
      let node_list = this.node_box.box_map.get(key);
      if (node_list !== undefined) {
        node_list.forEach((edge) => {
          box_nodes.add(edge);
        });
      }
    });
    box_nodes.delete(src_id);
    box_nodes.delete(target_id);
    let between_nodes: string[] = [];
    box_nodes.forEach((node) => {
      let loc = this.node_to_location.get(node);

      if (
        loc !== undefined &&
        Intersector.between(loc.x, src_location.x, target_location.x)
      ) {
        let middle = (src_location.y + target_location.y) / 2;
        let diff = Math.abs(loc.y - middle);
        if (diff < 50) {
          between_nodes.push(node);
        }
      }
    });
    // if (between_nodes.length > 0) {
    //   console.log(
    //     "Nodes between " + src_id + " " + target_id + " | " + between_nodes
    //   );
    // }
    return between_nodes.length > 0;
  }

  clear() {
    this.connection.clear_updated_set();
  }
  /**
   * Set the intersection points for a line
   * @param edge_id
   * @param intersection
   */
  cache_intersection(edge_id: string, intersection: XY_Pair[]) {
    this.intercetion_cache.set(edge_id, intersection);
  }
  /**
   * Get the locations to look at for intersection
   * @param edge_id the line to get cached locations
   * @returns the intersection points
   */
  get_cache_intersection(edge_id: string): XY_Pair[] {
    let result: XY_Cords[] = [];
    if (this.intercetion_cache.has(edge_id)) {
      let current_value = this.intercetion_cache.get(edge_id);
      if (current_value !== undefined) {
        result = current_value;
      }
    }
    return result;
  }
}
/**
 * Class for getting intersection from the dom (dependant on the dom)
 */
export class Intersector {
  /**
   * Get the attribute from the path (number)
   * @param aPath the html element
   * @param attribute
   * @returns
   */
  static get_attribute(aPath: HTMLElement, attribute: string): string {
    let result = "0";
    if (aPath !== undefined) {
      let temp = aPath.getAttribute(attribute);
      if (temp !== undefined && temp !== null) {
        result = temp;
      }
    }
    return result;
  }
  static between(a: number, q: number, s: number) {
    let result = false;
    if ((a < q && a > s) || (a > q && a < s)) {
      result = true;
    }
    return result;
  }

  static between_EQ(a: number, q: number, s: number) {
    let result = false;
    if ((a <= q && a >= s) || (a >= q && a <= s)) {
      result = true;
    }
    return result;
  }

  static get_intersection(
    line: NamedLine,
    element_ids: Set<string>
  ): XY_Pair[] {
    {
      let intersection: XY_Pair[] = [];
      let source_x1 = line.startLocation.x;
      let source_y1 = line.startLocation.y;
      let dest_y2 = line.endLocation.y;
      // let dest_x2 = line.endLocation.x;
      element_ids.forEach((element) => {
        if (element !== null) {
          let horizontal_line = document.getElementById(element);
          if (horizontal_line === null) {
            return intersection;
          }

          if (horizontal_line.nodeName === "line") {
            let x1 = parseFloat(
              Intersector.get_attribute(horizontal_line, "x1")
            );
            let y1 = parseFloat(
              Intersector.get_attribute(horizontal_line, "y1")
            );
            let x2 = parseFloat(
              Intersector.get_attribute(horizontal_line, "x2")
            );
            let y2 = parseFloat(
              Intersector.get_attribute(horizontal_line, "y2")
            );
            if (y1 === y2) {
              if (Intersector.between(source_x1, x1, x2)) {
                if (Intersector.between(y1, source_y1, dest_y2)) {
                  intersection.push({ x: source_x1, y: y1 });
                  // intersection.push(new XY_Cords(source_x1, y1));
                }
              }
            }
          } else if (horizontal_line.nodeName == "path") {
            if (horizontal_line instanceof SVGGeometryElement) {
              let len = horizontal_line.getTotalLength();
              let start = horizontal_line.getPointAtLength(0);
              let x1 = start.x;
              // let y1 = start.y;
              let end = horizontal_line.getPointAtLength(len);
              let x2 = end.x;
              if (Intersector.between(source_x1, x1, x2)) {
                let count = 0;
                let MAX = 20;
                let startDistance = 0;
                let endDistance = len;
                let versoina = true;
                let bestMid: DOMPoint | null = null;
                if (versoina) {
                  let mid = (startDistance + endDistance) / 2;
                  let midpoint = horizontal_line.getPointAtLength(mid);
                  count = 0;
                  while (Math.abs(midpoint.x - source_x1) > 5) {
                    if (midpoint.x > source_x1) {
                      endDistance = mid;
                    } else if (midpoint.x < source_x1) {
                      startDistance = mid;
                    } else if (midpoint.x === source_x1) {
                      bestMid = midpoint;
                      break;
                    }
                    count += 1;
                    if (count > MAX) break;
                    mid = (startDistance + endDistance) / 2;
                    midpoint = horizontal_line.getPointAtLength(mid);
                    bestMid = midpoint;
                  }
                  if (bestMid) {
                    if (this.between_EQ(bestMid.y, source_y1, dest_y2)) {
                      let res = { x: bestMid.x, y: bestMid.y };
                      intersection.push(res);
                      // intersection.push(new (bestMid.x, bestMid.y));
                      // intersection.push(new XY_Cords(bestMid.x, bestMid.y));
                    }
                  }
                }
              }
            }
          }
        }
      });

      return intersection;
    }
  }
}
// export default { Connection_Box_Watcher, Intersector };

class MyConnectionTests {
  test1() {
    let grid =
      "|--50---|--150--|--250--|--350--|--450--|\n" +
      "|--- ---|---x---|--- ---|---y---|--- ---| 50\n" +
      "|---a=======@=======b=======@=======c---| 150\n" +
      "|--- ---|---@---|---@---|---@---|--- ---| 250\n" +
      "|--- ---|---z=======@=======q---|--- ---| 350\n" +
      "|--- ---|--- ---|---m---|--- ---|--- ---| 450\n";
    console.log(grid);
    let watcher: Connection_Box_Watcher = new Connection_Box_Watcher();
    // let connection_maker = conn_box.getConnection();
    // let box_maker = conn_box.getBox();
    watcher.add_connections("a", "b", ["1-ab", "2-ab"]);
    watcher.add_connections("a", "b", ["1-ab", "2-ab"]);
    watcher.update_line_location("1-ab", 50, 150, 150, 150);
    watcher.update_line_location("2-ab", 150, 150, 250, 150);
    watcher.add_connections("x", "z", ["1-xz"]);
    watcher.update_line_location("1-xz", 150, 50, 150, 350);
    watcher.add_connections("z", "q", ["1-zq", "2-zq"]);
    watcher.update_line_location("1-zq", 150, 350, 250, 350);
    watcher.update_line_location("2-zq", 250, 350, 350, 350);
    watcher.add_connections("y", "q", ["1-yq"]);
    watcher.update_line_location("1-yq", 350, 50, 350, 350);
    watcher.add_connections("b", "c", ["1-bc"]);
    watcher.update_line_location("1-bc", 250, 150, 450, 150);
    console.log("\nMoving X");
    watcher.update_node("x");

    // console.log(connection_maker.updated_nodes);
    // console.log(connection_maker.updated_edges);
    let edge_list = ["1-ab", "2-ab", "1-xz", "1-zq", "2-zq"];
    edge_list.forEach((edge) => {
      console.log(
        "The edge is " +
          edge +
          " needs update --> " +
          watcher.needs_update(edge)
      );
    });
    console.log("\nMoving C");
    watcher.clear();
    watcher.update_node("c");
    edge_list = ["1-bc", "1-yq", "2-ab", "1-ab", "1-xz", "1-zq", "2-zq"];
    edge_list.forEach((edge) => {
      console.log(
        "The edge is " +
          edge +
          " needs update --> " +
          watcher.needs_update(edge)
      );
    });
    watcher.update_line_location("1-yq", 1000, 1000, 1110, 1000);
    console.log("\nMoving Y");
    edge_list.forEach((edge) => {
      console.log(
        "The edge is " +
          edge +
          " needs update --> " +
          watcher.needs_update(edge)
      );
    });
  }
}

// let test = new MyConnectionTests();
// test.test1();
