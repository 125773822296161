import React, { Component } from 'react';
import helpers from '../helpers/index';
import NavTop from '../components/nav-top';
import { cloneDeep } from 'lodash';
import { Link } from 'react-router-dom';
import ActivityIndicator from '../components/activity-indicator';
import AccountTabDisplay from '../components/accounts/account-tab-display';
import account_api from '../api/user-account-api';
import route_helper from '../route-helper';
import { radix_bases } from '../helpers/helper-number-bases';

const static_org = {
  id: null,
  org_name: null,
  org_type: null,
  units: null,
  password_reset_timeframe: null,
  auto_logout_time: null,
  logo: null,
  clinician_id: null,
  parent_org: null,
  license_id: null,
  is_locked: false,

  license: {
    id: null,
    boadicea: false,
    tyrer_cuzick: false,
    gail: false,
    claus: false
  },
  clinician_code: {
    id: null,
    code: null
  },
  parent_organization: null,
};

function parseToInt(val) {
  return parseInt(val, radix_bases.base10);
}

class ClinicianSubAccountPage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      org: static_org,
      loading: false,
      survey_display: []
    };
  }

  async componentDidMount() {
    helpers.setSiteTitle('Sub Account');
    const org_id = this.props.match.params.org_id;
    if(parseToInt(org_id)) {
      this.setState({loading: true});

      try {
        const org_data = await account_api.get_org_by_id(org_id);
        const org_surveys = await account_api.get_org_surveys(org_data.clinician_code.id);
        const survey_names = []
        for (let i = 0; i < org_surveys.length; i++) {
          survey_names.push(org_surveys[i].survey_id.name)
          if (i < org_surveys.length-1) {
            survey_names.push(', ')
          }
        }

        let parent_org_data = null;
        if(parseToInt(org_data.org.parent_org)) {
          parent_org_data = await account_api.get_org_by_id(org_data.org.parent_org);
        }

        this.setState({
          org: {
            ...org_data.org,
            license: org_data.license,
            clinician_code: org_data.clinician_code,
            parent_organization: parent_org_data,
          },
          survey_display: survey_names
        });
      } catch (error) {
        console.log(error);
      } finally {
        this.setState({loading: false});
      }
    }
  }

  render() {
    return (
      <React.Fragment>

        <NavTop history={this.props.history} />

        <section className="wow fadeIn section animated" style={{paddingBottom: '0px', visibility: 'visible', animationName: 'fadeIn'}}>
        	<div className="container">
        		<div className="section-header">
        			<div className="row vcenter">
        				<div className="col-md-6 col-xs-12">
        					<h2>{this.state.org.org_name}</h2>
        				</div>
        				<div className="col-md-4 col-md-offset-2 justify-right hidden"></div>
        			</div>
        		</div>

            <AccountTabDisplay
              org={this.state.org}
              survey_display={this.state.survey_display}
              showFilter={true}
              headerBtnCssClass="btn btn-light btn-xs"
              org_id={this.state.org.id}
              newSubOrg={this.newSubOrg}
            />

        	</div>
        </section>

        <ActivityIndicator
          loading={this.state.loading}
          modal={true}
        />

      </React.Fragment>

    );
  }
}

export default ClinicianSubAccountPage;
