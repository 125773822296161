import lodash from 'lodash'
import { createUUID } from '../components/react-flow-pedigree/utils';

export const family_members_count = {
  partners: '',
  brothers: '',
  sisters: '',
  paternal_uncles: '',
  paternal_aunts: '',
  maternal_uncles: '',
  maternal_aunts: ''
}

export const father_mother = {
  id: null,
  first_name: '',
  last_name: '',
  twin_set: null,
  twin_type: null,
  cause_of_death: null,
  is_dead: false,
  age_of_death: null,
  ancestry: null
}

export const partner = {
  rkey: null,
  id: null,
  first_name: '',
  last_name: '',
  status: null,
  blood_relation: false,
  sons_count: 0,
  daughters_count: 0,
  unknown_count: 0,
  relationship_data: {
    id: null,
    pregnancy: null,
    marital_status: null,
    is_parent_blood_related: false,
    blood_relation_type: null,
    mother_id: null,
    father_id: null,
  },
  hash_key: null,
  cause_of_death: null,
  is_dead: false,
  age_of_death: null
}

export const son_daughter = {
  rkey: null,
  id: null,
  first_name: '',
  last_name: '',
  twin_set: null,
  twin_type: null,
  adopted_status: null,
  gender: '', //M or F
  partner_count: 0,
  cause_of_death: null,
  is_dead: false,
  age_of_death: null
}

export const sibling = {
  rkey: null,
  id: null,
  first_name: '',
  last_name: '',
  twin_set: null,
  twin_type: null,
  adopted_status: null,
  half_sibling_shared_parent: null,
  half_sibling_other_parent: null,
  gender: '', //M or F
  partner_count: 0,
  cause_of_death: null,
  is_dead: false,
  age_of_death: null,
  father_id: null,
  mother_id: null
}

export const uncle_aunt = {
  rkey: null,
  id: null,
  first_name: '',
  last_name: '',
  twin_set: null,
  twin_type: null,
  adopted_status: null,
  half: false,
  half_sibling_shared_parent: null,
  half_sibling_other_parent: null,
  gender: '', //M or F
  partner_count: 0,
  cause_of_death: null,
  is_dead: false,
  age_of_death: null
}

export const patient = {
  rkey: createUUID(),
  hash_key: null,
  id: null,
  uuid: null,
  family_id: null,
  patient_id: '',
  first_name: '',
  last_name: '',
  middle_name: '',
  gender: '',
  gender_identity: null,
  twin_set: null,
  twin_type: null,
  adopted_status: null,
  partner_count: 0,
  ethnicity: null,
  birthDate: '',
  email: '',
  phone_number: '', //'(541) 754-3010',
  jewish: null,
  father_adopted: false,
  mother_adopted: false,
  pronoun: '',
  other_pronoun: '',
  other: '',
  father: null,
  mother: null,
  paternal_grandfather: null, //father_mother
  paternal_grandmother: null, //father_mother
  maternal_grandfather: null, //father_mother
  maternal_grandmother: null, //father_mother
  cause_of_death: null,
  is_dead: false,
  age_of_death: null
}

/* - - - - - - - - - - - - - - -
|
| METHODS
|
| - - - - - - - - - - - - - - */

export const createPatient = () => {
  let tmp = lodash.cloneDeep(patient)
  tmp.hash_key = new Date().getTime()
  tmp.father = createFatherMother('m')
  tmp.mother = createFatherMother('f')
  tmp.paternal_grandfather = createFatherMother('m')
  tmp.paternal_grandmother = createFatherMother('f')
  tmp.maternal_grandfather = createFatherMother('m')
  tmp.maternal_grandmother = createFatherMother('f')
  return tmp
}

export const createFatherMother = (gender='m') => {
  let tmp = lodash.cloneDeep(father_mother)
  tmp.gender = gender
  tmp.rkey = createUUID()
  return tmp
}

export const createPartner = () => {
  let tmp = lodash.cloneDeep(partner)
  tmp.rkey = createUUID()
  tmp.hash_key = new Date().getTime()
  return tmp
}

export const createSonDaughter = (gender='m') => {
  let tmp = lodash.cloneDeep(son_daughter)
  tmp.rkey = createUUID()
  tmp.gender = gender
  return tmp
}

export const createSibling = (gender='m') => {
  let tmp = lodash.cloneDeep(sibling)
  tmp.rkey = createUUID()
  tmp.gender = gender
  return tmp
}

export const createUncleAunt = (gender='m') => {
  let tmp = lodash.cloneDeep(uncle_aunt)
  tmp.rkey = createUUID()
  tmp.gender = gender
  return tmp
}

export const createPartnersByCount = (total_count) => {
  var tmp = []
  for(var i=1; i <= total_count; i++) {
    tmp.push(createPartner())
  }
  return tmp
}

export const createSiblingsByCount = (gender, total_count) => {
  var tmp = []
  for(var i=1; i <= total_count; i++) {
    tmp.push(createSibling(gender))
  }
  return tmp
}

export const createUnclesAuntsByCount = (gender, total_count) => {
  var tmp = []
  for(var i=1; i <= total_count; i++) {
    tmp.push(createUncleAunt(gender))
  }
  return tmp
}

export const createSonsDaughtersByCount = (gender, total_count) => {
  var tmp = []
  for(var i=1; i <= total_count; i++) {
    tmp.push(createSonDaughter(gender))
  }
  return tmp
}
