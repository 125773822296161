import React, { Component } from 'react';

import skip_logic_map from '../assets/json/diseases-skip-logic-map.json';
const keys_mapping = skip_logic_map.prostate

class SkipLogicProstateView extends Component {

  getValue(key) {
    if(!(key in keys_mapping)) return null

    let code = keys_mapping[key]
    let data = this.props.skipLogics.find(item => item.skip_logic_code == code)

    if(typeof(data) === 'undefined') return null
    return data.answer
  }
  
  render() {
    let sl = this.props.skipLogics;
    if(sl == null) return null;
    let metastatic = this.getValue('metastatic');
    let agressive = this.getValue('agressive'); 
    let intraductal = this.getValue('intraductal'); 

    return (
      <React.Fragment >
        <p>
          {metastatic == "y" && 'Metastatic'}
        </p>
        <p>
          {agressive == "y" && 'Aggressive (Gleason score 7 or higher)'}
        </p>
        <p>
          {intraductal == "y" && 'Intraductal/Cribriform'}
        </p>
      </React.Fragment>
    )
  }

}

export default SkipLogicProstateView