import React from "react";

import { CompletedNotificationItem } from "./notification-items";

const TabCompleted = props => {
  let {active, type, notifications} = props
  let active_class = active ? ' in active' : '';
  return (
    <div className={"tab-pane fade " + active_class}>

      {notifications.map((n, index) => (
        <CompletedNotificationItem key={index} notification={n} />
      ))}

      <br />
      <br />
    </div>
  );
};

export default TabCompleted;
