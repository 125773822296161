import React, { Component } from 'react';
import Dropdown from 'react-dropdown'

const SelectPositiveNegative = (props) => {
  let select_options = [
    { value: '', label: ' ' },
    { value: 'p', label: 'Positive' },
    { value: 'n', label: 'Negative' },
    { value: 'unknown', label: 'Unknown' },
  ]
  return (
    <Dropdown
      name={props.name}
      className='react-select-container'
      classNamePrefix="react-select"
      isClearable={true}
      placeholder=""
      options={select_options}
      value={props.value}
      onChange={(value) => {
        props.onChange(value)
      }}
    />

  )
}

export default SelectPositiveNegative