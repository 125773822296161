import types from "./types";

export const authenticated = (status=false) => {
  return {
		type: types.AUTHENTICATED,
		payload: {authenticated: status}
	 }
}

export const user_save = (payload) => {
  return {
		type: types.USER_SAVE,
		payload
	 }
}