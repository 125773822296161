import types from "./types";

export const save_patient = (payload) => {
  return {
		type: types.SAVE_PATIENT,
		payload: payload
	 }
}

export const clear_patient = () => {
  return {
		type: types.CLEAR_PATIENT
	 }
}

export const fill_profiles = (payload={}) => {
  return {
		type: types.FILL_PROFILES,
		payload: payload
	 }
}

export const save_profile = (payload) => {
  return {
		type: types.SAVE_PROFILE,
		payload: payload
	 }
}

export const delete_profile = (payload) => {
  return {
		type: types.DELETE_PROFILE,
		payload: payload
	 }
}

export const clear_profiles = () => {
  return {
		type: types.CLEAR_PROFILES
	 }
}

export const save_patient_data = (payload) => {
  return {
		type: types.SAVE_PATIENT_DATA,
		payload: payload
	 }
}

export const update_family = (payload) => {
  return {
		type: types.UPDATE_FAMILY,
		payload: payload
	 }
}

export const fill_partners = (payload={}) => {
  return {
		type: types.FILL_PARTNERS,
		payload: payload
	 }
}

export const populate_partner_by_count = (payload) => {
  return {
		type: types.POPULATE_PARTNERS_BY_COUNT,
		payload: payload
	 }
}

export const save_partner_detail = (payload) => {
  return {
		type: types.SAVE_PARTNER_DETAIL,
		payload: payload
	 }
}

export const clear_partners = (payload={}) => {
  return {
		type: types.CLEAR_PARTNERS,
		payload: payload
	 }
}

export const delete_partner = (payload) => {
  return {
		type: types.DELETE_PARTNER,
		payload: payload
	 }
}

// export const populate_sons_daughters_by_count = (payload) => {
//   return {
// 		type: types.POPULATE_SONS_DAUGHTERS_BY_COUNT,
// 		payload: payload
// 	 }
// }

export const fill_sons_daughters = (payload={}) => {
  return {
		type: types.FILL_SONS_DAUGHTERS,
		payload: payload
	 }
}

export const save_sons_daughters = (payload) => {
  return {
		type: types.SAVE_SONS_DAUGHTERS,
		payload: payload
	 }
}

// export const save_sons_daughters_by_gender = (payload) => {
//   return {
// 		type: types.SAVE_SONS_DAUGHTERS_BY_GENDER,
// 		payload: payload
// 	 }
// }

export const save_son_daughter_detail = (payload) => {
  return {
		type: types.SAVE_SON_DAUGHTER_DETAIL,
		payload: payload
	 }
}

export const clear_sons_daughters = () => {
  return {
		type: types.CLEAR_SONS_DAUGHTERS
	 }
}

export const delete_son_daughter = (payload) => {
  return {
		type: types.DELETE_SON_DAUGHTER,
		payload: payload
	 }
}

export const fill_siblings = (payload={}) => {
  return {
		type: types.FILL_SIBLINGS,
		payload: payload
	 }
}

export const populate_siblings_by_count = (payload) => {
  return {
		type: types.POPULATE_SIBLINGS_BY_COUNT,
		payload: payload
	 }
}

export const save_siblings = (payload) => {
  return {
		type: types.SAVE_SIBLINGS,
		payload: payload
	 }
}

export const save_sibling_detail = (payload) => {
  return {
		type: types.SAVE_SIBLING_DETAIL,
		payload: payload
	 }
}

export const clear_siblings = () => {
  return {
		type: types.CLEAR_SIBLINGS
	 }
}

export const delete_sibling = (payload) => {
  return {
		type: types.DELETE_SIBLING,
		payload: payload
	 }
}

export const delete_parent_ids_on_top_level_delete = (payload) => {
	return {
		type: types.DELETE_PARENTS_IDS_ON_TOP_LEVEL_DELETE,
		payload: payload
	 }
}

export const set_twins = (payload) => {
  return {
		type: types.SET_TWINS,
		payload: payload
	 }
}

export const fill_uncles_aunts = (payload={}) => {
  return {
		type: types.FILL_UNCLES_AUNTS,
		payload: payload
	 }
}

export const populate_uncles_aunts_by_count = (payload) => {
  return {
		type: types.POPULATE_UNCLES_AUNTS_BY_COUNT,
		payload: payload
	 }
}

export const save_uncles_aunts_by_gender = (payload) => {
  return {
		type: types.SAVE_UNCLES_AUNTS_BY_GENDER,
		payload: payload
	 }
}

export const save_uncles_aunts = (payload) => {
  return {
		type: types.SAVE_UNCLES_AUNTS,
		payload: payload
	 }
}

export const save_uncle_aunt_detail = (payload) => {
  return {
		type: types.SAVE_UNCLE_AUNT_DETAIL,
		payload: payload
	 }
}

export const clear_uncles_aunts = () => {
  return {
		type: types.CLEAR_UNCLES_AUNTS
	 }
}

export const delete_uncle_aunt = (payload) => {
  return {
		type: types.DELETE_UNCLE_AUNT,
		payload: payload
	 }
}

export const save_history_detail = (payload) => {
  return {
		type: types.SAVE_HISTORY_DETAIL,
		payload: payload
	 }
}

export const fill_history_diseases = (payload={}) => {
  return {
		type: types.FILL_HISTORY_DISEASES,
		payload: payload
	 }
}

export const save_history_diseases = (payload) => {
  return {
		type: types.SAVE_HISTORY_DISEASES,
		payload: payload
	 }
}

export const save_history_disease_detail = (payload) => {
  return {
		type: types.SAVE_HISTORY_DISEASE_DETAIL,
		payload: payload
	 }
}

export const clear_history_diseases = () => {
  return {
		type: types.CLEAR_HISTORY_DISEASES
	 }
}

export const delete_history_disease_detail = (payload) => {
  return {
		type: types.DELETE_HISTORY_DISEASE_DETAIL,
		payload: payload
	 }
}

export const fill_history_genes = (payload={}) => {
  return {
		type: types.FILL_HISTORY_GENES,
		payload: payload
	 }
}

export const save_history_genes = (payload) => {
  return {
		type: types.SAVE_HISTORY_GENES,
		payload: payload
	 }
}

export const save_history_gene_detail = (payload) => {
  return {
		type: types.SAVE_HISTORY_GENE_DETAIL,
		payload: payload
	 }
}

export const delete_history_gene_detail = (payload) => {
  return {
		type: types.DELETE_HISTORY_GENE_DETAIL,
		payload: payload
	 }
}

export const clear_history_genes = () => {
  return {
		type: types.CLEAR_HISTORY_GENES
	 }
}

export const save_documents = (payload) => {
  return {
		type: types.SAVE_DOCUMENTS,
		payload: payload
	 }
}

export const save_document = (payload) => {
  return {
		type: types.SAVE_DOCUMENT,
		payload: payload
	 }
}

export const delete_document = (payload) => {
  return {
		type: types.DELETE_DOCUMENT,
		payload: payload
	 }
}

export const save_risk_settings = (payload) => {
  return {
		type: types.SAVE_RISK_SETTINGS,
		payload: payload
	 }
}

export const save_risk_factors = (payload) => {
  return {
		type: types.SAVE_RISK_FACTORS,
		payload: payload
	 }
}

export const clear_risk_factors = () => {
  return {
		type: types.CLEAR_RISK_FACTORS
	 }
}

export const save_risk_results = (payload) => {
  return {
    type: types.SAVE_RISK_RESULTS,
    payload: payload
  }
}

export const save_risk_criteria = (payload) => {
  return {
    type: types.SAVE_RISK_CRITERIA,
    payload: payload
  }
}

export const reset_risk_results = (payload) => {
  return {
    type: types.RESET_RISK_RESULTS,
    payload: payload
  }
}

export const save_hidden_disease_colors = (payload) => {
	return {
		type: types.SAVE_HIDDEN_DISEASE_COLORS,
		payload: payload
	}
}

export const add_to_hidden_disease_colors = (payload) => {
	return {
		type: types.ADD_TO_HIDDEN_DISEASE_COLORS,
		payload: payload
	}
}

export const remove_from_hidden_disease_colors = (payload) => {
	return {
		type: types.REMOVE_FROM_HIDDEN_DISEASE_COLORS,
		payload: payload
	}
}

export const save_has_half_or_great_uncles_aunts = (payload) => {
	return {
		type: types.SAVE_HAS_HALF_OR_GREAT_UNCLES_AUNTS,
		payload: payload
	}
}

export const save_family_pedigree_notes = (payload) => {
	return {
		type: types.SAVE_FAMILY_PEDIGREE_NOTES,
		payload: payload
	}
}