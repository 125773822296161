import React, { Component } from 'react';

import Select from 'react-select';
import settings from '../configs/settings';

class ModalPartnerDetailsItem extends Component {

  constructor(props) {
    super(props)
    this.state = {
        rkey: null,
        first_name: '',
        sons_count: 0,
        daughters_count: 0,
        unknown_count: 0,
        relationship_data: {
          id: null,
          pregnancy: null,
          marital_status: null,
          is_parent_blood_related: false,
          blood_relation_type: null,
          mother_id: null,
          father_id: null
        },
    };
  }

  componentDidMount() {
    this.setState({...this.props.partner, sons_count: this.props.sons_count, daughters_count: this.props.daughters_count, unknown_count: this.props.unknown_count});
  }

  async onChange(field, value) {
    await this.setState({[field]: value});
    this.props.onChange(this.state);
  }

  async onChangeMaritalStatus(value) {
    let relationship_data = this.state.relationship_data
    relationship_data.marital_status = (value) ? value.value : null
    await this.setState({relationship_data})
    this.props.onChange(this.state);
  }

  async onChangePregnancy(value) {
    let relationship_data = this.state.relationship_data
    relationship_data.pregnancy = (value) ? value.value : null
    await this.setState({relationship_data})
    this.props.onChange(this.state);
  }

  async onChangeBloodRelation(value) {
    let relationship_data = this.state.relationship_data
    relationship_data.is_parent_blood_related = value
    await this.setState({relationship_data})
    this.props.onChange(this.state);
  }

  render() {
    let self = this;

    let sons = (self.state.sons_count > 0) ? self.state.sons_count : ""
    let daughters = (self.state.daughters_count > 0) ? self.state.daughters_count : ""
    let unknown = (self.state.unknown_count > 0) ? self.state.unknown_count : ""

    let marital_status = self.state.relationship_data.marital_status
    marital_status = settings.marriage_status_options.find( item => marital_status && marital_status.toLowerCase() == item.value.toLowerCase())
    marital_status = (typeof(marital_status) === 'undefined') ? null : marital_status;

    let pregnancy = self.state.relationship_data.pregnancy
    pregnancy = settings.pregnancy_options.find(item => pregnancy && pregnancy.toLowerCase() == item.value.toLowerCase())
    pregnancy = (typeof(pregnancy) === 'undefined') ? null : pregnancy;

    let is_parent_blood_related = self.state.relationship_data.is_parent_blood_related

    return (
      <React.Fragment>
        <div className="row">
          <div className="col-md-12">
            <p className="form-group-title">{this.props.label}</p>
          </div>
        </div>

        <div className="row margin-four-bottom">
          <div className="col-md-3 col-xs-12">
            <label>First Name</label>
            <div className="form-group">
              <input
                  onChange={(e) => this.onChange('first_name', e.target.value)}
                  value={this.state.first_name}
                  type="text" className="form-control normal-input-text" />
            </div>
          </div>

          <div className="col-md-3 col-xs-12">
            <label>Status</label>
            <div className="custom-select">
              <Select
                value={marital_status}
                onChange={(item) => this.onChangeMaritalStatus(item)}
                className='react-select-container'
                classNamePrefix="react-select"
                isClearable={true}
                placeholder=""
                options={settings.marriage_status_options}
              />
            </div>
          </div>

          <div className="col-md-3 col-xs-12">
            <div className="m-top-two">
              <label>Pregnancy</label>
              <div className="custom-select">
               <Select
                  value={pregnancy}
                  onChange={(item) => this.onChangePregnancy(item)}
                  className='react-select-container'
                  classNamePrefix="react-select"
                  isClearable={true}
                  placeholder=""
                  options={settings.pregnancy_options}
                />
              </div>
            </div>
          </div>

          <div className="col-md-3 col-xs-12">
            <div className="m-top-two">
              <label>Blood Relation?</label>
              <div>
                <label className="switch">
                  <input
                    checked={is_parent_blood_related}
                    onChange={(e) => this.onChangeBloodRelation(e.target.checked)}
                    type="checkbox" />
                  <span className="slider round"></span>
                </label>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="col-md-3 col-xs-12">
                <label>Sons</label>
                <div className="form-group">
                  <input
                    onChange={(e) => this.onChange('sons_count', e.target.value)}
                    value={sons}
                    type="text" className="form-control normal-input-text" />
                </div>
              </div>
              <div className="col-md-3 col-xs-12">
                <label>Daughters</label>
                <div className="form-group">
                  <input
                    onChange={(e) => this.onChange('daughters_count', e.target.value)}
                    value={daughters}
                    type="text" className="form-control normal-input-text" />
                </div>
              </div>
              <div className="col-md-3 col-xs-12">
                <label>Unspecified Sex</label>
                <div className="form-group">
                  <input
                    onChange={(e) => this.onChange('unknown_count', e.target.value)}
                    value={unknown}
                    type="text" className="form-control normal-input-text" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <hr />

      </React.Fragment>
    );
  }
}

export default ModalPartnerDetailsItem;
