import React, { Component } from 'react';

import * as model from '../model-states/m-family-tree'
//import lodash from 'lodash'

class ModalAddFamilyMember extends Component {

  constructor(props) {
    super(props)
    this.state = model.family_members_count
    this.onChange = this.onChange.bind(this)
  }

  componentDidUpdate(prevProps) {
    // if(!lodash.isEqual(prevProps.familyMembers, this.props.familyMembers)) {
    //   this.setState({...this.props.familyMembers})
    // }
  }

  componentDidMount() {
    // this.setState({...this.props.familyMembers})
  }

  onChange(e) {
    this.setState({[e.target.name]: e.target.value})
  }

  render() {
    return (

      <div onClick={() => this.props.onClose()}
        style={{ display: ((this.props.isOpen) ? 'block' : 'none') }}
        className={"modal fade " + ((this.props.isOpen) ? 'in' : '')}
        role="dialog">

        <div onClick={(e) => e.stopPropagation()} className="modal-dialog modal-sm" role="document">
          <div className="modal-content">

            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => this.props.onClose()}>
                <i className="fa fa-close"></i>
              </button>
              <h4 className="modal-title text-white text-capitalize">Add Family Member</h4>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-md-8 col-sm-8 col-xs-8">
                  <p className="form-group-title">Relationship</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                  <p className="form-group-title">Number</p>
                </div>
              </div>

              <div className="form-horizontal">

                {/* <div className="form-group">
                  <label className="control-label col-md-8 col-sm-8 col-xs-8">Partners</label>
                  <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                    <input
                      onChange={this.onChange}
                      value={this.state.partners}
                      name='partners'
                      type="text" className="form-control" placeholder="0" />
                  </div>
                </div> */}

                <div className="form-group">
                  <label className="control-label col-md-8 col-sm-8 col-xs-8">Brothers</label>
                  <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                    <input
                      autoFocus
                      onChange={this.onChange}
                      value={this.state.brothers}
                      name='brothers'
                      type="text" className="form-control" />
                  </div>
                </div>
                <div className="form-group">
                  <label className="control-label col-md-8 col-sm-8 col-xs-8">Sisters</label>
                  <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                    <input
                      onChange={this.onChange}
                      value={this.state.sisters}
                      name='sisters'
                      type="text" className="form-control" />
                  </div>
                </div>
                <div className="form-group">
                  <label className="control-label col-md-8 col-sm-8 col-xs-8">Paternal Uncles</label>
                  <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                    <input
                      onChange={this.onChange}
                      value={this.state.paternal_uncles}
                      name='paternal_uncles'
                      type="text" className="form-control" />
                  </div>
                </div>
                <div className="form-group">
                  <label className="control-label col-md-8 col-sm-8 col-xs-8">Paternal Aunts</label>
                  <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                    <input
                      onChange={this.onChange}
                      value={this.state.paternal_aunts}
                      name='paternal_aunts'
                      type="text" className="form-control" />
                  </div>
                </div>
                <div className="form-group">
                  <label className="control-label col-md-8 col-sm-8 col-xs-8">Maternal Uncles</label>
                  <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                    <input
                      onChange={this.onChange}
                      value={this.state.maternal_uncles}
                      name='maternal_uncles'
                      type="text" className="form-control" />
                  </div>
                </div>
                <div className="form-group">
                  <label className="control-label col-md-8 col-sm-8 col-xs-8">Maternal Aunts</label>
                  <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                    <input
                      onChange={this.onChange}
                      value={this.state.maternal_aunts}
                      name='maternal_aunts'
                      type="text" className="form-control" />
                  </div>
                </div>


              </div>
            </div>

            <div className="modal-footer">
              <button
                onClick={() => this.props.onClose()}
                type="button" className="btn btn-light-outline no-margin-right" data-dismiss="modal">Cancel</button>
              <button
                onClick={() => this.props.onClickSave(this.state)}
                type="button" className="btn btn-dark">Save</button>
            </div>
          </div>
        </div>
      </div>


    )
  }

}

export default ModalAddFamilyMember
