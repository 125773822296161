import React, { Component } from 'react';

import skip_logic_map from '../assets/json/diseases-skip-logic-map.json';
const keys_mapping = skip_logic_map.pancreatic_neuroendocrine

class SkipLogicPancreaticNeuroendocrineView extends Component {

  getValue(key) {
    if(!(key in keys_mapping)) return null

    let code = keys_mapping[key]
    let data = this.props.skipLogics.find(item => item.skip_logic_code == code)

    if(typeof(data) === 'undefined') return null
    return data.answer
  }

  render() {
    let sl = this.props.skipLogics;
    if(sl == null) return null;
    let pancreatic_neuro = this.getValue('pancreatic_neuro');    

    return (
      <React.Fragment >
        <p>
          {pancreatic_neuro == "y" && 'More than one tumor'}
        </p>
      </React.Fragment>
    )
  }

}

export default SkipLogicPancreaticNeuroendocrineView