import { combineReducers } from 'redux';
import types from "./types";
import { fromJS, updateIn, setIn } from 'immutable'
import init_states from './init_states'

function patient_bus(state = init_states.patient_bus, action) {
  switch (action.type) {
    case types.SAVE_PATIENT_BUS:
      return Object.assign({}, state, action.payload)
    default:
      return state;
  }
}

export default combineReducers({
  patient_bus
});
