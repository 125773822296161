import SurveyAPI from "../api/survey-api";
const surveyAPI = new SurveyAPI();

const get_survey = (payload, headers, callback, callbackError) => {
  surveyAPI.getSurvey(payload, headers)
  .then((response) => {

    if (response.status !== 200) callback(null);
    callback(response.data);

  }).catch((error) => {
    callbackError(error);
  });
}

const get_clinician_surveys = (payload, headers, callback, callbackError) => {
  surveyAPI.getClinicianSurveys(payload, headers)
  .then((response) => {

    if (response.status !== 200) callback(null);
    callback(response.data);

  }).catch((error) => {
    callbackError(error);
  });
}

const get_survey_answers_as_clinician = (payload, headers, callback, callbackError) => {
  surveyAPI.getSurveyAnswersAsClinician(payload, headers)
  .then((response) => {

    if (response.status !== 200) callback(null);
    callback(response.data);

  }).catch((error) => {
    callbackError(error);
  });
}

const get_risk_factor_survey_answers_as_clinician = (payload, headers, callback, callbackError) => {
  surveyAPI.getRiskFactorSurveyAnswersAsClinician(payload, headers)
  .then((response) => {

    if (response.status !== 200) callback(null);
    callback(response.data);

  }).catch((error) => {
    callbackError(error);
  });
}

const create_completed_survey_answer = (payload, headers, callback, callbackError) => {
  surveyAPI.createCompletedSurveyAnswer(payload, headers)
  .then((response) => {

    if (response.status !== 200 && response.status !== 201) callback(null, null);
    callback(response.data, payload.master_question_id);

  }).catch((error) => {
    callbackError(error);
  });
}

const update_completed_survey_answer = (payload, headers, callback, callbackError) => {
  surveyAPI.updateCompletedSurveyAnswer(payload, headers)
  .then((response) => {

    if (response.status !== 200) callback(null, null);
    callback(response.data, payload.master_question_id);

  }).catch((error) => {
    callbackError(error);
  });
}

const delete_completed_survey_answer = (payload, headers, callback, callbackError) => {
  surveyAPI.deleteCompletedSurveyAnswer(payload, headers)
  .then((response) => {

    if (response.status !== 200) callback(null, null);
    callback(response.data, payload.master_question_id);

  }).catch((error) => {
    callbackError(error);
  });
}

const save_risk_factor_answer_as_clinician = (payload, headers, callback, callbackError) => {
  surveyAPI.saveRiskFactorAnswerAsClinician(payload, headers)
  .then((response) => {

    if (response.status !== 200) callback(null);
    callback(response.data);

  }).catch((error) => {
    callbackError(error);
  });
}

const delete_risk_factor_answer_as_clinician = (payload, headers, callback, callbackError) => {
  surveyAPI.deleteRiskFactorAnswerAsClinician(payload, headers)
  .then((response) => {

    if (response.status !== 200) callback(null);
    callback(response.data);

  }).catch((error) => {
    callbackError(error);
  });
}

const save_completed_survey = (payload, headers, callback, callbackError) => {
  surveyAPI.saveCompletedSurvey(payload, headers)
  .then((response) => {

    if (response.status !== 200) callback(null);
    callback(response.data);

  }).catch((error) => {
    callbackError(error);
  });
}

const send_proband_completed_survey_notification = (payload, headers, callback, callbackError) => {
  surveyAPI.sendProbandCompletedSurveyNotification(payload, headers)
  .then((response) => {

    if (response.status !== 200) callback(null);
    callback(response.data);

  }).catch((error) => {
    callbackError(error);
  });
}

const send_notification_to_proband_for_updating_survey_answer = (payload, headers, callback, callbackError) => {
  surveyAPI.sendNotificationToProbandForSurveyAnswer(payload, headers)
  .then((response) => {

    if (response.status !== 200) callback(null);
    callback(response.data);

  }).catch((error) => {
    callbackError(error);
  });
}

const send_notification_to_clinicians_for_updating_survey_answer = (payload, headers, callback, callbackError) => {
  surveyAPI.sendNotificationToClinicianForSurveyAnswer(payload, headers)
  .then((response) => {

    if (response.status !== 200) callback(null);
    callback(response.data);

  }).catch((error) => {
    callbackError(error);
  });
}

export default {
  get_survey,
  get_clinician_surveys,
  get_survey_answers_as_clinician,
  get_risk_factor_survey_answers_as_clinician,
  create_completed_survey_answer,
  update_completed_survey_answer,
  delete_completed_survey_answer,
  save_risk_factor_answer_as_clinician,
  delete_risk_factor_answer_as_clinician,
  save_completed_survey,
  send_proband_completed_survey_notification,
  send_notification_to_proband_for_updating_survey_answer,
  send_notification_to_clinicians_for_updating_survey_answer,
}