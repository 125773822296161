import React, { Component } from 'react';
import ReactDOM from 'react-dom';


type  ModalProps = {
    onClose: () => void;
    onSave: (name:string) => void;
    modalTitle: string
  }


interface State  {
    surveyName: string

}
class CopySurveyModal extends Component<ModalProps, State> {

  constructor(props:ModalProps) {
    super(props);
    this.state = {
        surveyName: ""
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    this.setState({ surveyName: event.target.value });
  }

  render() {
    return ReactDOM.createPortal(

        <div
        onClick={() => this.props.onClose()}
        style={{ zIndex: 9999, display:"block"}}
        className={"modal fade " + "in"}
        role="dialog"
      >
        <div
          onClick={e => e.stopPropagation()}
          className="modal-dialog modal-sm"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <button
                onClick={() => this.props.onClose()}
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <i className="fa fa-close" />
              </button>
              <h4 className="modal-title text-white text-capitalize">
                {this.props.modalTitle}
              </h4>
            </div>

            <div className="modal-body">
              <div className="modal-confirm">
                <p>
                New Survey Name
                </p>
                <input
                    type="text"
                    onChange={this.handleChange}
                    className="form-control"
                    name="surveyName"
                    value={this.state.surveyName}
                    id="survey-name"                
                 />                
                <br />
                  <div className="buttons">
                  <button
                    onClick={() => this.props.onSave(this.state.surveyName)}
                    className="btn btn-purple btn-sm"
                    aria-label="Close"
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>,      
      document.body
    );
  }

}

export default CopySurveyModal;
