import React, { Component } from 'react'
import { connect } from "react-redux"
import { debounce, isEqual } from 'lodash';
import FamilyTreeSonDaughter from './son-daughter'
import HealthInputs from './health-inputs'

import { log_error } from '../../helpers/helper-error'
import * as helper_family_tree from '../../helpers/helper-family-tree'

import helpers from '../../helpers';
import family_api from '../../api/family-api'
import ErrorSummary from '../error-summary';
import * as model from '../../model-states/m-family-tree';
import * as patient_actions from '../../store/patient/actions';
import { radix_bases } from '../../helpers/helper-number-bases';

class FamilyTreePartner extends Component {

  constructor(props) {
    super(props)
    this.state = {

      collapsed: true,
      sons: null,
      daughters: null,
      unknown_children: null,
      partnerRkey: null,
      ownerRkey: null,

      errorMessages: [],
      loading: false,
      father_id: null,
      mother_id: null
    }

    this.updateFirstName = debounce(this.updateFirstName, 250)

  }

  componentDidUpdate(prevProps) {
    this.loadSonsDaughtersFromRedux()
  }

  async componentDidMount() {

    if ('collapsed' in this.props) this.setState({ collapsed: this.props.collapsed })

    this.setState({
      partnerRkey: this.props.partner.rkey,
      ownerRkey: this.props.ownerRkey
    })

    // await this.populateChildrenFromAPI();

  }

  // async populateChildrenFromAPI() {
  //   try {
  //     let spouse_id = this.props.owner.id
  //     let partner_id = this.props.partner.id
  //     if(spouse_id == null) return

  //     // Clear partner son daugher redux before repopulating
  //     this.props.dispatch(patient_actions.delete_son_daughter({partnerRkey: this.state.partnerRkey}))

  //     // Call API
  //     let children = await family_api.get_members_memberid_children(spouse_id, partner_id)
  //     for(var child of children) {
  //       // Save to Redux
  //       var child_from_model = model.createSonDaughter(child.gender)
  //       var child_data = Object.assign({}, child_from_model, child)

  //       // Save to Redux
  //       helper_family_tree.saveProfileToRedux(this.props.dispatch, child_data)
  //       helper_family_tree.saveSonDaughterDetailToRedux(this.props.dispatch, this.state.partnerRkey , child_data)
  //     }
  //   } catch (error) {
  //     helpers.logger(error)
  //   }
  // }


  loadSonsDaughtersFromRedux() {

    if (this.state.partnerRkey == null) return

    if (!(this.state.partnerRkey in this.props.patient.sons_daughters)) return

    let sons_daughters = this.props.patient.sons_daughters[this.state.partnerRkey]

    for(let s_d of sons_daughters) {
      s_d.gender = (s_d.gender == null) ? 'u' : s_d.gender
    }

    // filter based on gender identity
    let sons = sons_daughters.filter(item => item.gender_identity ? item.gender_identity == "male" : item.gender && item.gender.toLowerCase() === "m" && !item.is_no_children_node && !item.is_infertility_node);
    let daughters = sons_daughters.filter(item => item.gender_identity ? item.gender_identity == "female" : item.gender && item.gender.toLowerCase() === "f" && !item.is_no_children_node && !item.is_infertility_node);
    let unknown_children = sons_daughters.filter(item => item.gender_identity ? item.gender_identity == "non-binary" : item.gender && item.gender.toLowerCase() === 'u' && !item.is_no_children_node && !item.is_infertility_node)
 
    if (!isEqual(this.state.sons, sons)) {
      this.setState({ sons })
    }

    if (!isEqual(this.state.daughters, daughters)) {
      this.setState({ daughters })
    }

    if (!isEqual(this.state.unknown_children, unknown_children)) {
      this.setState({ unknown_children })
    }
  }

  onCollapseClick() {

    this.setState({ collapsed: !this.state.collapsed })
    this.clearSelection()

  }

  clearSelection() {
    if (window.getSelection) { window.getSelection().removeAllRanges(); }
    else if (document.selection) { document.selection.empty(); }
  }



  async onChangeName(first_name) {
    try {

      let profile = { rkey: this.state.partnerRkey, first_name }
      helper_family_tree.saveProfileToRedux(this.props.dispatch, profile)
      helper_family_tree.savePartnerToRedux(this.props.dispatch, this.state.ownerRkey, profile)
      this.updateFirstName(first_name)
    } catch (error) {
      log_error(this.constructor.name, error)
    }
  }

  async updateFirstName(first_name) {
    try {
      this.setState({errorMessages: []})

      let member = this.props.partner;
      await family_api.patch_member_memberid(member.id, {
        first_name
      })
    } catch (error) {
      this.setState({errorMessages: [error.message] })
    }
  }

  showCollapseButton() {
    return (parseInt(this.props.partner.sons_count, radix_bases.base10) + parseInt(this.props.partner.daughters_count, radix_bases.base10)) > 0;
  }

  getTotalIndent() {
    return this.props.level + this.props.extra_indent
  }

  getFirstName() {

    let profile = this.getProfile();
    if(profile == null) return null;

    return profile.first_name;
  }

  getProfile() {
    return helper_family_tree.getProfileFromRedux(this.props.patient, this.props.partner.rkey)
  }

  async onClickDeleteConfirmed() {
    try {

      this.setState({errorMessages: [], loading: true})

      await this.props.deleteMember(this.props.partner)

      helper_family_tree.deletePartner(
        this.props.patient,
        this.props.dispatch,
        this.props.ownerRkey,
        this.props.partner
      );

      this.setState({ openModalConfirmDelete: false })

    } catch (error) {
      helpers.logger(error)
      this.setState({errorMessages: [error.message]})
    } finally {
      this.setState({loading: false})
    }
  }

  render() {

    let indent = this.getTotalIndent();
    let indent_class="";
    if(indent === 1) {
      indent_class="table-indent-0-5";
    } else if (indent === 2) {
      indent_class="table-indent-2";
    } else if (indent === 3) {
      indent_class="table-indent-3-5";
    } else if (indent === 4) {
      indent_class="table-indent-5";
    } else if (indent === 5) {
      indent_class="table-indent-5-5";
    } else if (indent >= 6) {
      indent_class="table-indent-6";
    }

    return <React.Fragment>

      <tr>

        <td>
        </td>
        <td className="td--nowrap">

          <span className={indent_class}>
            {this.showCollapseButton() &&
              <a onClick={(e) => this.onCollapseClick(e)}>
                <i className={"fa fa-" + (this.state.collapsed ? "minus-square" : "plus-square")}></i>
              </a>
            }
            {this.props.label}
          </span>

        </td>

        <td>
          <input
            onChange={(e) => this.onChangeName(e.target.value)}
            value={this.getFirstName()}
            type="text" className="inputreg form-control normal-input-text" />

          <ErrorSummary errorMessages={this.state.errorMessages} transparent={true}/>
        </td>


        <HealthInputs
          for="partner"
          dispatch={this.props.dispatch}
          patient={this.props.patient}
          errorMessages={this.state.errorMessages}
          loading={this.state.loading}
          ownerRkey={this.props.partner.rkey}
          collapsible={this.props.collapsible}
          collapsed={this.state.collapsed}
          onCollapse={() => this.onCollapseClick() }
          canDelete={true}
          onDeleteConfirmed={async () => await this.onClickDeleteConfirmed() }
          profile={this.getProfile()}
        />

      </tr>

      {this.state.collapsed && this.state.sons &&
        this.state.sons.map((son, index) => son.pregnancy ? null : <FamilyTreeSonDaughter
          key={son.rkey}
          partnerRkey={this.state.partnerRkey}
          level={this.props.level}
          extra_indent={this.props.extra_indent}
          type={'son'}
          ordinal={index + 1}
          son_daughter={son}
          parent_side={this.props.parent_side}
          father_id={this.state.father_id}
          mother_id={this.state.mother_id}
          dispatch={this.props.dispatch}
          patient={this.props.patient}
          deleteMember={this.props.deleteMember}
        />
        )}

      {this.state.collapsed && this.state.daughters &&
        this.state.daughters.map((daughter, index) => daughter.pregnancy ? null : <FamilyTreeSonDaughter
          key={daughter.rkey}
          partnerRkey={this.state.partnerRkey}
          level={this.props.level}
          extra_indent={this.props.extra_indent}
          type={'daughter'}
          ordinal={index + 1}
          son_daughter={daughter}
          parent_side={this.props.parent_side}
          father_id={this.state.father_id}
          mother_id={this.state.mother_id}
          dispatch={this.props.dispatch}
          patient={this.props.patient}
          deleteMember={this.props.deleteMember}
        />
        )}

        {this.state.collapsed && this.state.unknown_children &&
        this.state.unknown_children.map((child, index) => child.pregnancy ? null : <FamilyTreeSonDaughter
          key={child.rkey}
          partnerRkey={this.state.partnerRkey}
          level={this.props.level}
          extra_indent={this.props.extra_indent}
          type={'unknown'}
          ordinal={index + 1}
          son_daughter={child}
          parent_side={this.props.parent_side}
          father_id={this.state.father_id}
          mother_id={this.state.mother_id}
          dispatch={this.props.dispatch}
          patient={this.props.patient}
          deleteMember={this.props.deleteMember}
        />
        )}

    </React.Fragment>

  }
}

export default FamilyTreePartner;
