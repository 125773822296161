import React, { Component } from 'react'
import { connect } from "react-redux"

import route_helper from '../route-helper';
import helpers from '../helpers/index'
import NavTop from '../components/nav-top'

import FamilyTreeProband from '../components/family-history/proband'
import FamilyTreeProbandSiblings from '../components/family-history/proband-siblings'
import FamilyTreeProbandUnclesAunts from '../components/family-history/proband-uncles-aunts'
import FamilyTreeProbandFatherMother from '../components/family-history/proband-father-mother'
import FamilyGrandparentsExtension from '../components/family-history/family-grandparents-extension.js'
import DropDownHeader from '../components/family-history/dropdown-header'
import ErrorSummary from '../components/error-summary'
import ActivityIndicator from '../components/activity-indicator'

import { Link } from 'react-router-dom';

import * as helper_family_tree from '../helpers/helper-family-tree';

import { cloneDeep } from 'lodash'
import family_api from '../api/family-api'

class FamilyHistoryPage extends Component {

    constructor(props) {
        super(props)
        this.state = {
            errorMessages: [],
            loading: false,
            showProband: true,
            showSiblings: true,
            showParents: true,
            showMothersSide: true,
            showFathersSide: true
        }
        this.toggleFathersSide = this.toggleFathersSide.bind(this)
        this.toggleMothersSide = this.toggleMothersSide.bind(this)
        this.toggleParents = this.toggleParents.bind(this)
        this.toggleProband = this.toggleProband.bind(this)
        this.toggleSiblings = this.toggleSiblings.bind(this)
        this.deleteMember = this.deleteMember.bind(this)
    }

    componentDidUpdate(prevProps) {
        // console.log(this.props.patient.partners)
    }

    async componentDidMount() {

        try {
            helpers.setSiteTitle('Family Health History')

            if(this.props.patient.patient.id == null) {
                this.setState({loading: true})
                await helper_family_tree.populateProband(this.props.dispatch, this.props.match.params.member_id)
            }

        } catch (error) {
            console.log(error)
        } finally {
            this.setState({loading: false})
        }

    }

    async validate() {

        await this.setState({ validation: null })

        let options = {
            patientId: {
                labelName: 'Patient ID',
                validations: { required: true }
            }
        }

        let validations = helpers.validateInputs(this.state, options)

        await this.setState({ validations })

        return validations.isValid

    }
    toggleProband(){
        this.setState({showProband: !this.state.showProband})
    }
    toggleSiblings(){
        this.setState({showSiblings: !this.state.showSiblings})
    }
    toggleParents(){
        this.setState({showParents: !this.state.showParents})
    }
    toggleMothersSide(toggle){
        this.setState({showMothersSide: !this.state.showMothersSide})
    }
    toggleFathersSide(){
        this.setState({showFathersSide: !this.state.showFathersSide})
    }

    async deleteMember(profile){
        try {

          const deletable_members_map = await family_api.can_delete_member(profile.id);
          if (deletable_members_map.member_ids.length === 0 && deletable_members_map.message !== "") {
            const error = {
              message: deletable_members_map.message,
              type: ""
            };
            this.setState({ errorMessages: [error.message] });
          }

          // make a map of the delete ids
          const deletable_member_ids_map = {}
          for (let z=0; z<deletable_members_map.member_ids.length; z++) {
            deletable_member_ids_map[deletable_members_map.member_ids[z]] = deletable_members_map.member_ids[z];
          }

          // loop through and get any children of the memebrs to be deleted and set their mother_id and father_id to null
          let temp_profiles = cloneDeep(Object.values(this.props.patient.profile));
          for (let z=0; z<temp_profiles.length; z++) {
            let temp_profile = temp_profiles[z];
            if (temp_profile.mother_id && temp_profile.father_id) {
              if ( (temp_profile.mother_id in deletable_member_ids_map) ||
                  (temp_profile.father_id in deletable_member_ids_map)) {
                    temp_profile.mother_id = null;
                    temp_profile.mother_id_id = null;
                    temp_profile.father_id = null;
                    temp_profile.father_id_id = null;
                    helper_family_tree.saveProfileToRedux(this.props.dispatch, temp_profile);
                  }
            }
          }

          // loop through and remove the person from their partners partner array and relationship_ids array
          // relationship_data should also be reset if it matches the id
          temp_profiles = cloneDeep(Object.values(this.props.patient.profile));
          for (let z=0; z<temp_profiles.length; z++) {
            let changed = false;
            let temp_profile = temp_profiles[z];
            let partners = temp_profile.partners;
            let relationship_ids = temp_profile.relationship_ids;

            if (Array.isArray(partners)) {
              const new_partners = [];
              for (let l=0; l<partners.length; l++) {
                if (partners[l].id in deletable_member_ids_map) {
                  continue;
                } else {
                  new_partners.push(partners[l]);
                }
              }

              if (new_partners.length !== partners.length) {
                temp_profile.partners = new_partners;
                changed = true;
              }
            }

            if (Array.isArray(relationship_ids)) {
              const new_relationships = [];
              for (let l=0; l<relationship_ids.length; l++) {
                if (relationship_ids[l].mother_id in deletable_member_ids_map || relationship_ids[l].father_id in deletable_member_ids_map) {
                  continue;
                } else {
                  new_relationships.push(relationship_ids[l]);
                }
              }

              if (new_relationships.length !== relationship_ids.length) {
                temp_profile.relationship_ids = new_relationships;
                changed = true;
              }
            }

            if (temp_profile.relationship_data &&
              (temp_profile.relationship_data.mother_id in deletable_member_ids_map || temp_profile.relationship_data.father_id in deletable_member_ids_map)) {
                delete temp_profile.relationship_data;
            }

            if (changed) {
              helper_family_tree.saveProfileToRedux(this.props.dispatch, temp_profile);
            }
          }

          // hit bulk delete endpoint
          await family_api.bulk_delete_members(profile.id, deletable_members_map.member_ids);

          if (profile.twin_id_id) {
            let people = cloneDeep(Object.values(this.props.patient.profile));
            const twins = [];
            for (let i=0; i<people.length; i++) {
              if (people[i].twin_id_id === profile.twin_id_id) twins.push(people[i]);
            }

            if (twins.length === 1) {
              // delete the twin entry and clear twin_id and twin_set
              // if there is only one twin left after deletion

              /* Note: deletion of twin record is done within member deletion if
              ** only one twin is left, no need to manually do it
              */
              // await this.deleteTwinsEntry(profile);

              twins[0].twin_id_id = null;
              twins[0].twin_id = null;
              twins[0].twin_set = null;
              twins[0].twin_type = null;
              helper_family_tree.saveProfileToRedux(this.props.dispatch, twins[0]);
            }
          }

          this.setState({
            openModalConfirmDelete: false,
            errorMessages: []
          });

        } catch (error) {
          console.log(error);
          this.setState({ errorMessages: [error.message] });
        }
    }

    render() {

      let maternal_grandfather_rkey = null;
      let maternal_grandmother_rkey = null;
      let paternal_grandfather_rkey = null;
      let paternal_grandmother_rkey = null;

      if(this.props.patient.patient.maternal_grandfather) {
        maternal_grandfather_rkey = 'apimem-' + this.props.patient.patient.maternal_grandfather.id;
        maternal_grandmother_rkey = 'apimem-' + this.props.patient.patient.maternal_grandmother.id;
        paternal_grandfather_rkey = 'apimem-' + this.props.patient.patient.paternal_grandfather.id;
        paternal_grandmother_rkey = 'apimem-' + this.props.patient.patient.paternal_grandmother.id;
      }

        return (

            <React.Fragment>

                <NavTop history={this.props.history} />



                <section className="wow fadeIn section">
                    <div className="container">
                        <div className="section-header">
                            <div className="row vcenter">
                                <div className="col-md-6 col-xs-12">
                                    <h2>Family HEALTH HISTORY</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <table id="example" className="display table-custom responsive no-wrap dataTable sticky-hdr">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th>Relationship</th>
                                            <th>First Name</th>
                                            <th>Deceased</th>
                                            <th>Age/Age at Death</th>
                                            <th>Disease</th>
                                            <th>Genetic Tests</th>
                                            <th>Notes</th>
                                            <th>Remove</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    <DropDownHeader
                                        title="Proband and Children"
                                        display={this.state.showProband}
                                        handleToggle={this.toggleProband}
                                    />
                                    {this.state.showProband &&(
                                        <FamilyTreeProband
                                            key={this.props.patient.patient.hash_key}
                                            ownerRkey='proband'
                                            patient={this.props.patient}
                                            dispatch={this.props.dispatch}
                                            deleteMember={this.deleteMember}
                                        />
                                    )}
                                    <DropDownHeader
                                        title="Siblings"
                                        display={this.state.showSiblings}
                                        handleToggle={this.toggleSiblings}
                                    />
                                    {this.state.showSiblings &&(
                                        <FamilyTreeProbandSiblings
                                            patient={this.props.patient}
                                            dispatch={this.props.dispatch}
                                            proband_siblings={this.props.patient.siblings}
                                            deleteMember={this.deleteMember}
                                        />
                                    )}
                                    <DropDownHeader
                                        title="Parents"
                                        display={this.state.showParents}
                                        handleToggle={this.toggleParents}
                                    />
                                    {this.state.showParents &&(
                                        <FamilyTreeProbandFatherMother
                                            father={this.props.patient.patient.father}
                                            mother={this.props.patient.patient.mother}
                                            patient={this.props.patient}
                                            dispatch={this.props.dispatch}
                                            deleteMember={this.deleteMember}
                                        />
                                    )}
                                    <DropDownHeader
                                        title="Mother's Side"
                                        display={this.state.showMothersSide}
                                        handleToggle={this.toggleMothersSide}
                                    />
                                    {this.state.showMothersSide &&(
                                        <>
                                        <FamilyTreeProbandUnclesAunts
                                          parent_side="maternal"
                                          uncles_aunts={this.props.patient.uncles_aunts.maternal}
                                          patient={this.props.patient}
                                          dispatch={this.props.dispatch}
                                          deleteMember={this.deleteMember}
                                        />

                                        <FamilyGrandparentsExtension
                                          side={"maternal"}
                                          level={2}
                                          member={this.props.patient.profile[maternal_grandmother_rkey]}
                                          patient={this.props.patient}
                                          dispatch={this.props.dispatch}
                                          deleteMember={this.deleteMember}
                                        />

                                        <FamilyGrandparentsExtension
                                          side={"maternal"}
                                          level={2}
                                          member={this.props.patient.profile[maternal_grandfather_rkey]}
                                          patient={this.props.patient}
                                          dispatch={this.props.dispatch}
                                          deleteMember={this.deleteMember}
                                        />
                                        </>
                                    )}
                                    <DropDownHeader
                                        title="Father's Side"
                                        display={this.state.showFathersSide}
                                        handleToggle={this.toggleFathersSide}
                                    />
                                    {this.state.showFathersSide &&(
                                    <>
                                        <FamilyTreeProbandUnclesAunts
                                          parent_side="paternal"
                                          uncles_aunts={this.props.patient.uncles_aunts.paternal}
                                          patient={this.props.patient}
                                          dispatch={this.props.dispatch}
                                          deleteMember={this.deleteMember}
                                        />

                                        <FamilyGrandparentsExtension
                                          side={"paternal"}
                                          level={2}
                                          member={this.props.patient.profile[paternal_grandmother_rkey]}
                                          patient={this.props.patient}
                                          dispatch={this.props.dispatch}
                                          deleteMember={this.deleteMember}
                                        />

                                        <FamilyGrandparentsExtension
                                          side={"paternal"}
                                          level={2}
                                          member={this.props.patient.profile[paternal_grandfather_rkey]}
                                          patient={this.props.patient}
                                          dispatch={this.props.dispatch}
                                          deleteMember={this.deleteMember}
                                        />
                                    </>
                                    )}

                                    </tbody>
                                    <tfoot className="hidden">
                                        <tr>
                                            <th>Relationship</th>
                                            <th>First Name</th>
                                            <th>Add Partner with Child</th>
                                            <th>Twin</th>
                                            <th>Adopted</th>
                                            <th>Half Sibling</th>
                                        </tr>
                                    </tfoot>
                                </table>

                            </div>

                            <div className="section-footer">
                                <hr />
                                <a onClick={() => {
                                    let url = route_helper.pedigree.pedigree_root.replace(":member_id", this.props.match.params.member_id) + '/#family-history';
                                    this.props.history.push(url)

                                    // let url = route_helper.patient.patient_profile.replace(':member_id', this.props.match.params.member_id)
                                    // this.props.history.push('patient-profile/' + this.props.match.params.member_id + '#family-history')
                                }}
                                    className="btn btn-dark btn-sm" data-toggle="modal" data-target="#IdentityConsang"><i className="fa fa-arrow-right text-white"></i>Save and Continue</a>
                                <button
                                    onClick={() => {
                                      let url = route_helper.family.family_tree.replace(':member_id', this.props.match.params.member_id);
                                      this.props.history.push(url)
                                    }}
                                    className="btn btn-light-outline btn-sm" data-toggle="modal" data-target="#AddFamilyMember">
                                        <i className="fa fa-arrow-left text-white"></i>
                                        Edit Family Members
                                </button>

                                <ActivityIndicator
                                    modal={true}
                                    loading={this.state.loading}
                                />
                            </div>

                        </div>
                    </div>
                </section>



            </React.Fragment>

        )
    }
}

const redux_state = state => ({
    ...state
})

const redux_actions = dispatch => ({
    dispatch: (action) => dispatch(action)
})

export default connect(redux_state, redux_actions)(FamilyHistoryPage)
