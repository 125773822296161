import React, { Component } from 'react';
import ReactDOM from 'react-dom';

class ModalUserProfile extends Component {

  constructor(props) {
    super(props);
    this.state = this.props.user;

    this.onClickSave = this.onClickSave.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  onClickSave(event) {
    this.props.onClickSave(this.state);
    this.props.onClose();
  }

  onChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  render() {
    return ReactDOM.createPortal(
      <div onClick={() => this.props.onClose()}
        style={{ display: 'block', paddingLeft: '12px' }}
        className="modal fade in"
        role="dialog"
        aria-labelledby="user-profile"
        id="user-profile">

        <div onClick={(e) => e.stopPropagation()} className="modal-dialog" role="document">
          <div className="modal-content">

            <div className="modal-header">
              <button onClick={() => this.props.onClose()} type="button" className="close" data-dismiss="modal" aria-label="Close">
                <i className="fa fa-close"></i>
              </button>
              <h4 className="modal-title text-white text-capitalize">{this.props.user.first_name} {this.props.user.last_name}</h4>
            </div>

            <div className="modal-body">
              <form className="form-horizontal form-label-left form-custom">
                <div className="form-group">
                  <div className="row">
                    <div className="col-md-3 col-sm-3 col-xs-12">
                      <label htmlFor="first_name" className="control-label">First Name</label>
                    </div>
                    <div className="col-md-8 col-sm-9 col-xs-12">
                      <input type="text" className="form-control normal-input-text" name="first_name" id="first_name" onChange={this.onChange} value={this.state.first_name} />
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <div className="row">
                    <div className="col-md-3 col-sm-3 col-xs-12">
                      <label htmlFor="last_name" className="control-label">Last Name</label>
                    </div>
                    <div className="col-md-8 col-sm-9 col-xs-12">
                      <input type="text" className="form-control normal-input-text" name="last_name" id="last_name" onChange={this.onChange} value={this.state.last_name} />
                    </div>
                  </div>
                </div>

                <div className="form-group ">
                  <div className="row">
                    <div className="col-md-3 col-sm-3 col-xs-12">
                      <label htmlFor="email" className="control-label">Email Address</label>
                    </div>
                    <div className="col-md-8 col-sm-9 col-xs-12">
                      <input type="text" className="form-control normal-input-text" name="email" id="email" onChange={this.onChange} value={this.state.email} />
                    </div>
                  </div>
                </div>
              </form>
            </div>

            <div className="modal-footer">
              <button
                onClick={() => this.props.onClose()}
                type="button" className="btn btn-light-outline no-margin-right" data-dismiss="modal">Cancel</button>
              <button
                onClick={this.onClickSave}
                type="button" className="btn btn-dark">Update</button>
            </div>
          </div>
        </div>
      </div>,
      document.body
    );
  }

}

export default ModalUserProfile;
