import React from "react";

export default props => {
  return (
    <div className="patient-fam patient-fam--padding">
      <ul className="nav nav-pills">
        <li role="presentation">
          {" "}
          <a>Updates</a>
        </li>
      </ul>
    </div>
  );
};
