import React, { Component } from 'react'
import { connect } from "react-redux"
import lodash from 'lodash'
import { log_error } from '../../helpers/helper-error'

import uniqid from 'uniqid'
import Select from 'react-select'
import { fromJS, updateIn, update } from 'immutable'

import * as helper_family_tree from '../../helpers/helper-family-tree'

import FamilyTreePartner from './partner'
import HealthInputs from './health-inputs';

import helpers from '../../helpers';
import family_api from '../../api/family-api'
import ErrorSummary from '../error-summary';
import * as model from '../../model-states/m-family-tree';
import * as patient_actions from '../../store/patient/actions';


class FamilyTreeUncleAunt extends Component {

  constructor(props) {
    super(props)
    this.state = {
      errorMessages: [],
      loading: false,

      collapsed: true,
      uncleAuntRkey: null,
      partners: null,
    }

  }

  async componentDidMount() {
    if ('collapsed' in this.props) this.setState({ collapsed: this.props.collapsed})
    this.setState({uncleAuntRkey: this.props.uncle_aunt.rkey})

    // await this.populatePartnersFromAPI()
  }

  componentDidUpdate(prevProps) {
    this.loadPartnersFromRedux()
  }


  loadPartnersFromRedux() {

    if (this.state.uncleAuntRkey == null) return

    if(!(this.state.uncleAuntRkey in this.props.patient.partners)) return

    let partners =this.props.patient.partners[this.state.uncleAuntRkey]
    
    for(var partner of partners) {
      let relKey = partner.rkey
      if(relKey.includes('-fake')) partners.pop(partner)
    }
    if(!lodash.isEqual(this.state.partners, partners)) {
      this.setState({partners})
    }

  }

  // async populatePartnersFromAPI() {
  //   try {
  //     let spouse_id = this.props.uncle_aunt.id
  //     if(spouse_id == null) return

  //     // Clear redux before repopulating
  //     this.props.dispatch(patient_actions.delete_partner({ownerRkey: this.props.uncle_aunt.rkey}))

  //     // Fetch Parter from API
  //     let partners = await family_api.get_member_memberid_partners(spouse_id)
  //     for(var partner of partners) {
  //       var partner_from_model = model.createPartner()
  //       var partner_data = Object.assign({}, partner_from_model, partner)

  //       // Save to Redux
  //       // partner_data.rkey = 'member_' + partner_data.id
  //       helper_family_tree.savePartnerToRedux(this.props.dispatch, this.props.uncle_aunt.rkey, partner_data)
  //       helper_family_tree.saveProfileToRedux(this.props.dispatch, partner_data)
  //     }
  //   } catch (error) {
  //     helpers.logger(error)
  //   }
  // }

  onCollapseClick(e) {

    this.setState({ collapsed: !this.state.collapsed })
    this.clearSelection()

  }

  clearSelection() {
    if (window.getSelection) { window.getSelection().removeAllRanges(); }
    else if (document.selection) { document.selection.empty(); }
  }


  async onChangeName(first_name) {
    try {
      let profile = { rkey: this.props.uncle_aunt.rkey, first_name }
      helper_family_tree.saveProfileToRedux(this.props.dispatch, profile)
      if(!this.props.level) helper_family_tree.saveUncleAuntDetailToRedux(this.props.dispatch, this.props.parent_side, profile);
      this.updateFirstName(first_name);
    } catch (error) {
      log_error(this.constructor.name, error);
    }
  }

  async updateFirstName(first_name) {
    try {
      this.setState({errorMessages: []})

      let member = this.props.uncle_aunt
      await family_api.patch_member_memberid(member.id, {
        first_name
      })

    } catch (error) {
      this.setState({errorMessages: [error.message]})
    }
  }

  getPartnersCount() {
    if(this.state.partners) return this.state.partners.length

    return 0
  }

  getFirstName() {

    let profile = this.getProfile();
    if(profile == null) return null;

    return profile.first_name;
  }

  getProfile() {
    return helper_family_tree.getProfileFromRedux(this.props.patient, this.props.uncle_aunt.rkey)
  }

  async onClickDeleteConfirmed() {

    try {

      this.setState({errorMessages: [], loading: true})

      await this.props.deleteMember(this.props.uncle_aunt)

      helper_family_tree.deleteUncleAunt(this.props.patient,
        this.props.dispatch,
        this.props.parent_side,
        this.props.uncle_aunt);

      this.setState({ openModalConfirmDelete: false })

    } catch (error) {
      helpers.logger(error)
      this.setState({errorMessages: [error.message]})
    } finally {
      this.setState({loading: false})
    }

  }

  render() {

    return <React.Fragment>

      <tr>
        <td>
            {this.getPartnersCount() > 0 &&
              <a onClick={(e) => this.onCollapseClick(e)}>
                <i className={"fa fa-" + (this.state.collapsed ? "minus-square" : "plus-square")}></i>
              </a>
            }
        </td>
        <td className="td--nowrap">

          <span className='text--capitalize'>
          { this.props.label }
          </span>

        </td>
        <td>
          <input
            onChange={(e) => this.onChangeName(e.target.value)}
            value={this.getFirstName()}
            type="text" className="inputreg form-control normal-input-text"/>
        </td>

        <HealthInputs
          for="uncle_aunt"
          patient={this.props.patient}
          dispatch={this.props.dispatch}
          errorMessages={this.state.errorMessages}
          loading={this.state.loading}
          ownerRkey={this.props.uncle_aunt.rkey}
          collapsible={this.state.partners && this.state.partners.length > 0}
          collapsed={this.state.collapsed}
          onCollapse={() => this.onCollapseClick() }
          canDelete={true}
          onDeleteConfirmed={async () => await this.onClickDeleteConfirmed() }
          profile={this.getProfile()}
        />

      </tr>

      { this.state.collapsed && this.state.partners &&
        this.state.partners.map((partner,index) => {
          return <FamilyTreePartner
            key={partner.rkey}
            ownerRkey={this.state.uncleAuntRkey}
            collapsed={true}
            level={0}
            extra_indent={1}
            label={'Partner #' + (index + 1) }
            partner={partner}
            owner={this.props.uncle_aunt}
            parent_side={this.props.parent_side}
            dispatch={this.props.dispatch}
            patient={this.props.patient}
            deleteMember={this.props.deleteMember}
          />
        })
      }

    </React.Fragment>

  }
}

export default FamilyTreeUncleAunt;
