import React, { Component } from "react";
import ReactDOM from 'react-dom';
import { Spinner } from "react-activity";
import Animation from '../assets/build/images/animated-loader.gif'
const ActivityIndicator = (props) => {

  if(props.modal == true) {
    return ReactDOM.createPortal(<ActivityIndicatorModal {...props} />, document.body);
  } else if(props.pedigree == true){
    return <ActivityIndicatorPedigree {...props} />;
  }else {
    return <ActivityIndicatorInline {...props} />;
  }

}

const ActivityIndicatorInline = (props) => {
  return (
    <div>
      {props.loading && (
        <div
          className={
            props.className === undefined ? "activity-indicator-wrapper" : props.className
           }>
            <Spinner
              color={
                props.color === undefined ? "#0E8488" : props.color
              }
              size={props.size ? props.size : 20}
              animating={true}
          />
        </div>
      )}
    </div>
  )}

const ActivityIndicatorPedigree = (props) =>{
  return(
    <React.Fragment>
      {props.loading &&
        <div className="activity-indicator-wrapper__modal">
          <div className="activity-indicator-wrapper__modal__spinner-pedigree">
            <img className="activity-Loader" src={Animation} alt='' />
            {props.riskAssessmentIndicator &&
              <button
                type="button"
                onClick={() => props.stopAction()}
                className="btn btn-light-outline no-margin-right risk-cancel-button"
                style={{border: 'none', color: 'white'}}
              >
                Cancel
              </button>
            }
          </div>
        </div>
      }
    </React.Fragment>
  )
}

const ActivityIndicatorModal = (props) => {
  return (
    <React.Fragment>
      {props.loading &&
        <div className="activity-indicator-wrapper__modal">
          <div className="activity-indicator-wrapper__modal__spinner-wrapper">
            <Spinner
                color={
                  props.color === undefined ? "#0E8488" : props.color
                }
                size={20}
                animating={true}
            />
          </div>
        </div>
      }
    </React.Fragment>
  )
}

export default ActivityIndicator;
