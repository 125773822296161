import React, { Component } from 'react';
import { connect } from 'react-redux';
import ResultFilterColumn from '../../components/query/result-filter-column'
import { fromJS, updateIn } from 'immutable'
import userAccountApi from '../../api/user-account-api';

import AccountFilters from './account-filters'

import lodash from 'lodash'
import { isEqual, cloneDeep } from 'lodash';
import OutsideAlerter from '../../components/query/OutsideAlerter'

let filterItemDisease = { andOr: 'and', cancerType: '', relationalOperator: '<=', age: '' }
let filterSetDisease = { andOr: 'and', items: [filterItemDisease] }

let filterItemGene = { andOr: 'and', gene: '', result: 'any' }
let filterSetGene = { andOr: 'and', items: [filterItemGene] }

let filterItemReferral = { andOr: 'and', referral: '' }
let filterSetReferral = { andOr: 'and', items: [filterItemReferral] }

let init_filter_state = {
  renewal_date_operator: '=',
  renewal_date_from: null,
  renewal_date_to: null,
  trial_expiration_operator: '=',
  trial_expiration_from: null,
  trial_expiration_to: null,
  locked_unlocked: '',
  account_type: '',
  client_type: ''
};

class AccountFiltersContainer extends Component {
  constructor(props) {
    super(props);
    let currentHash = new Date().getTime()

    this.filterRef  = React.createRef();

    this.state = {
      open: false,
      columns: [],
      successMessage: false,
      search_text: '',
      hashFilter: currentHash + 1,
      filterOpen: false,
      currentFilterPanelFilterSets: [],
      filters: init_filter_state,
      draggedItemIndex: null,
      copyMessage: false,
      includeUMLS: false,
    }

    this.handleOpenClick = this.handleOpenClick.bind(this);
    this.onChange = this.onChange.bind(this);
    this.handleOpenFilter = this.handleOpenFilter.bind(this);
    this.handleClearFilter = this.handleClearFilter.bind(this);
    this.downloadCSV = this.downloadCSV.bind(this);
    this.onDragOver = this.onDragOver.bind(this);
    this.onDragStart = this.onDragStart.bind(this);
    this.onDrop = this.handledDrop.bind(this);
    this.copyText = this.copyText.bind(this);
    this.savePDF = this.savePDF.bind(this);
    this.isActive = this.isActive.bind(this);
    this.handleOutsideClickThenQuery = this.handleOutsideClickThenQuery.bind(this);
    this.setFilterHashes = this.setFilterHashes.bind(this);
    this.handleColumnChecked = this.handleColumnChecked.bind(this);
    this.saveDefaultColumns = this.saveDefaultColumns.bind(this);
    this.loadDefaultColumns = this.loadDefaultColumns.bind(this);
    this.loadReportSetFilters = this.loadReportSetFilters.bind(this);
  }

  componentDidMount(){
    if(this.props.allColumns ){
      this.loadDefaultColumns()
    }
  }

  componentDidUpdate(){
    if(this.props.allColumns ){
      this.loadDefaultColumns()
    }
  }

  setFilterHashes() {
    let currentHash = new Date().getTime()
    this.setState({
      hashFilter: currentHash + 1
    });
  }

  onDragStart(ev) {
    this.setState({ draggedItemIndex: ev.target.id })
  }

  onDragOver(event) {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move'
  }

  handledDrop(event) {
    const droppedItemIndex = event.currentTarget.id - 1
    const draggedIndex = this.state.draggedItemIndex - 1
    const draggedItem = this.state.columns[draggedIndex]
    let i = 1
    let columns = this.state.columns

    columns = [
      ...columns.slice(0, draggedIndex),
      ...columns.slice(draggedIndex + 1)
    ]

    columns.splice(droppedItemIndex, 0, draggedItem)
    //update order value of each item
    columns.forEach((item) => {
      item.order = i
      i++
    })
    this.setState({ columns })
    let selected_columns = columns.filter(item => item.checked)
    this.props.onRearrangeColumn(selected_columns, columns)
  }

  handleOpenFilter() {
    this.setState({ filterOpen: !this.state.filterOpen }, () => {
      if (!this.state.filterOpen){
        this.props.setAccountFilters(this.state.filters)
      }
    })
  }

  handleClearFilter() {
    this.filterRef.current.handleClearFilter();
    this.setState({
      filters: init_filter_state
    });
    this.props.setAccountFilters(init_filter_state);
    this.setFilterHashes();
  }

  loadReportSetFilters = (filterSets) => {
    if(filterSets.length === 0){
      let clean = init_filter_state
      this.setState({ filters: clean })
      this.props.setAccountFilters(clean);
      this.filterRef.current.loadReportSetFilters(clean);
    }
    else{
      let newFilterSets = filterSets;
      this.setState({ filters: newFilterSets })
      this.props.setAccountFilters(newFilterSets);
      this.filterRef.current.loadReportSetFilters(newFilterSets);
    }
  }

  handleOpenClick() {
    this.setState({ open: !this.state.open })
  }

  setCurrentFilterPanelFilterSet(filterset) {
    this.setState({ currentFilterPanelFilterSets: filterset })
  }

  setFilterState(filters) {
    this.setState({ filters: filters })
  }

  isActive(filter_name) {
    let filterSet = this.state.currentFilterPanelFilterSets
    if (filter_name === 'disease') {
      filterSet = filterSetDisease
    }
    else if (filter_name === 'gene') {
      filterSet = filterSetGene
    }
    else if (filter_name === 'referral') {
      filterSet = filterSetReferral
    }
    else if (filter_name === 'more_filters') {
      let active = lodash.isEqual(this.state.filters, init_filter_state) === false;
      let dateRegex = '^(((0?[1-9]|1[012])/(0?[1-9]|1\\d|2[0-8])|(0?[13456789]|1[012])/(29|30)|(0?[13578]|1[02])/31)/(19|[2-9]\\d)\\d{2}|0?2/29/((19|[2-9]\\d)(0[48]|[2468][048]|[13579][26])|(([2468][048]|[3579][26])00)))$'
      let ageRegex = '^\\d+$'
      let dateRegexF = new RegExp(dateRegex)
      let ageRegexF = new RegExp(ageRegex)

      let datesAreValid = this.state.more_filters.dob_operator === 'between' ? dateRegexF.test(String(this.state.more_filters.dob_from)) && dateRegexF.test(String(this.state.more_filters.dob_to)) : dateRegexF.test(String(this.state.more_filters.dob_from))
      let agesAreValid = this.state.more_filters.age_operator === 'between' ? ageRegexF.test(String(this.state.more_filters.age)) && ageRegexF.test(String(this.state.more_filters.age_to)) : ageRegexF.test(String(this.state.more_filters.age))
      let genderIsValid = this.state.more_filters.gender === 'm' || this.state.more_filters.gender === 'f' || this.state.more_filters.gender === 'unknown'

      if (active && (datesAreValid || (agesAreValid && Number(this.state.more_filters.age) < 130) || genderIsValid) || this.state.more_filters.clinician) return true;

      return false;
    }
    let clean = [filterSet]
    let filterSets = this.state.currentFilterPanelFilterSets
    return lodash.isEqual(clean, filterSets) === false;
  }

  handleOutsideClickThenQuery() {
    this.setState({ filterOpen: false })
    this.props.setAccountFilters(this.state.filters)
  }

  handleIncludeUMLS(checked){
    this.setState({ includeUMLS: checked })
  }

  handleColumnChecked(checked, index) {
    let columns = cloneDeep(this.state.columns)
    let checked_columns = columns.filter(item => item.checked)

    // Cannot unchecked all columns
    if (checked === false && checked_columns.length == 1) return

    columns = fromJS(columns)
    columns = updateIn(columns, [index, 'checked'], _ => checked)
    columns = columns.toJS()
    this.setState({ columns })

    checked_columns = columns.filter(item => item.checked)
    this.props.onEditColumns(checked_columns, columns)
  }

  onChange(event) {
    this.setState({ search_text: event.currentTarget.value })
  }

  downloadCSV(event) {
    this.props.downloadCSV();
  }

  copyText(event) {
    this.setState({copyMessage: true})
    this.props.onCopy();
    setTimeout(() => {
      this.setState({copyMessage: false})
     }, 2000);
  }

  savePDF() {
    this.props.savePDF();
  }

  loadDefaultColumns(){
    if(this.state.columns !== undefined && (isEqual(this.props.allColumns, this.state.columns) == false)){
      this.setState({columns: this.props.allColumns})
    }
  }

  async saveDefaultColumns(){
    try{
      this.setState({loading: true})
      let payload ={
        columns: this.state.columns,
        user_id: this.props.user.user_id
      }
      await userAccountApi.save_default_columns(payload, this.props.user.clinician_id)
      this.setState({successMessage: true})
    }catch(err){
      console.log(err)
    }finally{
      this.setState({loading: false})
      setTimeout(() => {
        this.setState({successMessage: false})
       }, 3000);
      }
  }

  render() {
    return (
      <React.Fragment>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <div style={{marginLeft: '1%', marginBottom: '1%'}}><h5>Filter by:</h5></div>
          <div style={{marginRight: '4%', fontSize: 12}}><p>{this.props.recordsCount} records found</p></div>
        </div>

        <div className="query-filter col-md-7">
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <div className="row">
              <OutsideAlerter action={() => this.handleOutsideClickThenQuery()} viewPatient={(id) => this.props.viewPatient(id)} openDeletePatientModal={(id) => this.props.openDeletePatientModal(id)}>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <AccountFilters
                    key={this.state.hashFilter}
                    ref={this.filterRef}
                    open={this.state.filterOpen}
                    onOpen={() => this.handleOpenFilter()}
                    setFilterState={(filters) => this.setFilterState(filters)}
                    organizations={this.props.organizations}
                    columns={this.props.columns}
                    allColumns={this.props.allColumns}
                  />

                  <button
                    onClick={() => this.handleClearFilter()}
                    className="btn btn-light btn-xs"><i className="fa fa-times-circle"></i> Clear Filters</button>
                </div>
              </OutsideAlerter>
            </div>


            <div className="table-filter padding--none">
              <div className="form-inline">
                <div className="row">
                  <div className="col-md-5">
                    <div style={{ display: 'flex', flexDirection: 'row', marginLeft: '375%' }}>
                      <div className="form-group popover-query-wrapper">
                        <button
                          onClick={this.handleOpenClick}
                          className="btn btn-default btn-xs no-margin-right btn-default__width--inherit" ><span className="sr-only">Column Settings</span> <i className="fa fa-cog no-margin"></i> Edit Columns</button>

                        <div id="colPop"
                          style={{ display: (this.state.open ? 'block' : 'none') }}
                          className="popover popover-default popover-custom popover-x kv-popover-active in bottom popover-query">

                          <div className="arrow popover-query__arrow"></div>
                          <h5 className="popover-header popover-title">
                            <span
                              onClick={this.handleOpenClick}
                              className="close pull-right min-width--inherit" >&times;</span>Column Settings
                                {this.state.successMessage &&(
                                  <i className='column-success'>Saved!</i>
                                )}
                                <span className='i-tooltip-column'>
                                  <a onClick={this.saveDefaultColumns} className='column-save min-width--inherit'>
                                    <i className="fa fa-check-circle-o" aria-hidden="true"></i>
                                  </a>
                                <span className="i-tooltiptext-column">
                                  Save as default
                                </span>
                              </span>
                          </h5>
                          <div className="popover-body popover-content">
                            <ul>
                              {this.state.columns.map((item, index) =>
                                <ResultFilterColumn
                                  key={index}
                                  item={item}
                                  onChecked={(checked) => this.handleColumnChecked(checked, index)}
                                  onDragOver={(e) => this.onDragOver(e)}
                                  onDrag={(e) => this.onDragStart(e)}
                                  onDrop={(e) => this.handledDrop(e)} />)}
                            </ul>
                          </div>
                        </div>
                      </div>

                      <div className="form-group no-margin-right">
                        <div className="btn-group btn-group-xs" role="group" aria-label="..." style={{ display: 'flex', flexDirection: 'row' }}>
                          <button type="button" className="btn btn-default" onClick={this.copyText}>Copy</button>
                            {this.state.copyMessage &&(
                              <div id="copyPop" className="popover popover-wide popover-x popover-default popover-custom kv-popover-active in bottom popover-query">
                                <div className="arrow popover-query__arrow"></div>
                                  <h5 className="popover-header popover-title" style={{textTransform: 'none'}}> Copied to clipboard</h5>
                              </div>
                            )}
                          <button type="button" className="btn btn-default" onClick={this.downloadCSV}>CSV</button>
                          <button type="button" className="btn btn-default" onClick={this.savePDF}>PDF</button>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div >
        </div>
      </React.Fragment>
    )
  }
}

const redux_state = state => ({
  user: state.session.user,
  searchFilter: state.search.searchFilter,
  static_list: state.static_list,
  org_clinicians: state.session.user.org_clinicians
});

export default connect(
  redux_state, null, null, { forwardRef: true }
)( AccountFiltersContainer);
