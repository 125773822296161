import React, { Component } from "react";
import { connect } from "react-redux";

import * as helper_search from "../helpers/helper-search";

class NavSearch extends Component {
  constructor(props) {
    super(props);

    this.handleSearchTextChange = this.handleSearchTextChange.bind(this);
    this.handleSearchSubmit = this.handleSearchSubmit.bind(this);
  }

  /*SEARCH FORM HANDLERS*/
  handleSearchTextChange(search_text) {
    // update redux search with new search text
    helper_search.updateSearchText(this.props.dispatch, search_text);
  }

  handleSearchSubmit(event) {
    event.preventDefault();
    this.props.handleSearchSubmit();
  }

  render() {
    let placeholder = this.props.user.account_type === 'super_admin' ? 'Search...' : 'Search patients...';
    return (
      <div id="top-search">
        <form className="search-form" onSubmit={this.handleSearchSubmit}>
          <button
            id="search_button"
            className="close-search search-button"
            type="submit"
            onClick={this.props.handleSearchSubmit}
          >
            <i id="search_icon" className="fa fa-search"></i>
          </button>
          <input
            type="text"
            name="search"
            className="search-input"
            placeholder={placeholder}
            autoComplete="off"
            onChange={e =>
              this.handleSearchTextChange(e.target.value)
            }
            value={this.props.searchFilter.search_text}
          />
        </form>
      </div>
    );
  }
}

const redux_state = state => ({
  user: state.session.user,
  searchFilter: state.search.searchFilter
});

const redux_actions = dispatch => ({
  dispatch: action => dispatch(action)
});

export default connect(
  redux_state,
  redux_actions
)(NavSearch);
