import React, { Component } from "react";
import ReactDOM from "react-dom";
import { connect } from "react-redux";

import NotificationMenu from "./menu";
import NotificationConnectionRequest from "./connection-request";
import NotificationOnboardingComplete from "./onboarding-complete";
import NotificationPatientUpdates from "./patient-updates";
import NotificationDisconnected from "./disconnected";
import NotificationOther from "./other";
import notifications_api from "../../api/notifications-api";

import ActivityIndicator from "../activity-indicator";
import * as helper_error from "../../helpers/helper-error";


class NotificationSlider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      showConnectionRequestTab: true,
      showDisconnectedTab: false,
      showPatientUpdatesTab: false,
      showOtherTab: false,
      notifications: []
    };

    this.handleMenuClick = this.handleMenuClick.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside, true);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside, true);
  }

  handleClickOutside(event) {
    const domNode = ReactDOM.findDOMNode(this);
    if(!domNode || !domNode.contains(event.target)) this.props.handleClickOutside();
  }

  handleMenuClick(selectedTab) {
    this.toggleTab(selectedTab);
  }

  toggleTab(tab = "connection-request") {
    let showConnectionRequestTab = tab == "connection-request";
    let showDisconnectedTab = tab == "disconnected";
    let showPatientUpdatesTab = tab == "patient-updates";
    let showOtherTab = tab == "other";
    this.setState({
      showConnectionRequestTab,
      showPatientUpdatesTab,
      showDisconnectedTab,
      showOtherTab
    });
  }

  render() {
    return ReactDOM.createPortal(
      <nav className="notifs-wrap notifs-wrap--override notifs-wrap--show">
        <div onClick={() => this.props.onClose()} className="dismiss" style={{display: 'block'}}>
          <i className="fa fa-close"></i>
        </div>

        <div className="flexbox">
          <div className="col">
            <NotificationMenu
              notification_counts={this.props.notification_counts}
              onMenuClick={this.handleMenuClick} />
          </div>

          <div className="col">
            <div className="tab-content wrap">
              <h3>Notifications</h3>

              <hr />

              <ActivityIndicator loading={this.state.loading} />

              { this.state.showConnectionRequestTab && (
                <NotificationConnectionRequest
                  fetchNotificationCounts={this.props.fetchNotificationCounts}
                  refreshList={this.props.refreshList}
                  clinician_id={this.props.user.clinician_id}
                  clinician_user_id={this.props.user.user_id}
                />
              )}

              { this.state.showPatientUpdatesTab && (
                <NotificationPatientUpdates
                  type="patient_updates"
                  clinician_id={this.props.user.clinician_id}
                  user_id={this.props.user.user_id}
                />
              )}

              { this.state.showDisconnectedTab && (
                <NotificationDisconnected
                  fetchNotificationCounts={this.props.fetchNotificationCounts}
                  clinician_id={this.props.user.clinician_id}
                />
              )}

              <NotificationOther
                show={this.state.showOtherTab}
                clinician_id={this.props.user.clinician_id}
              />
            </div>

          </div>

        </div>
      </nav>,
      document.body
    );
  }
}

const mapStateToProps = state => ({
  user: state.session.user
});

export default connect(mapStateToProps)(NotificationSlider);
