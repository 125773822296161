import React, { Component } from 'react';
import { connect } from "react-redux";

import BasePage from "./pages/p-base-page.js";

import './assets/production/css/animate.css';
import './assets/production/css/bootstrap.css';
import './assets/production/css/et-line-icons.css';
import './assets/production/css/font-awesome.min.css';
import './assets/production/css/style.css';
import './assets/production/css/responsive.css';
import './assets/production/css/bootstrap-popover-x.css'

import './assets/build/css/jquery.dataTables.min.css';
import './assets/build/css/famhis.css';
import './assets/build/css/famhis-custom.css';
import './assets/css/dev-styles.css';
import './assets/css/bem-presets.css';

import 'icheck/skins/all.css';

import 'react-activity/dist/react-activity.css';

import { BrowserRouter as Router } from "react-router-dom";
import RouteList from "./route-list";

import sdk from "./api/sdk";
import * as action_auth from './store/session/actions'

import ActivityIndicator from "./components/activity-indicator";
import api_auth from './api/authentication';
import {set_cookie, remove_cookie, get_cookie} from "./helpers/helper-cookies";
import {
  save_login_data
} from './helpers/helper-login';
import axios from 'axios';

import * as broadcast_actions from './store/broadcast_message/actions';

const app_alert_banner = "app-alert-banner";
const broadcast_banner = "broadcast-banner";
const TIMEOUT_COOKIE_NAME = "TIMEOUT_TIMESTAMP";

class App extends Component {

  constructor(props) {
    super(props)
    this.state = {
      loading: false
    }

    this.timer = null;
    this.api_handler = axios.create({
      baseURL: process.env.REACT_APP_ROOT_API_URL
    });

    this.resetTimer = this.resetTimer.bind(this);
    this.checkOnlineStatus = this.checkOnlineStatus.bind(this);
    this.checkTimeoutTimestamp = this.checkTimeoutTimestamp.bind(this);
    this.update_session = this.update_session.bind(this);
    this.reloadCredentials = this.reloadCredentials.bind(this);
    this.save_data = this.save_data.bind(this);

    this.checkForBroadcastMessage = this.checkForBroadcastMessage.bind(this);
    this.close_broadcast_banner = this.close_broadcast_banner.bind(this);
    this.addBroadcastBannerCloseBtn = this.addBroadcastBannerCloseBtn.bind(this);
    this.clearHighAlertInterval = this.clearHighAlertInterval.bind(this);
    this.checkForHighAlert = this.checkForHighAlert.bind(this);

    // this is for opening multiple tabs it keeps the user signed in
    let tokens = localStorage.getItem('famgenix_app_tokens');
    let extra_data = localStorage.getItem('extra_data');
    if (tokens !== null && typeof (tokens) !== 'undefined') {
      localStorage.removeItem('famgenix_app_tokens');
      localStorage.removeItem('extra_data');
      sessionStorage.setItem('famgenix_app_tokens', tokens);
      sessionStorage.setItem('extra_data', extra_data);
    }

    // alert banner variables
    this.expiration_date = null;
    this.high_alert = null;
    this.high_alert_interval = null;
  }

  componentDidMount() {
    remove_cookie(TIMEOUT_COOKIE_NAME);
    
    let render_patient_pedigree = sessionStorage.getItem('render-patient-pedigree');
    render_patient_pedigree = render_patient_pedigree == 'true';
    if(!render_patient_pedigree) {
    // if(!render_patient_pedigree) {
      //reload credentials in the case of a hard refresh would log users out
      //with this in here we authenticate the user from the stored cookie and keep them logged in
      this.reloadCredentials();
    }

    let survey = window.location.pathname.search(/survey/);
    if(survey !== 1 && !render_patient_pedigree) {
      document.body.addEventListener('mousemove', (event) => this.resetTimer(event, 'mousemove')); // catches mouse movements
      document.body.addEventListener('mousedown', (event) => this.resetTimer(event, 'mousedown')); // catches mouse movements
      document.body.addEventListener('click', (event) => this.resetTimer(event, 'click'));  // catches mouse clicks
      document.body.addEventListener('scroll', (event) => this.resetTimer(event, 'scroll')); // catches scrolling
      document.body.addEventListener('keypress', (event) => this.resetTimer(event, 'keypress')); // catches keyboard actions
    }

    window.onbeforeunload = (event) => {
      if (typeof event == 'undefined') {
        event = window.event;
      }
      if (event) {
        this.logout();
      }
    };

    /* This event listener was causing a logout on browser refresh also so comment out */
    //window.addEventListener('beforeunload', (e) => this.onExit(e)); //catches window unload event

    if (!render_patient_pedigree) {
      setInterval(this.checkOnlineStatus, 2000);
      setInterval(this.checkTimeoutTimestamp, 5000);
      this.checkForBroadcastMessage();
    }
  }

  checkOnlineStatus() {
    const online = window.navigator.onLine;
    const alert_banner = document.getElementById(app_alert_banner);
    if (alert_banner) {
      alert_banner.innerHTML = "No Internet Connection, please check your Wi-Fi or VPN";
      if (online) {
        alert_banner.style.display = "none";
      } else {
        alert_banner.style.display = "block";
      }
    }
  }

  addBroadcastBannerCloseBtn(broadcast_banner) {
    broadcast_banner.style.display = "block";
    var closeButton = document.createElement("button");
    // Set button attributes
    closeButton.setAttribute("type", "button");
    closeButton.setAttribute("class", "close");
    closeButton.setAttribute("aria-label", "Close");
    var closeIcon = document.createElement("span");
    closeIcon.setAttribute("aria-hidden", "true");
    closeIcon.innerHTML = "&times;"; // Set the "x"
    closeButton.appendChild(closeIcon);
    broadcast_banner.appendChild(closeButton);
    closeButton.addEventListener("click", function() {
      // Hide the alert banner
      broadcast_banner.style.display = "none";
    });
  }

  clearHighAlertInterval() {
    clearInterval(this.high_alert_interval);
  }

  checkForHighAlert() {
    if (this.props.broadcast_message.length > 0) {
      const message = this.props.broadcast_message[0];
      console.log(message)
      console.log(this.high_alert.toISOString())
      console.log(this.expiration_date.toISOString())
      console.log("")
      if (this.props.session.authenticated) {
        if (this.expiration_date && this.high_alert) {
          const now = new Date();
          if (this.high_alert.toISOString() <= now.toISOString() && now.toISOString() <= this.expiration_date.toISOString()) {
            const broadcast = document.getElementById(broadcast_banner);
            if (message && broadcast) {
              this.addBroadcastBannerCloseBtn(broadcast);
              this.clearHighAlertInterval();
            } else {
              this.clearHighAlertInterval();
              this.close_broadcast_banner();
            }
          }
        } else {
          this.clearHighAlertInterval();
          this.close_broadcast_banner();
        }
      }
    } else {
      this.clearHighAlertInterval();
      this.close_broadcast_banner();
    }
  }

  async checkForBroadcastMessage() {
    try {
      const broadcast_messages = await api_auth.get_active_broadcasts();

      // should only be one message at a time
      // make sure the domain client id is targeting the clincian portal
      // or if it is both the clinician portal and patient app

      // const messages = broadcast_messages.active_messages.filter((msg) => !msg.domain_client);
      const messages = broadcast_messages.active_messages;
      const message = messages.slice(0, 1).pop();
      const broadcast = document.getElementById(broadcast_banner);
      if (message && broadcast) {
        const text = message ? message.text : "";
        const now = new Date();
        const expiration_date = new Date(message.expiration);
        const high_alert = new Date(message.high_alert);
        if (expiration_date.toISOString() <= now.toISOString()) {
          return
        } else {
          this.props.dispatch(broadcast_actions.save_broadcast_message([message]));
          this.high_alert_interval = setInterval(this.checkForHighAlert, 20000);

          this.expiration_date = expiration_date;
          this.high_alert = high_alert;
          broadcast.innerHTML = text;
          broadcast.style.display = "block";
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  close_broadcast_banner() {
    const broadcast = document.getElementById(broadcast_banner);
    if (broadcast && broadcast.style.display === "block") {
      // Hide the alert banner
      broadcast.style.display = "none";
    }
  }

  checkTimeoutTimestamp() {
    const default_logout_time = 900000;
    const timed_logout = this.props.session.user.auto_logout_time ? (this.props.session.user.auto_logout_time*60*1000) : default_logout_time;

    let timestamp = get_cookie(TIMEOUT_COOKIE_NAME);
    if (timestamp) {
      timestamp = new Date(timestamp);

      const now = new Date();

      if ((now - timestamp) > timed_logout) {// time is in milliseconds (1000 is 1 second)
        const response = api_auth.logoutRemoveSessionStorage();
        if (response) {
          // set flag in local storage and use in login page
          localStorage.setItem("timedLogout", true);
        }
      }
    } else {
      timestamp = new Date();
      set_cookie(TIMEOUT_COOKIE_NAME, timestamp.toUTCString());
    }

    // console.log("******TIMESTAMP******");
    // console.log(timestamp);
  }

  // onExit(event) {
  //   /* Cancel the event */
  //   // event.preventDefault();
  //
  //   /* Chrome requires returnValue to be set */
  //   // event.returnValue = '';
  //   this.logout();
  // }

  logout() {
    api_auth.logout();
  }

  update_session(event) {
    let token = null;
    try {
      let decoded = sdk.tokens_from_cookie();
      token = decoded.token.accessToken;
    } catch (error) {
      token = null;
    }
    if (token !== null) {
      this.api_handler.defaults.headers.common["Authorization"] = "Bearer " + token;
      this.api_handler.post("/famgenix_session/update_session/", {}).then((response) => {
        // console.log(response.data);
      }).catch((error) => {
        // console.log(error);
      });
    }
  }

  resetTimer(event, event_type) {
    let timestamp = new Date();
    set_cookie(TIMEOUT_COOKIE_NAME, timestamp.toUTCString());

    if (event_type === 'click') {
      this.update_session(event);
    }
  }

  save_data(payload) {
    save_login_data(this.props.session.user, payload, this.props.dispatch);
    this.props.dispatch(action_auth.authenticated(true));
    this.setState({loading: false});
  }

  reloadCredentials() {
    try {

      // Retrieve and check if cookie exists
      let payload = sdk.tokens_from_cookie();

      // Need another endpoint to re-validate the current token
      this.setState({loading: true});
      const save_data = this.save_data;
      setTimeout(function () { save_data(payload) }, 2000);

    } catch (err) {
      console.log(err)
    }
  }

  render() {

    if(this.state.loading) return <div className="app-loading-wraper"><ActivityIndicator loading={true} /></div>

    if (this.props.session.authenticated && this.expiration_date && this.high_alert) {
      const now = new Date();
      if (this.high_alert.toISOString() <= now.toISOString() && now.toISOString() <= this.expiration_date.toISOString()) {
        const broadcast = document.getElementById(broadcast_banner);
        if (broadcast) {
          this.addBroadcastBannerCloseBtn(broadcast);
          this.clearHighAlertInterval();
        } else {
          this.clearHighAlertInterval();
          this.close_broadcast_banner();
        }
      } else {
        this.close_broadcast_banner();
      }
    }

    return (
      <Router>
        <BasePage>
          <div id={app_alert_banner} className="alert alert-danger offline-banner" role="alert">No Internet Connection, please check your Wi-Fi or VPN</div>
          <div id={broadcast_banner} className="alert alert-danger offline-banner" role="alert"></div>
          <RouteList authenticated={this.props.session.authenticated} user={this.props.session.user} />
        </BasePage>
      </Router>
    )

  }
}

const redux_state = state => ({
  session: state.session,
  broadcast_message: state.broadcast_message.broadcastMessage
});

const redux_actions = dispatch => ({
  dispatch: (action) => dispatch(action)
});

export default connect(redux_state, redux_actions)(App);
