import React, { Component } from "react";

import LabelField from './label-field';

class ToggleField extends Component {
  constructor(props) {
    super(props);

    this.state = {value: ''};

    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    if (this.props.answer) {
      this.setState({ value: this.props.answer.answer });
    }
  }

  onChange(event) {
    const checked = event.currentTarget.checked;
    this.setState({ value: checked });

    const payload = {
      field_type: this.props.masterQuestion.type,
      master_question_id: this.props.masterQuestion.id,
      master_question_choice_id: null,
      answer: checked
    };

    this.props.saveCompletedSurveyAnswer(payload);
  }

  render() {
    let column_one = null;
    let column_two = null;
    const field = (
      <div className="checkbox">
        <label className="switch">
          <input
            name={this.props.masterQuestion.question_uuid}
            id={this.props.masterQuestion.question_uuid}
            type="checkbox"
            onChange={this.onChange}
            checked={(this.state.value === 'True') ? true : false}
          />
          <span className="slider round"></span>
        </label>
      </div>
    );

    if (this.props.masterQuestion.question_label) {

      column_one = (
        <div className="clinician-survey-toggle-label">
          <LabelField
            masterQuestion={this.props.masterQuestion}
          />
        </div>
      );

    }

    column_two = field;

    return (
      <div className="form-group">
        <div className="row">

          <div className="col-md-2">
            {column_two}
          </div>

          <div className="col-md-10" style={{left: -40}}>
            {column_one}
          </div>

        </div>
      </div>
    );

  }
}

export default ToggleField;
