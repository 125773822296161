import React, { Component } from "react";
import ErrorSummary from "./error-summary";
import { cloneDeep } from "lodash";
import { radix_bases } from '../helpers/helper-number-bases';

class SkipLogicBirthDefectsForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      keys: {},
      Description: '',
      errorMessages: []
    };
  }

  componentDidMount(){
    let keys = {}
    this.props.static_skip_logic.forEach(item=>{
      keys[item.short_name] = item.skip_logic_code
    })

    let inputs = cloneDeep(keys)
    for (var key in inputs) {
      inputs[key] = '';
    }

    this.setState({keys: keys, ...inputs})
  }
  async componentDidUpdate(prevProps) {
    if (prevProps.skip_logics.length !== this.props.skip_logics.length) {

        for (var key in this.state.keys) {

          var val = this.getValue(key);
          if (val === null) continue;

          if (key.includes("age")) {
            if (!isNaN(parseInt(val, radix_bases.base10))) {
              await this.setState({ [key]: val, [key.replace("_age", "")]: "y" });
            }
          } else {
            await this.setState({ [key]: val });
          }
        }

        // Reconstruct payload
        var skipLogicPayload = this.buildSkipLogicPayload();
        this.props.onUpdate(this.state, skipLogicPayload, true);

      }
  }

  getValue(key) {
    if (!(key in this.state.keys)) return null;

    let code = this.state.keys[key];
    let data = this.props.skip_logics.find(
      item => item.skip_logic_code === code
    );
    if (typeof data === "undefined") return null;
    return data.answer;
  }

  buildSkipLogicPayload() {
    let inputs = this.state;
    let mapping = this.state.keys;
    let skipLogicPayloads = []
    for(var key in mapping) {
      var static_skip_logic_item = this.props.static_skip_logic.find(item => item.skip_logic_code == mapping[key])
      if(typeof(static_skip_logic_item) === 'undefined') {
        throw new Error('Skip Logic code not found');
      }
      var input = inputs[key]
      var answer = input !== null && typeof(input) === 'object' ? input.value : input
      if(answer !== null){
        skipLogicPayloads.push({
          skip_logic_code: static_skip_logic_item.skip_logic_code,
          skip_logic_id: static_skip_logic_item.id,
          short_name: static_skip_logic_item.short_name,
          answer
        })
      }
    }

    return skipLogicPayloads;
  }

  async onChange(data) {
    try {
      await this.setState({ ...this.state, ...data, errorMessages: [] });
      var skipLogicPayload = this.buildSkipLogicPayload();
      this.props.onUpdate(this.state, skipLogicPayload);
    } catch (error) {
      this.setState({ errorMessages: [error.message] });
    }
  }

  render() {
    return (
      <div className="panel-form-2 sl-content birth-defects">
        <ErrorSummary errorMessages={this.state.errorMessages} />

        <div className="form-horizontal">
          <div className="row">
            <div className="col-md-3">
                <label>
                    Please describe
                </label>
                <input
                  type="text"
                  className="form-control normal-input-text"
                  value={this.state.Description || ''}
                  onChange={(event) => this.onChange({ Description: event.target.value })}
                ></input>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SkipLogicBirthDefectsForm;
