import React, { useRef } from 'react';
import Toolbar from './toolbar'
import Settings from './settings'
import NotificationTabs from './notification-tabs'
import PedigreeWrapper from '../../react-flow-pedigree/pedigree-wrapper';
export default props => {
  const pedigreeWrapperRef = useRef(null);

  async function onNotificationAction() {
    await props.NotificationAction();
    if (pedigreeWrapperRef.current) {
      await pedigreeWrapperRef.current.reRenderPedigree();
    }
  }

  return (
    <div style={{ display: 'flex' }}>
      <div style={{ width: '50%' }}>
        <PedigreeWrapper
          ref={pedigreeWrapperRef}
          key={props.pedigree_wrapper_key}
          setRiskCriteria={props.setRiskCriteria}
          getPedigreeData={props.getPedigreeData}
          getPedigreeDrawingData={props.getPedigreeDrawingData}
          getConnectionWatcher={props.getConnectionWatcher}
          read_only={props.read_only}
          user={props.user}
          memberid={props.memberid}
          organization_id={props.organization_id}
          clinician_id={props.clinician_id}
          reRenderTopBarFunction={() => props.reRenderTopBarFunction()}
          history={props.history}
          fetchProbandTree={props.fetchProbandTree}
          member_id_toBeSelected={props.member_id_toBeSelected}
          session={props.session}
          dispatch={props.dispatch}
          patient_updates_view={true}
        />
      </div>
      <div className="flex-grid-sidebar" style={{ width: '50%', borderLeft: '2px solid #CCCCCC' }}>
        <div style={{ width: '70%' }}>

          <Toolbar />
          <NotificationTabs onNotificationAction={onNotificationAction} notificationUpdates={props.notificationUpdates} />

        </div>

        <div>
          <Settings getPedigreeData={props.getPedigreeData} />
        </div>
      </div>
    </div>
  )
}
