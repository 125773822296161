import React, { Component } from "react";
import ReactDOM from "react-dom";
import ActivityIndicator from "./activity-indicator";
import ErrorSummary from "./error-summary";

class ModalConfirmReportDelete extends Component {
  render() {
    let message = "Are you sure you would like to delete this family member?";

    if(this.props.hasSpouses) {
      if(this.props.person.is_blood_related_to_proband) {
        message = "Before deleting a family member, all of their spouses must be deleted";
      }
    }

    if(this.props.message) {
      message = this.props.message;
    }

    return ReactDOM.createPortal(
      <div
        onClick={() => this.props.onCancel()}
        style={{ zIndex: 9999, display: this.props.isOpen ? "block" : "none" }}
        className={"modal fade " + (this.props.isOpen ? "in" : "")}
        role="dialog"
      >
        <div
          onClick={e => e.stopPropagation()}
          className="modal-dialog modal-sm"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <button
                onClick={() => this.props.onCancel()}
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <i className="fa fa-close" />
              </button>
              <h4 className="modal-title text-white text-capitalize">
                {this.props.title ? this.props.title : "Delete Details"}
              </h4>
            </div>

            <div className="modal-body">
              <div className="modal-confirm">
                <ErrorSummary errorMessages={this.props.errorMessages} />

                <p>
                  {message}
                </p>

                <ActivityIndicator loading={this.props.loading} />
                <br />

                { this.props.hasSpouses ? (
                  <div className="buttons">
                  <button
                    disabled={this.props.loading}
                    onClick={() => this.props.onCancel()}
                    className="btn btn-purple btn-sm"
                    aria-label="Close"
                  >
                    OK
                  </button>
                </div>
                ) : (
                  <div className="buttons">
                    <button
                      style={{paddingLeft: '0px', paddingRight: '0px', backgroundColor: '#6a358a'}}
                      disabled={this.props.loading}
                      onClick={() => this.props.onOk()}
                      className="btn btn-purple btn-sm"
                    >
                      Delete
                    </button>
                    <button
                      style={{paddingLeft: '0px', paddingRight: '0px'}}
                      disabled={this.props.loading}
                      onClick={() => this.props.onCancel()}
                      className="btn btn-light btn-md"
                      aria-label="Close"
                    >
                      Cancel
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>,
      document.body
    );
  }
}

export default ModalConfirmReportDelete;
