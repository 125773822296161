import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import english from "./locales/en/translation.json";
import french from "./locales/fr/translation.json";
import spanish from "./locales/es/translation.json";
import dutch from "./locales/nl/translation.json";
import german from "./locales/de/translation.json";

const languages_dict = {
  EN: "en",
  FR: "fr",
  ES: "es",
  NL: "nl",
  DE: "de",
};

const languages_array = ["en","fr","es","nl","de"];

// manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
const resources = {
  en: {
    translation: english
  },
  fr: {
    translation: french
  },
  es: {
    translation: spanish
  },
  nl: {
    translation: dutch
  },
  de: {
    translation: german
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: languages_dict["EN"], // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option
    supportedLngs: languages_array,

    fallbackLng: languages_dict["EN"],

    // have a common namespace used around the full app
    ns: ["translation"],
    defaultNS: "translation",

    interpolation: {
      escapeValue: false // react already safe from xss
    }
});

export default i18n;
