import React, { Component } from "react";
import ReactDOM from "react-dom";

const ErrorInline = props => {
  return (
    <div
      className={
        "alert alert-danger " + (props.transparent ? "alert-transparent" : "")
      }
    >
      <ul className="validation-summary">
        {props.errorMessages.map((message, index) => (
          <li key={index} className={(props.loginError ? "login-error-message__li" : "validation-summary__li")}>
            {message}
          </li>
        ))}
      </ul>
    </div>
  );
};

const ErrorModal = props => {
  return ReactDOM.createPortal(
        <div className="modal-error-wrapper" onClick={ () => 'onHide' in props && props.onHide() }>
          <div className="modal-error-wrapper__content">
            <div className="alert alert-danger alert-transparent">
              <h3 className="modal-error-wrapper__content--error-text">Oops!</h3>
              <ul className="validation-summary">
                {props.errorMessages.map((message, index) => (
                  <li key={index} className="validation-summary__li">
                    {message}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>,
      document.body);
};

const ErrorSummary = props => {
  if (props.errorMessages.length == 0) return null;

  if ("modal" in props && props.modal) {
    return <ErrorModal {...props} />
  } else {
    return <ErrorInline {...props} />;
  }
};

export default ErrorSummary;
