import {cloneDeep} from 'lodash'
import { createUUID } from '../components/react-flow-pedigree/utils';

export const history = {
  rkey: null,
  deceased: false,
  age_death: 0,
  cause_of_death: '',
  notes: ''
}

const skip_logic = {
  id: null,
  skip_logic_id: null,
  answer: null,
}

export const history_disease = {
  rkey: null,
  id: null,
  disease_code: null,
  age_diagnosed: null,
  age_diagnosed_string: null,
  disease_id: null,
  disease_name: null,
  // disease: null,
  skip_logics: null
}

export const history_gene = {
  rkey: null,
  id: null,
  gene: null,
  variant: '',
  status: null,
  result: null
}

export const createHistory = () => {
  let tmp = Object.assign({}, history )
  tmp.rkey = createUUID()
  return tmp
}

export const createHistoryGene = () => {
  let tmp = Object.assign({}, history_gene )
  tmp.rkey = createUUID()
  return tmp
}

export const createHistoryDisease = () => {
  let tmp = cloneDeep(history_disease)
  tmp.rkey = createUUID()
  return tmp
}
