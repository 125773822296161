import React, { Component } from 'react';
import InputReferral from './input-referral';
import InputAndOr from './input-andor';
import { fromJS, remove, updateIn } from 'immutable'
import lodash from 'lodash'
import { createUUID } from '../react-flow-pedigree/utils';

// let filterItem = { andOr: 'and', referral: '' }
let filterItem = { fieldType: 'referral_criteria', andOr: 'and', referral: '' }
// let filterSet = [filterItem]
let filterSet = {andOr: 'and', items: [filterItem]}
/*
| - - - - - - - - - - - - - - -
| Data Structure
| - - - - - - - - - - - - - - -

  filterSets = [
    {
      andOr: 'and',
      items: [
        { andOr: 'and', referral: '' },
        { andOr: 'and', referral: '' }
      ],
    },
    {
      andOr: 'and',
      items: [
        { andOr: 'and', referral: '' }
      ],
    },
  ]

| - - - - - - - - - - - - - - -
*/

class FilterReferral extends Component {

  constructor(props) {
    super(props);
    this.state = {
      filterSets: this.props.referrals.referral_filters ? this.props.referrals.referral_filters : [],
    }
    this.handleOpenClick = this.handleOpenClick.bind(this)
    this.handleAddSet = this.handleAddSet.bind(this)
    this.handleDeleteItem = this.handleDeleteItem.bind(this)
    this.handleFilterChange = this.handleFilterChange.bind(this)
    this.loadReportSetFilters = this.loadReportSetFilters.bind(this)
  }

  componentDidMount() {
    if(this.state.filterSets.length == 0){
      this.handleAddSet()
    }
  }

  loadReportSetFilters(filterSets){
    if(filterSets.referral_filters.length === 0){
      let clean = [filterSet]
      this.setState({ filterSets: clean })
    }
    else{
      let newFilterSets = filterSets.referral_filters;
      this.setState({ filterSets: newFilterSets })
      this.props.handleIsActiveChange('referral', this.isActive());
    }
  }


  isActive() {
    let clean = [filterSet]
    let filterSets = this.state.filterSets
    return lodash.isEqual(clean, filterSets) == false
  }

  handleOpenClick() {
    if (this.props.open) {
      this.props.onOpen('')
      this.props.handleIsActiveChange('referral', this.isActive());
      this.props.setCurrentFilterPanelFilterSet(lodash.cloneDeep(this.state.filterSets));
      this.props.changeReferralFilters(lodash.cloneDeep(this.state.filterSets), this.isActive())
    } else {
      this.props.onOpen('referral')
      this.props.setCurrentFilterPanelFilterSet(lodash.cloneDeep(this.state.filterSets));
    }
  }

  handleAddSet() {
    let filterSets = this.state.filterSets
    let filterSet_copy = lodash.cloneDeep(filterSet)
    filterSet_copy.andOr = filterSets[1] ? filterSets[1].andOr : filterSets[0] ? filterSets[0].andOr : 'and'
    let newFilterSet = Object.assign({}, filterSet_copy)
    filterSets.push(newFilterSet)
    this.setState({filterSets: filterSets}, () => this.props.setCurrentFilterPanelFilterSet(lodash.cloneDeep(this.state.filterSets)))
  }

  handleAddItem(selectedSetIndex) {
    let filterSets = this.state.filterSets
    let filterItem_copy = lodash.cloneDeep(filterItem)
    filterItem_copy.andOr = filterSets[selectedSetIndex].items[1] ? filterSets[selectedSetIndex].items[1].andOr : filterSets[selectedSetIndex].items[0] ? filterSets[selectedSetIndex].items[0].andOr : 'and'
    filterSets = fromJS(filterSets)
    filterSets = updateIn(filterSets, [selectedSetIndex, 'items'], items => items.push(filterItem_copy))
    filterSets = filterSets.toJS()
    this.setState({filterSets }, () => this.props.setCurrentFilterPanelFilterSet(lodash.cloneDeep(this.state.filterSets)))
  }

  handleDeleteItem(setIndex, itemIndex) {
    let filterSets = this.state.filterSets
    filterSets = fromJS(filterSets)

    // Remove item from set
    filterSets = updateIn(filterSets, [setIndex, 'items'], items => remove(items, itemIndex))

    // Remove empty set
    if(filterSets.get(setIndex).get('items').size == 0) filterSets = remove(filterSets, setIndex)

    filterSets = filterSets.toJS()
    this.setState({filterSets }, () => this.props.setCurrentFilterPanelFilterSet(lodash.cloneDeep(this.state.filterSets)))

    if(setIndex === 0 && itemIndex === 0 && filterSets.length === 0) {
      this.handleFilterChange(setIndex, itemIndex, filterItem)
    }
  }

  handleFilterChange(setIndex, itemIndex, item) {
    let filterSets = this.state.filterSets
    filterSets = fromJS(filterSets)
    filterSets = updateIn(filterSets, [setIndex, 'items'], items => updateIn(items, [itemIndex], _ => item))
    filterSets = filterSets.toJS()

    for(let filter of filterSets[setIndex].items){
      filter.andOr = item.andOr
    }

    this.setState({filterSets: filterSets }, () => this.props.setCurrentFilterPanelFilterSet(lodash.cloneDeep(this.state.filterSets)))
  }

  handleChangeSet(andOr, setIndex) {
    let filterSets = this.state.filterSets
    // filterSets = fromJS(filterSets)
    // filterSets = updateIn(filterSets, [setIndex, 'andOr'], _ => andOr)
    // filterSets = filterSets.toJS()

    for(let filter of filterSets){
      filter.andOr = andOr
    }

    this.setState({filterSets: filterSets }, () => this.props.setCurrentFilterPanelFilterSet(lodash.cloneDeep(this.state.filterSets)))
  }

  render() {

    let btnClass = this.isActive() ? 'btn-purple' : 'btn-light-outline';

    let showDeleteButtonOnSetOne = false;

    if(this.state.filterSets.length === 1 && this.isActive()){
      showDeleteButtonOnSetOne = true;
    }

    return (
      <React.Fragment >
        <div className="popover-query-wrapper">

          <button
            onClick={this.handleOpenClick}
            className={"btn btn-xs " + btnClass} >Referral Criteria</button>

          <div id="refPop"
            style={{ display: (this.props.open ? 'block' : 'none') }}
            className="popover popover-wide popover-x popover-default popover-custom kv-popover-active in bottom popover-query">

            <div className="arrow popover-query__arrow"></div>
            <h5 className="popover-header popover-title">
                <span
                  onClick={this.handleOpenClick}
                  className="close pull-right">&times;</span>
            Referral Criteria</h5>
            <div className="popover-body popover-content">

                {this.state.filterSets.map((set, setIndex) => {
                  return <FilterSet
                          key={createUUID()}
                          set={set}
                          totalSets={this.state.filterSets.length}
                          setIndex={setIndex}
                          showDeleteButtonOnSetOne={showDeleteButtonOnSetOne}
                          onChangeSet={(andOr) => this.handleChangeSet(andOr, setIndex)}
                          onChangeItem={(item, itemIndex) => this.handleFilterChange(setIndex, itemIndex, item)}
                          onAddItemClick={() => this.handleAddItem(setIndex)}
                          onDeleteItemClick={(itemIndex) => this.handleDeleteItem(setIndex, itemIndex)}
                        />
                })}

                <div className="margin-two">
                  <a onClick={this.handleAddSet} className="btn btn-teal btn-xs">Add Set</a>
                </div>

            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

/*
Functional Components
*/

const FilterSet = (props) => {
  return (
    <React.Fragment>

      {props.setIndex > 0 &&
        <div className="row margin-three">
          <InputAndOr
            value={props.set.andOr}
            onChange={(e) => props.onChangeSet(e.target.value) }
            colWidth='col-md-3'
          />
        </div>
      }

      <div className="panel-form-3">

        {props.set.items.map((item, itemIndex) => {
          const showDeleteButton = () => {
            if(itemIndex === 0 && props.setIndex === 0 && props.totalSets === 1){
              return props.showDeleteButtonOnSetOne;
            }
            else{
              return props.set.items.length > 1 || props.totalSets > 1
            }
          }
          return (
            <div key={createUUID()} className={"row margin-one " + (itemIndex == 0 ? 'padding-left--15' : '')}>

              <InputReferral
                key={createUUID()}
                item={item}
                itemIndex={itemIndex}
                onAddItemClick={() => props.onAddItemClick()}
                onDeleteItemClick={() => props.onDeleteItemClick(itemIndex)}
                onChange={(data) => props.onChangeItem(data, itemIndex)}
                showDeleteButton={showDeleteButton()}
                showAddButton={itemIndex == props.set.items.length - 1}
              />

            </div>
          )

        })}

      </div>

    </React.Fragment>
  )
}

/* Functional Components */

export default FilterReferral;
