import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import NavTop from '../nav-top';
import SurveyList from './survey-list';
import SurveySearch from './survey-search';
interface ModalProps {
    user: any;
    history: any;
  }


interface State{
  surveyList: any;
}

class SurveyListPage extends Component<ModalProps, State> {
    constructor(props: ModalProps) {
        super(props);
        this.state = {
          surveyList: []
        };

      this.componentDidMount = this.componentDidMount.bind(this);
    }
  componentDidMount(){
  }

render() {
    return ReactDOM.createPortal(
    <React.Fragment>
      <NavTop
        history={this.props.history}
        page='survey-results'
      />
        <section className="wow fadeIn section">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <SurveyList

                  />
                </div>
              </div>
            </div>
          </section>
    </React.Fragment>,
      
    document.body
    );
  };
}
export default SurveyListPage;
