import React, { memo } from 'react';
import { ControlProps, Handle, Position } from '@xyflow/react';
import DeleteDOM from './delete-dom';
import OneClickAdd from './one-click-add';
import Cookie from 'js-cookie'

import $ from 'jquery'


import {
  PedigreeStylesheet,
  PersonNodeWidth,
  PersonNodeHeight,
  ConnectorNodeWidth,
  ConnectorNodeHeight,
  showPersonID
} from './pedigree-constants';
import Node_Line_Store from './Node_Line_Store';

export default memo(({ data, positionAbsoluteX, positionAbsoluteY, type, selected, connectable, sourcePosition, targetPosition }) => {
  const {
    gender_identity,
    is_dead,
    pregnancy,
    adopted_in,
    adopted_out,
    carrier,
    is_no_children_node,
    is_infertility_node,
    is_abortion_node,
    diseases
  } = data.profile;

  if (data.selected_after_pedigree_load) {
    selected = true;
  }
  let selected_count = data.getPedigreeDrawingData().getSelectedCount();
  let watcher = data.getConnectionWatcher();
  watcher.update_node_location(data.id, positionAbsoluteX, positionAbsoluteY, data.getPedigreeData().getAllProfiles());
  // CHECK IF CLONED
  let cloned_members = data.getPedigreeData().getClonedMembers();
  let has_existing_proband_record = cloned_members.find(patient => patient.uuid_code == data.profile.uuid_code);

  // CHECK IF MANUALLY LINKED
  let linked_members = data.getPedigreeData().getLinkedMembers()
  let manually_linked_proband = null;
  let check_if_manually_linked = data.getPedigreeData().getMemberLinks().find(link => link.member_one_uuid == data.profile.uuid_code || link.member_two_uuid == data.profile.uuid_code)
  if (check_if_manually_linked) {
    manually_linked_proband = linked_members.find(proband => proband.uuid_code == check_if_manually_linked.member_two_uuid || proband.uuid_code == check_if_manually_linked.member_one_uuid)
  }

  // CHECK IF DONOR/SURROGATE
  let donors = data.getPedigreeData().getDonors();
  let is_donor = donors.find(donor => donor.donor_id + '' == data.profile.id + '');

  let render_patient_pedigree = sessionStorage.getItem('render-patient-pedigree')
  render_patient_pedigree = render_patient_pedigree == 'true'

  const core7Ids = [
    data.proband_id, data.proband_father_id,
    data.proband_mother_id, data.proband_maternal_grandfather_id,
    data.proband_maternal_grandmother_id, data.proband_paternal_grandfather_id,
    data.proband_paternal_grandmother_id
  ]

  const core7Member = core7Ids.includes(data.id)

  const subtext = showPersonID ? (data.subtext) ? data.profile.id + '\n' + data.subtext.data.label : data.profile.id : (data.subtext) ? data.subtext.data.label : null;

  const proband = (data.is_proband) ? PedigreeStylesheet.personNodeProband : "";

  let gender = data.gender;
  let assigned_birth = null;
  let birth_assignment = null;
  if (gender == 'male') {
    birth_assignment = 'AMAB';
  } else if (gender == 'female') {
    birth_assignment = 'AFAB';
  } else if (gender == 'unknown') {
    birth_assignment = 'AUAB'
  }
  if (gender_identity && gender_identity !== gender) {
    gender = gender_identity;
    assigned_birth = (
      <div className='react-flow__node react-flow__node-birth_assignment' style={{ position: 'absolute', fontFamily: '"Inter", sans-serif', fontSize: '10px', left: '0px', top: '-15px', color: '#000000' }}>
        {birth_assignment}
      </div>
    );
  }

  let node_svg = null;
  let selectedStyle = {};

  // data.datastore.addNode(data.id, "person", xPos, yPos, selected);
  if (selected) selectedStyle = PedigreeStylesheet.personNodeSelected;

  let donor_assign = data.getPedigreeData().getDonorAssign();
  let people = Object.values(data.getPedigreeData().getAllProfiles());
  let donee = people.find(person => person.id == donor_assign.donee_id)
  if (gender === 'male') {
    if (donor_assign.active) {
      let selected_condition = donor_assign.active && donor_assign.donor_record.value == "sperm_donor" && donee.father_id + '' != data.id + '' && donee.mother_id + '' != data.id + '' && donee.id + '' != data.id + '';
      if (donee.father_id + '' == data.profile.id + '' || donee.mother_id + '' == data.profile.id + '') {
        if (data.profile.relationship_ids.length > 0) {

          let relationship_data_of_donee_parents = data.profile.relationship_ids.find(relationship => (relationship.father_id + '' == donee.father_id + '' && relationship.mother_id + '' == donee.mother_id + '') || (relationship.father_id + '' == donee.mother_id + '' && relationship.mother_id + '' == donee.father_id + ''))
          let parent1 = people.find(person => person.id == relationship_data_of_donee_parents.father_id);
          let parent2 = people.find(person => person.id == relationship_data_of_donee_parents.mother_id);

          // if parents of donee are same sex partners, include them to possible people that can be donors
          if (parent1.gender == parent2.gender) {
            selected_condition = donor_assign.active && donor_assign.donor_record.value == "sperm_donor" && donee.id + '' != data.id + '';
          }
        }
      }
      if (selected_condition) {
        selected = true;
      }
    }
    if (selected) selectedStyle = PedigreeStylesheet.personNodeSelected;
    const s = Object.assign({}, PedigreeStylesheet.personNodeMale, proband, selectedStyle);
    node_svg = (<rect x={1} y={1} width={38} height={38} style={s} />);
  } else if (gender === 'female') {

    if (donor_assign.active) {
      let selected_condition = donor_assign.active && (donor_assign.donor_record.value == "egg_donor" || donor_assign.donor_record.value == "surrogate" || donor_assign.donor_record.value == "surrogate_egg_donor") && donee.father_id + '' != data.id + '' && donee.mother_id + '' != data.id + '' && donee.id + '' != data.id + '';
      if (donee.father_id + '' == data.profile.id + '' || donee.mother_id + '' == data.profile.id + '') {
        if (data.profile.relationship_ids.length > 0) {

          let relationship_data_of_donee_parents = data.profile.relationship_ids.find(relationship => (relationship.father_id + '' == donee.father_id + '' && relationship.mother_id + '' == donee.mother_id + '') || (relationship.father_id + '' == donee.mother_id + '' && relationship.mother_id + '' == donee.father_id + ''))
          let parent1 = people.find(person => person.id == relationship_data_of_donee_parents.father_id);
          let parent2 = people.find(person => person.id == relationship_data_of_donee_parents.mother_id);

          // if parents of donee are same sex partners, include them to possible people that can be donors
          if (parent1.gender == parent2.gender) {
            selected_condition = donor_assign.active && (donor_assign.donor_record.value == "egg_donor" || donor_assign.donor_record.value == "surrogate" || donor_assign.donor_record.value == "surrogate_egg_donor") && donee.id + '' != data.id + '';
          }
        }
      }
      if (selected_condition) {
        selected = true;
      }
    }

    if (selected) selectedStyle = PedigreeStylesheet.personNodeSelected;
    const s = Object.assign({}, PedigreeStylesheet.personNodeFemale, proband, selectedStyle);
    node_svg = (<circle cx={20} cy={20} r={19} style={s} />);
  } else {
    const s = Object.assign({}, PedigreeStylesheet.personNodeUnknown, proband, selectedStyle);

    // this works because our svg box is a 40x40 box
    const d = `M ${20},${1}L ${1},${20}L ${20},${39}L ${39},${20}L ${20},${1} Z`;
    node_svg = (<path d={d} style={s} />);
  }

  let arrow = null;
  if (data.is_proband) {
    arrow = (
      <div style={{ position: 'relative', zIndex: 999, marginLeft: '-30px', marginBottom: '-8px', marginTop: '-8px', width: '20px', height: '20px' }}>
        <svg height="20px" width="20px">
          <polygon points="20 0, 7 4, 16 13" stroke="grey" fill="grey" />
          <line x1={11.5} y1={8.5} x2={0} y2={20} stroke="grey" fill="grey" strokeWidth={2} />
        </svg>
      </div>
    );
  }

  let deceased_line = null;
  if (is_dead) {
    deceased_line = (
      <div style={{ position: 'absolute', fontSize: '8px', left: '-4px', top: '-4px', width: '48px', height: '48px' }}>
        <svg height={48} width={48}>
          <line x1={is_abortion_node ? 8 : 0} y1={is_abortion_node ? 35 : 48} x2={is_abortion_node ? 40 : 48} y2={is_abortion_node ? 0 : 0} style={PedigreeStylesheet.colorScheme} strokeWidth={2} />
        </svg>
      </div>
    );
  }

  let pregnancy_text = null;
  if (pregnancy) {
    pregnancy_text = (
      <text x={16} y={25} style={PedigreeStylesheet.pregnancyNodeText}>P</text>
    );
  }

  let carrier_circle = null;
  if (carrier) {
    carrier_circle = (
      <circle cx={20} cy={20} r={8} style={PedigreeStylesheet.colorScheme} strokeWidth={1} />
    );
  }


  let donor_text_svg = null;
  if (is_donor) {
    let donor_text = '';
    if (is_donor.donor_type == 'sperm_donor' || is_donor.donor_type == 'egg_donor') {
      donor_text = 'D';
    }
    else if (is_donor.donor_type == 'surrogate' || is_donor.donor_type == 'surrogate_egg_donor') {
      donor_text = 'S';
    }
    donor_text_svg = (
      <text x={16} y={25} style={PedigreeStylesheet.pregnancyNodeText}>{donor_text}</text>
    );
  }

  let adoptedPathLeft = null;
  let adoptedPathRight = null;
  if (adopted_in || adopted_out) {
    adoptedPathLeft = (
      <div style={{ position: 'absolute', fontSize: '8px', left: '-4px', top: '-4px', width: '18px', height: '48px' }}>
        <svg height={48} width={18}>
          <g>
            <line x1={0} y1={0} x2={18} y2={0} style={PedigreeStylesheet.colorScheme} strokeWidth={2} />
            <line x1={0} y1={0} x2={0} y2={48} style={PedigreeStylesheet.colorScheme} strokeWidth={2} />
            <line x1={0} y1={48} x2={18} y2={48} style={PedigreeStylesheet.colorScheme} strokeWidth={2} />
          </g>
        </svg>
      </div>
    );

    adoptedPathRight = (
      <div style={{ position: 'absolute', fontSize: '8px', left: '26px', top: '-4px', width: '18px', height: '48px' }}>
        <svg height={48} width={18}>
          <g>
            <line x1={0} y1={0} x2={18} y2={0} style={PedigreeStylesheet.colorScheme} strokeWidth={2} />
            <line x1={18} y1={0} x2={18} y2={48} style={PedigreeStylesheet.colorScheme} strokeWidth={2} />
            <line x1={0} y1={48} x2={18} y2={48} style={PedigreeStylesheet.colorScheme} strokeWidth={2} />
          </g>
        </svg>
      </div>
    );
  }

  // patterns
  {/* solid black */ }
  let img1 = <pattern id="img1" patternUnits="userSpaceOnUse" viewBox='0 0 1600 800' width="1600" height="800">
    <rect fill='#000000' width='1600' height='800' />
    <g fill-opacity='1'>
      <path fill='#000000' d='M486 705.8c-109.3-21.8-223.4-32.2-335.3-19.4C99.5 692.1 49 703 0 719.8V800h843.8c-115.9-33.2-230.8-68.1-347.6-92.2C492.8 707.1 489.4 706.5 486 705.8z' />
      <path fill='#000000' d='M1600 0H0v719.8c49-16.8 99.5-27.8 150.7-33.5c111.9-12.7 226-2.4 335.3 19.4c3.4 0.7 6.8 1.4 10.2 2c116.8 24 231.7 59 347.6 92.2H1600V0z' />
      <path fill='#000000' d='M478.4 581c3.2 0.8 6.4 1.7 9.5 2.5c196.2 52.5 388.7 133.5 593.5 176.6c174.2 36.6 349.5 29.2 518.6-10.2V0H0v574.9c52.3-17.6 106.5-27.7 161.1-30.9C268.4 537.4 375.7 554.2 478.4 581z' />
      <path fill='#000000' d='M0 0v429.4c55.6-18.4 113.5-27.3 171.4-27.7c102.8-0.8 203.2 22.7 299.3 54.5c3 1 5.9 2 8.9 3c183.6 62 365.7 146.1 562.4 192.1c186.7 43.7 376.3 34.4 557.9-12.6V0H0z' />
      <path fill='#000000' d='M181.8 259.4c98.2 6 191.9 35.2 281.3 72.1c2.8 1.1 5.5 2.3 8.3 3.4c171 71.6 342.7 158.5 531.3 207.7c198.8 51.8 403.4 40.8 597.3-14.8V0H0v283.2C59 263.6 120.6 255.7 181.8 259.4z' />
      <path fill='#000000' d='M1600 0H0v136.3c62.3-20.9 127.7-27.5 192.2-19.2c93.6 12.1 180.5 47.7 263.3 89.6c2.6 1.3 5.1 2.6 7.7 3.9c158.4 81.1 319.7 170.9 500.3 223.2c210.5 61 430.8 49 636.6-16.6V0z' />
      <path fill='#000000' d='M454.9 86.3C600.7 177 751.6 269.3 924.1 325c208.6 67.4 431.3 60.8 637.9-5.3c12.8-4.1 25.4-8.4 38.1-12.9V0H288.1c56 21.3 108.7 50.6 159.7 82C450.2 83.4 452.5 84.9 454.9 86.3z' />
      <path fill='#000000' d='M1600 0H498c118.1 85.8 243.5 164.5 386.8 216.2c191.8 69.2 400 74.7 595 21.1c40.8-11.2 81.1-25.2 120.3-41.7V0z' />
      <path fill='#000000' d='M1397.5 154.8c47.2-10.6 93.6-25.3 138.6-43.8c21.7-8.9 43-18.8 63.9-29.5V0H643.4c62.9 41.7 129.7 78.2 202.1 107.4C1020.4 178.1 1214.2 196.1 1397.5 154.8z' />
      <path fill='#000000' d='M1315.3 72.4c75.3-12.6 148.9-37.1 216.8-72.4h-723C966.8 71 1144.7 101 1315.3 72.4z' />
    </g>
  </pattern>
  {/* solid #b2b2b2 */ }
  let img2 = <pattern id="img2" patternUnits="userSpaceOnUse" viewBox='0 0 1600 800' width="1600" height="800">
    <rect fill='#b2b2b2' width='1600' height='800' />
    <g fill-opacity='1'>
      <path fill='#b2b2b2' d='M486 705.8c-109.3-21.8-223.4-32.2-335.3-19.4C99.5 692.1 49 703 0 719.8V800h843.8c-115.9-33.2-230.8-68.1-347.6-92.2C492.8 707.1 489.4 706.5 486 705.8z' />
      <path fill='#b2b2b2' d='M1600 0H0v719.8c49-16.8 99.5-27.8 150.7-33.5c111.9-12.7 226-2.4 335.3 19.4c3.4 0.7 6.8 1.4 10.2 2c116.8 24 231.7 59 347.6 92.2H1600V0z' />
      <path fill='#b2b2b2' d='M478.4 581c3.2 0.8 6.4 1.7 9.5 2.5c196.2 52.5 388.7 133.5 593.5 176.6c174.2 36.6 349.5 29.2 518.6-10.2V0H0v574.9c52.3-17.6 106.5-27.7 161.1-30.9C268.4 537.4 375.7 554.2 478.4 581z' />
      <path fill='#b2b2b2' d='M0 0v429.4c55.6-18.4 113.5-27.3 171.4-27.7c102.8-0.8 203.2 22.7 299.3 54.5c3 1 5.9 2 8.9 3c183.6 62 365.7 146.1 562.4 192.1c186.7 43.7 376.3 34.4 557.9-12.6V0H0z' />
      <path fill='#b2b2b2' d='M181.8 259.4c98.2 6 191.9 35.2 281.3 72.1c2.8 1.1 5.5 2.3 8.3 3.4c171 71.6 342.7 158.5 531.3 207.7c198.8 51.8 403.4 40.8 597.3-14.8V0H0v283.2C59 263.6 120.6 255.7 181.8 259.4z' />
      <path fill='#b2b2b2' d='M1600 0H0v136.3c62.3-20.9 127.7-27.5 192.2-19.2c93.6 12.1 180.5 47.7 263.3 89.6c2.6 1.3 5.1 2.6 7.7 3.9c158.4 81.1 319.7 170.9 500.3 223.2c210.5 61 430.8 49 636.6-16.6V0z' />
      <path fill='#b2b2b2' d='M454.9 86.3C600.7 177 751.6 269.3 924.1 325c208.6 67.4 431.3 60.8 637.9-5.3c12.8-4.1 25.4-8.4 38.1-12.9V0H288.1c56 21.3 108.7 50.6 159.7 82C450.2 83.4 452.5 84.9 454.9 86.3z' />
      <path fill='#b2b2b2' d='M1600 0H498c118.1 85.8 243.5 164.5 386.8 216.2c191.8 69.2 400 74.7 595 21.1c40.8-11.2 81.1-25.2 120.3-41.7V0z' />
      <path fill='#b2b2b2' d='M1397.5 154.8c47.2-10.6 93.6-25.3 138.6-43.8c21.7-8.9 43-18.8 63.9-29.5V0H643.4c62.9 41.7 129.7 78.2 202.1 107.4C1020.4 178.1 1214.2 196.1 1397.5 154.8z' />
      <path fill='#b2b2b2' d='M1315.3 72.4c75.3-12.6 148.9-37.1 216.8-72.4h-723C966.8 71 1144.7 101 1315.3 72.4z' />
    </g>
  </pattern>
  {/* brick wall vertical */ }
  let img3 = <pattern id="img3" patternTransform="scale(0.3) rotate(90)" patternUnits="userSpaceOnUse" viewBox='0 0 30 30' width="30" height="30">
    <path d='M0 22.5h30v15H0zm15-15h30v15H15m-30-15h30v15h-30zm15-15h30v15H0z' stroke-width='1' stroke='hsla(259, 0%, 0%, 1)' fill='none' />
  </pattern>
  {/* circles */ }
  let img4 = <pattern id='img4' patternTransform="scale(0.5)" patternUnits="userSpaceOnUse" viewBox='0 0 20 20' width="20" height="20">
    <path d='M10 15a5 5 0 110-10 5 5 0 010 10z' stroke-width='1' stroke='hsla(259, 0%, 0%, 1)' fill='none' />
  </pattern>
  {/* chevron */ }
  let img5 = <pattern id="img5" patternTransform="scale(0.6 0.6)" patternUnits="userSpaceOnUse" viewBox='0 0 20 20' width="20" height="20">
    <path d='M-5 5L5.1 15 15 5l10 10' stroke-linecap='square' stroke-width='1.5' stroke='hsla(0, 0%, 0%, 1)' fill='none' />
  </pattern>
  {/* diagonal stripes */ }
  let img6 = <pattern id="img6" patternTransform="scale(0.3 0.3)" patternUnits="userSpaceOnUse" viewBox='0 0 40 40' width="40" height="40">
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="diagonal-stripes" fill="#000000">
        <polygon id="Path-2" points="0 40 40 0 20 0 0 20"></polygon>
        <polygon id="Path-2-Copy" points="40 40 40 20 20 40"></polygon>
      </g>
    </g>
  </pattern>
  {/* diamonds */ }
  let img7 = <pattern id="img7" patternTransform="scale(0.3 0.3)" patternUnits="userSpaceOnUse" viewBox='0 0 50 50' width="50" height="50">
    <path d='M50 25L37.5 50 25 25 37.5 0zm-25 0L12.5 50 0 25 12.5 0z' stroke-width='1' stroke='hsla(259, 0%, 0%, 1)' fill='none' />
  </pattern>
  {/* herringbone1 */ }
  let img8 = <pattern id="img8" patternTransform="scale(0.5 0.5)" patternUnits="userSpaceOnUse" viewBox='0 0 20 20' width="20" height="20">
    <path d='M10-10L20 0v10L10 0zM20 0L10-10V0l10 10zm0 10L10 0v10l10 10zm0 10L10 10v10l10 10zM0 20l10-10v10L0 30zm0-10L10 0v10L0 20zM0 0l10-10V0L0 10z' stroke-width='1.5' stroke='hsla(0, 0%, 0%, 1)' fill='none' />
  </pattern>
  {/* jagged square */ }
  let img9 = <pattern id="img9" patternTransform="scale(0.5 0.5)" patternUnits="userSpaceOnUse" viewBox='0 0 20 20' width="20" height="20">
    <path d='M -5,-3 5,2 15,-3 25,2 M -5,17 5,22 15,17 25,22 M -5,7 5,12 15,7 25,12 m -27.5,12.5 5,-10 -5,-10 5,-10 m 15,30 5,-10 -5,-10 5,-10 m -15,30 5,-10 -5,-10 5,-10' stroke-width='1.5' stroke='hsla(0, 0%, 0%, 1)' fill='none' />
  </pattern>
  {/* lines horiz */ }
  let img10 = <pattern id="img10" patternTransform="scale(0.5 0.5)" patternUnits="userSpaceOnUse" viewBox='0 0 20 20' width="20" height="20">
    <path d='M0 10h20z' stroke-width='2.5' stroke='hsla(0, 0%, 0%, 1)' fill='none' />
  </pattern>
  {/* pixel dots */ }
  let img11 = <pattern id="img11" patternTransform="scale(0.6 0.6)" patternUnits="userSpaceOnUse" viewBox='0 0 16 16' width="16" height="16">
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="pixel-dots" fill="#000000">
        <path d="M0,4.16333634e-16 L16,4.16333634e-16 L16,2 L10,2 L10,8 L16,8 L16,16 L8,16 L8,10 L2,10 L2,16 L0,16 L0,4.16333634e-16 Z M4,4 L6,4 L6,6 L4,6 L4,4 Z M12,12 L14,12 L14,14 L12,14 L12,12 Z M4,12 L6,12 L6,14 L4,14 L4,12 Z M12,4 L14,4 L14,6 L12,6 L12,4 Z" id="Combined-Shape"></path>
      </g>
    </g>
  </pattern>
  {/* stripes */ }
  let img12 = <pattern id="img12" patternTransform="scale(0.5) rotate(90)" patternUnits="userSpaceOnUse" viewBox='0 0 20 20' width="20" height="20">
    <path d='M0 10h20z' stroke-width='8' stroke='hsla(259, 0%, 0%, 1)' fill='hsla(0,0%,100%,1)' />
  </pattern>
  {/* tiny checkers */ }
  let img13 = <pattern id="img13" patternTransform="scale(1.2 1.2)" patternUnits="userSpaceOnUse" viewBox='0 0 8 8' width="8" height="8">
    <path fill="#000000" fill-rule="evenodd" d="M0 0h4v4H0V0zm4 4h4v4H4V4z" />
  </pattern>
  {/* waves 2 */ }
  let img14 = <pattern id="img14" patternTransform="scale(0.5 0.5)" patternUnits="userSpaceOnUse" viewBox='0 0 100 20' width="100" height="20">
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="bank-note" fill="#000000">
        <path d="M21.1841339,20 C21.5411448,19.869748 21.9037256,19.7358773 22.272392,19.5983261 C22.6346445,19.4631679 23.8705367,18.9999982 24.0399055,18.9366758 C33.6397477,15.3475548 39.6469349,14 50,14 C60.2711361,14 65.3618399,15.2217689 74.6286093,18.9284767 C75.584355,19.310775 76.4978747,19.6675274 77.3787841,20 L83.604005,20 C81.0931362,19.2694473 78.4649665,18.3089537 75.3713907,17.0715233 C65.8881601,13.2782311 60.5621972,12 50,12 C39.3741437,12 33.144814,13.3973866 23.3395101,17.0633242 C23.1688625,17.1271247 21.9338538,17.5899633 21.5732596,17.7245028 C19.0984715,18.6478581 16.912678,19.3994574 14.8494171,20 L21.1841339,20 L21.1841339,20 Z M21.1841339,0 C13.2575214,2.89194861 8.07672845,4 7.87150385e-14,4 L7.81597009e-14,4 L0,2 C5.74391753,2 9.9514017,1.4256397 14.8494171,1.40165657e-15 L21.1841339,6.9388939e-17 L21.1841339,0 Z M77.3787841,2.21705987e-12 C85.238555,2.9664329 90.5022896,4 100,4 L100,2 C93.1577329,2 88.6144135,1.4578092 83.604005,1.04805054e-13 L77.3787841,0 L77.3787841,2.21705987e-12 Z M7.87150385e-14,14 C8.44050043,14 13.7183277,12.7898887 22.272392,9.59832609 C22.6346445,9.46316794 23.8705367,8.99999822 24.0399055,8.9366758 C33.6397477,5.34755477 39.6469349,4 50,4 C60.2711361,4 65.3618399,5.2217689 74.6286093,8.92847669 C84.1118399,12.7217689 89.4378028,14 100,14 L100,12 C89.7288639,12 84.6381601,10.7782311 75.3713907,7.07152331 C65.8881601,3.2782311 60.5621972,2 50,2 C39.3741437,2 33.144814,3.39738661 23.3395101,7.0633242 C23.1688625,7.12712472 21.9338538,7.58996334 21.5732596,7.72450279 C13.2235239,10.8398294 8.16350991,12 0,12 L7.81597009e-14,14 L7.87150385e-14,14 L7.87150385e-14,14 Z" id="Combined-Shape"></path>
      </g>
    </g>
  </pattern>
  {/* zigzag */ }
  let img15 = <pattern id="img15" patternTransform="scale(0.8 0.8) rotate(90)" patternUnits="userSpaceOnUse" viewBox='0 0 40 12' width="40" height="12">
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="zig-zag" fill="#000000">
        <path d="M2.84217094e-14,6.17157288 L6.17157288,0 L11.8284271,0 L0,11.8284271 L2.84217094e-14,6.17157288 L2.84217094e-14,6.17157288 Z M40,11.8284271 L28.1715729,0 L33.8284271,3.55271368e-15 L40,6.17157288 L40,11.8284271 L40,11.8284271 Z M6.17157288,12 L18.1715729,0 L21.8284271,0 L33.8284271,12 L28.1715729,12 L20,3.82842712 L11.8284271,12 L6.17157288,12 L6.17157288,12 Z M18.1715729,12 L20,10.1715729 L21.8284271,12 L18.1715729,12 L18.1715729,12 Z" id="Combined-Shape"></path>
      </g>
    </g>
  </pattern>

  function getImage(color) {
    switch (color) {
      case 1:
        return img1;
      case 2:
        return img2;
      case 3:
        return img3;
      case 4:
        return img4;
      case 5:
        return img5;
      case 6:
        return img6;
      case 7:
        return img7;
      case 8:
        return img8;
      case 9:
        return img9;
      case 10:
        return img10;
      case 11:
        return img11;
      case 12:
        return img12;
      case 13:
        return img13;
      case 14:
        return img14;
      case 15:
        return img15;
      default:
        return img1;
    }
  };

  // 20,20 is the center point of our 40x40 nodes
  // male, female & unknown are stroke width 1px
  let displayBlackAndWhite = Cookie.get('famgenix_pedigree_black_and_white');
  const displayBlackAndWhiteBool = displayBlackAndWhite === 'true';
  let node_disease_colors = [];
  let node_patterns = [];
  if (diseases && Array.isArray(diseases) && diseases.length > 0) {
    let sliced = JSON.parse(JSON.stringify(diseases))
    const hidden_colors = data.getPedigreeData().getHiddenDiseaseColors();
    for (let i = 0; i < hidden_colors.length; i++) {
      const disease_id = hidden_colors[i].disease_id ? hidden_colors[i].disease_id : hidden_colors[i].umls_id;
      sliced = sliced.filter(d => d.disease_id !== disease_id);
    }

    sliced = sliced.slice(0, 4);

    if (sliced.length === 1) {
      const color = data.disease_color_map[sliced[0].disease_id];
      const fill = displayBlackAndWhiteBool ? `url(#img${color})` : color
      if (displayBlackAndWhiteBool) {
        node_patterns.push(getImage(color));
      }
      if (is_abortion_node) {
        const box_path = `M ${20},${4}L ${6},${18}L ${20},${18}L ${34},${18}L ${20},${4} Z`;
        node_disease_colors.push((<path d={box_path} stroke={fill} fill={fill} />));
      } else if (gender === 'male') {
        const box_path = `M ${3},${3}L ${3},${37}L ${37},${37}L ${37},${3}L ${3},${3} Z`;
        node_disease_colors.push((<path d={box_path} fill={fill} stroke={fill} />));
      } else if (gender === 'female') {
        node_disease_colors.push((<circle cx={20} cy={20} r={17} fill={fill} stroke={fill} />));
      } else {
        // gender unknown
        const box_path = `M ${20},${4}L ${4},${20}L ${20},${36}L ${36},${20}L ${20},${4} Z`;
        node_disease_colors.push((<path d={box_path} stroke={fill} fill={fill} />));
      }
    } else if (sliced.length === 2) {
      const color1 = data.disease_color_map[sliced[0].disease_id];
      const color2 = data.disease_color_map[sliced[1].disease_id];

      const fill1 = displayBlackAndWhiteBool ? `url(#img${color1})` : color1
      const fill2 = displayBlackAndWhiteBool ? `url(#img${color2})` : color2

      if (displayBlackAndWhiteBool) {
        node_patterns.push(getImage(color1));
        node_patterns.push(getImage(color2));
      }

      if (is_abortion_node) {
        const box_path1 = `M ${19},${4}L ${4},${19}L ${20},${19}L ${20},${18}L ${20},${3} Z`;
        const box_path2 = `M ${20},${4}L ${20},${19}L ${20},${19}L ${36},${19}L ${20},${3} Z`;
        node_disease_colors.push((<path d={box_path1} stroke="none" fill={fill1} />));
        node_disease_colors.push((<path d={box_path2} stroke="none" fill={fill2} />));
      } else if (gender === 'male') {
        const box_path1 = `M ${3},${3}L ${3},${20}L ${37},${20}L ${37},${3}L ${3},${3} Z`;
        const box_path2 = `M ${3},${20}L ${3},${37}L ${37},${37}L ${37},${20}L ${7},${20} Z`;
        node_disease_colors.push((<path d={box_path1} stroke={fill1} fill={fill1} />));
        node_disease_colors.push((<path d={box_path2} stroke={fill2} fill={fill2} />));
      } else if (gender === 'female') {
        node_disease_colors.push((<path d="M 37,20A 17 17 0 1 0 3,20 Z" fill={fill1} stroke={fill1} />));
        node_disease_colors.push((<path d="M 37,20A 17 17 0 1 1 3,20 Z" fill={fill2} stroke={fill2} />));
      } else {
        // gender unknown
        const box_path1 = `M ${20},${4}L ${12},${12}L ${28},${28}L ${36},${20}L ${20},${4} Z`;
        const box_path2 = `M ${12},${12}L ${4},${20}L ${20},${36}L ${28},${28}L ${12},${12} Z`;
        node_disease_colors.push((<path d={box_path1} stroke={fill1} fill={fill1} />));
        node_disease_colors.push((<path d={box_path2} stroke={fill2} fill={fill2} />));
      }
    } else if (sliced.length === 3) {
      const color1 = data.disease_color_map[sliced[0].disease_id];
      const color2 = data.disease_color_map[sliced[1].disease_id];
      const color3 = data.disease_color_map[sliced[2].disease_id];

      const fill1 = displayBlackAndWhiteBool ? `url(#img${color1})` : color1
      const fill2 = displayBlackAndWhiteBool ? `url(#img${color2})` : color2
      const fill3 = displayBlackAndWhiteBool ? `url(#img${color3})` : color3

      if (displayBlackAndWhiteBool) {
        node_patterns.push(getImage(color1));
        node_patterns.push(getImage(color2));
        node_patterns.push(getImage(color3));
      }

      if (is_abortion_node) {
        // bottom boxes
        const box_path1 = `M ${6},${18}L ${20},${18}L ${20},${18}L ${20},${12}L ${12},${12} Z`;
        const box_path2 = `M ${21},${12}L ${28},${12}L ${34},${18}L ${30},${18}L ${21},${18} Z`;

        // top box
        const box_path3 = `M ${20},${4}L ${12},${12}L ${20},${12}L ${28},${12}L ${20},${4} Z`;

        node_disease_colors.push((<path d={box_path1} stroke={fill1} fill={fill1} />));
        node_disease_colors.push((<path d={box_path2} stroke={fill2} fill={fill2} />));
        node_disease_colors.push((<path d={box_path3} stroke={fill3} fill={fill3} />));
      } else if (gender === 'male') {
        // bottom boxes
        const box_path1 = `M ${3},${20}L ${3},${37}L ${20},${37}L ${20},${20}L ${3},${20} Z`;
        const box_path2 = `M ${20},${20}L ${20},${37}L ${37},${37}L ${37},${20}L ${20},${20} Z`;

        // top box
        const box_path3 = `M ${3},${3}L ${3},${20}L ${37},${20}L ${37},${3}L ${3},${3} Z`;

        node_disease_colors.push((<path d={box_path1} stroke={fill1} fill={fill1} />));
        node_disease_colors.push((<path d={box_path2} stroke={fill2} fill={fill2} />));
        node_disease_colors.push((<path d={box_path3} stroke={fill3} fill={fill3} />));
      } else if (gender === 'female') {
        node_disease_colors.push((<path d="M 37,20A 17 17 0 1 0 3,20 Z" fill={fill1} stroke={fill1} />));
        node_disease_colors.push((<path d="M20,37 a17,17 0 0,1 -17,-17h 17,0v 0,17 Z" fill={fill2} stroke={fill2} />));
        node_disease_colors.push((<path d="M37,20 a17,17 0 0,1 -17,17v 0,-17h 17,0 Z" fill={fill3} stroke={fill3} />));
      } else {
        // gender unknown

        // bottom boxes
        const box_path1 = `M ${12},${12}L ${4},${20}L ${12},${28}L ${20},${20}L ${12},${12} Z`;
        const box_path2 = `M ${20},${20}L ${12},${28}L ${20},${36}L ${28},${28}L ${20},${20} Z`;

        // top box
        const box_path3 = `M ${20},${4}L ${12},${12}L ${28},${28}L ${36},${20}L ${20},${4} Z`;

        node_disease_colors.push((<path d={box_path1} stroke={fill1} fill={fill1} />));
        node_disease_colors.push((<path d={box_path2} stroke={fill2} fill={fill2} />));
        node_disease_colors.push((<path d={box_path3} stroke={fill3} fill={fill3} />));
      }
    } else if (sliced.length === 4) {
      const color1 = data.disease_color_map[sliced[0].disease_id];
      const color2 = data.disease_color_map[sliced[1].disease_id];
      const color3 = data.disease_color_map[sliced[2].disease_id];
      const color4 = data.disease_color_map[sliced[3].disease_id];

      const fill1 = displayBlackAndWhiteBool ? `url(#img${color1})` : color1
      const fill2 = displayBlackAndWhiteBool ? `url(#img${color2})` : color2
      const fill3 = displayBlackAndWhiteBool ? `url(#img${color3})` : color3
      const fill4 = displayBlackAndWhiteBool ? `url(#img${color4})` : color4

      if (displayBlackAndWhiteBool) {
        node_patterns.push(getImage(color1));
        node_patterns.push(getImage(color2));
        node_patterns.push(getImage(color3));
        node_patterns.push(getImage(color4));
      }

      if (is_abortion_node) {
        // bottom boxes
        const box_path1 = `M ${4},${19}L ${20},${19}L ${20},${18}L ${20},${12}L ${11},${12} Z`;
        const box_path2 = `M ${20},${12}L ${29},${12}L ${36},${19}L ${30},${19}L ${20},${19} Z`;

        // top box
        const box_path3 = `M ${19},${4}L ${11},${12}L ${20},${12}L ${20},${12}L ${20},${3} Z`;
        const box_path4 = `M ${20},${4}L ${20},${12}L ${20},${12}L ${29},${12}L ${20},${3} Z`;

        node_disease_colors.push((<path d={box_path1} stroke="none" fill={fill1} />));
        node_disease_colors.push((<path d={box_path2} stroke="none" fill={fill2} />));
        node_disease_colors.push((<path d={box_path3} stroke="none" fill={fill3} />));
        node_disease_colors.push((<path d={box_path4} stroke="none" fill={fill4} />));
      } else if (gender === 'male') {
        // top boxes
        const box_path1 = `M ${3},${3}L ${3},${20}L ${20},${20}L ${20},${3}L ${3},${3} Z`;
        const box_path2 = `M ${20},${3}L ${20},${20}L ${37},${20}L ${37},${3}L ${20},${3} Z`;

        // bottom boxes
        const box_path3 = `M ${3},${20}L ${3},${37}L ${20},${37}L ${20},${20}L ${3},${20} Z`;
        const box_path4 = `M ${20},${20}L ${20},${37}L ${37},${37}L ${37},${20}L ${20},${20} Z`;

        node_disease_colors.push((<path d={box_path1} stroke={fill1} fill={fill1} />));
        node_disease_colors.push((<path d={box_path2} stroke={fill2} fill={fill2} />));
        node_disease_colors.push((<path d={box_path3} stroke={fill3} fill={fill3} />));
        node_disease_colors.push((<path d={box_path4} stroke={fill4} fill={fill4} />));
      } else if (gender === 'female') {
        node_disease_colors.push((<path d="M3,20 a17,17 0 0,1 17,-17v 0,17h -17,0" stroke={fill1} fill={fill1} />));
        node_disease_colors.push((<path d="M20,37 a17,17 0 0,1 -17,-17h 17,0v 0,16" stroke={fill2} fill={fill2} />));
        node_disease_colors.push((<path d="M37,20 a17,17 0 0,1 -17,17v 0,-17h 17,0" stroke={fill3} fill={fill3} />));
        node_disease_colors.push((<path d="M20,3 a17,17 0 0,1 17,16h -17,0v 0,-16" stroke={fill4} fill={fill4} />));
      } else {
        // gender unknown

        // top boxes
        const box_path1 = `M ${20},${4}L ${12},${12}L ${20},${20}L ${28},${12}L ${20},${4} Z`;
        const box_path2 = `M ${28},${12}L ${20},${20}L ${28},${28}L ${36},${20}L ${28},${12} Z`;

        // bottom boxes
        const box_path3 = `M ${12},${12}L ${4},${20}L ${12},${28}L ${20},${20}L ${12},${12} Z`;
        const box_path4 = `M ${20},${20}L ${12},${28}L ${20},${36}L ${28},${28}L ${20},${20} Z`;

        node_disease_colors.push((<path d={box_path1} stroke={fill1} fill={fill1} />));
        node_disease_colors.push((<path d={box_path2} stroke={fill2} fill={fill2} />));
        node_disease_colors.push((<path d={box_path3} stroke={fill3} fill={fill3} />));
        node_disease_colors.push((<path d={box_path4} stroke={fill4} fill={fill4} />));
      }
    }
  }

  const person_node = (
    <svg style={PedigreeStylesheet.personNode}>
      {node_svg}
      {node_disease_colors.length > 0 && (
        <g>
          <defs>
            {node_patterns}
          </defs>
          {node_disease_colors}
        </g>
      )}
      {pregnancy_text}
      {donor_text_svg}
      {carrier_circle}
    </svg>
  );

  // if there are multiple people selected or node is being dragged
  // then do not show the selected JSX block
  // console.log('data ', data.id);
  let clickcount = data.getPedigreeDrawingData().getClickCount(data.id);
  // console.log('clickcounter X', clickcount + ' ' + data.id);
  // if (clickcount > 0) {
  //   console.log('clickcounter ', clickcount + ' ' + data.id);
  // }

  let node_build = (
    <>
      {adoptedPathLeft}
      {adoptedPathRight}
      {deceased_line}
      {assigned_birth}
      {person_node}
      {arrow}
      <div id={`domSubtext${data.profile.id}`} style={PedigreeStylesheet.subtextNode}>
        {((has_existing_proband_record || manually_linked_proband) && !data.profile.is_proband && !render_patient_pedigree && !data.readOnlyUser) && <span id='domViewRecord'><a onClick={(event) => data.navigateToRecord(event, has_existing_proband_record ? has_existing_proband_record.id : manually_linked_proband.id)} style={{ display: 'block', color: '#65D1B9', fontWeight: 600 }}>View Record</a></span>}
        <div id='domText'>
          {subtext ? subtext : ''}
        </div>
      </div>

      {selected && selected_count <= 1 && !data.isNodeDragging && clickcount > 1 && (
        <>
          {!core7Member && (
            <DeleteDOM
              hasSubtext={(subtext) ? true : false}
              onDeleteCheck={data.onDeleteCheck}
              onDeleteOpen={data.onDeleteOpen}
            />
          )}

          <OneClickAdd
            hasSubtext={(subtext) ? true : false}
            gender={data.gender}
            profile={data.profile}
            data={data}
            onAddParents={data.onAddParents}
            onAddChildren={data.onAddChildren}
            onCreatePartnerWtihChildren={data.onCreatePartnerWtihChildren}
            onAddPartner={data.onAddPartner}
            onAddSiblings={data.onAddSiblings}
            proband_id={data.proband_id}
            proband_mother_id={data.proband_mother_id}
            proband_father_id={data.proband_father_id}
            proband_paternal_grandfather_id={data.proband_paternal_grandfather_id}
            proband_paternal_grandmother_id={data.proband_paternal_grandmother_id}
            proband_maternal_grandfather_id={data.proband_maternal_grandfather_id}
            proband_maternal_grandmother_id={data.proband_maternal_grandmother_id}
          />
        </>
      )}
    </>
  );

  let subTextNodeWidth = $(`#domSubtext${data.profile.id}`).width()
  let subtextElement = document.getElementById(`domSubtext${data.profile.id}`)
  if (subtextElement) {
    // adjustment here for subtext width
    let left = (140 - subTextNodeWidth) * (25 / 50)
    subtextElement.style.left = `${left}px`
  }

  // this is still in use even when switching to no_children and infertile flags,
  // fake nodes have this flag so they'll rendered correctly
  if (is_no_children_node) {
    node_build = (
      <>
        {selected && selected_count <= 1 && !data.isNodeDragging && (
          <>
            {!core7Member && (
              <DeleteDOM
                hasSubtext={(subtext) ? true : false}
                onDeleteCheck={data.onDeleteCheck}
                onDeleteOpen={data.onDeleteOpen}
              />
            )}
          </>
        )}
        <svg style={PedigreeStylesheet.personNode}>
          <line x1={0} y1={15} x2={40} y2={15} style={PedigreeStylesheet.colorScheme} strokeWidth={3} />
        </svg>
      </>
    );
  }

  // this is still in use even when switching to no_children and infertile flags,
  // fake nodes have this flag so they'll rendered correctly
  if (is_infertility_node) {
    node_build = (
      <>
        {selected && !core7Member && selected_count <= 1 && !data.isNodeDragging && (
          <>
            {!core7Member && (
              <DeleteDOM
                hasSubtext={(subtext) ? true : false}
                onDeleteCheck={data.onDeleteCheck}
                onDeleteOpen={data.onDeleteOpen}
              />
            )}
          </>
        )}
        <svg style={PedigreeStylesheet.personNode}>
          <line x1={0} y1={15} x2={40} y2={15} style={PedigreeStylesheet.colorScheme} strokeWidth={3} />
          <line x1={0} y1={22} x2={40} y2={22} style={PedigreeStylesheet.colorScheme} strokeWidth={3} />
        </svg>
      </>
    );
  }

  //for abortion node
  if (is_abortion_node) {
    const s = Object.assign({}, PedigreeStylesheet.personNodeUnknown, proband, selectedStyle);

    const d = `M ${20},${1}L ${1},${20}L ${20},${20}L ${39},${20}L ${20},${1} Z`;
    let abortion_node_svg = (<path d={d} style={s} />);

    node_build = (
      <>
        <svg style={PedigreeStylesheet.personNode}>
          {abortion_node_svg}
          {node_disease_colors.length > 0 && (
            <g>
              <defs>
                {node_patterns}
              </defs>
              {node_disease_colors}
            </g>
          )}
        </svg>
        {deceased_line}
        <div id={`domSubtext${data.profile.id}`} style={PedigreeStylesheet.subtextNode}>
          {((has_existing_proband_record || manually_linked_proband) && !data.profile.is_proband && !render_patient_pedigree && !data.readOnlyUser) && <span id='domViewRecord'><a onClick={(event) => data.navigateToRecord(event, has_existing_proband_record ? has_existing_proband_record.id : manually_linked_proband.id)} style={{ display: 'block', color: '#65D1B9', fontWeight: 600 }}>View Record</a></span>}
          <div id='domText'>
            {subtext ? subtext : ''}
          </div>
        </div>
        {selected && selected_count <= 1 && !data.isNodeDragging && clickcount > 1 && (
          <>
            {!core7Member && (
              <DeleteDOM
                hasSubtext={(subtext) ? true : false}
                onDeleteCheck={data.onDeleteCheck}
                onDeleteOpen={data.onDeleteOpen}
              />
            )}

            <OneClickAdd
              hasSubtext={(subtext) ? true : false}
              gender={data.gender}
              profile={data.profile}
              data={data}
              onAddParents={data.onAddParents}
              onAddChildren={data.onAddChildren}
              onCreatePartnerWtihChildren={data.onCreatePartnerWtihChildren}
              onAddPartner={data.onAddPartner}
              onAddSiblings={data.onAddSiblings}
              proband_id={data.proband_id}
              proband_mother_id={data.proband_mother_id}
              proband_father_id={data.proband_father_id}
              proband_paternal_grandfather_id={data.proband_paternal_grandfather_id}
              proband_paternal_grandmother_id={data.proband_paternal_grandmother_id}
              proband_maternal_grandfather_id={data.proband_maternal_grandfather_id}
              proband_maternal_grandmother_id={data.proband_maternal_grandmother_id}
            />
          </>
        )}
      </>
    );

    let subTextNodeWidth = $(`#domSubtext${data.profile.id}`).width()
    let subtextElement = document.getElementById(`domSubtext${data.profile.id}`)
    if (subtextElement) {
      // adjustment here for subtext width
      let left = (140 - subTextNodeWidth) * (25 / 50)
      subtextElement.style.left = `${left}px`
    }

  }

  // console.log(targetPosition)
  // console.log(sourcePosition)
  let consanguineous_source = null;
  let is_same_sex_as_consanguineous_source = false;
  let is_current_partner_of_consanguineous_source = false;
  if (data.getPedigreeData().getConsanguineousConnectionActive()) {
    consanguineous_source = Object.values(data.getPedigreeData().getAllProfiles());
    consanguineous_source = consanguineous_source.find(node => node.is_consanguineous_source);
    is_same_sex_as_consanguineous_source = String(consanguineous_source.gender).toLowerCase() == String(data.profile.gender).toLowerCase();

    if (String(data.profile.gender).toLowerCase() == 'null' || String(data.profile.gender).toLowerCase() == 'u') {
      if (data.profile.id + '' == consanguineous_source.id + '') {
        is_same_sex_as_consanguineous_source = true;
      }
      else {
        is_same_sex_as_consanguineous_source = false;
      }
    }
    let partner_search = consanguineous_source.relationship_ids.find(relationship => relationship.father_id == data.profile.id || relationship.mother_id == data.profile.id);
    if (partner_search) is_current_partner_of_consanguineous_source = true;
  }

  let is_consanguineous_source = selected && selected_count <= 1 && !data.isNodeDragging && clickcount > 1 && !is_no_children_node && !is_infertility_node && !is_donor;
  let is_possible_consanguineous_target = data.getPedigreeData().getConsanguineousConnectionActive() && !is_same_sex_as_consanguineous_source && !is_current_partner_of_consanguineous_source & !is_no_children_node && !is_infertility_node && !is_donor;

  let is_reassign_parents_source = selected && selected_count <= 1 && !data.isNodeDragging && clickcount > 1 && !data.is_proband && data.profile.id != data.getPedigreeData().getProband().father.id && data.profile.id != data.getPedigreeData().getProband().mother.id && !is_no_children_node && !is_infertility_node && !is_donor;

  return (
    <>
      {node_build}
      <Handle id='consanguinity-target' type="target" style={{ position: 'absolute', left: 37, top: 18, visibility: is_possible_consanguineous_target ? 'visible' : 'hidden' }} position={Position.Right} isConnectable={is_possible_consanguineous_target} />
      <span className="pedigree-handle">
        <Handle id='consanguinity-source' type="source" style={{ position: 'absolute', left: -3, top: 18, cursor: 'pointer', visibility: is_consanguineous_source ? 'visible' : 'hidden' }} position={Position.Left} isConnectable={is_consanguineous_source}>
          <span
            className="i-tooltiptext-pedigree"
          >
            Click the node and drag to the partner
          </span>
        </Handle>
      </span>

      {/* re-assign parents source connector node */}
      <span className="pedigree-handle">
        <Handle id='re-assign-parents-source' type="source" style={{ position: 'absolute', left: 17, top: 0, cursor: 'pointer', visibility: is_reassign_parents_source ? 'visible' : 'hidden' }} position={Position.Right} isConnectable={is_reassign_parents_source}>
          <span
            className="i-tooltiptext-pedigree"
          >
            Click the node and drag to the new parents
          </span>
        </Handle>
      </span>
      {/* <Handle type="target" position={Position.Top} isConnectable={true}/>
      <Handle type="source" position={Position.Bottom} isConnectable={true}/> */}
    </>
  );
});
