import React, { Component } from 'react';

import skip_logic_map from '../assets/json/diseases-skip-logic-map.json';
const keys_mapping = skip_logic_map.colorectal

class SkipLogicColerectalView extends Component {

  msiText() {
    let sl = this.props.skipLogics;
    if(sl.msi == null) return ''
    if(sl.msi.value=='') return ''
    if(sl.msi.value=='1') return 'MSI-' + sl.msi.label + ','

    return sl.msi.label + ','
  }

  getValue(key) {
    if(!(key in keys_mapping)) return null

    let code = keys_mapping[key]
    let data = this.props.skipLogics.find(item => item.skip_logic_code == code)

    if(typeof(data) === 'undefined') return null
    return data.answer
  }

  render() {

    let sl = this.props.skipLogics;
    if(sl == null) return null

    let more_primary = this.getValue('more_primary')
    let tumor_location = this.getValue('tumor_location')
    let mlh1_ihc = this.getValue('mlh1_ihc')
    let msi = this.getValue('msi')
    let msh1_ihc = this.getValue('msh1_ihc')
    let msh2_ihc = this.getValue('msh2_ihc')
    let msh6_ihc = this.getValue('msh6_ihc')
    let pms2_ihc = this.getValue('pms2_ihc')
    let mlh1_hypermethylation = this.getValue('mlh1_hypermethylation')
    let braf_v600e = this.getValue('braf_v600e')

    return (
      <React.Fragment >
        <p>
          {more_primary && more_primary == 'y' && 'More than one primary, '}
          {tumor_location}
        </p>
        <p>
          {msi &&
          msi + ','
          }

          {mlh1_ihc &&
            'MLH1 IHC-' +  mlh1_ihc + ','
          }

          {msh2_ihc &&
            'MSH2 IHC-' +  msh2_ihc + ','
          }
        </p>
        <p>

          {msh6_ihc &&
            'MSH6 IHC-' +  msh6_ihc + ','
          }

          {pms2_ihc &&
            'PMS2 IHC-' +  pms2_ihc + ','
          }

        </p>

        <p>

          {mlh1_hypermethylation &&
            'MLH1 Hypermethylation-' +  mlh1_hypermethylation + ','
          }

          {braf_v600e &&
            'BRAF v600E-' +  braf_v600e + ','
          }

        </p>
      </React.Fragment>
    )
  }

}

export default SkipLogicColerectalView