import * as search_actions from '../store/search/actions';

// USE IN DEVELOPMENT TO REPLICATE db PRIMARY KEY
import { createUUID } from '../components/react-flow-pedigree/utils';


const updateSearchText = (dispatch, search_text) => {
  let payload = { search_text: search_text };
  dispatch(search_actions.update_search_text(payload));
};

const saveSearchFilter = (dispatch, search_filter) => {
  let payload = Object.assign({}, search_filter, {id: createUUID()});
  dispatch(search_actions.save_search_filter(payload));
};

const updateSearchResults = (dispatch, search_results) => {
  dispatch(search_actions.update_search_results(search_results));
};

const updateSearchHistory = (dispatch, search_history) => {
  dispatch(search_actions.update_search_history(search_history));
};

const updateArchivedDataSearchText = (dispatch, search_text) => {
  dispatch(search_actions.update_archived_data_search_text(search_text))
}


export {
  updateSearchText,
  saveSearchFilter,
  updateSearchResults,
  updateSearchHistory,
  updateArchivedDataSearchText
}
