import React, { Component } from 'react';
import { connect } from "react-redux";
import PatientDiseaseView from '../../patient-disease-view';
import PatientGeneTestView from '../../patient-gene-test-view';
import risk_api from '../../../api/risk-api';
import { save_risk_criteria as patient_action_save_risk_criteria } from '../../../store/patient/actions';
import helpers from "../../../helpers/index";
import * as helper_family_tree from '../../../helpers/helper-family-tree';

class PatientHealthHistory extends Component {

  constructor(props) {
    super(props);

    const render_patient_pedigree = sessionStorage.getItem('render-patient-pedigree');
    this.render_patient_pedigree = render_patient_pedigree == 'true';
  }

  getProfile() {
    let patient = this.props.getPedigreeData !== null && this.props.getPedigreeData !== undefined ? this.props.getPedigreeData() : this.props.patient
    let patientRkey = this.props.getPedigreeData !== null && this.props.getPedigreeData !== undefined ? this.props.getPedigreeData().proband.rkey : this.props.patientRkey

    return helper_family_tree.getProfileFromRedux(patient, patientRkey)
  }

  async handleDiseaseUpdate() {
    // refresh the risk referral criteria for proband
    if(this.render_patient_pedigree) return
    let dispatcher = this.props.dispatch;
    risk_api.post_check_risk(this.props.patient.patient.id).then(risk_criteria_result => {
      dispatcher(patient_action_save_risk_criteria(risk_criteria_result.result));
    }).catch(error => helpers.logger(error));
  }

  async handleGeneTestUpdate() {
    // refresh the risk referral criteria for proband
    if(this.render_patient_pedigree) return
    let dispatcher = this.props.dispatch;
    risk_api.post_check_risk(this.props.patient.patient.id).then(risk_criteria_result => {
      dispatcher(patient_action_save_risk_criteria(risk_criteria_result.result));
    }).catch(error => helpers.logger(error));
  }

  render() {
    let patientRkey = this.props.getPedigreeData !== null && this.props.getPedigreeData !== undefined ? this.props.getPedigreeData().proband.rkey : this.props.patientRkey
    return (
      <React.Fragment>
        <div className="gene-disease-container">

          <PatientDiseaseView
            patientRkey={patientRkey}
            profile={this.getProfile()}
            onDiseaseUpdate={() => this.handleDiseaseUpdate()}
            getPedigreeData={this.props.getPedigreeData}
          />

          <PatientGeneTestView
            patientRkey={patientRkey}
            profile={this.getProfile()}
            onGeneTestUpdate={() => this.handleGeneTestUpdate()}
            getPedigreeData={this.props.getPedigreeData}
          />
        </div>
      </React.Fragment>
    );
  }
}

const redux_state = state => ({
  patientRkey: state.patient.patient.rkey,
  patient: state.patient
});

export default connect(redux_state, null)(PatientHealthHistory);
