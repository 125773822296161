import React, { Component } from 'react';

class NccnCriteriaText extends Component {
    constructor(props) {
        super(props);

        this.state = {};

    }

    render() {
        return (
            <React.Fragment>
                {this.props.riskCriteriaLength > 0 ? (
                    <span className="i-tooltiptext-right-nccn">
                        <p><b>Meets one or more criteria from NCCN Guidelines®<sup>1,2 </sup> or ACMG/NSGC Practice Guidelines<sup>3</sup></b></p>
                        <ol type="1">
                            <li>
                                Referenced with permission from the NCCN Clinical Practice Guidelines in Oncology (NCCN Guidelines®)
                                for Genetic/Familial: High Risk Assessment: Breast, Ovarian, and Pancreatic V.3.2024. © National Comprehensive Cancer Network, Inc. 2024.
                                All rights reserved.  Accessed April 26, 2024.  To view the most recent and complete version of the guideline, go online to <a href="https://www.nccn.org/"> NCCN.org </a>.
                            </li>
                            <li>
                                Referenced with permission from the NCCN Clinical Practice Guidelines in Oncology (NCCN Guidelines®)
                                for Genetic/Familial: High Risk Assessment: Colorectal V.1.2023. © National Comprehensive Cancer Network, Inc. 2023.  All rights reserved.  Accessed October 10, 2023.
                                To view the most recent and complete version of the guideline, go online to <a rel="noopener noreferrer" target="_blank" href="https://www.nccn.org/"> NCCN.org </a>
                                Disclaimer: The NCCN Guidelines® and other content provided by NCCN are works in progress that may be refined as often as new significant data becomes available.
                                They are statements of consensus of its authors regarding their views of currently accepted approaches to treatment.
                                Any clinician seeking to apply or consult any NCCN Guidelines® or other NCCN Content is expected to use independent medical judgment in the context of individual clinical circumstances to determine any patient’s care or treatment.
                                The National Comprehensive Cancer Network makes no warranties of any kind whatsoever regarding their content, use or application and disclaims any responsibility for their application or use in any way.
                            </li>
                            <li>
                                Hampel H, Bennett RL, Buchanan A, Pearlman R, Wiesner GL; Guideline Development Group, American College of Medical Genetics and Genomics Professional Practice and Guidelines Committee and National Society of Genetic Counselors Practice Guidelines Committee.
                                A practice guideline from the American College of Medical Genetics and Genomics and the National Society of Genetic Counselors: referral indications for cancer predisposition assessment. Genet Med. 2015 Jan;17(1):70-87. doi: 10.1038/gim.2014.147. Epub 2014 Nov 13. PMID: 25394175.
                            </li>
                        </ol>
                    </span>
                    )
                    :
                    (
                        <span className="i-tooltiptext-right-nccn">
                            <p><b>Assessed Criteria Includes NCCN Guidelines®<sup>1,2</sup> or ACMG/NSGC Practice Guidelines <sup>3</sup></b></p>
                            <ol type="1">
                                <li>
                                    Referenced with permission from the NCCN Clinical Practice Guidelines in Oncology (NCCN Guidelines®)
                                    for Genetic/Familial: High Risk Assessment: Breast, Ovarian, and Pancreatic V.3.2024. © National Comprehensive Cancer Network, Inc. 2024.
                                    All rights reserved.  Accessed April 26, 2024.  To view the most recent and complete version of the guideline, go online to <a href="https://www.nccn.org/"> NCCN.org </a>.
                                </li>
                                <li>
                                    Referenced with permission from the NCCN Clinical Practice Guidelines in Oncology (NCCN Guidelines®)
                                    for Genetic/Familial: High Risk Assessment: Colorectal V.1.2023. © National Comprehensive Cancer Network, Inc. 2023.  All rights reserved.  Accessed October 10, 2023.
                                    To view the most recent and complete version of the guideline, go online to <a rel="noopener noreferrer" target="_blank" href="https://www.nccn.org/"> NCCN.org </a>
                                    Disclaimer: The NCCN Guidelines® and other content provided by NCCN are works in progress that may be refined as often as new significant data becomes available.
                                    They are statements of consensus of its authors regarding their views of currently accepted approaches to treatment.
                                    Any clinician seeking to apply or consult any NCCN Guidelines® or other NCCN Content is expected to use independent medical judgment in the context of individual clinical circumstances to determine any patient’s care or treatment.
                                    The National Comprehensive Cancer Network makes no warranties of any kind whatsoever regarding their content, use or application and disclaims any responsibility for their application or use in any way.
                                </li>
                                <li>
                                    Hampel H, Bennett RL, Buchanan A, Pearlman R, Wiesner GL; Guideline Development Group, American College of Medical Genetics and Genomics Professional Practice and Guidelines Committee and National Society of Genetic Counselors Practice Guidelines Committee.
                                    A practice guideline from the American College of Medical Genetics and Genomics and the National Society of Genetic Counselors: referral indications for cancer predisposition assessment. Genet Med. 2015 Jan;17(1):70-87. doi: 10.1038/gim.2014.147. Epub 2014 Nov 13. PMID: 25394175.
                                </li>
                            </ol>
                        </span>
                    )
                }
            </React.Fragment>
        );
    }
}

export default NccnCriteriaText;
