import React, { Component } from 'react';
import { connect } from "react-redux";
import qs from 'qs';
import route_helper from '../../route-helper';

import ActivityIndicator from "../activity-indicator";
import ErrorSummary from "../error-summary";
import api_auth from '../../api/authentication';

import * as action_auth from '../../store/session/actions';
import { save_risk_settings } from '../../store/patient/actions';
import Cookie from 'js-cookie'
import settings from "../../configs/settings";

import {
  save_login_data
} from '../../helpers/helper-login';

class SamlLogin extends Component {

    constructor(props) {
      super(props);
      this.state = {
        errorMessages: [],

      };

      this.authenticateSamlUser = this.authenticateSamlUser.bind(this);
      this.handleLoginFail = this.handleLoginFail.bind(this);
      this.handleLoginSuccess = this.handleLoginSuccess.bind(this);
    }
    
    async componentDidMount() {
      await this.authenticateSamlUser()
    }

    async componentWillUnmount() {
      this.mounted = false;
    }

    async authenticateSamlUser(){
        this.setState({loading: true})
        api_auth.clearTokens();

        try{
          let query = qs.parse(this.props.location.search, {
            ignoreQueryPrefix: true
          })
          let token = query['token'];
          let payload = await api_auth.saml_account_lookup(token);

          let fname = localStorage.getItem('fname')
          let lname = localStorage.getItem('lname')
          let dob = localStorage.getItem('dob')
          let finalMrn = localStorage.getItem('finalMrn')
          let is_emr_session = sessionStorage.getItem('is_emr_session')

          //placeholder
          // let emr_always_read_only = payload.organization.emr_always_read_only

          // let display_read_only = is_read_only || emr_always_read_only ? true : false
          save_login_data(this.props.session.user, payload, this.props.dispatch);
          this.props.dispatch(action_auth.authenticated(true));
          this.handleLoginSuccess(payload.organization.org_type, is_emr_session, fname, lname, dob, finalMrn);

        } catch (error) {
          this.handleLoginFail('Error logging in. Contact your administrator.')
        } finally {
          if(this.mounted) {
            this.setState({ loading: false });
          }
        }
    }

    async handleLoginSuccess(account_type, is_emr_session, firstName, lastName, dob, mrn) {
      if(is_emr_session){
          this.props.history.push(`/emr_patients?fname=${firstName}&lname=${lastName}&dob=${dob}&mrn=${mrn}`);
          localStorage.removeItem('fname')
          localStorage.removeItem('lname')
          localStorage.removeItem('dob')
          localStorage.removeItem('finalMrn')
      }
      else if(account_type === settings.app_constants.famhisOrg.value) {
        this.navigateToPage(route_helper.administrative.fh_dashboard);
      }else{
          this.navigateToPage(route_helper.query.query_root);
      }
    }
    handleLoginFail(error) {
      this.setState({errorMessages: [error]});
    }

    async navigateToPage(path) {
        const { from } = this.props.location.state || { from: { pathname: path } };
        this.props.history.replace(from);
      }

    render() {
      const copyright_year = new Date().getFullYear();
      return (
        <React.Fragment>
          <div className="login_wrapper">
            <div className="animate form login_form">
              <section className="login_content">
                <ErrorSummary errorMessages={this.state.errorMessages} />
                  <div className="logo">
                      {/* <img src={images.famhisLogo} className="img-responsive" alt="FamGenix Logo" /> */}
                  </div>
                  <div className="animate form login_form">
                    <div><p className="text-center text-muted margin-nine-top"><span>&copy; {copyright_year} by FamHis, Inc. All rights reserved.</span></p></div>
                  </div>
              </section>
            </div>
          </div>
        <ActivityIndicator loading={this.state.loading} />

        </React.Fragment>
      );
    }
  }
  const redux_state = state => ({
    session: state.session
  });

  const redux_actions = dispatch => ({
    dispatch: (action) => dispatch(action)
  });

  export default connect(redux_state, redux_actions)(SamlLogin);
