// RISK FACTOR CONSTANTS

const CM_IN_INCH = 2.54;
const CM_IN_FOOT = 30.48;
const CM_IN_METER = 100;
const LBS_IN_KG = 2.2046;

// FRONTEND NAMES
const WEIGHT_UNIT_SYSTEM = "weightUnitSystem";
const WEIGHT_POUNDS = "weightPounds";
const WEIGHT_KILOGRAMS = "weightKilograms";
const HEIGHT_UNIT_SYSTEM = "heightUnitSystem";
const HEIGHT_MAJOR_UNITS = "heightMajorUnits";
const HEIGHT_MINOR_UNITS = "heightMinorUnits";
const HEIGHT_FEET = "heightFeet";
const HEIGHT_INCHES = "heightInches";
const HEIGHT_METERS = "heightMeters";
const HEIGHT_CENTIMETERS = "heightCentimeters";
const AGE_FIRST_MENSTRUATION = "ageFirstMenstruation";
const AGE_FIRST_BIRTH = "ageFirstBirth";
const POST_MENOPAUSAL = "postMenopausal";
const AGE_AT_MENOPAUSE = "ageAtMenopause";
const TAKEN_HRT = "takenHRT";
const HOW_MANY_YEARS_ON_HRT = "howManyYearsOnHRT";
const TYPE_OF_HRT = "typeOfHRT";
const YEARS_SINCE_LAST_TAKEN_HRT = "yearsSinceLastTakenHRT";
const YEARS_OF_INTENDED_USE_OF_HRT = "yearsOfIntendedUseOfHRT";
const HAD_BREAST_BIOPSY = "hadBreastBiopsy";
const AMOUNT_OF_BREAST_BIOPSIES = "amountOfBreastBiopsies";
const BREAST_BIOPSY_BENIGN = "breastBiopsyBenign";
const BREAST_BIOPSY_HYPERPLASIA = "breastBiopsyHyperplasia";
const BREAST_BIOPSY_ATYPICAL_HYPERPLASIA = "breastBiopsyAtypicalHyperplasia";
const BREAST_BIOPSY_LCIS = "breastBiopsyLCIS";
const BREAST_BIOPSY_UNKNOWN = "breastBiopsyUnknown";
const HAD_HYSTERECTOMY = "hadHysterectomy";
const AGE_AT_HYSTERECTOMY = "ageAtHysterectomy";
const HAD_MASTECTOMY = "hadMastectomy";
const AGE_AT_MASTECTOMY = "ageAtMastectomy";
const TYPE_OF_MASTECTOMY = "typeOfMastectomy";
const HAD_OOPHORECTOMY = "hadOophorectomy";
const AGE_AT_OOPHORECTOMY = "ageAtOophorectomy";
const TYPE_OF_OOPHORECTOMY = "typeOfOophorectomy";
const HAD_SALPINGECTOMY = "hadSalpingectomy";
const AGE_AT_SALPINGECTOMY = "ageAtSalpingectomy";
const HAD_TUBAL_LIGATION = "hadTubalLigation";
const AGE_AT_TUBAL_LIGATION = "ageAtTubalLigation";



// CUSTOM SURVEY NAMES
const WEIGHT_UNIT = "weight_unit";
const WEIGHT_NUM = "weight_num";
const HEIGHT_UNIT = "height_unit";
const HEIGHT_FIRST_NUM = "height_first_num";
const HEIGHT_SECOND_NUM = "height_second_num";
const FIRST_MENS_AGE = "first_mens_age";
const FIRST_CHILD_AGE = "first_child_age";
const MENOPAUSE_CHOICE = "menopause_choice";
const MENOPAUSE_AGE = "menopause_age";
const HRT_CHOICE = "hrt_choice";
const HRT_LENGTH_USE = "hrt_length_use";
const HRT_TYPE = "hrt_type";
const HRT_LAST_TAKEN = "hrt_last_taken";
const HRT_INTENDED = "hrt_intended";
const BREAST_BIOPSY_CHOICE = "breast_biopsy_choice";
const BREAST_BIOPSY_NUM = "breast_biopsy_num";
const BREAST_BIOPSY_RESULT_CHOICE = "breast_biopsy_result_choice";
const BENIGN = "benign";
const HYPERPLASIA = "hyperplasia";
const ATYPICAL_HYPERPLASIA = "atypical_hyperplasia";
const LCIS = "lcis";
const UNKNOWN = "unknown";
const HYSTERECTOMY = "hysterectomy";
const HYSTERECTOMY_AGE = "hysterectomy_age";
const MASTECTOMY = "mastectomy";
const MASTECTOMY_AGE = "mastectomy_age";
const MASTECTOMY_BREAST_CHOICE = "mastectomy_breast_choice";
const OOPHORECTOMY = "oophorectomy";
const OOPHORECTOMY_AGE = "oophorectomy_age";
const OOPHORECTOMY_CHOICE = "oophorectomy_choice";
const SALPINGECTOMY = "salpingectomy";
const SALPINGECTOMY_AGE = "salpingectomy_age";
const TUBAL_LIGATION = "tubal_ligation";
const TUBAL_LIGATION_AGE = "tubal_ligation_age";
const ALCOHOL = "alcohol";
const DRINKS_PER_DAY = "drinks_per_day";
const OC_PILL = "oral_contraceptive_pill";
const OC_PILL_YEARS = "ocp_years";
const OC_PILL_LAST_TWO_YEARS = "ocp_last_2_years";
const BREAST_DENSITY_METHOD = "breast_density_method";
const BI_RADS_DENSITY = "bi_rads_density";
const PERCENTAGE_DENSITY = "percentage_density";

export default {
  CM_IN_INCH,
  CM_IN_FOOT,
  CM_IN_METER,
  LBS_IN_KG,
  WEIGHT_UNIT_SYSTEM,
  WEIGHT_POUNDS,
  WEIGHT_KILOGRAMS,
  HEIGHT_UNIT_SYSTEM,
  HEIGHT_MAJOR_UNITS,
  HEIGHT_MINOR_UNITS,
  HEIGHT_FEET,
  HEIGHT_INCHES,
  HEIGHT_METERS,
  HEIGHT_CENTIMETERS,
  AGE_FIRST_MENSTRUATION,
  AGE_FIRST_BIRTH,
  POST_MENOPAUSAL,
  AGE_AT_MENOPAUSE,
  TAKEN_HRT,
  HOW_MANY_YEARS_ON_HRT,
  TYPE_OF_HRT,
  YEARS_SINCE_LAST_TAKEN_HRT,
  YEARS_OF_INTENDED_USE_OF_HRT,
  HAD_BREAST_BIOPSY,
  AMOUNT_OF_BREAST_BIOPSIES,
  BREAST_BIOPSY_BENIGN,
  BREAST_BIOPSY_HYPERPLASIA,
  BREAST_BIOPSY_ATYPICAL_HYPERPLASIA,
  BREAST_BIOPSY_LCIS,
  BREAST_BIOPSY_UNKNOWN,
  HAD_HYSTERECTOMY,
  AGE_AT_HYSTERECTOMY,
  HAD_MASTECTOMY,
  AGE_AT_MASTECTOMY,
  TYPE_OF_MASTECTOMY,
  HAD_OOPHORECTOMY,
  AGE_AT_OOPHORECTOMY,
  TYPE_OF_OOPHORECTOMY,
  HAD_SALPINGECTOMY,
  AGE_AT_SALPINGECTOMY,
  HAD_TUBAL_LIGATION,
  AGE_AT_TUBAL_LIGATION,
  WEIGHT_UNIT,
  WEIGHT_NUM,
  HEIGHT_UNIT,
  HEIGHT_FIRST_NUM,
  HEIGHT_SECOND_NUM,
  FIRST_MENS_AGE,
  FIRST_CHILD_AGE,
  MENOPAUSE_CHOICE,
  MENOPAUSE_AGE,
  HRT_CHOICE,
  HRT_LENGTH_USE,
  HRT_TYPE,
  HRT_LAST_TAKEN,
  HRT_INTENDED,
  BREAST_BIOPSY_CHOICE,
  BREAST_BIOPSY_NUM,
  BREAST_BIOPSY_RESULT_CHOICE,
  BENIGN,
  HYPERPLASIA,
  ATYPICAL_HYPERPLASIA,
  LCIS,
  UNKNOWN,
  HYSTERECTOMY,
  HYSTERECTOMY_AGE,
  MASTECTOMY,
  MASTECTOMY_AGE,
  MASTECTOMY_BREAST_CHOICE,
  OOPHORECTOMY,
  OOPHORECTOMY_AGE,
  OOPHORECTOMY_CHOICE,
  SALPINGECTOMY,
  SALPINGECTOMY_AGE,
  TUBAL_LIGATION,
  TUBAL_LIGATION_AGE,
  ALCOHOL,
  DRINKS_PER_DAY,
  OC_PILL,
  OC_PILL_YEARS,
  OC_PILL_LAST_TWO_YEARS,
  BREAST_DENSITY_METHOD,
  BI_RADS_DENSITY,
  PERCENTAGE_DENSITY
}
