import React, { Component } from 'react';
import { connect } from "react-redux";

import * as model from '../model-states/m-family-tree';

import PartnerDetailsItem from './modal-partner-details-item';
import lodash from 'lodash';

import * as helper_family_tree from '../helpers/helper-family-tree';

import helpers from '../helpers';
import helper_family_api from '../helpers/helper-family-api';
import family_api from '../api/family-api'
import ErrorSummary from './error-summary';
import ActivityIndicator from '../components/activity-indicator'
import { radix_bases } from '../helpers/helper-number-bases';

class ModalPartnerDetails extends Component {

  constructor(props) {
    super(props)
    this.detailItemRef = React.createRef();
    this.state = {
      partners: [],
      errorMessages: [],
      loading: false
    };
  }

  async componentDidMount() {
    await this.loadPartnersFromRedux();
  }

  async loadPartnersFromRedux() {
    if (!(this.props.ownerRkey in this.props.patient.partners)) {
      this.addPartner();
      return;
    }

    // cloneDeep so we don't directly modify the store
    let partners = lodash.cloneDeep(this.props.patient.partners[this.props.ownerRkey]);
    if(!lodash.isEqual(this.state.partners, partners)) {
      let self = this;
      partners = partners.map(item => {
        let pregnancy = self.getPregnancy(item.rkey);
        item.relationship_data.pregnancy = pregnancy;
        return item;
      })
      await this.setState({partners});
    }

    if(partners.length === 0) {
      this.addPartner();
    }
  }

  getPregnancy(partnerRkey) {
    let fetus = this.getFetusChild(partnerRkey)
    if(fetus == null) return null
    switch(fetus.gender) {
      case 'm':
        return 'm';
      case 'f':
        return 'f';
      default:
        return 'u'
    }
  }

  onChange(field, value) {
    this.setState({[field]: value});
  }

  onClickAddPartner() {
    this.addPartner();
  }

  addPartner() {
    let partner = model.createPartner();
    let partners = this.state.partners;
    partners.push(partner);

    this.setState( {partners} );
  }

  getFetusGender(pregnancy) {
    let fetus_gender = null
    if(pregnancy != null) {
      pregnancy = pregnancy.toLowerCase()
      switch(pregnancy) {
        case 'm':
          fetus_gender = 'm';
          break;
        case 'f':
          fetus_gender = 'f'
          break;
        default:
          fetus_gender = 'u'
      }
    }
    return fetus_gender;
  }

  getFetusChild(partnerRkey) {
    let sons_daughters = []
    if ((partnerRkey in this.props.patient.sons_daughters)) {
      sons_daughters = this.props.patient.sons_daughters[partnerRkey];
    }
    let fetus = sons_daughters.filter(item => item.pregnancy == true)
    return fetus.length == 0 ? null : fetus[0]
  }

  async saveFetusAsChildren(spouse, partner) {
    let pregnancy = partner.relationship_data.pregnancy
    let partnerRkey = partner.rkey

    // Determine fetus gender base on current user selection
    let fetus_gender = this.getFetusGender(pregnancy)

    // Retrieve fetus child from the list of children
    let fetus_child = this.getFetusChild(partnerRkey)

    let payload = {}
    let child_data = null;
    if(fetus_child == null) {
      // Create
      payload = model.createSonDaughter(fetus_gender);
      payload.pregnancy = true
      payload.gender = fetus_gender == 'u' ? null : fetus_gender
      child_data = await helper_family_api.create_member_as_child(spouse, partner, payload, this.props.parent_side)
    } else {
      // Update
      payload = fetus_child
      payload.gender = fetus_gender == 'u' ? null : fetus_gender
      child_data = await family_api.patch_member_memberid(payload.id, payload)
      child_data = Object.assign({}, payload, child_data)
    }

    // Save to Redux
    helper_family_tree.saveProfileToRedux(this.props.dispatch, child_data)
    helper_family_tree.saveSonDaughterDetailToRedux(this.props.dispatch, partnerRkey, child_data)

  }

  async deleteFetusChild(partner) {
    let partnerRkey = partner.rkey

    // Retrieve fetus child from the list of children
    let fetus_child = this.getFetusChild(partnerRkey)

    if(fetus_child == null) return

    await family_api.delete_member_memberid(fetus_child.id)

    helper_family_tree.deleteChildren(this.props.patient, this.props.dispatch, partnerRkey, fetus_child.rkey)
  }

  async saveChildren(gender, children_total, spouse, partner, pregnancy) {

    // Disable deletion
    // let children = helper_family_tree.getSonsDaughtersFromRedux(this.props.patient, partnerRkey, gender)
    // var count = children_total - children.length

    // is_no_children_node or is_infertility_node filters should still work
    // since it's still present on the fake child nodes
    let sons_daughters = this.props.patient.sons_daughters[partner.rkey];
    let children = [];
    let checkForNoChildrenInfertility = [];
    if(gender === 'M') {
      children = (sons_daughters === null || sons_daughters === undefined) ? [] : sons_daughters.filter(item => item.gender === 'm' && !item.is_no_children_node && !item.is_infertility_node);
      checkForNoChildrenInfertility = (sons_daughters === null || sons_daughters === undefined) ? [] : sons_daughters.filter(item => item.is_no_children_node || item.is_infertility_node)
    } else if (gender === 'F') {
      children = (sons_daughters === null || sons_daughters === undefined) ? [] : sons_daughters.filter(item => item.gender === 'f' && !item.is_no_children_node && !item.is_infertility_node);
      checkForNoChildrenInfertility = (sons_daughters === null || sons_daughters === undefined) ? [] : sons_daughters.filter(item => item.is_no_children_node || item.is_infertility_node)
    } else if (gender === 'U') {
      children = (sons_daughters === null || sons_daughters === undefined) ? [] : sons_daughters.filter(item => item.gender === 'u' && !item.is_no_children_node && !item.is_infertility_node);
      checkForNoChildrenInfertility = (sons_daughters === null || sons_daughters === undefined) ? [] : sons_daughters.filter(item => item.is_no_children_node || item.is_infertility_node)
    }

    let partnerRkey = partner.rkey;
    let saved_children = children.length;

    //check for no children and infertility nodes when adding pregnancy
    if (checkForNoChildrenInfertility.length > 0 && pregnancy != null) {
      throw new Error('Pregnancy cannot be added if no children by choice or infertility is indicated on pedigree.')
    }

    if(children_total == saved_children){
      this.setState({errorMessages: []})
      return;
    }

    // if(gender === 'U') {
    //   gender = null
    // }


    if (children_total > 0 && children_total > saved_children) {
      //check for no children and infertility nodes when adding children
      if (checkForNoChildrenInfertility.length > 0) {
        throw new Error('Children cannot be added if no children by choice or infertility is indicated on pedigree.')
      }
      for(let i=saved_children; i<children_total; i++) {
        var child = model.createSonDaughter(gender)
        // Save to API
        let child_data = await helper_family_api.create_member_as_child(spouse, partner, child, this.props.parent_side)
        // Save to Redux
        helper_family_tree.saveProfileToRedux(this.props.dispatch, child_data)
        helper_family_tree.saveSonDaughterDetailToRedux(this.props.dispatch, partnerRkey, child_data)
      }
    } else {
      throw new Error('Error: Removing children through this menu is prohibited. To remove a child, click the trash icon under \'Remove\'.');
    }

    /*
    else {
      // Disable deletion here to prevent unintentional tree deletion
      var remove_count = Math.abs(count) // count is negative
      for(remove_count; remove_count > 0; remove_count--){
        var child = children.pop()

        // delete partners of the child
        helper_family_tree.deleteAllPartners(this.props.patient, this.props.dispatch, child.rkey)

        helper_family_tree.deleteProfileFromRedux(this.props.dispatch, child.rkey)

        helper_family_tree.deleteSonDaughterFromRedux(this.props.dispatch, partnerRkey, child.rkey)
      }
    }
    */


  }

  async onClickSave() {
    // console.log('spouse', this.props.owner)
    // return;

    try {
      let spouse = this.props.owner
      let all_sons_daughters = this.props.patient.sons_daughters
      this.setState({loading: true, errorMessages: []});
      for(let partner of this.state.partners) {
        let sons_count = partner.sons_count;
        let daughters_count = partner.daughters_count;
        let unknown_count = partner.unknown_count;

        // is_no_children_node or is_infertility_node filters should still work
        // since it's still present on the fake child nodes

        if(all_sons_daughters[partner.rkey]) {
          let sons_daughters = all_sons_daughters[partner.rkey];
          let sons = (sons_daughters === null || sons_daughters === undefined) ? [] : sons_daughters.filter(item => item.gender === 'm' && !item.is_no_children_node && !item.is_infertility_node);
          let daughters = (sons_daughters === null || sons_daughters === undefined) ? [] : sons_daughters.filter(item => item.gender === 'f' && !item.is_no_children_node && !item.is_infertility_node);
          let unknown = (sons_daughters === null || sons_daughters === undefined) ? [] : sons_daughters.filter(item => item.gender === 'u' && !item.is_no_children_node && !item.is_infertility_node);
          sons_count = ( sons_count ) ? sons_count : sons.length;
          daughters_count = ( daughters_count ) ? daughters_count : daughters.length;
          unknown_count = ( unknown_count ) ? unknown_count : unknown.length;
        }

        // Save to API
        let partner_data = null
        if(partner.id == null) {
          // Create
          partner_data = await helper_family_api.create_partner(spouse, partner, this.props.parent_side)
        } else {
          // Update
          partner_data = await helper_family_api.update_partner(partner)
        }

        // Save to Redux
        partner_data.sons_count = 0
        partner_data.daughters_count = 0
        partner_data.unknown_count = 0

        // if the owner is the proband also save to his partners[rkey] profile
        if (this.props.ownerRkey === 'proband') helper_family_tree.savePartnerToRedux(this.props.dispatch, this.props.owner.rkey, partner_data);
        helper_family_tree.savePartnerToRedux(this.props.dispatch, this.props.ownerRkey, partner_data)
        helper_family_tree.saveProfileToRedux(this.props.dispatch, partner_data)


        let pregnancy = partner_data.relationship_data.pregnancy

        // Save Children
        await this.saveChildren('M', sons_count, spouse, partner_data, pregnancy)
        await this.saveChildren('F', daughters_count, spouse, partner_data, pregnancy)
        await this.saveChildren('U', unknown_count, spouse, partner_data, pregnancy)

        // Save Fetus
        if(pregnancy == null) {
          await this.deleteFetusChild(partner_data)
        } else {
          await this.saveFetusAsChildren(spouse, partner_data)
        }
      }

      this.props.onClickSave(null)

    } catch (error) {

      helpers.logger(error);
      this.setState({errorMessages: [error.message]});

    } finally {

      this.setState({loading: false});

    }

  }

  // With delete behaviour
  // async onClickSave_bak() {

  //   try {
  //     let spouse = this.props.owner
  //     this.setState({loading: true, errorMessages: []});

  //     for(var partner of this.state.partners) {

  //       if(parseInt(partner.sons_count, radix_bases.base10) === 0 && parseInt(partner.daughters_count, radix_bases.base10) === 0) {

  //         helper_family_tree.deletePartner(this.props.patient, this.props.dispatch, this.props.ownerRkey, partner.rkey)

  //       } else {

  //         // Save to API
  //         var partner_data = await helper_family_api.create_partner(spouse, partner)

  //         // Save to Redux
  //         helper_family_tree.savePartnerToRedux(this.props.dispatch, this.props.ownerRkey, partner_data)
  //         helper_family_tree.saveProfileToRedux(this.props.dispatch, partner_data)

  //         await this.saveChildren('M', partner.sons_count, spouse, partner_data)
  //         await this.saveChildren('F', partner.daughters_count, spouse, partner_data)
  //       }
  //     }
  //     this.props.onClickSave(null)

  //   } catch (error) {
  //     helpers.logger(error);
  //     this.setState({errorMessages: [error.message]});
  //   } finally {
  //     this.setState({loading: false});
  //   }

  // }

  updatePartner(index, partner_detail) {
    let partners = this.state.partners;
    partners = this.state.partners.map((partner, i) => {
      if( i === index ){
        return partner_detail
      } else {
        return partner;
      }
    });
    this.setState({partners});
  }

  render() {
    let all_sons_daughters = this.props.patient.sons_daughters
    let partner_details = this.state.partners.map((partner, index) => {
      let sons_daughters = all_sons_daughters[partner.rkey];
      let sons = (sons_daughters === null || sons_daughters === undefined) ? [] : sons_daughters.filter(item => item.gender === 'm' && !item.is_no_children_node && !item.is_infertility_node);
      let daughters = (sons_daughters === null || sons_daughters === undefined) ? [] : sons_daughters.filter(item => item.gender === 'f' && !item.is_no_children_node && !item.is_infertility_node);
      let unknown = (sons_daughters === null || sons_daughters === undefined) ? [] : sons_daughters.filter(item => item.gender === 'u' && !item.is_no_children_node && !item.is_infertility_node);

      return (<PartnerDetailsItem
          key={partner.rkey}
          ref={this.detailItemRef}
          label={'Partner #' + (index + 1)}
          partner={partner}
          sons_count={sons.length}
          daughters_count={daughters.length}
          unknown_count={unknown.length}
          onChange={(partner_updates) => this.updatePartner(index, partner_updates)}
        />);
      }
    );

    return (
      // <div onClick={() => this.props.onClose()}
      <div
        style={{ display: ((this.props.isOpen) ? 'block' : 'none') }}
        className={"modal fade " + ((this.props.isOpen) ? 'in' : '')}
        role="dialog">

        <div onClick={(e) => e.stopPropagation()} className="modal-dialog-wide" role="document">
          <div className="modal-content">

            <div className="modal-header">
              <button
                onClick={() => this.props.onClose()}
                type="button" className="close" data-dismiss="modal" aria-label="Close">
                <i className="fa fa-close"></i>
              </button>
              <h4 className="modal-title text-white text-capitalize">Children Details</h4>
            </div>

            <div className="modal-body">

              <div className="partner-details-container">
                {partner_details}
              </div>

              <button
                onClick={() => this.onClickAddPartner() }
                className="btn btn-teal"><i className="fa fa-plus"> </i> Partner</button>

            </div>

            <div className="modal-footer">

              <ErrorSummary
                transparent={true}
                errorMessages={this.state.errorMessages}
              />

              <button
                onClick={() => this.props.onClose()}
                className="btn btn-light-outline no-margin-right">Cancel</button>
              <button
                onClick={() => this.onClickSave()}
                className="btn btn-dark">Save</button>


            </div>

            <ActivityIndicator
              modal={true}
              loading={this.state.loading}
            />

          </div>
        </div>
      </div>
    );
  }
}

const redux_state = state => ({
  patient: state.patient
});

const redux_actions = dispatch => ({
  dispatch: (action) => dispatch(action)
});

export default connect(redux_state, redux_actions)(ModalPartnerDetails);
