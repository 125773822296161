import React, { Component } from 'react';
import { connect } from "react-redux";

import Select from 'react-select';

import settings from '../configs/settings';

import helper from '../helpers/index'
import * as helper_family_tree from '../helpers/helper-family-tree'
import * as helper_health_history from '../helpers/helper-health-history'
import helper_family_api from '../helpers/helper-family-api';
import * as model from '../model-states/m-family-tree'

import * as patient_actions from '../store/patient/actions';
import family_api from '../api/family-api'
import { cloneDeep } from 'lodash'
import ActivityIndicator from '../components/activity-indicator'
import ErrorSummary from '../components/error-summary';
import { radix_bases } from '../helpers/helper-number-bases';

class ModalHalfSiblings extends Component {

  constructor(props) {
    super(props);
    this.state = {
      sibling: null,
      proband_father: null,
      proband_mother: null,
      loading: false,
      errorMessages: []
    };
  }

  componentDidMount() {
    this.parseAndLoadSibling()
  }

  parseAndLoadSibling() {
    try {
      let {father, mother} = this.props.patient.patient;

      let sibling = cloneDeep(this.props.sibling)

      let half_sibling_shared_parent = null;
      let half_sibling_other_parent = null;

      // If half sibling, then set the shared parent, and other parent
      if ( sibling.father_id != father.id || sibling.mother_id != mother.id) {
        half_sibling_shared_parent = (sibling.father_id == father.id) ? 'father' : 'mother';
        var partners = this.getParentOtherPartners(half_sibling_shared_parent)
        if(partners.length > 0) {
          var other_parent_id = (half_sibling_shared_parent == 'father') ? sibling.mother_id : sibling.father_id
          var index = partners.findIndex(partner => partner.id == other_parent_id);
          half_sibling_other_parent = (typeof(index) !== 'undefined') ? index + 1 : null;
        }
      }

      sibling.half_sibling_shared_parent = half_sibling_shared_parent
      sibling.half_sibling_other_parent = half_sibling_other_parent

      this.setState({
        sibling: sibling,
        proband_father: father,
        proband_mother: mother
      });
    } catch (error) {
      console.log(error)
    }
  }

  getParentOtherPartners(half_sibling_shared_parent) {
    let partners = this.props.patient.partners
    var ownerRkey = 'proband_' + half_sibling_shared_parent; // proband_father or proband_mother
    if(ownerRkey in partners) {
      var partners_sorted = partners[ownerRkey].sort( (a,b) => {
        if(a.id < b.id) return -1;
        if(a.id > b.id) return 1;
        return 0;
      })
      return partners_sorted;
    }

    return []
  }

  twinCheck() {
    this.props.isTwin ? this.props.onTwins() : this.onClickSave()
  }

  async onClickSave() {
    try {

      let {father, mother} = this.props.patient.patient;
      let shared_parent = this.state.sibling.half_sibling_shared_parent
      let other_parent = this.state.sibling.half_sibling_other_parent
      var spouse = (shared_parent == 'father') ? father : mother;

      let both_null = (shared_parent === null && other_parent === null)
      let both_set = (shared_parent && other_parent)

      // If only one has value, then enforce validation
      if(!both_null  && !both_set) {
        let message = (this.state.sibling.half_sibling_shared_parent == null) ? 'Shared parent is required' : 'Other parent is required';
        throw new Error (message)
      }

      this.setState({loading: true})

      await this.updateSiblingName();

      if(both_set)
      {
        // Put to new parent (this is half sibling)
        var partner = await this.getOrCreatePartner()
        await this.updateSiblingParent(this.state.sibling, spouse, partner)

      } else {
        // Put back under proband parent (this is now full sibling)
        await this.updateSiblingParent(this.state.sibling, father, mother)
      }

      this.props.onClose()
    } catch (error) {
      this.setState({errorMessages: [error.message]})
      helper.logger(error)
    } finally {
      this.setState({loading: false})
    }

  }

  async getOrCreatePartner() {

    try {
      let {father, mother} = this.props.patient.patient;
      let shared_parent = this.state.sibling.half_sibling_shared_parent
      let other_parent = this.state.sibling.half_sibling_other_parent
      var ownerRkey = 'proband_' + shared_parent

      var spouse = (shared_parent == 'father') ? father : mother;
      var partner = null;

      var otherPartners = this.getParentOtherPartners(shared_parent);
      var index = parseInt(other_parent, radix_bases.base10) - 1;
      if(index < otherPartners.length) {
        partner = cloneDeep(otherPartners[index])
      } else {
        partner = model.createPartner()
        var data = await helper_family_api.create_partner(spouse, partner, 'both')
        partner = Object.assign({}, partner, data)
      }

      helper_family_tree.savePartnerToRedux(this.props.dispatch, ownerRkey, partner)
      helper_family_tree.saveProfileToRedux(this.props.dispatch, partner)
      return partner

    } catch (error) {
      throw error
    }

  }

  async updateSiblingName() {
    try {
      await family_api.patch_member_memberid(this.state.sibling.id, {
        first_name: this.state.sibling.first_name
      })
      helper_family_tree.saveProfileToRedux(this.props.dispatch, this.state.sibling)
      helper_family_tree.saveSiblingDetailToRedux(this.props.dispatch, this.state.sibling)
    } catch (error) {
      throw error
    }
  }

  async updateSiblingParent(sibling, spouse, partner) {
    try {

      let father_id = spouse.id;
      let mother_id = partner.id;
      if(spouse.gender.toLowerCase() == 'f') {
        father_id = partner.id;
        mother_id = spouse.id;
      }

      var parent = await family_api.post_childs({
        father_id: father_id,
        mother_id: mother_id,
        member_id: sibling.id,
      })

      // Update Sibling Father and Mother ID
      let cloneSibling = cloneDeep(sibling)
      cloneSibling.father_id = father_id;
      cloneSibling.mother_id = mother_id;
      this.setState({sibling: cloneSibling})
      helper_family_tree.saveProfileToRedux(this.props.dispatch, cloneSibling)
      helper_family_tree.saveSiblingDetailToRedux(this.props.dispatch, cloneSibling)

    } catch (error) {
      throw error
    }
  }

  onChange(field, value) {

    let sibling = Object.assign({}, this.state.sibling, {[field]: value})
    this.setState({sibling})

  }

  render() {

    if(this.state.sibling == null) return <React.Fragment></React.Fragment>

    let half_sibling_shared_parent = helper.getSelectedOption(settings.half_siblings_shared_parent_options, this.state.sibling.half_sibling_shared_parent);
    let half_sibling_other_parent = helper.getSelectedOption(settings.half_siblings_other_parent_options, this.state.sibling.half_sibling_other_parent);

    return (
      <div role="dialog"
        onClick={() => this.props.onClose()}
        style={{ zIndex: 9998, display: ((this.props.isOpen) ? 'block' : 'none') }}
        className={"modal fade " + ((this.props.isOpen) ? 'in' : '')}>

        <div className="modal-dialog" role="document" onClick={(e) => e.stopPropagation()}>
          <div className="modal-content">
            <div className="modal-header">
              <button
                onClick={() => this.props.onClose()}
                type="button" className="close" data-dismiss="modal" aria-label="Close">
                <i className="fa fa-close"></i>
              </button>
              <h4 className="modal-title text-white text-capitalize">Half-Sibling</h4>
            </div>

            <div className="modal-body">

            <ErrorSummary errorMessages={this.state.errorMessages} />

                  <div className="row">
                    <div className="col-md-4 col-xs-12">
                      <div className="form-group">
                        <label>{this.props.label}</label>
                        <input
                          value={this.state.sibling.first_name}
                          onChange={(e) => this.onChange('first_name', e.target.value)}
                          type="text" className="form-control   " placeholder="First Name" />
                      </div>
                    </div>

                    <div className="col-md-4 col-xs-12">
                      <label>Shared Parent</label>
                      <div className="custom-select">
                        <Select
                          value={half_sibling_shared_parent}
                          onChange={(item) => {
                            let value = item ? item.value : null
                            this.onChange('half_sibling_shared_parent', value)}
                          }
                          className='react-select-container'
                          classNamePrefix="react-select"
                          isClearable={true}
                          placeholder=""
                          options={settings.half_siblings_shared_parent_options}
                        />
                      </div>
                    </div>

                    <div className="col-md-4 col-xs-12">
                      <label>Other Parent</label>
                      <div className="custom-select">
                        <Select
                          value={half_sibling_other_parent}
                          onChange={(item) => {
                            let value = item ? item.value : null
                            this.onChange('half_sibling_other_parent', value)}
                          }
                          className='react-select-container'
                          classNamePrefix="react-select"
                          isClearable={true}
                          placeholder=""
                          options={settings.half_siblings_other_parent_options}
                        />
                      </div>
                    </div>
                  </div>
            </div>

            <div className="modal-footer">
              <button
                onClick={() => this.props.onClose()}
                className="btn btn-light-outline no-margin-right">Cancel</button>
              <button
                onClick={() => this.twinCheck()}
                className="btn btn-dark">Save</button>

                <ActivityIndicator
                  modal={true}
                  loading={this.state.loading}
                />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ModalHalfSiblings;

