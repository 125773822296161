import React, { Component } from 'react'

import { debounce, isEqual } from 'lodash'
import { log_error } from '../../helpers/helper-error'

// import uniqid from 'uniqid'
// import Select from 'react-select'
// import { fromJS, updateIn, update } from 'immutable'

import * as helper_family_tree from '../../helpers/helper-family-tree'

import HealthInputs from './health-inputs';
import FamilyTreePartner from './partner'
import * as model from '../../model-states/m-family-tree';

import family_api from '../../api/family-api';
import helpers from '../../helpers';
import ErrorSummary from '../error-summary';


import * as patient_actions from '../../store/patient/actions';

class FamilyTreeProband extends Component {

  constructor(props) {
    super(props)
    this.state = {
      collapsed: true,
      partners: [],

      errorMessages: [],
      first_name: null
    }

    this.updateFirstName = debounce(this.updateFirstName, 250)
  }

  componentDidUpdate(prevProps) {
    this.loadPartnersFromRedux()

    let first_name = this.getFirstName();
    if(first_name == null) return
    if(this.state.first_name != null) return
    this.setState({first_name})

  }

  async componentDidMount() {
    this.loadPartnersFromRedux()

    // this.populatePartnersFromAPI()
  }

  // async populatePartnersFromAPI() {
  //   try {
  //     let spouse_id = this.props.patient.patient.id
  //     if(spouse_id == null) return

  //     // Clear redux before repopulating
  //     this.props.dispatch(patient_actions.delete_partner({ownerRkey: 'proband'}))

  //     // Fetch API
  //     let partners = await family_api.get_member_memberid_partners(spouse_id)
  //     for(var partner of partners) {
  //       var partner_from_model = model.createPartner()
  //       var partner_data = Object.assign({}, partner_from_model, partner)

  //       // Save to Redux
  //       // partner_data.rkey = 'member_' + partner_data.id
  //       helper_family_tree.savePartnerToRedux(this.props.dispatch, 'proband', partner_data)
  //       helper_family_tree.saveProfileToRedux(this.props.dispatch, partner_data)
  //     }
  //   } catch (error) {
  //     helpers.logger(error)
  //   }
  // }

  loadPartnersFromRedux() {

    if(!('proband' in this.props.patient.partners)) return

    let partners = this.props.patient.partners.proband
    if(!isEqual(this.state.partners, partners)) {
      this.setState({partners})
    }
  }

  async onChangeName(first_name) {
    try {

      this.setState({first_name: first_name})
      let profile = { rkey: this.props.patient.patient.rkey, first_name }
      helper_family_tree.saveProfileToRedux(this.props.dispatch, profile)
      this.updateFirstName(first_name)

      // let the_patient = Object.assign({}, this.props.patient.patient, {firstName})
      // helper_family_tree.savePatientToRedux(this.props.dispatch, the_patient)

    } catch (error) {
      log_error(this.constructor.name, error)
    }
  }

  async updateFirstName(first_name) {
    try {
      this.setState({errorMessages: []})

      let member = this.props.patient.patient
      await family_api.patch_member_memberid(member.id, {
        first_name
      })
    } catch (error) {
      this.setState({errorMessages: [error.message] })
    }
  }


  partners() {
    return ('proband' in this.props.patient.partners) ? this.props.patient.partners.proband : []
  }

  onCollapseClick() {

    this.setState({ collapsed: !this.state.collapsed })
    this.clearSelection()

  }

  clearSelection() {
    if (window.getSelection) { window.getSelection().removeAllRanges(); }
    else if (document.selection) { document.selection.empty(); }
  }

  getFirstName() {

    let profile = this.getProfile();
    if(profile == null) return null;

    return profile.first_name;
  }

  getProfile() {
    return helper_family_tree.getProfileFromRedux(this.props.patient, this.props.patient.patient.rkey)
  }


  render() {

    return <React.Fragment>

      <tr className={this.state.errorMessages.length > 0 ? 'table-row__error' : ''}>
        <td>
          { (this.state.partners.length > 0) &&
            <a onClick={(e) => this.onCollapseClick()}
              className="float--right">
              <i className={"fa fa-" + (this.state.collapsed ? "minus-square" : "plus-square")}></i>
            </a>
          }
        </td>
        <td className="td--nowrap">

          <span className='text--capitalize'>
          Proband
          </span>

        </td>
        <td>
          <input
            onChange={(e) => this.onChangeName(e.target.value)}
            value={ this.state.first_name }
            type="text" className="inputreg form-control normal-input-text"/>
        </td>

        <HealthInputs
          ownerRkey={this.props.patient.patient.rkey}
          collapsible={false}
          collapsed={false}
          onCollapse={() => null }
          onError={(error) => this.setState({errorMessage: [error.message]})}
          canDelete={false}
          dispatch={this.props.dispatch}
          patient={this.props.patient}
          profile={this.getProfile()}
        />

      </tr>

      { this.state.collapsed &&
        this.state.partners.map((partner, index) => {
          return <FamilyTreePartner
            key={partner.rkey}
            ownerRkey='proband'
            collapsed={true}
            level={1}
            extra_indent={0}
            label={'Partner #' + (index + 1) }
            partner={partner}
            collapsible={false}
            owner={this.props.patient.patient}
            parent_side='both'
            dispatch={this.props.dispatch}
            patient={this.props.patient}
            deleteMember={this.props.deleteMember}
          />
        })
      }

    </React.Fragment>

  }
}

export default FamilyTreeProband;
