import React, { Component } from 'react';
import PatientPersonalData from './patient-data-tabs/patient-personal-data';
import PatientHealthHistory from './patient-data-tabs/patient-health-history';
import PatientDocuments from './patient-data-tabs/patient-documents';
import PatientSurvey from './patient-data-tabs/patient-survey';

import helper_survey_api from "../../helpers/helper-survey-api";
import sdk from "../../api/sdk";
import family_api from '../../api/family-api'

import i18n from 'i18next';
import { createUUID } from '../react-flow-pedigree/utils';

class PatientDataTabs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPersonalDataTab: true,
      showDocumentsTab: false,
      showSurveyTab: false,

      surveys: [],
      survey_answers: [],
      chosen_survey: null,
      member_relationship_to_proband: null
    };

    this.handlePDTabClicked  = this.handlePDTabClicked.bind(this);
    this.handleDocTabClicked = this.handleDocTabClicked.bind(this);

    this.surveysCallback = this.surveysCallback.bind(this);
    this.surveyAnswersCallback = this.surveyAnswersCallback.bind(this);
    this.surveyClicked = this.surveyClicked.bind(this);
    this.resetSideMenuHeight = this.resetSideMenuHeight.bind(this);
    this.setCustomMenuHeight = this.setCustomMenuHeight.bind(this);
    this.clearCustomMenuHeight = this.clearCustomMenuHeight.bind(this);
  }

  async componentDidMount() {
    try {
      let tokens = sdk.tokens_from_cookie();
      const headers = { accessToken: tokens.token.accessToken };
      let payload = {
        clinician_id: this.props.session.user.clinician_id,
        proband_id: this.props.proband_id
      };

      helper_survey_api.get_clinician_surveys(payload, headers, this.surveysCallback, (error) => {
        console.log(error);
      });

      // add proband_id to payload, this will be matched back on the server to lead back to the app users answers for the surveys
      // proband_id -> family_id, family_id -> duplicate_of_id, duplicate_of_id -> app user account_id, account_id -> user_id
      // pull the survey answer for the corresponding user_id
      helper_survey_api.get_survey_answers_as_clinician(payload, headers, this.surveyAnswersCallback, (error) => {
        console.log(error);
      });

      let relationships = null;
      let member_relationship_to_proband = null;
      if (this.props.member) {
        relationships = await family_api.get_family_relationships(this.props.proband_id);
        member_relationship_to_proband = relationships[this.props.member.id];
        member_relationship_to_proband = member_relationship_to_proband.charAt(0).toUpperCase() + member_relationship_to_proband.slice(1);
        this.setState({ member_relationship_to_proband: member_relationship_to_proband });
      }
    } catch (err) {
      console.log(err);
    }
  }

  handlePDTabClicked(event) {
    this.clearCustomMenuHeight();

    this.setState({
      showPersonalDataTab: true,
      showHealthHistoryTab: false,
      showDocumentsTab: false,
      showSurveyTab: false,
      chosen_survey: null
    });
  }

  handleDocTabClicked(event) {
    this.clearCustomMenuHeight();

    this.setState({
      showPersonalDataTab: false,
      showHealthHistoryTab: false,
      showDocumentsTab: true,
      showSurveyTab: false,
      chosen_survey: null
    });
  }

  surveysCallback(surveys) {
    if (surveys && Array.isArray(surveys)) {
      this.setState({ surveys: surveys });
      if (this.props.member) {
        const s = surveys.length > 0 ? surveys[0].id : null;
        this.setState({ showPersonalDataTab: false, showDocumentsTab: false, showSurveyTab: true, chosen_survey: s});
      }
    }
  }

  surveyAnswersCallback(survey_answers) {
    if (survey_answers && Array.isArray(survey_answers)) this.setState({ survey_answers: survey_answers });
  }

  surveyClicked(event) {
    const survey_id = event.currentTarget.getAttribute('data-survey-id');
    this.setState({
      showPersonalDataTab: false,
      showHealthHistoryTab: false,
      showDocumentsTab: false,
      showSurveyTab: true,
      chosen_survey: survey_id
    });
  }

  /*********************************************************************/
  /* A little hack for adjusting the height of the sidemenu to match the
    content when its a custom survey and the height is dynamic. Probably
    a better solution, but this is what I came up with for now.
  */
  resetSideMenuHeight() {
    if (this.state.chosen_survey) {
      let elem = document.getElementById('tab-survey');
      if (elem) {
        let h = elem.clientHeight;
        this.setCustomMenuHeight(h);
      }
    }
  }

  setCustomMenuHeight(height) {
    let side_menu = document.getElementById('patient-sidetab');

    if (height > side_menu.clientHeight) {
      side_menu.setAttribute('style', 'height:' + height + 'px;');
    }
  }

  clearCustomMenuHeight() {
    let side_menu = document.getElementById('patient-sidetab');
    side_menu.setAttribute('style', '');
  }
  /*********************************************************************/

  async saveClinician(proband){
    if(!('clinicians' in proband)) return;
    try {
      let payload = {
        clinicians: proband.clinicians,
        proband_id: proband.id
      }
      await family_api.save_family_clinicians(payload)

    } catch (err) {
      console.log(err);
    }

  }

  render() {
    const pd_tab_active  = (this.state.showPersonalDataTab) ? 'active' : '';
    const doc_tab_active = (this.state.showDocumentsTab) ? 'active' : '';

    const pd_content_css  = (this.state.showPersonalDataTab) ? 'tab-pane fade in active' : 'tab-pane fade';
    const doc_content_css = (this.state.showDocumentsTab) ? 'tab-pane fade in active' : 'tab-pane fade';
    const survey_content_css = (this.state.showSurveyTab) ? 'tab-pane fade in active' : 'tab-pane fade';

    let survey_list = null;
    if (this.state.surveys.length > 0) {
      // build the survey list
      const survey_items = this.state.surveys.map((survey) => {
        const s_id = parseInt(survey.id, 10);
        const chosen_s_id = parseInt(this.state.chosen_survey, 10);
        const active = (s_id && chosen_s_id && s_id === chosen_s_id) ? true : false;

        const translation_key = survey.translation_key + ".name";
        let survey_name_translation = i18n.t(translation_key);
        if (survey_name_translation === translation_key) {
          survey_name_translation = survey.name;
        }

        return (
          <li key={survey.id} className={(active) ? 'active' : ''}>
            <button className="patient-tab-btn" data-survey-id={survey.id} onClick={this.surveyClicked}>
              {survey_name_translation}
            </button>
          </li>);
      });

      if (this.props.member) {
        survey_list = (
          <div className="survey-side-tab-margin" style={{marginTop: '0px'}}>
            <ul className="nav nav-pills nav-stacked nav-pills-unstyled">
              <li key={createUUID()}><button className="patient-tab-btn survey-side-tab-header">Surveys</button></li>
              {survey_items}
            </ul>
          </div>
        );
      } else {
        survey_list = (
          <div className="survey-side-tab-margin">
            <ul className="nav nav-pills nav-stacked nav-pills-unstyled">
              <li key={createUUID()}><button className="patient-tab-btn survey-side-tab-header">Surveys</button></li>
              {survey_items}
            </ul>
          </div>
        );
      }
    }

    let survey_tab_content = null;
    if (this.state.chosen_survey) {
      let memberID = null;
      if (this.props.member) {
        memberID = this.props.member.id
      } else if (this.props.getPedigreeData) {
        memberID = this.props.getPedigreeData().proband.id;
      }
      // add a unique key attribute so the component re-renders with new survey ID
      survey_tab_content = <PatientSurvey key={createUUID()} session={this.props.session} 
      surveyId={this.state.chosen_survey} memberID={memberID}/>;
      setTimeout(this.resetSideMenuHeight, 2000);
    }

    let content = null;
    if (this.props.member) {
      document.body.style.overflow = 'visible';
      const member = this.props.member.data.profile;
      content = (
        <>
        <div className="row-fluid no-gutter" style={{overflow: 'auto'}}>
          <div className="patient-fam" 
                style={{marginBottom: '-15px', fontFamily: '"Montserrat", sans-serif', fontSize: '13px', fontWeight: '600'}}
          >
            <ul className="nav nav-pills">
              <li className='patient-details' style={{float: 'left'}}>
                <p style={{paddingLeft: '0px'}}>{member.first_name ? member.first_name + "," : ""} {this.state.member_relationship_to_proband} </p>
              </li>
              <li className='patient-details' style={{float: 'right'}}>
                <a onClick={this.props.closeCustomDataSurvey}>
                  <i class="fa fa-close"></i>{" CLOSE"}
                </a>
              </li>
            </ul>
          </div>

            <div className="col-md-2 xs-no-padding">
              <div className="patient-sidetab" id="patient-sidetab">
                {survey_list}
              </div>
            </div>

            <div className="col-md-10 xs-no-padding">
              <div className="tab-content">
                <div className={survey_content_css} id="tab-survey">
                  <div className="clinician-survey-tab">
                    {this.state.showSurveyTab && (
                      survey_tab_content
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      );
    } else {
      content = (
        <div className="row-fluid no-gutter">

            <div className="col-md-2 xs-no-padding">
              <div className="patient-sidetab" id="patient-sidetab">
                <ul className="nav nav-pills nav-stacked nav-pills-unstyled">
                  <li className={pd_tab_active}><button className="patient-tab-btn" onClick={this.handlePDTabClicked}>Personal</button></li>
                  <li className={doc_tab_active}><button className="patient-tab-btn" onClick={this.handleDocTabClicked}>Documents</button></li>
                </ul>

                {survey_list}
              </div>
            </div>

            <div className="col-md-10 xs-no-padding">
              <div className="tab-content">
                <div className={pd_content_css} id="tab-personal">
                  <div className="patient-maintab">
                    {this.state.showPersonalDataTab && (
                      <>
                        <PatientPersonalData
                          isNewPatient={false}
                          getPedigreeData={this.props.getPedigreeData}
                          saveClinician={async (proband) => await this.saveClinician(proband)}
                        />
                      <PatientHealthHistory
                        dispatch={this.props.dispatch}
                        getPedigreeData={this.props.getPedigreeData}
                      />
                      </>
                    )}
                  </div>
                </div>

                <div className={doc_content_css} id="tab-doc">
                  <div className="patient-maintab">
                    {this.state.showDocumentsTab && (
                      <PatientDocuments getPedigreeData={this.props.getPedigreeData}/>
                    )}
                  </div>
                </div>

                <div className={survey_content_css} id="tab-survey">
                  <div className="clinician-survey-tab">
                    {this.state.showSurveyTab && (
                      survey_tab_content
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
      );
    }

    return (
      <div>
        {content}
      </div>
    );
  }
}

export default PatientDataTabs;
