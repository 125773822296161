export default {
  user: {
    username: '',
    first_name: '',
    last_name: '',
    email: '',
    phone_number: '',
    clinician_id: '',
    admin: true,
    locked: false,
    account_type: 'standard_org', // account_type: [super_org, sub_org, standard_org, super_admin]
    read_only: false,
    risk_model_years: 'five-year',
    dial_code: '',
    staff_id: null,
    account_id: null,
    user_id: null,
    organization_id: null,
    criteria_source_category_settings: [],
    criteria_source_categories: [],
    emr_always_read_only: false,
    login_type: null,
    auto_logout_time: 15,
    default_partner_view: '',
    max_number_of_pedigrees: null,
  }
}
