import React, { Component } from "react";

import LabelField from './label-field';
import i18n from 'i18next';
import { createUUID } from '../../../../react-flow-pedigree/utils';

class SelectAllThatApplyField extends Component {
  constructor(props) {
    super(props);

    this.state = {};

    this.onChange = this.onChange.bind(this);
  }

  onChange(event) {
    const choice_id = event.currentTarget.value;
    this.setState({ [choice_id]: event.currentTarget.checked });

    const payload = {
      field_type: this.props.masterQuestion.type,
      master_question_id: this.props.masterQuestion.id,
      master_question_choice_id: choice_id,
      answer: null
    };

    this.props.saveCompletedSurveyAnswer(payload);
  }

  render() {
    let column_one = null;
    let column_two = null;

    const choices = this.props.masterQuestion.master_question_choices.map((choice) => {
      const key = createUUID();

      let checked = (this.state[choice.id+""]) ? true : false;

      if (!checked) {
        const a = this.props.answers.find((answer) => parseInt(answer.master_question_choice_id, 10) === parseInt(choice.id, 10));
        checked = (a) ? true: false;
      }

      const translation_key = choice.translation_key + ".choice"
      let choice_label_translation = i18n.t(translation_key);
      if (choice_label_translation === translation_key) {
        choice_label_translation = choice.choice;
      }

      return (
        <div key={createUUID()}>
          <div className="row">

            <div className="col-md-2">
              <label className="switch">
                <input
                  name={key}
                  id={key}
                  type="checkbox"
                  onChange={this.onChange}
                  checked={checked}
                  value={choice.id}
                />
                <span className="slider round"></span>
              </label>
            </div>

            <div className="col-md-10" style={{left: -40}}>
              <label className="clinician-survey-label">
                {choice_label_translation}
              </label>
            </div>

          </div>
        </div>
      );
    });

    if (this.props.masterQuestion.question_label) {

      column_one = (
        <LabelField
          masterQuestion={this.props.masterQuestion}
        />
      );

    }

    column_two = choices;

    return (
      <div style={{display: 'flex', flexDirection: 'column'}}>

          <div>
            {column_one}
          </div>


          <div>
            {column_two}
          </div>

      </div>
    );

  }
}

export default SelectAllThatApplyField;
