

const x = {
  patterBG: require(`../assets/production/images/pattern_bg.png`),
//   famhisLogo: require(`../assets/production/images/FamGenix.png`),
  famhisLogo: require(`../assets/production/images/FamGenix.svg`),
  famhisLogoRegSVG: require(`../assets/production/images/FamGenix.svg`),
  famhisLogoRegNew: require(`../assets/production/images/FamGenixAllWhiteLogo.png`),
  famhisLogoBig: require(`../assets/production/images/FamGenix.svg`),
  search: {
    iconFileCopy24px: require(`../assets/production/images/icon/file_copy_24px.png`),
    iconPersonAdd24px: require(`../assets/production/images/icon/person_add_24px.png`),
    iconMail24px: require(`../assets/production/images/icon/mail_24px.png`),
    iconPeople24px: require(`../assets/production/images/icon/people_24px.png`),
  },
  resetPasswordLinkSent: require(`../assets/production/images/illus-sent.png`),
  cliniciansProfileAvatar: require(`../assets/production/images/clinicians_profile_pic.png`),
  logoSample: require(`../assets/production/images/logo-sample.png`),
  navAvatar: require(`../assets/production/images/avatar.png`),
  iconNotification: '/assets/images/icon-notif.png',
  upload: '/assets/images/baseline-cloud_upload-24px.svg',
  uploadComplete: '/assets/images/baseline-check_circle_outline-24px.svg',
  completeSurveyIcon: require(`../assets/production/images/complete-survey-1.png`),
  surveyBackArrow: require(`../assets/production/images/back/white.png`),
}
export default x;