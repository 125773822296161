import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PatientGeneTestView from './patient-gene-test-view';

class ModalGeneTestView extends Component {

  render() {

    return ReactDOM.createPortal(
      <React.Fragment>


      <div style={{ display: 'block', zIndex: 1040 }} className="modal fade in" role="dialog">
        <div onClick={(e) => e.stopPropagation()} className="modal-dialog modal-xl" role="document">

          <div className="modal-content" style={{ display: "grid" }}>

            <div className="modal-header">
              <button
                onClick={() => this.props.onClose()}
                type="button" className="close" data-dismiss="modal" aria-label="Close">
                <i className="fa fa-close"></i>
              </button>
              <h4 className="modal-title text-white text-capitalize">{this.props.title}</h4>
            </div>

            <div className="modal-body" style={{ display: "grid" }}>

              <PatientGeneTestView
                patientRkey={this.props.patientRkey}
                profile={this.props.profile}
              />

            </div>
          </div>
        </div>
      </div>
      </React.Fragment>
      ,
      document.body
    );
  }

}

export default ModalGeneTestView;