export class PedigreeDrawingData {
	constructor() {
		this.clickMap = new Map();
		this.selected_id_list = new Set();
	}

	set_selected_id_list(id_list) {
		this.selected_id_list.clear();
		for (const element of id_list) {
			this.selected_id_list.add(element);
		}
	}
	getSelectedCount() {
		return this.selected_id_list.size;
	}
	assign_click_count(node_id, count) {
		this.clickMap.set(node_id, count);
	}
	click_node(node_id, increment = 1) {
		// let count = 0;
		// if (this.clickMap.has(node_id)) {
		// 	count = this.clickMap.get(node_id);
		// }
		// count = Math.min(count + increment, 2);
		// this.clickMap.clear();
		// this.clickMap.set(node_id, count);
		// console.log(this.clickMap)
		// return count;
		let count = 0;
		if (this.clickMap.has(node_id)) {
			count = this.clickMap.get(node_id);
		}
		count += increment;
		this.clickMap.clear();
		this.clickMap.set(node_id, count);
		if (count > 2){
			this.clickMap.set(node_id, 1);
		}
		return count;

		// this.clickMap.set(node_id, count);
		// console.log(this.clickMap)
		// return count;
		// node.data = {
		// 	...node.data,
		// 	nodeClickCount: node.data.nodeClickCount + 1,
		// };
		// if (node.data.nodeClickCount > 2) {

		// }
	}
	clear_click_count_for_node(node_id) {
		this.clickMap.delete(node_id);
	}
	clear_click_count() {
		this.clickMap.clear();
	}
	getClickCount(node_id) {
		let count = 0;
		if (this.clickMap.has(node_id)) {
			count = this.clickMap.get(node_id);
		}
		return count;
	}
}
