import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { components } from 'react-select';


class ModalOverwriteReport extends Component {
    constructor(props) {
        super(props);
        this.onSave = this.onSave.bind(this);
    }


    onSave(event) {
        this.props.onClickSave(this.props.reportTitle);
        this.props.onClose();
    }


    render() {
        return ReactDOM.createPortal(
            <div
                onClick={() => this.props.onClose()}
                style={{ display: 'block' }}
                className="modal fade in"
                role="dialog"
            >

                <div onClick={(e) => e.stopPropagation()} className="modal-dialog modal-sm" role="document">
                    <div className="modal-content">

                        <div className="modal-header">
                            <button onClick={() => this.props.onClose()} type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <i className="fa fa-close"></i>
                            </button>
                            <h4 className="modal-title text-white text-capitalize">Save Report</h4>
                        </div>

                        <div className="modal-body">
                            <div className="modal-confirm">
                                <p>A report with this name already exists. Do you want to replace it with this one?</p>
                            </div>
                        </div>

                        <div className="modal-footer" style={{ textAlign: 'center' }}>
                            <button onClick={() => this.props.onClose()} className="btn btn-light-outline no-margin-right">Cancel</button>
                            <button onClick={this.onSave} className="btn btn-dark" data-dismiss="modal" aria-label="Close">Replace</button>
                        </div>
                    </div>
                </div>
            </div>,
            document.body
        );
    }

}


export default ModalOverwriteReport