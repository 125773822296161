import { combineReducers } from 'redux';
import types from "./types";

import mock from './mock';
import { cloneDeep } from 'lodash';

/*UNCOMMENT IF USING IMMUTABLE JS*/
//import { fromJS, updateIn, setIn, removeIn } from 'immutable';

function searchFilter(state=mock.searchFilter, action) {
  switch(action.type) {
    case types.UPDATE_SEARCH_TEXT:
      return action.payload;
    default:
      return state;
  }
}

function searchHistory(state=mock.searchHistory, action) {
  switch(action.type) {
    case types.SAVE_SEARCH_FILTER:
      /*LODASH JS*/
      //console.time("TIMER");
      let deep_clone = cloneDeep(state);

      // limit the saved search history to 10
      if(deep_clone.length === 10) {deep_clone = deep_clone.slice(1);}
      deep_clone.push(action.payload);
      //console.timeEnd("TIMER");
      return deep_clone;


      /*IMMUTABLE JS*/
      // console.time("TIMER");
      // let deep_clone = fromJS(state);
      //
      // // limit the saved search history to 10
      // if(deep_clone.size === 10) {deep_clone = deep_clone.shift();}
      // deep_clone = deep_clone.push(action.payload);
      // let final_clone = deep_clone.toJS();
      // console.timeEnd("TIMER");
      // return final_clone;

    case types.UPDATE_SEARCH_HISTORY:
      return action.payload;
    default:
      return state;
  }
}

function searchResults(state=mock.searchResults, action) {
  switch(action.type) {
    case types.UPDATE_SEARCH_RESULTS:
      return action.payload;
    default:
      return state;
  }
}

function archivedDataSearchText(state=mock.archivedDataSearchText, action){
  switch(action.type) {
    case types.UPDATE_ARCHIVED_DATA_SEARCH_TEXT:
      return action.payload;
    default:
      return state;
  }
}

const search = combineReducers({
  searchFilter,
  searchHistory,
  searchResults,
  archivedDataSearchText
});

export default search;
