import React, {Component} from 'react';
import {
  PedigreeStylesheet,
  oneClickAddActionTypes as actions
} from './pedigree-constants';

class AddParentDOM extends Component {

  render() {
    const container_styles = Object.assign({}, PedigreeStylesheet.oneClickDOMContainer, {top: this.props.top, left: this.props.left});
    const parents_btn_style = Object.assign({}, PedigreeStylesheet.oneClickAddBtnParents);
    const svg_symbol_style = PedigreeStylesheet.oneClickAddSvgSymbol;
    const svg_style = PedigreeStylesheet.oneClickAddParentsSvg;

    const default_num_to_add = 1;
    return (
      <div style={container_styles}>
        <button
          onClick={() => this.props.onClickAdd(actions.PARENTS, default_num_to_add)}
          className='btn-default'
          style={parents_btn_style}>

          <svg style={svg_style}>
            <rect
              x={PedigreeStylesheet.oneClickAddSvgSymbolMale.x}
              y={PedigreeStylesheet.oneClickAddSvgSymbolMale.y}
              width={PedigreeStylesheet.oneClickAddSvgSymbolMale.width}
              height={PedigreeStylesheet.oneClickAddSvgSymbolMale.height}
              style={svg_symbol_style}
            />
            <path
              d={`M ${13},${7}L ${28},${7} Z`}
              style={svg_symbol_style}
            />
            <circle
              cx={28}
              cy={PedigreeStylesheet.oneClickAddSvgSymbolFemale.cy}
              r={PedigreeStylesheet.oneClickAddSvgSymbolFemale.r}
              style={svg_symbol_style}
            />
          </svg>
        </button>
      </div>
    );

  }
}

export default AddParentDOM;
