import React, { Component } from 'react';
import Dropdown from 'react-dropdown'
import ErrorSummary from './error-summary'
import { cloneDeep } from 'lodash'
import { radix_bases } from '../helpers/helper-number-bases';

class SkipLogicLeukemiaForm extends Component{
    constructor(props) {
        super(props)
        this.state = {
          keys: {},
          skipLogicChoices: [],
          errorMessages: []
        }
        this.createSkipLogicOptions = this.createSkipLogicOptions.bind(this)
      }
      componentDidMount(){
        let keys = {}
        this.props.static_skip_logic.forEach(item=>{
          keys[item.short_name] = item.skip_logic_code
        })
        let choices = this.createSkipLogicOptions()
        this.setState({keys: keys, skipLogicChoices: choices})
      }

      async componentDidUpdate(prevProps) {
        if (prevProps.skip_logics.length != this.props.skip_logics.length) {
          for (var key in this.state.keys) {
            var val = this.getValue(key);
            if (val === null) continue;

            if (key.includes("age")) {
              if (!isNaN(parseInt(val, radix_bases.base10))) {
                await this.setState({ [key]: val, [key.replace("_age", "")]: "y" });
              }
            } else {
              await this.setState({ [key]: val , leukemia_type: val});
            }
          }

          // Reconstruct payload
          var skipLogicPayload = this.buildSkipLogicPayload();
          this.props.onUpdate(this.state, skipLogicPayload, true);

        }
      }

  createSkipLogicOptions(){
    let choices = [{value: ' ', label: ' '}]

    this.props.static_skip_logic.forEach(option =>{
      let short_name = ''
      if(option.short_name!== option.question){
        short_name = ' (' + option.short_name + ')'
      }
      choices.push({value: option.short_name , label: option.question})
    })
    return choices
  }

  getValue(key) {
    if(!(key in this.state.keys)) return null
    let code = this.state.keys[key]

    let data = this.props.skip_logics.find(item => item.skip_logic_code == code)

    if(typeof(data) === 'undefined') return null
    return data.short_name
  }

  buildSkipLogicPayload() {
    let inputs = this.state.leukemia_type
    let mapping = this.state.keys;

    let skipLogicPayloads = []
    for(var key in mapping) {
      if(inputs.value == key){
      var static_skip_logic_item = this.props.static_skip_logic.find(item => item.skip_logic_code == mapping[key])
      if(typeof(static_skip_logic_item) === 'undefined') {
        throw new Error('Skip Logic code not found');
      }
      var answer = inputs !== null && typeof(inputs) === 'object' ? inputs.label : inputs
      if(answer !== null && answer.trim() !== '') {
        skipLogicPayloads.push({
          skip_logic_code: static_skip_logic_item.skip_logic_code,
          skip_logic_id: static_skip_logic_item.id,
          short_name: static_skip_logic_item.short_name,
          answer
        })
      }
    }
  }
    return skipLogicPayloads;
  }

  async onChange(data){
    try {
      this.setState({errorMessages: []})
      await this.setState({...this.state, ...data});

      var skipLogicPayload = this.buildSkipLogicPayload();
      this.props.onUpdate(this.state, skipLogicPayload);
    } catch (error) {
      this.setState({errorMessages: [error.message]})
    }
  }

  render(){
   return(
    <div className="panel-form-2 sl-content colorectal">
    <ErrorSummary errorMessages={this.state.errorMessages} />
    <div className="row">
          <div className="col-md-3">
            <div className="form-group">
              <label>Type, if known:</label>
              <div className="custom-select">

                <Dropdown
                  options={this.state.skipLogicChoices}
                  onChange={(leukemia_type) => this.onChange({leukemia_type})}
                  value={this.state.leukemia_type}
                  placeholder="&nbsp;"
                  />

              </div>
            </div>
          </div>
          </div>
    </div>

    )
  }
}

export default SkipLogicLeukemiaForm
