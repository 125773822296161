import React, { Component } from 'react';
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import helpers from '../helpers/index';
import images from '../configs/images';
import route_helper from '../route-helper';
import ModalResetPasswordLink from "../components/login/modal-reset-password-link";
import ModalResetPasswordLinkSent from "../components/login/modal-reset-password-link-sent";
import LoginForm from "../components/login/login-form";

import settings from "../configs/settings";
import api_auth from '../api/authentication';
import sdk from '../api/sdk';
import Cookies from 'js-cookie';
import * as action_auth from '../store/session/actions';
import { save_risk_settings } from '../store/patient/actions';

import {
  generateAuthenticationOptionsWebAuthn,
  verifyAuthenticationOptionsWebAuthn
} from '../api/webauthn-api';

import {
  preformatGetAssertReq,
  publicKeyCredentialToJSON
} from '../helpers/authenticator-helper';

import {
  save_login_data
} from '../helpers/helper-login';

class LoginPage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      openModalResetPasswordLink: false,
      openModalResetPasswordLinkSent: false,
      username: '',
      loginAttempts: 0,
      loginDisabled: false,
      versionMismatch: false,
      hidePassword: false,
      showTimedOutMessage: false
    };

    this.handlePasswordlessLogin               = this.handlePasswordlessLogin.bind(this);
    this.handleLoginSuccess                    = this.handleLoginSuccess.bind(this);
    this.handleLoginFail                       = this.handleLoginFail.bind(this);
    this.handleResetPasswordLink               = this.handleResetPasswordLink.bind(this);
    this.handleForgotPasswordClick             = this.handleForgotPasswordClick.bind(this);
    this.handleCloseModalResetPasswordLink     = this.handleCloseModalResetPasswordLink.bind(this);
    this.handleCloseModalResetPasswordLinkSent = this.handleCloseModalResetPasswordLinkSent.bind(this);
    this.saveNetworkData = this.saveNetworkData.bind(this)
    this.showPasswordLink = this.showPasswordLink.bind(this);
    this.hidePasswordLink = this.hidePasswordLink.bind(this);
    this.getApiVersion = this.getApiVersion.bind(this);
    this.buttonRef = React.createRef();
  }

  componentDidMount(){
    helpers.setSiteTitle('Login');

    const timedLogout = localStorage.getItem("timedLogout");
    const showTimedOutMessage = timedLogout ? true : false;

    // remove timed logout flag after successful sign in
    localStorage.removeItem("timedLogout");

    setTimeout(this.getApiVersion, 500);

    this.setState({showTimedOutMessage: showTimedOutMessage});
  }

  async handleLoginSuccess(account_type, authenticator_data) {
    await this.saveNetworkData()
    if ("trigger_webauthn_flow" in authenticator_data && authenticator_data.trigger_webauthn_flow) {
      this.navigateToPage(route_helper.authenticator.authenticate, authenticator_data.data);
    } else {
      if(account_type === settings.app_constants.famhisOrg.value) {
        this.navigateToPage(route_helper.administrative.fh_dashboard, null);
      } else {
        this.navigateToPage(route_helper.query.query_root, null);
      }
    }
  }

  async saveNetworkData(){
    let networkData = Cookies.get('logStatus')
    networkData = JSON.parse(networkData)

    try{
        let response = api_auth.log_network_data(networkData)
        response.then(Cookies.remove('logStatus'))

      }catch(err){
        console.log(err.message)
      }
  }

  async navigateToPage(path, data) {
    const { from } = this.props.location.state || { from: { pathname: path, state: {detail: data} } };
    this.props.history.replace(from);
  }

  async handleLoginFail() {
    await this.saveNetworkData();
    // TODO: 3 failed login attempts should require a password reset or unlock by admin
    let login_attempts = this.state.loginAttempts;
    login_attempts += 1
    if(login_attempts >= settings.app_constants.login.max_login_attempts) {
      this.setState({loginDisabled: true});
    }
    this.setState({loginAttempts: login_attempts });
  }

  handleForgotPasswordClick(event) {
    this.setState({
      openModalResetPasswordLink: true,
      username: ''
    });
  }

  async handleResetPasswordLink(username) {
    this.setState({
      openModalResetPasswordLink: false,
      openModalResetPasswordLinkSent: true,
      username: username
    });

    await api_auth.send_password_reset_email(username, false);

    // TODO: send the entered email address back to the server for an email to be sent out
    // axios.post(
    //   api_routes.reset_password_link,
    //   {email: email}
    // ).then(response => {
    //   this.setState({
    //     openModalResetPasswordLink: false,
    //     openModalResetPasswordLinkSent: true,
    //     username: email
    //   });
    // }).catch(error => {
    //   return;
    // });
  }

  handleCloseModalResetPasswordLink() {
    this.setState({openModalResetPasswordLink: false});

    // re-focus button for accessibility
    if(this.buttonRef && this.buttonRef.current) this.buttonRef.current.focus();
  }

  handleCloseModalResetPasswordLinkSent() {
    this.setState({openModalResetPasswordLinkSent: false});

    // re-focus button for accessibility
    if(this.buttonRef && this.buttonRef.current) this.buttonRef.current.focus();
  }

  async getApiVersion() {
    try {
      const api_version = await api_auth.get_api_version();
      if (api_version !== process.env.REACT_APP_API_VERSION) {
        this.setState({versionMismatch: true});
      }
    } catch (error) {}
  }
  hidePasswordLink(){
    this.setState({hidePassword: true})
  }

  showPasswordLink(){
    this.setState({hidePassword: false})
  }

  async handlePasswordlessLogin(event) {
    try {

  		const response = await generateAuthenticationOptionsWebAuthn();
      const { key, options } = response;
      const publicKey = preformatGetAssertReq(options);
			const creds = await navigator.credentials.get({ publicKey });
  		const makeCredResponse = publicKeyCredentialToJSON(creds);
  		const verification_response = await verifyAuthenticationOptionsWebAuthn({key: key, credential: makeCredResponse});

      save_login_data(this.props.session.user, verification_response, this.props.dispatch);
      sdk.tokens_to_cookie(verification_response);
      this.props.dispatch(action_auth.authenticated(true));

    } catch (exception) {
      if (exception instanceof DOMException && "message" in exception) {
        this.setState({errorMessages: [`${exception.name}: ${exception.message}`]});
      }
    }
  }

  render() {
    const copyright_year = new Date().getFullYear();

    let jsx = null;
    if (this.state.versionMismatch) {
      jsx = (
        <>
          <p className="text-center text-muted margin-nine-top">Clinician Portal is out of date with the API, please try reloading by clicking the button</p>

          <div className="form-group">
            <button className="btn btn-teal btn-block btn-lg no-margin-top" onClick={() => document.location.reload(true)}>Reload</button>
          </div>
        </>
      );
    } else {
      let msg = null;
      if (this.props.location.state && this.props.location.state.password_success_message) {
        msg = (<p>{this.props.location.state.password_success_message}</p>);
      }

      jsx = (
        <React.Fragment>
          {msg}

          <>
            <LoginForm
              onLoginSuccess={this.handleLoginSuccess}
              onLoginFail={this.handleLoginFail}
              disabled={this.state.loginDisabled}
              hidePasswordLink={()=>this.hidePasswordLink()}
              showPasswordLink={()=>this.showPasswordLink()}
            />
            {!this.state.hidePassword && (
              <button className="btn-link" id="forgot_password" onClick={this.handleForgotPasswordClick} ref={this.buttonRef}>Forgot Password?</button>
            )}
            <Link to='#' className="hiddenanchor" id="signup"></Link>
            <Link to='#' className="hiddenanchor" id="signin"></Link>
            {/* {!this.state.hidePassword && (
              <div>
                <button className="btn-link" onClick={this.handlePasswordlessLogin}>Passwordless Login</button>
              </div>
            )}*/}
          </>
        </React.Fragment>
      );
    }

    const showTimedOutMessage = this.state.showTimedOutMessage;

    return (
      <React.Fragment>
        <div className="login_wrapper">
          <div className="animate form login_form">
            {showTimedOutMessage && (
              <div><p className="text-center" style={{marginBottom: 30}}>Logged out due to inactivity.</p></div>
            )}
            <section className="login_content">

              <div className="logo">
                <img src={images.famhisLogo.default} className="img-responsive" alt="FamGenix Logo" />
              </div>

              {jsx}

            </section>
            <div><p className="text-center text-muted margin-nine-top"><span>&copy; {copyright_year} by FamHis, Inc. All rights reserved.</span></p></div>
          </div>
        </div>
        {this.state.openModalResetPasswordLink &&
          <ModalResetPasswordLink
              onClose={this.handleCloseModalResetPasswordLink}
              onClickResetPassword={this.handleResetPasswordLink}
          />
        }

        {this.state.openModalResetPasswordLinkSent &&
          <ModalResetPasswordLinkSent
              onClose={this.handleCloseModalResetPasswordLinkSent}
              username={this.state.username}
          />
        }
      </React.Fragment>
    );
  }
}

const redux_state = state => ({
  session: state.session
});

const redux_actions = dispatch => ({
  dispatch: (action) => dispatch(action)
});

export default connect(redux_state, redux_actions)(LoginPage);
