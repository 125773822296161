import React, { Component } from 'react';
import ModalDeleteSubAccount from './modal-delete-sub-account';
import TableFilter from '../tables/table-filter';
import TableFooter from '../tables/table-footer';
import account_api from '../../api/user-account-api';
import { cloneDeep } from 'lodash';
import { radix_bases } from '../../helpers/helper-number-bases';
import route_helper from '../../route-helper';

function parseToInt(val) {
  return parseInt(val, radix_bases.base10)
}

class SubAccountsTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDeleteSubOrgModal: false,
      currentSubOrgKey: null,
      entries_per_page: 10,
      page: 1,
      search_filter: null,
      sub_orgs: [],
      codes: [],
      parent_org: null,
      deleteErrorMessage: null,
    };

    this.openDeleteSubOrgModal  = this.openDeleteSubOrgModal.bind(this);
    this.closeDeleteSubOrgModal = this.closeDeleteSubOrgModal.bind(this);

    this.onChange = this.onChange.bind(this);
    this.onSaveSubOrg = this.onSaveSubOrg.bind(this);
    this.onDeleteSubOrg = this.onDeleteSubOrg.bind(this);
    this.downloadCSV = this.downloadCSV.bind(this);
    this.clearDeleteMessage = this.clearDeleteMessage.bind(this);
    this.viewOrgClicked = this.viewOrgClicked.bind(this);
  }

  async componentDidMount() {

    try {
      const parent_org = await account_api.get_org_by_id(this.props.parentOrgId);
      const orgs = await account_api.get_sub_orgs(this.props.parentOrgId);

      let sub_orgs = [];
      for(let org of orgs.sub_orgs) {
        org.clinician_code = orgs.codes.find((code) => {
          return parseToInt(code.id) === parseToInt(org.clinician_id);
        });
        sub_orgs.push(org);
      }

      this.setState({
        sub_orgs: sub_orgs,
        codes: sub_orgs.codes,
        parent_org: parent_org.org,
      });
    } catch (error) {
      console.log(error);
    }
  }

  openDeleteSubOrgModal(event) {
    this.setState({
      showDeleteSubOrgModal: true,
      currentSubOrgKey: event.target.name
    });
  }

  closeDeleteSubOrgModal(event) {
    this.setState({
      showDeleteSubOrgModal: false,
      currentSubOrgKey: null
    });
  }

  async onChange(event) {
    const name = event.target.name;

    const cloned = cloneDeep(this.state.sub_orgs);
    let target_org = null;
    const new_orgs = cloned.map((org) => {
      if( parseToInt(org.id) === parseToInt(name) ) {
        org.is_locked = event.target.checked;
        target_org = org;
      }
      return org;
    });

    if(target_org) {
      // send saved data to the server
      const json = {
        org_name: target_org.org_name,
        org_type: target_org.org_type,
        units: target_org.units,
        password_reset_timeframe: target_org.password_reset_timeframe,
        logo: target_org.logo,
        is_locked: target_org.is_locked,
        parent_org: target_org.parent_org,
      };

      try {
        let response = await account_api.patch_organization(target_org.id, json)

        this.setState({ sub_orgs: new_orgs });
      } catch(error) {
        console.log(error);
      }
    }
  }

  onSaveSubOrg(payload) {

  }

  async onDeleteSubOrg(org_id) {
    try {

      await account_api.delete_org(org_id);

      const cloned = cloneDeep(this.state.sub_orgs);
      const new_orgs = cloned.filter((org) => {
        if( parseToInt(org.id) !== parseToInt(org_id) ) {
          return org;
        }
      });

      this.setState({ sub_orgs: new_orgs });

    } catch (error) {

      this.setState({ deleteErrorMessage: error.message });
      const clearMessage = this.clearDeleteMessage;
      setTimeout(function() {
        clearMessage();
      }, 5000);

    }
  }

  clearDeleteMessage() {
    this.setState({ deleteErrorMessage: null });
  }

  filterValues(value) {
    this.setState({entries_per_page: value, page: 1});
  }

  async onSearchSubmit(search) {
    // implement client side search
    this.setState({search_filter: search});
  }

  downloadCSV() {
    let csv = '';
    csv+= 'Organization, Clinician Code, Unit System, Password Reset TimeFrame, Locked, Parent Org \r\n'
    this.state.sub_orgs.forEach((org) => {
      const org_name = org.org_name;
      const code = (org.code) ? org.clinician_code.code : '';
      const units = org.units;
      const password_reset_timeframe = org.password_reset_timeframe;
      const locked = org.is_locked;
      const parent_org = (this.state.parent_org) ? this.state.parent_org.org_name : '';

      const line = `${org_name},${code},${units},${password_reset_timeframe},${locked},${parent_org}\r\n`;
      csv += line;
    });

    var downloadLink = document.createElement("a");
    var blob = new Blob(["\ufeff", csv]);
    var url = URL.createObjectURL(blob);
    downloadLink.href = url;
    downloadLink.download = "data.csv";

    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }

  viewOrgClicked(event) {
    const org_id = event.currentTarget.name;
    this.props.viewSubOrg(org_id);
  }

  render() {
    let counter = 1;
    const onChange = this.onChange;
    const openDeleteSubOrgModal = this.openDeleteSubOrgModal;
    const viewOrgClicked = this.viewOrgClicked;
    const search_filter = this.state.search_filter ? this.state.search_filter.split(' ') : null;
    let entries = 0;
    let tableRows = [];

    this.state.sub_orgs.forEach((org) => {
      let search_check = 0;

      const code = (org.clinician_code) ? org.clinician_code.code.toLowerCase() : '';
      if(search_filter) {
        const search_entry = org.org_name.toLowerCase() + ' ' + code;

        for(let filter of search_filter) {
          search_check = search_entry.search(`${filter.toLowerCase()}`);
          if(search_check === -1) break;
        }
      }

      if(search_check !== -1) {
        tableRows.push(
          <tr key={org.id} className={( (counter++ % 2) === 0) ? "even" : "odd"}>
            <td className="middle">{org.org_name}</td>
            <td className="middle">{code}</td>
            <td className="middle">
              {this.props.superAdmin && (
                <div className="checkbox">
                  <label className="switch">
                    <input name={org.id} id={org.id} type="checkbox" checked={org.is_locked} onChange={onChange} />
                    <span className="slider round"></span>
                  </label>
                </div>
              )}
            </td>
            <td className="middle">
              <button className="btn btn-teal table-btn-edit margin-right-six" onClick={viewOrgClicked} name={org.id}><i className="fa fa-eye"></i>View</button>
              {this.props.superAdmin && (
                <button className="btn btn-light-outline table-btn-edit" onClick={openDeleteSubOrgModal} name={org.id}><i className="fa fa-trash"></i>Delete</button>
              )}
            </td>
          </tr>
        );
        entries++;
      }
    });

    return (
      <React.Fragment>
        <div className="form-header">
          <div className="row vcenter">
            <div className="col-md-4 ">
              <h3 className="title">Sub-accounts</h3>
            </div>
            {this.props.superAdmin && (
              <div className="col-md-8">
                <button className="btn btn-light btn-xs" onClick={() => this.props.newSubOrg()}><i className="fa fa-plus"></i>  Add Sub Account</button>
              </div>
            )}
          </div>
        </div>

        {this.props.showFilter && (
          <TableFilter
            tableType='sub-accounts'
            filterValues={(value) => this.filterValues(value)}
            onSearchSubmit={(search) => this.onSearchSubmit(search)}
            downloadCSV={this.downloadCSV}
          />
        )}

        {this.state.deleteErrorMessage && (
          <div className="alert alert-danger">
            {this.state.deleteErrorMessage}
          </div>
        )}

        <table className="table table-custom responsive no-wrap margin-one">
          <thead>
            <tr>
              <th>Company Name</th>
              <th>Clinician Id</th>
              {this.props.superAdmin &&(
              <th>Lock</th>
              )}
              <th></th>
            </tr>
          </thead>

          <tbody>
            {tableRows}
          </tbody>
        </table>

        {entries > 0 && (
          <TableFooter
            entries={entries}
            entries_per_page={this.state.entries_per_page}
            page={this.state.page}
            page_change={(page) => this.setState({page})}
          />
        )}

        {this.state.showDeleteSubOrgModal && (
          <ModalDeleteSubAccount
            orgKey={this.state.currentSubOrgKey}
            onClose={this.closeDeleteSubOrgModal}
            onClickDelete={(payload) => this.onDeleteSubOrg(payload)}
          />
        )}

      </React.Fragment>
    );
  }
}

export default SubAccountsTable;
