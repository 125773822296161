import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import Select from 'react-select';
import ValidationErrorSummary from '../../../validation-error-summary';
import helpers from '../../../../helpers/index';
import file_api from '../../../../api/file-api';

class ModalEditDocument extends Component {

  constructor(props) {
    super(props);

    // add validation state variable
    let doc = this.props.documentEditing.document;
    doc.validations = null;

    this.state = doc;
    this.onChange = this.onChange.bind(this);
    this.onSaveDocument = this.onSaveDocument.bind(this);
    this.validate = this.validate.bind(this);
  }

  onChange(event) {
    this.setState({[event.target.name]: event.target.value})
  }

  async onSaveDocument(event) {
    // check and make sure the user has chosen the required fields before letting them save a document
    let isValid = this.validate();
    if (!isValid) return;

    // set an docKey to identify the document
    let docKey = this.props.documentEditing.rkey;
    let document = this.state;
    delete document.validations;
    let payload = { docKey, document };

    // send request to update file record
    let response = await file_api.update_user_file_in_db(document.id, document);
    if ('data' in response) {
      this.props.onClickSave(payload);
    }
    this.props.onClose();
  }

  validate() {
    this.setState({ validations: null });

    let options = {
      name: {
        labelName: 'File Name',
        validations: {
          required: true
        }
      },
      type: {
        labelName: 'Type',
        validations: {
          required: true
        }
      }
    };

    let validations = helpers.validateInputs(this.state, options);
    this.setState({ validations: validations });
    return validations.isValid;
  }

  render() {
    let doc_types = [];
    if(this.props.isFamhisSuperAdmin){
      doc_types = [
        { value: 'Invoice', label: 'Invoice' },
        { value: 'Quote', label: 'Quote' },
        { value: 'Contract', label: 'Contract' },
        { value: 'Other', label: 'Other' },
      ];
    }
    else{
      doc_types = [
        { value: 'Lab Results', label: 'Lab Results' },
        { value: 'Radiology / Imaging', label: 'Radiology / Imaging' },
        { value: 'Hospital Records', label: 'Hospital Records' },
        { value: 'Other Docs', label: 'Other Docs' },
      ];
    }

    let chosen_doc_type = null;
    for(let item of doc_types) {
      if(this.state.file_category && this.state.file_category === item.value) {
        chosen_doc_type = item;
      }
    }

    return ReactDOM.createPortal(
      <div onClick={() => this.props.onClose()}
        style={{ display: 'block' }}
        className="modal fade in"
        role="dialog"
        aria-labelledby="edit-doc"
        id="edit-doc">

        <div onClick={(e) => e.stopPropagation()} className="modal-dialog" role="document">
          <div className="modal-content">

            <div className="modal-header">
              <button onClick={() => this.props.onClose()} type="button" className="close" data-dismiss="modal" aria-label="Close">
                <i className="fa fa-close"></i>
              </button>
              <h4 className="modal-title text-white text-capitalize">Edit Document</h4>
            </div>

            <div className="modal-body">
              <ValidationErrorSummary
                validations={this.state.validations}
              />

              <form className="form-horizontal" onSubmit={this.onSubmit}>
                <div className="form-group">
                  <label htmlFor="name" className="control-label col-md-3 col-sm-3 col-xs-12">File Name</label>
                  <div className="col-md-6 col-sm-9 col-xs-12">
                    <input name="custom_filename" id="custom_filename" onChange={this.onChange} value={this.state.custom_filename} type="text" className="form-control normal-input-text" />
                  </div>
                </div>

                <div className="form-group">
                  <label htmlFor="type" className="control-label col-md-3 col-sm-3 col-xs-12">Type</label>
                  <div className="col-md-6 col-sm-9 col-xs-12">
                    <Select
                      name="file_category"
                      id="file_category"
                      onChange={(item) => {
                        (item) ? this.setState({ file_category: item.value }) : this.setState({ file_category: null })
                      }}
                      value={chosen_doc_type}
                      className='react-select-container'
                      classNamePrefix="react-select"
                      isClearable={true}
                      placeholder=""
                      options={doc_types}
                    />
                  </div>
                </div>

                <div className="form-group">
                  <label htmlFor="note" className="control-label col-md-3 col-sm-3 col-xs-12">Note</label>
                  <div className="col-md-6 col-sm-9 col-xs-12">
                    <textarea name="file_note" id="file_note" onChange={this.onChange} value={this.state.file_note} rows="2" className="form-control"></textarea>
                  </div>
                </div>
              </form>
            </div>

            <div className="modal-footer">
              <button
                onClick={() => this.props.onClose()}
                type="button" className="btn btn-light-outline no-margin-right" data-dismiss="modal">Cancel</button>
              <button
                onClick={this.onSaveDocument}
                type="button" className="btn btn-teal">Save</button>
            </div>
          </div>
        </div>
      </div>,
      document.body
    );
  }

}

export default ModalEditDocument;
