import moment from 'moment';
import { radix_bases } from './helper-number-bases';

export const ageOptions = {
    'w': 'w',
    'd': 'd',
    'm': 'm'
}

export const DAYS = 365
export const WEEKS = 52
export const MONTHS = 12


const ageIsString = (ageString) => {
  let age_string = ageString
  if (typeof age_string !== 'string') {
    age_string = String(ageString);
  }
  return age_string
}
export function stringToAge(ageInt, ageKey){
  if(ageInt && ageKey){
    if(ageKey == 'm'){
      return  ageInt/MONTHS
    }else if(ageKey == 'w'){
      return ageInt/WEEKS
    }else if(ageKey == 'd'){
      return ageInt/DAYS
    }
  }else if(ageInt){
    return ageInt
   }else{
    return ''
   }
}

export function yearBirthToAgeString(string_dob){
    let calculatedAge = ''
    let years =  moment().diff(string_dob, "years")
    let months =  moment().diff(string_dob, "months")
    let weeks = moment().diff(string_dob, "weeks")
    let days =  moment().diff(string_dob, "days")    

    if(years >= 1){
      calculatedAge = `${years}` 
    }else if(months >= 1){
      calculatedAge = `${months}m` 
    }else if(weeks >= 1){
      calculatedAge = `${weeks}w` 
    }else if(days >= 1){
      calculatedAge = `${days}d` 
    }
    return calculatedAge
  }


export function updateYear(ageInt, ageKey){
    let currentYear = moment().year()
    let year = null
    if(ageKey ===  'm'){
      let diff = moment().subtract(ageInt, 'months').year()
      year = diff
    }else if(ageKey == 'w'){
      let diff = moment().subtract(ageInt, 'weeks').year()
      year = diff
    }else if(ageKey == 'd'){
      let diff = moment().subtract(ageInt, 'days').year()
      year = diff
    }else{
      year = currentYear - ageInt
    }

    return year
  }

export function ageStringToAgeInt(ageString){
  let age_string = ageIsString(ageString)
  let ageInt = parseInt(age_string, radix_bases.base10)
  let ageOption = age_string.replace(/\d+/g, '').trim();
  let ageKey = null
  for(let key in ageOptions){
    if(ageOption.startsWith(ageOptions[key])){
      ageKey = key
    }
  }
  return stringToAge(ageInt, ageKey)
}

export function ageStringToAgeFloat(ageString){
  if (typeof ageString === 'string' || ageString instanceof String){
   
    let ageOption = ageString.replace(/\d+/g, '').trim();
    let ageInt = parseInt(ageString, radix_bases.base10)
    
    if(ageOption.toLowerCase().startsWith('m')){
      return  ageInt/MONTHS
    }else if(ageOption.toLowerCase().startsWith('w')){
      return ageInt/WEEKS
    }else if(ageOption.toLowerCase().startsWith('d')){
      return ageInt/DAYS
    }

  }else if(ageString){

    return ageString
  }else{
    return ''
   }
}

export function normalizeAgeString(ageString){
  let age_string = ageIsString(ageString)
  let ageInt = parseInt(age_string, radix_bases.base10)
  let ageOption = age_string.replace(/\d+/g, '').trim();
  if(ageOption){
    if(ageOption.toLowerCase().startsWith('m')){
      return `${ageInt}m`
    }else if(ageOption.toLowerCase().startsWith('w')){
      return `${ageInt}w`
    }else if(ageOption.toLowerCase().startsWith('d')){
      return `${ageInt}d`
    }
  }else if(ageInt){
    return ageInt
   }else{
    return ''
   }
}

export function ageStringToYOB(ageString){
  let age_string = ageIsString(ageString)
  let ageInt = parseInt(age_string, radix_bases.base10)
  let ageOption = age_string.replace(/\d+/g, '').trim();
  let ageKey = null
  for(let key in ageOptions){
    if(ageOption.startsWith(ageOptions[key])){
      ageKey = key
    }
  }
  return updateYear(ageInt, ageKey)
}