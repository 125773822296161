import React, { Component } from 'react'
import lodash from 'lodash';
import { cloneDeep } from 'lodash';

import ModalPartnerDetails from '../modal-partner-details';
import ModalAdopted from '../modal-adopted';

import * as helper_family_tree from '../../helpers/helper-family-tree';
import FamilyTreePartner from './partner';
import ButtonAddEdit from '../button-add-edit';

import ModalConfirmDelete from '../modal-confirm-delete';

import helpers from '../../helpers';
import family_api from '../../api/family-api'
import ErrorSummary from '../error-summary';

class FamilyTreeSonDaughter extends Component {

  constructor(props) {
    super(props);
    this.state = {
      errorMessages: [],
      loading: false,

      collapsed: true,
      label: '',
      partners: null,
      sonDaughterRkey: null,
      openModalPartnerCount: false,
      modalPartnerCountHash: new Date().getTime(),

      openModalAdopted: false,
      modalAdoptedHash: new Date().getTime(),

      openModalPartnerDetail: false,
      modalPartnerDetailHash: new Date().getTime(),

      openModalConfirmDelete: false,
      modalConfirmDeleteHash: new Date().getTime(),
    };

    this.updateFirstName = lodash.debounce(this.updateFirstName, 250)

  }

  componentDidUpdate(prevProps) {
    this.loadPartnersFromRedux();
  }

  componentDidMount() {
    this.setLabel();
    this.setState({ sonDaughterRkey: this.props.son_daughter.rkey });
    // this.populatePartnersFromAPI()
  }

  // async populatePartnersFromAPI() {
  //   try {
  //     let spouse_id = this.props.son_daughter.id
  //     if(spouse_id == null) return

  //     // Clear redux before repopulating
  //     this.props.dispatch(patient_actions.delete_partner({ownerRkey: this.props.son_daughter.rkey}))

  //     // Fetch Parter from API
  //     let partners = await family_api.get_member_memberid_partners(spouse_id)
  //     for(var partner of partners) {
  //       // Save to Redux
  //       var partner_from_model = model.createPartner()
  //       var partner_data = Object.assign({}, partner_from_model, partner)

  //       // Save to Redux
  //       // partner_data.rkey = 'member_' + partner_data.id
  //       helper_family_tree.savePartnerToRedux(this.props.dispatch, this.props.son_daughter.rkey, partner_data)
  //       helper_family_tree.saveProfileToRedux(this.props.dispatch, partner_data)
  //     }
  //   } catch (error) {
  //     helpers.logger(error)
  //   }
  // }

  loadPartnersFromRedux() {
    if (this.state.sonDaughterRkey === null) return;

    if (!(this.state.sonDaughterRkey in this.props.patient.partners)) return;

    let partners = this.props.patient.partners[this.state.sonDaughterRkey];
    for(var partner of partners) {
      let relKey = partner.rkey
      if(relKey.includes('-fake')) partners.pop(partner)
    }
    if (!lodash.isEqual(this.state.partners, partners)) {
      this.setState({ partners });
    }
  }

  setLabel() {
    if (this.props.son_daughter.gender_identity) {
      if (this.props.son_daughter.gender_identity == "male") {
        var label = "Son #"
      } else if (this.props.son_daughter.gender_identity == "female") {
        var label = "Daughter #"
      } else if (this.props.son_daughter.gender_identity == "non-binary") {
        var label = "Child #"
      }
    } else {
      if(this.props.type === 'unknown') {
        var label = "Child #"
      } else if (this.props.type === 'son') {
        var label = 'Son #'
      } else {
        var label = 'Daughter #'
      }
    }
    label = label + this.props.ordinal
    // check if gender identity and gender are different and assign the appropriate label
    if (this.props.son_daughter.gender_identity) {
      if (this.props.son_daughter.gender_identity == "male") {
        const g = this.props.son_daughter.gender;
        if (g && g.toLowerCase() == "f") {
          label = label + ' (AFAB)'
        } else if (g && g.toLowerCase() == "u") {
          label = label + ' (AUAB)'
        }
      } else if (this.props.son_daughter.gender_identity == "female") {
        const g = this.props.son_daughter.gender;
        if (g && g.toLowerCase() == "m") {
          label = label + ' (AMAB)'
        } else if (g && g.toLowerCase() == "u") {
          label = label + ' (AUAB)'
        }
      } else if (this.props.son_daughter.gender_identity == "non-binary") {
        const g = this.props.son_daughter.gender;
        if (g && g.toLowerCase() == "m") {
          label = label + ' (AMAB)'
        } else if (g && g.toLowerCase() == "f") {
          label = label + ' (AFAB)'
        }
      }
    }
    if (!lodash.isEqual(this.state.label, label)) {
      this.setState({ label });
    }
  }

  async onChangeName(first_name) {
    try {

      let profile = { rkey: this.props.son_daughter.rkey, first_name }
      helper_family_tree.saveProfileToRedux(this.props.dispatch, profile)
      helper_family_tree.saveSonDaughterDetailToRedux(this.props.dispatch, this.props.partnerRkey, profile)

      this.updateFirstName(first_name)
    } catch (error) {
      helpers.logger(error)
    }
  }

  async updateFirstName(first_name) {
    try {
      this.setState({errorMessages: []})

      let member = this.props.son_daughter
      await family_api.patch_member_memberid(member.id, {
        first_name
      })

    } catch (error) {
      this.setState({errorMessages: [error.message]})
    }
  }

  getTotalIndent() {
    return this.props.level + this.props.extra_indent;
  }

  onClickOpenAdopted() {
    this.setState({
      openModalAdopted: true,
      modalAdoptedHash: new Date().getTime()
    });
  }

  onCollapseClick(e) {
    this.setState({ collapsed: !this.state.collapsed });
    this.clearSelection();
  }

  clearSelection() {
    if (window.getSelection) { window.getSelection().removeAllRanges(); }
    else if (document.selection) { document.selection.empty(); }
  }

  showCollapseButton() {
    return (
      this.props.level === 0 &&
      this.state.partners &&
      this.state.partners.length > 0
    );
  }

  hasSiblings() {
    if (this.props.partnerRkey in this.props.patient.sons_daughters) {
      return this.props.patient.sons_daughters[this.props.partnerRkey].length > 1;
    }
    return false;
  }

  hasPartners() {
    try {
      return this.state.partners.length > 0;
    } catch (err) {
      return false;
    }
  }

  onClickOpenModalPartnerDetail() {
    this.setState({
      openModalPartnerDetail: true,
      modalPartnerDetailHash: new Date().getTime()
    });
  }

  onClickModalAdoptedSave(data) {
    helper_family_tree.saveProfileToRedux(this.props.dispatch, data)
  }

  onClickOpenModalConfirmDelete() {
    this.setState({
      openModalConfirmDelete: true,
      modalConfirmDeleteHash: new Date().getTime()
    });
  }

  async onClickDelete() {
    try {

      this.setState({errorMessages: [], loading: true})

      let leftover_id = null

      // Delete twins from API
      if(this.props.son_daughter.twin_id) {
        let {mother, father} = await family_api.get_member_memberid_parents(this.props.son_daughter.id);
        let twin_set = await family_api.get_twin(father.id, mother.id);
        for(let set of twin_set) {
          if(set.twin_id === this.props.son_daughter.twin_id) {
            let twin_length = set.members.length;
            if(twin_length === 2) {
              await family_api.delete_twin_set(set.twin_id);
              for(let member of set.members) {
                if(member.id !== this.props.son_daughter.id) leftover_id = member.id;
              }
            }
          }
        }
      }

      // Handle leftover twins from Redux and Local State
      if(leftover_id) {
        let twin_fix = {twin_id: null, twin_set: null, twin_type: null};
        for(let child of this.props.patient.sons_daughters[this.props.partnerRkey]) {
          if(child.id === leftover_id) {
            // fix son_daughter, save to redux
            let patient = Object.assign({}, cloneDeep(child), twin_fix)
            helper_family_tree.saveSonDaughterDetailToRedux(this.props.dispatch, this.props.partnerRkey, patient)
            helper_family_tree.saveProfileToRedux(this.props.dispatch, patient)
          }
        }
      }

      await this.props.deleteMember(this.props.son_daughter)

      helper_family_tree.deleteChildren(this.props.patient, this.props.dispatch, this.props.partnerRkey, this.props.son_daughter.rkey)
      this.setState({ openModalConfirmDelete: false })

    } catch (error) {
      helpers.logger(error)
      this.setState({errorMessages: [error.message]})
    } finally {
      this.setState({loading: false})
    }
  }

  getFirstName() {

    let profile = this.getProfile();
    if(profile == null) return null;

    return profile.first_name;
  }

  getProfile() {
    let profile = helper_family_tree.getProfileFromRedux(this.props.patient, this.props.son_daughter.rkey)
    return profile;
  }

  getIsAdopted() {
    let profile = this.getProfile();
    if(profile == null) return false;
    return (profile.adopted_in || profile.adopted_out)
  }

  isTwin() {
    let profile = this.getProfile();
    if(profile == null) return false;
    return (profile.twin_id !== null );
  }

  render() {
    let indent = this.getTotalIndent();
    let indent_class="";
    if(indent === 1) {
      indent_class="table-indent";
    } else if (indent === 2) {
      indent_class="table-indent-2-5";
    } else if (indent === 3) {
      indent_class="table-indent-4";
    } else if (indent === 4) {
      indent_class="table-indent-5-5";
    } else if (indent >= 5) {
      indent_class="table-indent-6";
    }
    return (
      <React.Fragment>

        <tr>
          <td>

            {this.state.openModalConfirmDelete &&
              <ModalConfirmDelete
                loading={this.state.loading}
                errorMessages={this.state.errorMessages}
                isOpen={this.state.openModalConfirmDelete}
                onCancel={() => this.setState({ openModalConfirmDelete: false })}
                onOk={() => this.onClickDelete()}
              />
            }

            {this.state.openModalPartnerDetail &&
              <ModalPartnerDetails
                key={this.state.modalPartnerDetailHash}
                ownerRkey={this.props.son_daughter.rkey}
                owner={this.props.son_daughter}
                parent_side={this.props.parent_side}
                isOpen={this.state.openModalPartnerDetail}
                onClose={() => this.setState({ openModalPartnerDetail: false })}
                onClickSave={(partner_count) => {
                  this.setState({ openModalPartnerDetail: false })
                }}
              />
            }

            {this.state.openModalAdopted &&
              <ModalAdopted
                key={this.state.modalAdoptedHash}
                isOpen={this.state.openModalAdopted}
                onClose={() => this.setState({ openModalAdopted: false })}
                onSave={(data) => this.onClickModalAdoptedSave(data)}
                label={this.state.label}
                adopted={this.getProfile()}
              />
            }

          </td>
          <td>

            <span className={indent_class}>{this.state.label}</span>

          </td>
          <td>
            <input
              onChange={(e) => this.onChangeName(e.target.value)}
              value={this.getFirstName()}
              type="text" className="inputreg form-control normal-input-text" />

            <ErrorSummary errorMessages={this.state.errorMessages} transparent={true}/>

          </td>
          <td>

            <ButtonAddEdit
              onClick={() => this.onClickOpenModalPartnerDetail()}
              type={ this.hasPartners() ? 'edit' : 'add'}
            />

          </td>
          <td>
            {this.hasSiblings() &&
              <button
                onClick={() => this.props.onClickTwin(this.props.son_daughter.id)}
                className={"btn btn-xs table-btn-add btn-" + (this.isTwin() ? 'teal' : 'light')}>
                <i className="fa fa-pencil"></i> Edit</button>
            }
          </td>
          <td>
            <button
              onClick={() => this.onClickOpenAdopted() }
              className={"btn btn-xs table-btn-add btn-" + (this.getIsAdopted() ? 'teal' : 'light')}>
              <i className="fa fa-pencil"></i> Edit</button>
          </td>
          <td>
          </td>

          <td>

            <a
              onClick={() => this.onClickOpenModalConfirmDelete()}
              className="btn table-btn-add btn-xs"><i className="fa fa-trash"></i></a>

          </td>

        </tr>

        {this.state.collapsed && this.state.partners &&
          this.state.partners.map((partner, index) => {
            return <FamilyTreePartner
              key={partner.rkey}
              collapsed={true}
              ownerRkey={this.state.sonDaughterRkey}
              level={this.props.level + 1}
              extra_indent={this.props.extra_indent}
              label={'Partner #' + (index + 1)}
              partner={partner}
              hiddenFromParent={!this.state.collapsed}
              owner={this.props.son_daughter}
              parent_side={this.props.parent_side}
              dispatch={this.props.dispatch}
              patient={this.props.patient}
              deleteMember={this.props.deleteMember}
            />
          })
        }

      </React.Fragment>
    );
  }
}

export default FamilyTreeSonDaughter;
