import React, { Component } from "react";
import ReactDOM from "react-dom";
import { cloneDeep, debounce } from "lodash";
import { fromJS, updateIn } from "immutable";

import TwinItem from "./modal-twins-item";

import helpers from "../helpers";
import family_api from "../api/family-api";
import ErrorSummary from "../components/error-summary";
import * as helper_family_tree from "../helpers/helper-family-tree";
import ActivityIndicator from "../components/activity-indicator";
import settings from "../configs/settings";

class ModalTwins extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      errorMessages: [],
      patient: null,
      label_prefix: "",
      items: [],
      profiles: [],
      members_with_error: [],
      twin_set: 1,
      selected_family_id: null,
      selected_family_profile: null,
      include_proband: false
    };

    this.updateFirstName = debounce(this.updateFirstName, 250)
  }

  async componentDidMount() {
    if ("patient" in this.props) {
      await this.setState({
        patient: this.props.openedFromPedigree ? this.props.patient : this.props.patient.patient,
        profiles: this.props.openedFromPedigree ? this.props.profiles : this.props.patient.profile,
      });
      await this.loadTwinFromAPI();
    }

    switch (this.props.twinFor) {
      case "siblings":
        this.setSiblings();
        break;
      case "uncles_aunts":
        this.setUnclesAunts();
        break;
      case "sons_daughters":
        this.setSonsDaughters();
        break;
      default:
        break;
    }

    // set the selected family member id to the member that was selected to open the modal
    if (this.props.selected_sibling_id) {
      this.setState({selected_family_id: this.props.selected_sibling_id})
    } else if (this.props.selected_uncle_aunt_id) {
      this.setState({selected_family_id: this.props.selected_uncle_aunt_id})
    } else if (this.props.selected_son_daughter_id) {
      this.setState({selected_family_id: this.props.selected_son_daughter_id})
    }

    // loop through family to get the next twin_set #
    let twin_set = 0;
    for (let profile in this.state.profiles) {
      profile = this.state.profiles[profile]
      // set the selected family member to profile that matches
      if (profile.id === this.state.selected_family_id) {
        this.setState({selected_family_profile: profile})
      }
      if (profile.twin_set > twin_set) {
        twin_set = profile.twin_set
      }
    }
    twin_set = twin_set + 1
    this.setState({twin_set: twin_set})

    if (this.state.patient.twin_set) {
      this.setState({include_proband: false})
    }
    if (this.state.selected_family_id === this.state.patient.id) {
      this.setState({include_proband: true})
    }
    if (this.state.selected_family_profile.twin_set === this.state.patient.twin_set) {
      this.setState({include_proband: true})
    }

    if(this.props.twinFor == 'siblings'){
      let people = Object.values(this.state.profiles)
      let all_siblings = people.filter(person => (person.mother_id == this.state.selected_family_profile.mother_id && person.father_id == this.state.selected_family_profile.father_id) || (person.mother_id == this.state.selected_family_profile.father_id && person.father_id == this.state.selected_family_profile.mother_id))
      let is_proband_on_siblings = all_siblings.find(sibling => sibling.id == this.state.patient.id)
      if(is_proband_on_siblings){
        if((this.state.patient.twin_set == null) || (this.state.patient.twin_set === this.state.selected_family_profile.twin_set)){
          this.setState({include_proband: true})
        }
      }
    }
  }

  getProfileById(member_id) {
    let profiles = this.state.profiles;
    for (var rkey in profiles) {
      if (profiles[rkey].id == member_id) return cloneDeep(profiles[rkey]);
    }
    return null;
  }

  async loadTwinFromAPI() {
    try {
      let profiles = cloneDeep(this.state.profiles);

      this.setState({ errorMessages: [], loading: true });

      let twins = await family_api.get_twin(
        this.props.fatherId,
        this.props.motherId
      );

      // Load to  Redux and local state
      let set = 0;
      for (var twin of twins) {
        set = set + 1;
        for (var member of twin.members) {
          let profile = this.getProfileById(member.id);
          if (profile == null) continue;

          profile = Object.assign({}, profile, { twin_id: member.twin_id, twin_set: set, twin_type: member.twin_type });
          helper_family_tree.saveProfileToRedux(this.props.dispatch, profile);
          profiles[profile.rkey] = profile;

          if(this.props.openedFromPedigree){
            this.props.getPedigreeData().setProfile(profile.rkey, profile)
          }

          // console.log(member)
          // var twin_set = settings.twins_set_options.find(
          //   option => option.value == set
          // );
          // var twin_type = settings.twins_type_options.find(option => {
          //   return member.twin_type == null
          //     ? false
          //     : option.label.toLowerCase() == member.twin_type.toLowerCase();
          // });

          // if (typeof twin_type !== "undefined") {
          //   profile = Object.assign({}, profile, { twin_set, twin_type });
          //   helper_family_tree.saveProfileToRedux(this.props.dispatch, profile);
          //   profiles[profile.rkey] = profile;
          // }
        }
      }
      this.setState({ profiles });
    } catch (error) {
      this.setState({ errorMessages: [error.message] });
    } finally {
      this.setState({ loading: false });
    }
  }

  setSiblings() {
    this.setState({ items: this.props.openedFromPedigree ? this.props.siblings : this.props.patient.siblings });
  }

  setUnclesAunts() {
    let label_prefix =
      "parent_side" in this.props ? this.props.parent_side + " " : "";
    this.setState({
      items: this.props.patient.uncles_aunts[this.props.parent_side],
      label_prefix
    });
  }

  setSonsDaughters() {
    let sons_daughters = this.props.patient.sons_daughters[
      this.props.partnerRkey
    ];
    sons_daughters = sons_daughters.filter(item => item.pregnancy === false);
    this.setState({ items: sons_daughters });
  }

  onChange(field, value, rkey) {
    let profiles = cloneDeep(this.state.profiles)
    let profile = profiles[rkey]
    profile[field] = value
    profiles[rkey] = profile
    this.setState({ profiles});
  }

  async onClickSave() {
    try {
      this.setState({ errorMessages: [], loading: true });

      // Save twin to API
      let payload = this.buildTwinPayload();
      let data = await family_api.post_twin_new(payload);

      // Update twin profiles to redux
      this.saveProfilesToRedux(data);

      if (this.props.openedFromPedigree){
        let people = Object.values(this.props.getPedigreeData().profiles)
        if(payload.twins.length > 0){
          for (let twin_set of payload.twins){
            let members = twin_set.members
            let twin_member = people.find(person => person.id == members[0].member_id)
            let pedigree_data_store_twin_members = people.filter(person => person.twin_id == twin_member.twin_id)
            for (let member of members){
              let pedigree_data_profile = this.props.getPedigreeData().getProfile(member.rkey)
              let member_data = this.getTwinFromApiResponse(data, pedigree_data_profile.id)
              pedigree_data_profile.twin_id = (member_data == null) ? null : member_data.twin_id;
              pedigree_data_profile.twin_id_id = (member_data == null) ? null : member_data.twin_id;
              pedigree_data_profile.twin_set = (member_data == null) ? null : member_data.twin_set;
              pedigree_data_profile.twin_type = (member_data == null) ? null : member_data.twin_type
              //update the twin_type on pedigree data store
              await this.props.getPedigreeData().setProfile(pedigree_data_profile.rkey, pedigree_data_profile)
            }
            let removed_members = pedigree_data_store_twin_members.filter((store_member) => !members.find((member) => store_member.id === member.member_id));

            for (let removed of removed_members){
              removed.twin_id = null;
              removed.twin_id_id = null;
              removed.twin_set = null;
              removed.twin_type = null;
              await this.props.getPedigreeData().setProfile(removed.rkey, removed)
            }
          }
        }
        else{
          let pedigree_data_store_twin_members = people.filter(person => person.twin_id && person.mother_id == payload.mother_id && person.father_id == payload.father_id)
          for (let removed of pedigree_data_store_twin_members){
            removed.twin_id = null;
            removed.twin_id_id = null;
            removed.twin_set = null;
            removed.twin_type = null;
            await this.props.getPedigreeData().setProfile(removed.rkey, removed)
          }
        }
      }

      this.setState({profiles: this.props.openedFromPedigree ? this.props.getPedigreeData().profiles : this.props.patient.profile})
      this.props.onClose();
      if (this.props.openedFromPedigree) {
        this.props.reRenderPedigree();
      }
    } catch (error) {
      console.log(error)
      this.setState({ errorMessages: [error.message] });
    } finally {
      this.setState({ loading: false });
    }
  }

  getTwinsProfileRkeys() {
    let profile = null;

    let twinsProfileRkeys = this.state.items.map(item => item.rkey);

    //Append Proband if siblings
    if (this.props.twinFor == "siblings" && (!this.props.openedFromPedigree || (this.props.openedFromPedigree && this.props.proband_on_siblings))) {
      profile = this.state.profiles[this.state.patient.rkey];
      twinsProfileRkeys.push(profile.rkey);
    }

    //Append proband parent if uncle/aunts
    if (this.props.twinFor == "uncles_aunts") {
      let probandParent = this.getProbandParentProfile();
      profile = this.state.profiles[probandParent.rkey];
      twinsProfileRkeys.push(profile.rkey);
    }

    return twinsProfileRkeys;
  }

  buildTwinPayload() {

    var twinsProfileRkeys = this.getTwinsProfileRkeys();

    this.setState({members_with_error: []})
    this.validateRequiredType(twinsProfileRkeys);
    this.validateRequiredSet(twinsProfileRkeys);

    var twins = this.buildTwinsBySet(twinsProfileRkeys);
    this.validateTwinFromSet(twins);

    return {
      father_id: this.props.fatherId,
      mother_id: this.props.motherId,
      family_id: this.props.openedFromPedigree ? this.props.patient.family_id : this.props.patient.patient.family_id,
      twins: twins
    };

  }

  buildTwinsBySet(twinsProfileRkeys) {
    let twins = [];
    //Append the rest of the members
    for (var rkey of twinsProfileRkeys) {
      var profile = this.state.profiles[rkey];
      if(profile.twin_type == null) continue;

      var twin_set = (profile.twin_set == null) ? 1 : profile.twin_set;

      let twinIndex = twins.findIndex(twin => twin.set == twin_set);
      if (twinIndex == -1) {
        // Create new twin set and add the member into it
        twins.push({
          set: twin_set,
          members: [
            {
              rkey: profile.rkey,
              member_id: profile.id,
              twin_type: profile.twin_type.toLowerCase()
            }
          ]
        });
      } else {
        // Add new twin member
        var twinMembers = cloneDeep(twins[twinIndex]);
        twinMembers.members.push({
          rkey: profile.rkey,
          member_id: profile.id,
          twin_type: profile.twin_type.toLowerCase()
        });
        twins[twinIndex] = twinMembers;
      }
    }
    return twins;
  }

  validateTwinFromSet(twins) {
    var members_with_error = []

    for(var twin of twins) {

      // The type is required for 0 or at least 2 or more persons in order to save successfully (cannot have just 1 person with twin type specified).
      if(twin.members.length == 1) {
        members_with_error.push(twin.members[0].rkey)
        this.setState({members_with_error});
        throw new Error ('A twin should have 2 members or more.')
      }

      // If only 2 are in the set, twin type must match
      if(twin.members.length == 2) {
        if(twin.members[0].twin_type != twin.members[1].twin_type) {
          members_with_error.push(twin.members[0].rkey)
          members_with_error.push(twin.members[1].rkey)
          this.setState({members_with_error});
          throw new Error ('A twin of 2 members must be of the same type.')
        }
      }

      // If there are more than 2 in the set and one is identical, at least 2 must be marked as identical
      if(twin.members.length >= 3) {
        var identical_twins = twin.members.filter(m => m.twin_type == 'identical')
        if(identical_twins.length == 1)  {
          members_with_error.push(identical_twins[0].rkey)
          this.setState({members_with_error});
          throw new Error ('An identical twin should have 2 members or more.')
        }
      }

    }
  }

  validateRequiredType(twinsRkeys) {

    // If set is specified and ‘Type’ is missing when clicking Save, then prompt with error message stating ‘Please specify the twin type’.
    var error = false;
    var members_with_error = []
    for (var rkey of twinsRkeys) {
      var profile = this.state.profiles[rkey];
      if (profile.twin_set != null && profile.twin_type == null) {
        error = true;
        members_with_error.push(profile.rkey)
      };
    }

    if(error) {
      this.setState({members_with_error});
      throw new Error('Please specify the twin type');
    }
  }

  validateRequiredSet(twinsRkeys) {

    // If set is not specified assume that all with a type are part of the same set
    var twin_with_set_count = 0
    var twin_with_type_count = 0
    var members_with_unspecified_set = []

    for (var rkey of twinsRkeys) {
      var profile = this.state.profiles[rkey];

      if (profile.twin_set !== null) twin_with_set_count++;
      if (profile.twin_type !== null) twin_with_type_count++;

      if(profile.twin_type !== null && profile.twin_set == null) {
        members_with_unspecified_set.push(profile.rkey)
      }
    }

    if(twin_with_set_count > 0) {
      if(twin_with_set_count != twin_with_type_count) {
        this.setState({members_with_error: members_with_unspecified_set});
        throw new Error ('Please specify the twin set.')
      }
    }

  }

  getTwinFromApiResponse(data, id) {

    if(data.length == 0) return null

    let members = data[0].members

    if(members.length == 0) return null

    let member = members.find(item => item.id == id)

    if(typeof(member) == 'undefined') return null

    return member;

  }

  saveProfilesToRedux(data) {
    var profile = null;
    var member = null;
    //Append Proband if siblings
    if (this.props.twinFor == "siblings") {
      profile = this.state.profiles[this.state.patient.rkey];
      member = this.getTwinFromApiResponse(data, profile.id)
      profile.twin_id = (member == null) ? null : member.twin_id;
      helper_family_tree.saveProfileToRedux(this.props.dispatch, profile);
      helper_family_tree.savePatientToRedux(this.props.dispatch, profile);
    }

    //Append proband parent if uncle/aunts
    if (this.props.twinFor == "uncles_aunts") {
      let probandParent = this.getProbandParentProfile();
      profile = this.state.profiles[probandParent.rkey];
      member = this.getTwinFromApiResponse(data, profile.id)
      profile.twin_id = (member == null) ? null : member.twin_id;
      helper_family_tree.saveProfileToRedux(this.props.dispatch, profile);
    }

    //Save the rest of the members
    for (var item of this.state.items) {
      profile = this.state.profiles[item.rkey];
      member = this.getTwinFromApiResponse(data, profile.id)
      profile.twin_id = (member == null) ? null : member.twin_id;
      helper_family_tree.saveProfileToRedux(this.props.dispatch, profile);

      // Save to sons_daughters, uncles_aunts, siblings
      switch(this.props.twinFor) {
        case 'sons_daughters': {
          helper_family_tree.saveSonDaughterDetailToRedux(this.props.dispatch, this.props.partnerRkey, profile);
          break;
        }
        case 'uncles_aunts': {
          helper_family_tree.saveUncleAuntDetailToRedux(this.props.dispatch, profile.side, profile);
          break;
        }
        case 'siblings': {
          helper_family_tree.saveSiblingDetailToRedux(this.props.dispatch, profile);
          break;
        }
        default: {
          break;
        }
      }
    }
  }

  // onPatientChange(field, value) {
  //   let the_patient = fromJS(this.state.patient);
  //   the_patient = updateIn(the_patient, [field], _ => value);
  //   this.setState({ patient: the_patient.toJS() });
  // }

  getProbandParentProfile() {
    let rkey = "";
    let proband = this.props.openedFromPedigree ? this.props.patient : this.props.patient.patient
    if (this.props.parent_side === "paternal") {
      rkey = proband.father.rkey;
    } else {
      rkey = proband.mother.rkey;
    }
    return cloneDeep(this.state.profiles[rkey]);
  }

  onProbandParentChange(field, value) {
    let profile = this.getProbandParentProfile();
    profile[field] = value;

    let profiles = cloneDeep(this.state.profiles);
    profiles[profile.rkey] = profile;
    this.setState({ profiles });

    // let the_patient = fromJS(this.state.patient);
    // let father_mother = this.props.parent_side === 'paternal' ? 'father' : 'mother';
    // the_patient = updateIn(the_patient, [father_mother, field], _ => value);
    // this.setState({ patient: the_patient.toJS() });
  }

  async onChangeName(first_name, rkey, member_id) {
    try {

      let profile = { rkey, first_name }
      helper_family_tree.saveProfileToRedux(this.props.dispatch, profile)
      if (this.props.openedFromPedigree){
        let pedigree_data_profile = this.props.getPedigreeData().getProfile(profile.rkey)
        pedigree_data_profile.first_name = first_name
        this.props.getPedigreeData().setProfile(pedigree_data_profile.rkey, pedigree_data_profile)
      }
      this.onChange('first_name', first_name, rkey);

      this.updateFirstName(first_name, member_id)
    } catch (error) {
      helpers.logger(error)
    }
  }

  async updateFirstName(first_name, member_id) {
    try {
      this.setState({errorMessages: []})
      await family_api.patch_member_memberid(member_id, {
        first_name
      })

    } catch (error) {
      this.setState({errorMessages: [error.message]})
    }
  }

  getTwinItems(gender) {
    let all_profiles = this.state.profiles;
    var pos = 0;
    let self = this;
    let twin_items = this.state.items.map((item, index) => {
      if (gender.toLowerCase() === "m" || gender.toLowerCase() === "f"){
        if(item.gender){
          if (item.gender.toLowerCase() !== gender.toLowerCase()) return null;
        }
        else{
          if (gender.toLowerCase() !== "u" && gender !== null) return null;
        }
      }
      else{
        // take into account that gender could be null
        if(item.gender){
          if (item.gender.toLowerCase() !== "u" && item.gender !== null) return null;
        }
      }

      pos+=1;

      let label_suffix = gender.toLowerCase() === "m" ? self.props.maleLabel : gender.toLowerCase() === 'f' ? self.props.femaleLabel : self.props.otherLabel

      let profile = all_profiles[item.rkey];
      // if selected member is already a twin
      let selected_family = this.state.selected_family_profile
      if (selected_family) {
        if (selected_family.twin_set) {
          if ((profile.twin_set == null) || (profile.twin_set === selected_family.twin_set)) {
            return (
              <TwinItem
                key={"F-" + index}
                label={ `${self.state.label_prefix}  ${label_suffix} #${pos}`}
                profile={profile}
                onChange={(field, value) => self.onChange(field, value, item.rkey)}
                onChangeName={(first_name) =>
                  self.onChangeName(first_name, item.rkey, item.id)
                }
                hasError={this.state.members_with_error.includes(profile.rkey)}
                twin_set={selected_family.twin_set}
              />
            );
          }
        } else { // if selected member is not a twin
          if ((profile.twin_set == null) || (profile.twin_set == this.state.twin_set)) {
            return (
              <TwinItem
                key={"F-" + index}
                label={ `${self.state.label_prefix}  ${label_suffix} #${pos}`}
                profile={profile}
                onChange={(field, value) => self.onChange(field, value, item.rkey)}
                onChangeName={(first_name) =>
                  self.onChangeName(first_name, item.rkey, item.id)
                }
                hasError={this.state.members_with_error.includes(profile.rkey)}
                twin_set={this.state.twin_set}
              />
            );
          }
        }
      }
    });

    return twin_items;
  }

  render() {
    let proband_parent_profile = this.getProbandParentProfile();
    return ReactDOM.createPortal(
        <div
          role="dialog"
          id="modal-twins"
          // onClick={() => this.props.onClose()}
          style={{ display: "block", zIndex: 9999 }}
          className="modal fade in"
        >
          <div
            className="modal-dialog"
            role="document"
            onClick={e => e.stopPropagation()}
          >
            <div className="modal-content">
              <div className="modal-header">
                <button
                  onClick={() => this.props.onClose()}
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="fa fa-close" />
                </button>
                <h4 className="modal-title text-white text-capitalize">Twins</h4>
              </div>
              <div className={"modal-body modal-twin__modal-body " + (this.state.errorMessages.length > 0 ? 'modal-body__with-error': '')  }>

                <ErrorSummary errorMessages={this.state.errorMessages} />

                <div className="modal-twin__container">
                  <div className="twin-select-column">
                    <label>
                      Twin
                    </label>
                  </div>
                {this.state.patient && this.props.twinFor === "siblings" && this.state.include_proband && this.props.proband_on_siblings && (

                  <TwinItem
                    label='Patient'
                    profile={this.state.profiles[this.state.patient.rkey]}
                    onChange={(field, value) => this.onChange(field, value, this.state.patient.rkey)}
                    onChangeName={(first_name) =>
                      this.onChangeName(first_name, this.state.patient.rkey, this.state.patient.id)
                    }
                    hasError={this.state.members_with_error.includes(this.state.patient.rkey)}
                    twin_set={(this.state.patient.twin_set == null) || (this.state.patient.twin_set === this.state.selected_family_profile.twin_set) ? this.state.selected_family_profile.twin_set || this.state.twin_set : this.state.twin_set}
                  />
                )}

                {this.state.patient &&
                  this.props.twinFor === "uncles_aunts" && (

                    <TwinItem
                      label={this.props.parent_side === 'paternal' ? 'Father' : 'Mother' }
                      profile={proband_parent_profile}
                      onChange={(field, value) =>
                        this.onProbandParentChange(field, value)
                      }
                      onChangeName={(first_name) => {
                        this.onChangeName(first_name, proband_parent_profile.rkey, proband_parent_profile.id)
                      }}
                      hasError={this.state.members_with_error.includes(proband_parent_profile.rkey)}
                      twin_set={this.state.twin_set}
                    />

                  )}

                <div className="row twins-modal">
                  <div className="col-md-5">
                    <h3>
                      {this.state.label_prefix}
                      {this.props.maleLabel}S
                    </h3>
                  </div>
                </div>

                {this.getTwinItems("M")}

                <div className="row twins-modal">
                  <div className="col-md-5">
                    <h3>
                      {this.state.label_prefix}
                      {this.props.femaleLabel}S
                    </h3>
                  </div>
                </div>

                {this.getTwinItems("F")}

                <div className="row twins-modal">
                  <div className="col-md-5">
                    <h3>
                      {this.state.label_prefix}
                      {this.props.otherLabel == 'Child' ? 'Children' : `${this.props.otherLabel}S`}
                    </h3>
                  </div>
                </div>

                {this.getTwinItems("U")}

                </div>

              </div>
              <div className="modal-footer">
                <button onClick={() => this.props.onClose()} className="btn btn-light-outline no-margin-right">
                  Cancel
                </button>
                <button
                  onClick={() => this.onClickSave()}
                  className="btn btn-dark"
                >
                  Save
                </button>
                <ActivityIndicator modal={true} loading={this.state.loading} />
              </div>
            </div>
          </div>
        </div>
      ,document.body);
  }
}

export default ModalTwins;
