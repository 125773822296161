import React, { Component } from 'react';

import FamilyFatherMother from './father-mother';
import FamilyTreeProbandUnclesAunts from './proband-uncles-aunts';

import { log_error } from '../../helpers/helper-error';
import * as helper_family_tree from '../../helpers/helper-family-tree';

import family_api from '../../api/family-api';

class FamilyGrandparentsExtension extends Component {

  constructor(props) {
    super(props);
    this.state = {
      first_name: null,
      errorMessages: []
    };
  }

  componentDidMount() {
    let first_name = this.getFirstName();
    if(first_name == null) return;
    if(this.state.first_name != null) return;
    this.setState({first_name})
  }

  getFirstName() {

    let profile = this.getProfile();
    if(profile == null) return null;

    return profile.first_name;
  }

  getProfile() {
    if(!this.props.member) return null;
    return helper_family_tree.getProfileFromRedux(this.props.patient, this.props.member.rkey);
  }


  async onChangeName(first_name) {
    try {

      this.setState(first_name);
      this.updateFirstName(first_name);

      let profile = { rkey: this.props.member.rkey, first_name };
      helper_family_tree.saveProfileToRedux(this.props.dispatch, profile);

    } catch (error) {
      log_error(this.constructor.name, error);
    }
  }

  async updateFirstName(first_name) {
    try {

      this.setState({errorMessages: []});
      await family_api.patch_member_memberid(this.props.member.id, { first_name });

    } catch (error) {
      this.setState({errorMessages: [error.message] });
    }
  }

  onCollapseClick() {

    this.setState({ collapsed: !this.state.collapsed });
    this.clearSelection();

  }

  clearSelection() {

    if (window.getSelection) { window.getSelection().removeAllRanges(); }
    else if (document.selection) { document.selection.empty(); }

  }

  render() {
    if (!this.props.member) return (<></>)

    let parents_label = " ";
    if(this.props.level === 3) {
      parents_label += "Great ";
    } else if (this.props.level > 3) {
      for(let index = 3; index <= this.props.level; index++) parents_label += "Gr ";
    }
    parents_label += this.props.member.gender === 'f' ? "Grandmother" : "Grandfather";

    const mother_rkey = "apimem-" + this.props.member.mother_id;
    const father_rkey = "apimem-" + this.props.member.father_id;

    let uncles_aunts = [];
    if(this.props.patient.sons_daughters[mother_rkey]) uncles_aunts = [...this.props.patient.sons_daughters[mother_rkey]];
    if(this.props.patient.sons_daughters[father_rkey]) uncles_aunts = [...uncles_aunts, ...this.props.patient.sons_daughters[father_rkey]];
    uncles_aunts = uncles_aunts.filter(person => person.id !== this.props.member.id);

    let half_uncles_aunts = [];

    let father_other_partners = []
    let mother_other_partners = []

    if (this.props.patient.partners[father_rkey]) father_other_partners = this.props.patient.partners[father_rkey].filter(partner => partner.id !== this.props.member.mother_id)
    if (this.props.patient.partners[mother_rkey]) mother_other_partners = this.props.patient.partners[mother_rkey].filter(partner => partner.id !== this.props.member.father_id)

    let people = Object.values(this.props.patient.profile)

    for(let partner of father_other_partners){
      let half_uncle_aunt_one_partner = people.filter(person => person.mother_id === partner.id && person.father_id === this.props.member.father_id)
      half_uncles_aunts = half_uncles_aunts.concat(half_uncle_aunt_one_partner)
    }

    for(let partner of mother_other_partners){
      let half_uncle_aunt_one_partner = people.filter(person => person.mother_id === this.props.member.mother_id && person.father_id === partner.id)
      half_uncles_aunts = half_uncles_aunts.concat(half_uncle_aunt_one_partner)
    }

    for(let uncle_aunt of uncles_aunts){
      uncle_aunt.half = false;
    }

    for(let uncle_aunt of half_uncles_aunts){
      uncle_aunt.half = true;
    }

    uncles_aunts = uncles_aunts.concat(half_uncles_aunts)

    return <React.Fragment>

      <FamilyFatherMother
        label={this.props.side + parents_label}
        for={this.props.side + parents_label}
        father_mother={this.props.member}
        patient={this.props.patient}
        dispatch={this.props.dispatch}
        deleteMember={this.props.deleteMember}
      />

      <FamilyTreeProbandUnclesAunts
        parent_side={this.props.side}
        uncles_aunts={uncles_aunts}
        level={this.props.level}
        patient={this.props.patient}
        dispatch={this.props.dispatch}
        deleteMember={this.props.deleteMember}
      />

      {this.props.member.mother_id && <FamilyGrandparentsExtension
        side={this.props.side}
        level={this.props.level+1}
        member={this.props.patient.profile[mother_rkey]}
        patient={this.props.patient}
        dispatch={this.props.dispatch}
        deleteMember={this.props.deleteMember}
      />}

      {this.props.member.father_id && <FamilyGrandparentsExtension
        side={this.props.side}
        level={this.props.level+1}
        member={this.props.patient.profile[father_rkey]}
        patient={this.props.patient}
        dispatch={this.props.dispatch}
        deleteMember={this.props.deleteMember}
      />}

    </React.Fragment>

  }
}

export default FamilyGrandparentsExtension;
