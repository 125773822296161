import React, { Component } from "react";
import Select from 'react-select';

import LabelField from './label-field';
import i18n from 'i18next';

const radio_val = {
  YES: 'yes',
  NO: 'no',
}

class YesOrNoField extends Component {
  constructor(props) {
    super(props);

    this.state = {value: ''};

    this.onChangeSelect = this.onChangeSelect.bind(this);
  }

  componentDidMount() {
    if (this.props.answer) {
      this.setState({ value: this.props.answer.answer });
    }
  }

  onChangeSelect(value) {
    this.setState({ value: value });
    if (value) {
      const payload = {
        field_type: this.props.masterQuestion.type,
        master_question_id: this.props.masterQuestion.id,
        master_question_choice_id: null,
        answer: value
      };

      this.props.saveCompletedSurveyAnswer(payload);
    } else {
      // delete answer
      if (this.props.answer) {
        this.props.deleteCompletedSurveyAnswer(this.props.answer);
      }
    }
  }

  itemExists(item) {
    if(item === undefined || item === null) {return '';}
    return item.value;
  }

  render() {
    const yes = i18n.t("yes");
    const no = i18n.t("no");

    let choices = [
      { value: radio_val.YES, label: yes },
      { value: radio_val.NO, label: no }
    ];

    let chosen_choice = '';
    if (this.state.value === radio_val.YES) {
      chosen_choice = { value: radio_val.YES, label: yes};
    } else if (this.state.value === radio_val.NO) {
      chosen_choice = { value: radio_val.NO, label: no};
    }

    let column_one = null;
    let column_two = null;
    const field = (
      <Select
        name={this.props.masterQuestion.question_uuid}
        id={this.props.masterQuestion.question_uuid}
        onChange={(item) => this.onChangeSelect(this.itemExists(item))}
        value={chosen_choice}
        className='react-select-container'
        classNamePrefix="react-select"
        isClearable={true}
        placeholder=""
        options={choices}
      />
    );

    if (this.props.masterQuestion.question_label) {

      column_one = (
        <LabelField
          masterQuestion={this.props.masterQuestion}
        />
      );

    }

    column_two = field;

    return (
      <div className="form-group">
        <div className="row">

          <div className="col-md-6">
            {column_one}
          </div>

          <div className="col-md-6">
            {column_two}
          </div>

        </div>
      </div>
    );

  }
}

export default YesOrNoField;
