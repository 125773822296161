import React, { Component } from 'react'
import uniqid from 'uniqid'
import Select from 'react-select'
import { fromJS, updateIn, update } from 'immutable'

// import FamilyPartnersTwinModal from './family-partners-twin-modal'
// import FamilyPartnersAdoptedModal from './family-partners-adopted-modal'


import FamilyTreeSibling from './sibling'

class FamilyTreeProbandSiblings extends Component {

  render() {

    for(let sibling of this.props.proband_siblings) {
      sibling.gender = (sibling.gender === null) ? 'u' : sibling.gender
    }

    return <React.Fragment>

      {
        this.props.proband_siblings.filter(i => i.gender_identity ? i.gender_identity == 'male' : i.gender.toLowerCase() =='m')
        .map((sibling, index) => {
          var label = 'Brother #' + (index + 1)
          if (sibling.gender_identity) {
            if (sibling.gender && sibling.gender.toLowerCase() == 'f') {
              label = label + ' (AFAB)'
            } else if (sibling.gender && sibling.gender.toLowerCase() == 'u') {
              label = label + ' (AUAB)'
            }
          }
          return <FamilyTreeSibling
            key={sibling.rkey}
            collapsed={true}
            label={label}
            sibling={sibling}
            parent_side='both'
            patient={this.props.patient}
            dispatch={this.props.dispatch}
            deleteMember={this.props.deleteMember}
          />
        })
      }

      {
        this.props.proband_siblings.filter(i => i.gender_identity ? i.gender_identity == 'female' : i.gender.toLowerCase() =='f')
        .map((sibling, index) => {
          var label = 'Sister #' + (index + 1)
          if (sibling.gender_identity) {
            if (sibling.gender && sibling.gender.toLowerCase() == 'm') {
              label = label + ' (AMAB)'
            } else if (sibling.gender && sibling.gender.toLowerCase() == 'u') {
              label = label + ' (AUAB)'
            }
          }
          return <FamilyTreeSibling
            key={sibling.rkey}
            collapsed={true}
            label={label}
            sibling={sibling}
            parent_side='both'
            patient={this.props.patient}
            dispatch={this.props.dispatch}
            deleteMember={this.props.deleteMember}
          />
        })
      }

      {
        this.props.proband_siblings.filter(i => i.gender_identity ? i.gender_identity == 'non-binary' : i.gender.toLowerCase() =='u')
        .map((sibling, index) => {
          var label = 'Sibling #' + (index + 1)
          if (sibling.gender_identity) {
            if (sibling.gender && sibling.gender.toLowerCase() == 'f') {
              label = label + ' (AFAB)'
            } else if (sibling.gender && sibling.gender.toLowerCase() == 'm') {
              label = label + ' (AMAB)'
            }
          }
          return <FamilyTreeSibling
            key={sibling.rkey}
            collapsed={true}
            label={label}
            sibling={sibling}
            parent_side='both'
            patient={this.props.patient}
            dispatch={this.props.dispatch}
            deleteMember={this.props.deleteMember}
          />
        })
      }

    </React.Fragment>

  }
}

export default FamilyTreeProbandSiblings
