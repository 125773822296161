import React, { Component } from 'react';
import {Radio, RadioGroup} from 'react-icheck';

const RadioYesNo = (props) => {
  return (
    <RadioGroup
    name={props.name}
    value={props.value}
    className="radio radio-group radio-yes-no"
    onChange={(e, value) => {
      props.onChange(value)
    }} >
      <Radio
        value="y"
        radioClass="radio iradio iradio_flat-green"
        increaseArea="20%"
        label="Yes"
      />
      <Radio
        value="n"
        radioClass="radio iradio iradio_flat-green"
        increaseArea="20%"
        label="No"
      />
    </RadioGroup>
  )
}

export default RadioYesNo